import constants from '@/components/map/style/constants'
import { lineWidth } from '@/components/map/style/util'
import { Feature } from 'ol'
import { Stroke, Style } from 'ol/style'

function pointSize(resolution: number) {
  return Math.ceil(lineWidth(resolution) * 0.8)
}

export function emfStyle(feature: Feature, resolution: number) {
  const backgroundStroke = new Style({
    stroke: new Stroke({
      width: 2 * pointSize(resolution) + 4,
      color: constants.medium.color()
    })
  })

  return [
    backgroundStroke,
    new Style({
      stroke: new Stroke({
        width: pointSize(resolution) + 4,
        color: feature.get('color')
      }),
      zIndex: 1000 + feature.get('EMF_abs')
    })
  ]
}
