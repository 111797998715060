<script lang="ts">
import { TowerType } from '@/model'
import { defineComponent, PropType, toRefs } from 'vue'
import TowerSVG from '@/components/tower/TowerSVG.vue'
import { useTowerTypeStore } from '@/stores/tower-type'
import { useTowerTypePositions } from '@/composables/useTowerTypePositions'

export default defineComponent({
  name: 'TowerTypeImage',
  components: { TowerSVG },
  props: {
    towerType: {
      type: Object as PropType<TowerType>,
      required: true
    }
  },
  setup(props) {
    const { towerType } = toRefs(props)
    const { allPositionsFiltered } = useTowerTypePositions(towerType)
    return {
      towerTypeStore: useTowerTypeStore(),
      allPositionsFiltered
    }
  }
})
</script>

<template>
  <TowerSVG v-bind="$attrs" :wireData="allPositionsFiltered"></TowerSVG>
</template>

<style scoped lang="css"></style>
