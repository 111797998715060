import { HttpClient } from '@/api/useHttpClient'
import { WebSocket } from '@/api/useWebSocket'
import { HSBApi } from '@/api/HSBApiBase'

type HealthResponse = {
  db: 'ok' | string
  dbPool: 'ok' | string
  redis: 'ok' | string
  status: 'ok' | string
  userSessions: number
  workerQueues: 'ok' | string
}

export class HealthApiClass implements HSBApi {
  constructor(public httpClient: HttpClient, public webSocket: WebSocket) {}

  async getHealth(): Promise<HealthResponse> {
    const res = await this.httpClient.get<HealthResponse>('health')
    return res.data
  }
}
