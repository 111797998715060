import { FieldConfigs, NumberFieldConfig, SelectFieldConfig } from '@prionect/ui'
import { ConductorTypeEnum } from '@/model'

const fields: FieldConfigs = {
  name: {
    name: 'name',
    label: 'Name',
    required: true,
    type: 'text'
  },
  type: {
    name: 'type',
    label: 'Typ',
    required: true,
    type: 'select',
    items: Object.values(ConductorTypeEnum)
  } as SelectFieldConfig,
  innerRadius: {
    name: 'innerRadius',
    label: 'Innenradius',
    required: false, // required true when for type: 'tubular'
    type: 'number',
    unit: 'mm'
  },
  muR: {
    name: 'muR',
    label: 'μr',
    required: true,
    type: 'number'
  },
  outerRadius: {
    name: 'outerRadius',
    label: 'Außenradius',
    required: true,
    type: 'number',
    unit: 'mm'
  },
  rDC: {
    name: 'rDC',
    label: 'RDC 20°C ',
    required: true,
    type: 'number',
    unit: 'Ω/km',
    step: 0.0001
  },
  subconductorCount: {
    name: 'subconductorCount',
    label: 'Anzahl Leiter',
    required: false,
    type: 'number',
    min: 1, // when value is 1 -> "Bündelleiter"
    max: 6
  } as NumberFieldConfig,
  subconductorSpacing: {
    name: 'subconductorSpacing',
    label: 'Abstand',
    required: false, // true when count > 1
    type: 'number',
    unit: 'm',
    min: 0,
    step: 0.1
  },
  subconductorAngle: {
    name: 'subconductorAngle',
    label: 'Drehung',
    required: false, // true when count > 1
    type: 'number',
    min: 0,
    max: 359,
    step: 30,
    unit: '°'
  } as NumberFieldConfig
}

export default fields
