<script lang="ts">
import { computed, defineComponent, inject, watch } from 'vue'
import { useTowerTypeStore } from '@/stores/tower-type'
import { useProject } from '@/composables/useProject'
import { useConductorAllocationStore } from '@/stores/conductor-allocation'
import { FieldConfig, SelectItem } from '@prionect/ui'
import { FormContextKey } from 'vee-validate'
import { TowerTypeId } from '@/model'

export default defineComponent({
  name: 'TowerAllocationSelectField',
  setup(_, ctx) {
    // stores
    const { projectId } = useProject()
    const towerTypeStore = useTowerTypeStore()
    const conductorAllocationStore = useConductorAllocationStore()

    const form = inject(FormContextKey)
    const towerTypeField = computed(() => {
      const name = (ctx.attrs as FieldConfig).name
      const typeFieldName = `${name.substring(0, name.lastIndexOf('.'))}.type`
      return form?.getPathState<string>(typeFieldName)
    })
    const towerTypeId = computed<TowerTypeId>(() => {
      return towerTypeField?.value?.value
    })

    /**
     * Reset allocation field when RELATED tower type changed
     */
    watch(towerTypeId, () => {
      form?.setFieldValue((ctx.attrs as FieldConfig).name, null, false)
    })

    return {
      projectId,
      towerTypeStore,
      conductorAllocationStore,
      towerTypeId
    }
  },
  async created() {
    await this.towerTypeStore.ensureLoadedByProject(this.projectId)
    await this.conductorAllocationStore.ensureLoaded(this.projectId)
  },
  computed: {
    towerType() {
      return this.towerTypeStore.findById(this.towerTypeId)
    },
    towerConductorCount() {
      return this.towerType?.conductorPositions.length || 0
    },
    allocationNoDataText() {
      return `keine Leiterzuordnung für diesen Masttyp mit ${this.towerConductorCount} Positionen gefunden`
    },
    conductorAllocations(): SelectItem[] {
      return this.conductorAllocationStore
        .itemsWithConductorCount(this.towerConductorCount || 0)
        .map((type) => ({
          value: type.id,
          label: type.name
        }))
    }
  }
})
</script>

<template>
  <p-field
    v-bind="$attrs"
    :items="conductorAllocations"
    dense
    :disabled="!towerType"
    :placeholder="!towerType ? 'Bitte zuerst Masttyp auswählen' : 'Daten wählen'"
    :no-data-text="allocationNoDataText"
  />
</template>

<style scoped lang="css"></style>
