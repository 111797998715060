/**
 * https://carbondesignsystem.com/guidelines/icons/library/
 */
import {
  ChevronDown,
  ChevronRight,
  ChevronUp,
  Locked,
  Play,
  SkipBack,
  SkipForward,
  ZoomFit,
  ZoomIn,
  ZoomOut
} from '@vicons/carbon'

/**
 * https://ionic.io/ionicons/
 */
import {
  AddOutline,
  CloseOutline,
  CloudDownloadOutline,
  ContractOutline,
  CreateOutline,
  DuplicateOutline,
  EllipsisHorizontal,
  ExpandOutline,
  EyeOutline,
  LogOutOutline,
  Options,
  Person,
  PlayBackOutline,
  RefreshOutline,
  Search,
  SettingsOutline,
  TrashBinOutline,
  WarningOutline
} from '@vicons/ionicons5'

import MediumAdd from '@/icons/custom/medium-add.svg'
import MediumDelete from '@/icons/custom/medium-delete.svg'
import MediumJoin from '@/icons/custom/medium-join.svg'
import MediumSplit from '@/icons/custom/medium-split.svg'
import TowerAdd from '@/icons/custom/tower-add.svg'
import TowerDelete from '@/icons/custom/tower-delete.svg'

export default {
  Add: AddOutline,
  ChevronDown,
  ChevronUp,
  ChevronRight,
  Close: CloseOutline,
  Delete: TrashBinOutline,
  Download: CloudDownloadOutline,
  Duplicate: DuplicateOutline,
  Edit: CreateOutline,
  Ellipsis: EllipsisHorizontal,
  ExitFullscreen: ContractOutline,
  First: SkipBack,
  Fullscreen: ExpandOutline,
  Logout: LogOutOutline,
  Locked,
  MediumAdd,
  MediumDelete,
  MediumJoin,
  MediumSplit,
  Next: Play,
  Last: SkipForward,
  Options: Options,
  Previous: PlayBackOutline,
  User: Person,
  Search: Search,
  Settings: SettingsOutline,
  TowerAdd,
  TowerDelete,
  Warning: WarningOutline,
  Show: EyeOutline,
  Refresh: RefreshOutline,
  ZoomFit,
  ZoomIn,
  ZoomOut
}
