<script lang="ts">
import fieldConfig from '@/config/fields/medium'
import { defineComponent, PropType } from 'vue'
import MediumPipeTypeSelectField from '@/components/medium/MediumPipeTypeSelectField.vue'
import { BaseMedium, Medium, PipeMedium, ProjectId, WireMedium } from '@/model'
import {
  FeaturePropertyMapping,
  MappingMode,
  PipeTypeMappingModeOptions
} from '@/components/medium/MediumMappingTypes'
import { Feature, FeatureCollection, LineString } from 'geojson'
import { v4 } from 'uuid'
import { useProject } from '@/composables/useProject'

export default defineComponent({
  name: 'MediumPropertiesMapped',
  components: { MediumPipeTypeSelectField },
  props: {
    modelValue: {
      type: Array as PropType<Medium[]>,
      required: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    geoJson: {
      type: Object as PropType<FeatureCollection>,
      required: true
    }
  },
  data() {
    return {
      pipeTypeMappingModeSelected: MappingMode.ONE_FOR_ALL as MappingMode.ONE_FOR_ALL | string,
      mappingIndividual: [] as FeaturePropertyMapping[],
      mappingOneForAll: '',
      mediumProperties: {
        height: '',
        pipeType: '',
        rhoE: ''
      },
      MappingMode,
      fieldConfig
    }
  },
  setup() {
    return { project: useProject() }
  },
  emits: ['update:modelValue'],
  watch: {
    /**
     * Depending on selected mapping mode emit different modelValue
     */
    pipeTypeMappingModeSelected() {
      if (this.pipeTypeMappingModeSelected !== MappingMode.ONE_FOR_ALL) {
        this.mappingIndividual = this.getFeaturePropertyMapping(
          this.pipeTypeMappingModeSelected,
          this.geoJson
        )
      }
    },
    media(value) {
      this.$emit('update:modelValue', value)
    }
  },
  computed: {
    isPipe() {
      return this.project.project.value?.mediaType === 'pipe'
    },
    media(): Medium[] {
      const media: Medium[] = []
      const properties = this.mediumProperties
      const mode = this.pipeTypeMappingModeSelected
      const geoJson = this.geoJson
      const mappingOneForAll = this.mappingOneForAll
      const mappingIndividual = this.mappingIndividual
      const propertiesCommon = {
        height: parseInt(properties.height),
        project: this.project.projectId.value
      }

      // one for all mapping
      if (this.isPipe && mode === MappingMode.ONE_FOR_ALL) {
        geoJson.features.forEach((feature, index) => {
          media.push(
            this.makeMedia(
              feature,
              index,
              {
                type: 'pipe',
                pipeType: mappingOneForAll,
                rhoE: parseInt(properties.rhoE)
              },
              propertiesCommon
            )
          )
        })
      }

      // individual mapping
      if (this.isPipe && mode !== MappingMode.ONE_FOR_ALL) {
        let nameIndex = 0
        for (const mapping of mappingIndividual) {
          for (const feature of mapping.features) {
            media.push(
              this.makeMedia(
                feature,
                nameIndex,
                {
                  type: 'pipe',
                  pipeType: mapping.pipeTypeId,
                  rhoE: parseInt(properties.rhoE)
                },
                propertiesCommon
              )
            )
            nameIndex++
          }
        }
      }

      // wire media
      if (!this.isPipe) {
        geoJson.features.forEach((feature, index) => {
          media.push(this.makeMedia(feature, index, { type: 'wire' }, propertiesCommon))
        })
      }
      return media
    },
    /**
     * Extract all available keys from geoJson-feature properties
     */
    geoJsonFeatureProperties(): string[] {
      if (!this.geoJson || !this.isPipe) {
        return []
      }
      const featureProperties = new Set<string>()
      for (const feature of this.geoJson.features) {
        if (feature.properties === null) {
          continue
        }
        for (const propertiesKey in feature.properties) {
          featureProperties.add(propertiesKey)
        }
      }
      return Array.from(featureProperties)
    },
    /**
     * Items for select field
     */
    pipeTypeMappingItems(): PipeTypeMappingModeOptions {
      const options: PipeTypeMappingModeOptions = [
        { label: 'ohne (alle Medien vom gleichen Typ)', value: MappingMode.ONE_FOR_ALL }
      ]
      for (const prop of this.geoJsonFeatureProperties) {
        options.push({ label: prop, value: prop })
      }
      return options
    }
  },
  methods: {
    makeMedia(
      feature: Feature,
      nameIndex: number,
      propertiesType: Omit<PipeMedium, keyof BaseMedium> | Omit<WireMedium, keyof BaseMedium>,
      propertiesCommon: { height: number; project: ProjectId }
    ): Medium {
      return {
        id: v4(),
        ...propertiesCommon,
        ...propertiesType,
        name: `Medium ${nameIndex + 1}`,
        path: (feature.geometry as LineString).coordinates.map((coord) => ({
          x: coord[0],
          y: coord[1]
        }))
      }
    },
    /**
     * Creates mappings of unique values for given key in geoJson-features
     */
    getFeaturePropertyMapping(key: string, geoJson: FeatureCollection): FeaturePropertyMapping[] {
      const mappings: { [key in string]: FeaturePropertyMapping } = {}
      for (const feature of geoJson.features) {
        const featurePropertyValue: string | undefined =
          feature.properties && typeof feature.properties[key] === 'string'
            ? feature.properties[key]
            : undefined
        if (!featurePropertyValue) {
          if ('empty' in mappings) {
            mappings.empty.features.push(feature)
          } else {
            mappings.empty = { featurePropertyValue: '(leer)', pipeTypeId: '', features: [feature] }
          }
          continue
        }
        const exists = mappings[featurePropertyValue]
        if (exists) {
          exists.features.push(feature)
          continue
        }
        mappings[featurePropertyValue] = {
          featurePropertyValue,
          features: [feature],
          pipeTypeId: ''
        }
      }
      return Object.values(mappings)
    }
  }
})
</script>

<template>
  <!-- telecommunication  -->
  <div v-if="!isPipe" v-bind="$attrs">
    <p-field v-model="mediumProperties.height" :dense="dense" v-bind="fieldConfig.height" />
  </div>

  <!-- pipe -->
  <div v-else class="grid grid-cols-3 gap-x-6 gap-y-2" v-bind="$attrs">
    <div class="col-span-2">
      <p-field
        v-model="pipeTypeMappingModeSelected"
        :items="pipeTypeMappingItems"
        label="Feld für Rohrleitungstyp (GeoJSON-Feature-Property)"
        name="_mappingMode"
        required
        type="select"
      ></p-field>

      <!-- case: mapping "one for all"-->
      <div v-if="pipeTypeMappingModeSelected === MappingMode.ONE_FOR_ALL">
        <MediumPipeTypeSelectField v-model="mappingOneForAll" v-bind="fieldConfig.pipeType" />
      </div>

      <!-- case: mapping "individual"-->
      <div v-else class="table-simple table-simple--striped">
        <table class="w-full">
          <thead>
            <tr>
              <th>Wert</th>
              <th></th>
              <th>Rohrleitungstyp</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(mapping, index) in mappingIndividual" :key="mapping.featurePropertyValue">
              <td>{{ mapping.featurePropertyValue }}</td>
              <td class="text-center text-gray-500">
                ({{ mapping.features.length }}
                {{ mapping.features.length === 1 ? 'Medium' : 'Medien' }})
              </td>
              <td>
                <MediumPipeTypeSelectField
                  v-model="mapping.pipeTypeId"
                  :dense="dense"
                  :name="`${index}`"
                  required
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-span-1">
      <p-field v-model="mediumProperties.height" :dense="dense" v-bind="fieldConfig.height" />
      <p-field v-model="mediumProperties.rhoE" :dense="dense" v-bind="fieldConfig.rhoE" />
    </div>
  </div>
</template>
