import { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '/users',
    component: () => import('@/layouts/pages/SplitView.vue'),
    meta: {
      title: 'Nutzer'
    },
    children: [
      {
        path: '',
        name: 'users',
        component: () => import('@/views/user/UserListView.vue')
      },
      {
        path: ':userId',
        name: 'user-edit',
        components: {
          default: () => import('@/views/user/UserListView.vue'),
          detail: () => import('@/views/user/UserForm.vue')
        }
      },
      {
        path: 'create',
        name: 'user-create',
        meta: {
          create: true
        },
        components: {
          default: () => import('@/views/user/UserListView.vue'),
          detail: () => import('@/views/user/UserForm.vue')
        }
      }
    ]
  }
]

export default routes
