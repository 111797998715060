import { RouteRecordRaw } from 'vue-router'

const mapRoutes: RouteRecordRaw[] = [
  {
    path: 'map',
    component: () => import('@/layouts/pages/SplitView.vue'),
    props: {
      overlay: true,
      maxWidth: '38rem',
      class: 'splitview'
    },
    children: [
      {
        path: '',
        name: 'project-map',
        component: () => import('@/views/project/MapView.vue')
      },
      {
        path: 'tower/create',
        name: 'project-map-tower-create',
        meta: {
          create: true,
          returnRoute: { name: 'project-map' }
        },
        components: {
          default: () => import('@/views/project/MapView.vue'),
          detail: () => import('@/views/project/towers/TowerDetail.vue')
        }
      },
      {
        path: 'tower/:id',
        name: 'project-map-tower-edit',
        meta: {
          returnRoute: { name: 'project-map' }
        },
        components: {
          default: () => import('@/views/project/MapView.vue'),
          detail: () => import('@/views/project/towers/TowerDetail.vue')
        }
      },
      {
        path: 'span/:id',
        name: 'project-map-span-edit',
        meta: {
          returnRoute: { name: 'project-map' }
        },
        components: {
          default: () => import('@/views/project/MapView.vue'),
          detail: () => import('@/views/project/spans/SpanDetail.vue')
        }
      },
      {
        path: 'medium/create',
        name: 'project-map-medium-create',
        meta: {
          create: true,
          returnRoute: { name: 'project-map' }
        },
        props: {
          detail: { editPath: false }
        },
        components: {
          default: () => import('@/views/project/MapView.vue'),
          detail: () => import('@/views/project/media/MediumDetail.vue')
        }
      },
      {
        path: 'medium/:id',
        name: 'project-map-medium-edit',
        meta: {
          returnRoute: { name: 'project-map' }
        },
        props: {
          detail: { editPath: false }
        },
        components: {
          default: () => import('@/views/project/MapView.vue'),
          detail: () => import('@/views/project/media/MediumDetail.vue')
        }
      },
      {
        path: 'operational-mode/:id',
        name: 'project-map-operationalmode-edit',
        meta: {
          returnRoute: { name: 'project-map' }
        },
        components: {
          default: () => import('@/views/project/MapView.vue'),
          detail: () => import('@/views/project/operational-modes/OperationalModeDetail.vue')
        }
      }
    ]
  }
]
export default mapRoutes
