<script lang="ts">
import EmfHover from '@/components/map/hover/EmfHover.vue'
import MediumHover from '@/components/map/hover/MediumHover.vue'
import { defineComponent, onMounted, onUnmounted, PropType } from 'vue'
import { Map } from 'ol'
import { useMediumStore } from '@/stores/medium'
import { useTowerStore } from '@/stores/tower'
import { useSpanStore } from '@/stores/span'
import useHoverInformation from '@/components/map/composables/useHoverInformation'
import { useTowerTypeStore } from '@/stores/tower-type'
import { useConductorAllocationStore } from '@/stores/conductor-allocation'
import { useConductorTypeStore } from '@/stores/conductor-type'
import { usePipeTypeStore } from '@/stores/pipe-type'
import { useProject } from '@/composables/useProject'
import SpanHover from '@/components/map/hover/SpanHover.vue'
import TowerHover from '@/components/map/hover/TowerHover.vue'
import LoadFlowHover from '@/components/map/hover/LoadFlowHover.vue'
import { createVirtualRef } from '@/util/helpers'

export default defineComponent({
  name: 'MapHoverInformation',
  components: { LoadFlowHover, EmfHover, MediumHover, TowerHover, SpanHover },
  props: {
    map: {
      required: true,
      type: Object as PropType<Map>
    }
  },

  setup(props) {
    const mediumStore = useMediumStore()
    const towerStore = useTowerStore()
    const spanStore = useSpanStore()
    const { hoveredFeatures } = useHoverInformation()

    const towerTypeStore = useTowerTypeStore()
    const conductorAllocationStore = useConductorAllocationStore()
    const conductorTypeStore = useConductorTypeStore()
    const pipeTypeStore = usePipeTypeStore()

    const { projectId } = useProject()

    const virtualTooltipEl = createVirtualRef()

    onMounted(() => {
      if (projectId.value) {
        mediumStore.ensureLoaded(projectId.value)
        towerStore.ensureLoaded(projectId.value)
        spanStore.ensureLoaded(projectId.value)
        conductorAllocationStore.ensureLoaded(projectId.value)
      }
      towerTypeStore.ensureLoaded()
      conductorTypeStore.ensureLoaded()
      pipeTypeStore.ensureLoaded()

      /**
       * Update Position of tooltip container
       */
      const eventHandle = props.map.on('pointermove', (event) => {
        const elMap = event.map.getTarget()
        if (!(elMap instanceof HTMLElement)) {
          return
        }

        virtualTooltipEl.value.position = DOMRect.fromRect({
          width: 0,
          height: 0,
          x: event.originalEvent.clientX,
          y: event.originalEvent.clientY
        })
      })

      onUnmounted(() => {
        props.map.un('pointermove', eventHandle.listener)
      })
    })

    return {
      hoveredFeatures,
      virtualTooltipEl
    }
  },

  computed: {
    emfFeatures() {
      return this.hoveredFeatures.filter((feature) => feature.get('_type') === 'emf')
    },
    loadFlowFeatures() {
      return this.hoveredFeatures.filter((feature) => feature.get('_type') === 'loadFlow')
    },
    mediumFeatures() {
      return this.hoveredFeatures.filter((feature) => feature.get('_type') === 'medium')
    },
    spanFeatures() {
      return this.hoveredFeatures.filter((feature) => feature.get('_type') === 'span')
    },
    towerFeatures() {
      return this.hoveredFeatures.filter((feature) => feature.get('_type') === 'tower')
    }
  }
})
</script>

<template>
  <el-popover
    :visible="hoveredFeatures.length > 0"
    :teleported="false"
    :virtual-ref="virtualTooltipEl"
    virtual-triggering
    :offset="20"
    transition="none"
    placement="right-start"
    :popper-style="{ maxWidth: '600px' }"
    width="auto"
    popper-class="!p-4 rounded overflow-hidden"
  >
    <EmfHover v-for="(feature, index) in emfFeatures" :key="index" :feature="feature" />
    <LoadFlowHover v-for="(feature, index) in loadFlowFeatures" :key="index" :feature="feature" />
    <SpanHover v-for="(feature, index) in spanFeatures" :key="index" :feature="feature" />
    <TowerHover v-for="(feature, index) in towerFeatures" :key="index" :feature="feature" />
    <MediumHover v-for="(feature, index) in mediumFeatures" :key="index" :feature="feature" />
  </el-popover>
</template>

<style scoped lang="css">
:deep(h3) {
  @apply font-bold mb-2 text-sm;
}

:not(:first-child) :deep(h3) {
  border-top: 1px solid var(--tw-color-gray-200);
  padding-top: 12px;
  margin-top: 12px;
}

:deep(th),
:deep(td),
:deep(.label),
:deep(.value) {
  @apply text-xs py-0.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

:deep(tbody th),
:deep(.label) {
  @apply text-gray-500 align-bottom text-right pr-3 whitespace-nowrap font-normal;
}
:deep(thead th) {
  @apply text-gray-500 align-bottom text-center whitespace-nowrap font-normal;
}

:deep(td),
:deep(.value) {
  @apply font-semibold min-w-[8ch];
}
</style>
