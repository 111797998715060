<script lang="ts">
import TwoColumnSelectList, { TreeItem } from '@/components/common/TwoColumnSelectList.vue'
import { defineComponent, PropType } from 'vue'

export type SelectItem = Omit<TreeItem, 'children'>

export default defineComponent({
  name: 'TypeSelectDialog',
  components: { TwoColumnSelectList },

  props: {
    globalItems: {
      type: Array as PropType<SelectItem[]>,
      default: () => []
    },
    modelValue: {
      type: [Number, String]
    },
    onSubmit: {
      type: Function,
      default: async () => {}
    },
    projectItems: {
      type: Array as PropType<SelectItem[]>,
      default: () => []
    }
  },
  emits: ['close', 'update:modelValue', 'update:show'],

  data: () => ({
    filter: '',
    saving: false,
    selectedId: undefined as undefined | SelectItem['value']
  }),

  computed: {
    treeData(): TreeItem[] {
      return [
        {
          label: 'Projektbibliothek',
          value: 'project',
          children: this.filterItems(this.projectItems)
        },
        {
          label: 'Globale Typbibliothek',
          value: 'global',
          children: this.filterItems(this.globalItems)
        }
      ]
    }
  },

  mounted() {
    if (this.modelValue) {
      this.selectedId = this.modelValue
    }
  },

  watch: {
    modelValue() {
      this.selectedId = this.modelValue
    },
    selectedId() {
      this.$emit('update:modelValue', this.selectedId)
    }
  },

  methods: {
    close() {
      this.$emit('close')
      this.$emit('update:show', false)
    },
    filterItems(items: SelectItem[]) {
      if (this.filter) {
        return items.filter((item) => item.label.toLowerCase().includes(this.filter.toLowerCase()))
      } else {
        return items
      }
    },
    async onSubmitClick() {
      this.saving = true
      try {
        if (typeof this.onSubmit === 'function') {
          await this.onSubmit(this.selectedId)
        }
        this.close()
      } finally {
        this.saving = false
      }
    }
  }
})
</script>

<template>
  <p-dialog v-bind="$attrs" style="max-width: none; min-width: 50vw" @close="close">
    <div class="flex w-full space-x-8 h-[60vh] overflow-hidden w-[50vw] xl:min-w-[70rem]">
      <div class="min-w-[50ch] max-w-[50%]">
        <slot name="list">
          <div class="flex flex-col space-y-2 h-full">
            <p-field
              v-model="filter"
              name="search"
              type="text"
              placeholder="Suchen"
              suffix-icon="SearchIcon"
              dense
            />
            <TwoColumnSelectList
              v-model="selectedId"
              class="flex-1 overflow-y-auto overflow-x-hidden"
              :items="treeData"
            />
          </div>
        </slot>
      </div>
      <div class="hidden xl:block flex-1">
        <slot v-bind="{ selectedId }">
          <div class="text-gray-400 h-full flex-1 flex items-center justify-center">
            <div>Bitte wählen Sie einen Eintrag aus der Liste aus.</div>
          </div>
        </slot>
      </div>
    </div>
    <template #footer="{ close }">
      <p-btn text @click="close">Abbrechen</p-btn>
      <p-btn type="primary" :loading="saving" @click="onSubmitClick">Übernehmen</p-btn>
    </template>
  </p-dialog>
</template>

<style scoped lang="css"></style>
