<script lang="ts">
import ConductorTypeSelectField from '@/components/conductor-type/ConductorTypeSelectField.vue'
import { computed, defineComponent } from 'vue'
import DetailPanel from '@/layouts/pages/DetailPanel.vue'
import { useSystemStore } from '@/stores/system'
import { System, SystemCreate, SystemId } from '@/model'
import { ElMessage } from 'element-plus'
import { useConductorTypeStore } from '@/stores/conductor-type'
import { system } from '@/config/fields'
import { useProject } from '@/composables/useProject'
import { useRoute } from 'vue-router'
import { int } from '@/util'

export default defineComponent({
  name: 'SystemForm',
  components: { ConductorTypeSelectField, DetailPanel },

  setup() {
    const store = useSystemStore()
    const route = useRoute()
    const { projectId } = useProject()

    const id = computed<SystemId>(() => {
      return route.params.id as string
    })

    const item = computed<System | SystemCreate>(() => {
      const storeItem = store.findById(id.value)
      if (storeItem) {
        // Kill object references, otherwise vee-validate mutates object (we don't want that)
        return JSON.parse(JSON.stringify(storeItem))
      }
      return {
        conductorTypes: [],
        project: projectId.value,
        wireCount: 3,
        isolatorLength: 0
      }
    })
    return { conductorTypeStore: useConductorTypeStore(), store, id, item, projectId }
  },

  data: () => ({
    fieldConfig: system,
    maxWireCount: 3,
    conductorTypeFields: [],
    saving: false
  }),

  computed: {
    create(): boolean {
      return !this.id
    }
  },

  mounted() {
    this.conductorTypeStore.ensureLoaded()
    this.conductorTypeStore.ensureLoadedByProject(this.projectId)
  },

  methods: {
    int,
    asSystem(item: any): System {
      return item as System
    },
    async save(values: System) {
      // sometimes, the conductorTypes array contains undefined items
      const data: System = {
        ...values,
        conductorTypes: values.conductorTypes.filter((c) => c !== undefined)
      }
      this.saving = true

      try {
        const savedItem = await this.store.save(data)
        if (this.create) {
          ElMessage.success('System wurde erfolgreich angelegt.')
          this.$router.push({ name: 'project-system-edit', params: { id: savedItem.id } })
        } else {
          ElMessage.success('Daten wurden erfolgreich gespeichert.')
        }
      } finally {
        this.saving = false
      }
    }
  }
})
</script>

<template>
  <DetailPanel
    :title="create ? 'Neues System' : 'System bearbeiten'"
    @close="$router.push({ name: 'project-systems' })"
  >
    <p-form
      v-if="store.loaded"
      :item="item"
      cancelable
      :saving="saving"
      @cancel="$router.push({ name: 'project-systems' })"
      @submit="save"
      v-slot="{ values }"
    >
      <p-field v-bind="fieldConfig.name" />
      <p-field v-bind="fieldConfig.wireCount" />
      <p-field v-bind="fieldConfig.isolatorLength" />

      <p-form-section title="Leitertypen" class="!mt-0">
        <table v-if="asSystem(values).conductorTypes" class="w-full my-4">
          <!-- make sure we definitely handle integers... -->
          <tr v-for="i in int(asSystem(values).wireCount) || 1" :key="i">
            <td class="align-top pt-2 pr-3 font-bold text-gray-500">L{{ i }}</td>
            <td class="pb-2">
              <ConductorTypeSelectField
                :field-config="fieldConfig.conductorTypes"
                :index="i - 1"
                :conductors="asSystem(values).conductorTypes"
              />
            </td>
          </tr>
        </table>
      </p-form-section>
    </p-form>
  </DetailPanel>
</template>

<style scoped lang="css"></style>
