<script lang="ts">
import { defineComponent } from 'vue'
import { useUserStore } from '@/stores/user'
import { UserId } from '@/model'
import EntityTableView from '@/components/common/EntityTableView.vue'

export default defineComponent({
  name: 'UserListView',
  components: { EntityTableView },
  setup() {
    const userStore = useUserStore()
    userStore.load()
    return { userStore }
  },

  computed: {
    loading(): boolean {
      return this.userStore.loading
    }
  },

  methods: {
    async addUser() {
      await this.$router.push({ name: 'user-create' })
    },
    deleteItem(id: UserId) {
      this.userStore.delete(id)
    }
  }
})
</script>

<template>
  <EntityTableView
    create-label="Nutzer hinzufügen"
    :items="userStore.users"
    :loading="userStore.loading"
    title="Nutzerverwaltung"
    :search-properties="['name', 'role', 'email']"
    @create="addUser"
    @edit="$router.push({ name: 'user-edit', params: { userId: $event } })"
    @delete="deleteItem"
  >
    <template #columns>
      <el-table-column prop="name" label="Name" sortable>
        <template #default="scope">
          <div class="font-semibold leading-snug">{{ scope.row.name }}</div>
          <div class="text-sm text-gray-400">{{ scope.row.email }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="role" label="Rolle" sortable>
        <template #default="scope">
          {{ scope.row.role && $t('user.role.' + scope.row.role) }}
        </template>
      </el-table-column>
    </template>
  </EntityTableView>
</template>

<style scoped lang="css"></style>
