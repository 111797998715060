<script lang="ts">
import fieldConfig from '@/config/fields/medium'
import { defineComponent, PropType } from 'vue'
import MediumPipeTypeSelectField from '@/components/medium/MediumPipeTypeSelectField.vue'
import { PipeTypeId } from '@/model'

export default defineComponent({
  name: 'MediumProperties',
  components: { MediumPipeTypeSelectField },
  props: {
    modelValue: {
      type: String as PropType<PipeTypeId>
    },
    dense: {
      type: Boolean,
      default: false
    },
    isPipe: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return { fieldConfig }
  }
})
</script>

<template>
  <div class="grid grid-cols-2 gap-x-6 gap-y-2">
    <p-field :class="{ 'col-span-2': !isPipe }" :dense="dense" v-bind="fieldConfig.height" />
    <template v-if="isPipe">
      <p-field :dense="dense" v-bind="fieldConfig.rhoE" />
      <MediumPipeTypeSelectField
        :model-value="modelValue"
        class="col-span-2"
        v-bind="fieldConfig.pipeType"
      />
    </template>
  </div>
</template>
