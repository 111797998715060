import { z } from 'zod'
import { Container, HSBLibItemsEnum } from '@/config/schemas/hsb-lib/util'

/**
 * https://gridside.gitlab.io/hsb-backend/hsblib_api.html#hsblib.ConducorType
 */
export const HSBLibConductorTypeSchema = z.object({
  objclass: z.literal(HSBLibItemsEnum.ConductorType),
  type: z.union([z.literal(0), z.literal(1)]),
  R_dc20: z.number(),
  mu_r: z.number(),
  inner_radius: z.number(),
  outer_radius: z.number(),
  subconductor_number: z.number(),
  subconductor_spacing: z.number(),
  subconductor_angle: z.number()
})

export type HSBLibConductorType = z.infer<typeof HSBLibConductorTypeSchema>
