import { FieldConfigs } from '@prionect/ui'

const fields: FieldConfigs = {
  name: {
    name: 'name',
    label: 'Name',
    required: 'Bitte geben Sie einen Namen ein.'
  },
  type: {
    name: 'type',
    type: 'select',
    items: [
      { value: 'normal', label: 'operationalMode.type.normal' },
      { value: 'failure', label: 'operationalMode.type.failure' }
    ],
    label: 'Typ',
    required: true
  },
  expectationFactor: {
    name: 'expectationFactor',
    label: 'Erwartungsfaktor',
    type: 'number',
    min: 0,
    max: 1,
    step: 0.1,
    required: true,
    minFractionDigits: 1
  },
  limitVoltage: {
    name: 'limitVoltage',
    label: 'Grenzwert',
    type: 'number',
    min: 0,
    step: 10,
    required: true,
    unit: 'V'
  },
  frequency: {
    name: 'frequency',
    label: 'Frequenz',
    type: 'number',
    required: true,
    unit: 'Hz',
    min: 0
  },
  current: {
    name: '', // defined at runtime
    label: 'Strom',
    type: 'number',
    min: 0,
    required: true,
    unit: 'A'
  },
  angle: {
    name: '', // defined at runtime
    label: 'Phase',
    type: 'number',
    required: true,
    min: -360,
    max: 360,
    step: 30,
    unit: '°'
  }
}

export default fields
