import { AbilityBuilder, createMongoAbility, MongoAbility } from '@casl/ability'
import { Project, User, UserRole } from '@/model'

export type AbilityAction = 'create' | 'read' | 'update' | 'delete' | 'manage'
export type AbilitySubject =
  | 'Library'
  | 'LibraryProject'
  | 'Project'
  | Project
  | 'User'
  | User
  | 'all'
export type AppAbility = MongoAbility<[AbilityAction, AbilitySubject]>

/**
 * Define the role/permission rules for our applications
 *
 * For background concepts, see the CASL documentation at https://casl.js.org/
 * Since MongoDB query is used, you can find all query operators here: https://www.mongodb.com/docs/manual/reference/operator/query/
 */
export function defineRulesFor(user: User) {
  const { can, cannot, rules } = new AbilityBuilder<AppAbility>(createMongoAbility)

  const role = user.role

  // everyone
  can('read', 'Library')

  if (role === UserRole.GUEST || role === UserRole.USER) {
    // Access only own projects
    can(['create', 'read', 'update', 'delete'], 'Project', { users: { $all: [user.id] } })

    // Can only unlock a project which the user locked himself
    cannot('update', 'Project', 'locked')
    can('update', 'Project', 'locked', { lockedBy: user.id })
  }

  // Guest users can update project library if they are assigned/invited to Project
  if (role === UserRole.GUEST) {
    can('update', 'LibraryProject', { 'Project.users': { $all: [user.id] } })
  }

  if (role === UserRole.USER) {
    // Can write components into the global library
    can('update', 'Library')
  }

  if (role === UserRole.ADMIN) {
    // admins have all permissions
    can('manage', 'all')
  }

  return rules
}

export function buildAbilityFor(user?: User) {
  return createMongoAbility(user ? defineRulesFor(user) : [])
}

export const AppAbility = buildAbilityFor()
