import { Map } from 'ol'
import { Interaction } from 'ol/interaction'
import { inject, onBeforeUnmount, onMounted } from 'vue'
import { UseMapInjectKeys } from '@/components/map/composables/useMapInjectKeys'

/**
 * Adds an interaction to a map on mount and removes it on component unmount
 * @param interaction
 */
export default function useMapInteraction(interaction: Interaction) {
  const map = inject(UseMapInjectKeys.map) as Map

  onMounted(() => {
    map.addInteraction(interaction)
  })

  onBeforeUnmount(() => {
    map.removeInteraction(interaction)
  })
}
