<script lang="ts" setup>
import { LogLevelEnum } from '@/model'
import { useCalculationStore } from '@/stores/calculation'
import SectionHeading from '@/components/map/util/SectionHeading.vue'
import { computed, nextTick, ref, watch } from 'vue'

const store = useCalculationStore()
const logEl = ref<HTMLDivElement | undefined>(undefined)

const log = computed(() => store.log)
const logLength = computed(() => log.value.length)

const scrollToEnd = () => {
  logEl.value?.scrollTo({ top: logEl.value?.scrollHeight + 1000 })
}

watch(logLength, () => {
  nextTick(scrollToEnd)
})

watch(logEl, () => {
  nextTick(scrollToEnd)
})

const colors = {
  [LogLevelEnum.CRITICAL]: '#ffdddd',
  [LogLevelEnum.ERROR]: '#ffcccc',
  [LogLevelEnum.WARNING]: '#ffeecc',
  [LogLevelEnum.INFO]: '#f6f6ff',
  [LogLevelEnum.DEBUG]: 'transparent',
  [LogLevelEnum.NOTSET]: 'transparent'
}
</script>

<template>
  <div
    v-if="store.showLog"
    class="flex flex-col p-4 pt-2 bg-white rounded-lg shadow overflow-hidden"
  >
    <SectionHeading class="absolute !mt-0">Berechnungs-Log (HSBlib)</SectionHeading>
    <div
      ref="logEl"
      class="text-xs overflow-y-auto mt-6 flex-1 whitespace-pre font-mono leading-none text-gray-500"
    >
      <div
        v-for="(logItem, i) in store.log"
        :key="i"
        :style="`background-color: ${colors[logItem.level] || 'transparent'}`"
        style="margin-bottom: 2px"
      >
        [{{ logItem.level }}] {{ logItem.data.replaceAll('\r', '\n') }}
      </div>
    </div>
    <CloseIcon
      class="absolute right-2 top-2 0 w-6 h-6 bg-white text-gray-500 hover:text-gray-700 cursor-pointer"
      @click="store.showLog = false"
    />
  </div>
</template>

<style scoped lang="css"></style>
