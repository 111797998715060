<script lang="ts">
import { defineComponent } from 'vue'
import ImportDialog from '@/components/common/ImportDialog.vue'
import { fromHSBLibTower, TowerType, WirePosition, WirePositionLabeled } from '@/model'
import { arrayEquals } from '@/util/helpers'
import { ElTable } from 'element-plus'
import { labelWirePositions } from '@/util'
import TowerSVG from '@/components/tower/TowerSVG.vue'
import { v4 } from 'uuid'
import { useTowerTypeStore } from '@/stores/tower-type'
import { useBulkRequests } from '@/composables/useBulkRequests'
import { PFileInput } from '@prionect/ui'
import FileInputResettable from '@/components/common/FileInputResettable.vue'
import Icons from '@/icons'
import RequestsProgress from '@/components/common/RequestsProgress.vue'
import {
  HSBLibTowerSchema,
  HSBLibTower,
  hasChangingAllocationPositions
} from '@/config/schemas/hsb-lib/Tower'
import {
  conductorPositionsLabeled,
  earthwirePositionsLabeled
} from '@/composables/useTowerTypePositions'

type ParsedTowerData = {
  towerData: HSBLibTower
  towerType: TowerType
  conductorPositionsCount: number
  earthwirePositionsCount: number
  errors: string[]
}

export default defineComponent({
  name: 'TowerTypeImport',
  components: { RequestsProgress, FileInputResettable, TowerSVG, ImportDialog },
  setup() {
    const towerTypeStore = useTowerTypeStore()
    return {
      useBulkRequests: useBulkRequests(towerTypeStore.save)
    }
  },
  data() {
    return {
      showDialog: true,
      fileReader: new FileReader(),
      fileJsonContent: null as ReturnType<typeof JSON.parse>,
      fileJsonContentItemsLength: 0,
      parsedTowerData: [] as ParsedTowerData[],
      parsedTowerDataSelected: [] as ParsedTowerData[],
      previewTitle: '',
      previewSVG: [] as WirePositionLabeled[],
      Icons,
      errors: [] as (string | Error)[]
    }
  },
  /**
   * Binds file reader onload handler
   */
  created() {
    this.fileReader.onload = (ev) => {
      this.handleFileData(ev.target ? ev.target.result : null)
    }
    this.fileReader.onerror = (ev) => {
      this.errors.push(JSON.stringify(ev))
    }
  },
  methods: {
    resetFile() {
      this.$nextTick(() => {
        ;(this.$refs.fileInput as InstanceType<typeof PFileInput>).$el.click()
      })
    },
    tableRef(): InstanceType<typeof ElTable> {
      return this.$refs.elTable as InstanceType<typeof ElTable>
    },
    handleSelectionChange(val: ParsedTowerData[]) {
      this.parsedTowerDataSelected = val
    },
    /**
     * Close dialog and navigate
     */
    closeDialog() {
      this.showDialog = false
      setTimeout(() => {
        this.$router.push({ name: 'towertypes' })
      }, 200)
    },

    /**
     * Performs the import to the backend with progress and errors
     */
    async handleImport() {
      await this.useBulkRequests.doRequests(
        this.parsedTowerDataSelected.map((item) => item.towerType)
      )
      if (!this.useBulkRequests.progress.errors.length) {
        this.closeDialog()
      }
    },
    /**
     * Checks if file is a .json and use FileReader to read it
     */
    onFileChange(file: File): any {
      this.errors = []
      if (!file.name.endsWith('.json')) {
        this.errors.push('Invalid file format. Only .json files are supported.')
        return
      }
      this.fileReader.readAsText(file)
    },

    /**
     * File reader bound function to read tower types from file
     */
    async handleFileData(data: string | ArrayBuffer | null) {
      try {
        // clear previous
        this.errors = []
        this.parsedTowerData = []
        this.parsedTowerDataSelected = []
        this.fileJsonContent = null
        const fileJsonContent: object | any = JSON.parse(data as string)

        // Prevent iterating on null
        if (typeof fileJsonContent !== 'object') {
          this.errors.push('Could not parse JSON: Result is not an object')
          return
        }

        for (const item of Object.values(fileJsonContent)) {
          const res = HSBLibTowerSchema.safeParse(item)

          // only interested in data which fits parse
          if (!res.success) {
            continue
          }
          const towerTypes = this.extractTowerTypes(res.data)
          for (const towerType of towerTypes) {
            this.parsedTowerData.push({
              towerData: { ...(item as HSBLibTower), ...res.data },
              towerType,
              conductorPositionsCount: towerType.conductorPositions.length,
              earthwirePositionsCount: towerType.earthwirePositions.length,
              errors:
                towerType.conductorPositions.length < 1
                  ? [
                      'Anzahl der Leiter muss größer 1 sein!',
                      '(incoming_phase_conductors_positions & outgoing_phase_conductors_positions)'
                    ]
                  : []
            })
          }
        }
        this.tableRef().toggleAllSelection()
        this.fileJsonContent = fileJsonContent
      } catch (error) {
        this.errors.push('Error parsing JSON file:', error as Error | TypeError)
      }
    },

    /**
     * Returns multiple tower type datasets if tower data has changing allocation
     */
    extractTowerTypes(towerData: HSBLibTower): TowerType[] {
      const towerTypeRawIn: TowerType = fromHSBLibTower(towerData, 'in')
      if (hasChangingAllocationPositions(towerData)) {
        const towerTypeRawOut: TowerType = fromHSBLibTower(towerData, 'out')
        towerTypeRawIn.name += ' (ingoing)'
        towerTypeRawOut.name += ' (outgoing)'
        return [towerTypeRawIn, towerTypeRawOut]
      }
      return [towerTypeRawIn]
    },
    previewItem(item: ParsedTowerData) {
      this.previewTitle = `Vorschau Mast "${item.towerData.name}"`
      this.previewSVG = [
        ...earthwirePositionsLabeled(item.towerType),
        ...conductorPositionsLabeled(item.towerType)
      ]
    },
    rowTypeHelper(row: any): ParsedTowerData {
      return row as ParsedTowerData
    },
    rowIsSelectable(row: ParsedTowerData) {
      return row.errors.length === 0
    }
  }
})
</script>

<template>
  <import-dialog v-model="showDialog" title="Masttypen importieren" @close="closeDialog">
    <template #panel>
      <!-- File Input -->
      <file-input-resettable accept=".json" @change="onFileChange" />

      <!-- Data info -->
      <div class="overflow-auto">
        <table v-if="fileJsonContent">
          <tbody>
            <tr>
              <td>Ermittelte Datensätze:</td>
              <th>{{ Object.keys(fileJsonContent).length }}</th>
            </tr>
            <tr>
              <td>davon Masten:</td>
              <th>{{ parsedTowerData.length }}</th>
            </tr>
          </tbody>
        </table>
      </div>
      <TowerSVG :caption="previewTitle" :wire-data="previewSVG" limit-height></TowerSVG>
    </template>

    <template #default>
      <!-- Errors -->
      <table v-if="errors.length" class="bg-error-200 rounded-lg">
        <tbody>
          <tr>
            <th>Es sind fehler aufgetreten:</th>
          </tr>
          <tr v-for="(error, key) in errors" :key="key">
            <td>{{ error }}</td>
          </tr>
        </tbody>
      </table>

      <!-- Data table -->
      <el-table
        v-else
        ref="elTable"
        :data="parsedTowerData"
        border
        height="100%"
        row-key="towerType.id"
        size="small"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="index" />

        <!-- Tower -->
        <el-table-column label="Name Mast " prop="towerData.name" />

        <!-- Tower Type -->
        <el-table-column label="Name Masttyp" min-width="150" prop="towerType.name">
          <template #default="item">
            <el-input v-model="rowTypeHelper(item.row).towerType.name">
              <template #suffix>
                <el-button
                  :disabled="
                    rowTypeHelper(item.row).towerType.name == rowTypeHelper(item.row).towerData.name
                  "
                  circle
                  text
                  type="primary"
                  @click="
                    rowTypeHelper(item.row).towerType.name = rowTypeHelper(item.row).towerData.name
                  "
                >
                  <el-icon>
                    <RefreshIcon></RefreshIcon>
                  </el-icon>
                </el-button>
              </template>
            </el-input>
          </template>
        </el-table-column>

        <!-- Conductor  -->
        <el-table-column label="Anzahl Leiterseile" prop="conductorPositionsCount" />

        <!-- Earthwire -->
        <el-table-column label="Anzahl Erdseile" prop="earthwirePositionsCount" />

        <!-- Select -->
        <el-table-column
          :selectable="rowIsSelectable"
          fixed="right"
          type="selection"
        ></el-table-column>

        <!-- Actions -->
        <el-table-column fixed="right" label="Aktionen">
          <template #default="item">
            <div class="w-100 flex justify-center">
              <el-button
                icon="ShowIcon"
                type="info"
                @click="previewItem(rowTypeHelper(item.row))"
              />

              <el-popover
                v-if="rowTypeHelper(item.row).errors.length"
                :persistent="false"
                placement="left"
                trigger="hover"
                width="auto"
              >
                <template #default>
                  <div class="max-h-60 overflow-auto">
                    <p
                      v-for="(error, index) in rowTypeHelper(item.row).errors"
                      :key="index"
                      class="text-error-500"
                    >
                      {{ error }}
                    </p>
                    <pre>
                      {{ rowTypeHelper(item.row).towerData }}
                    </pre>
                  </div>
                </template>
                <template #reference>
                  <el-button type="danger" icon="WarningIcon" />
                </template>
              </el-popover>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </template>

    <template #actions>
      <RequestsProgress :progress="useBulkRequests.progress" class="flex-grow" />

      <p-btn
        :disabled="!parsedTowerDataSelected.length"
        :loading="useBulkRequests.progress.pending"
        type="primary"
        @click="handleImport"
      >
        {{ parsedTowerDataSelected.length }} Masttypen importieren
      </p-btn>
    </template>
  </import-dialog>
</template>

<style lang="css" scoped></style>
