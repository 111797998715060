<script lang="ts" setup>
import MediumImportWizard from '@/components/medium/import-wizard/MediumImportWizard.vue'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

const show = ref(true)
const router = useRouter()

const onClose = () => {
  show.value = false
  setTimeout(() => {
    router.push({ name: 'project-media' })
  }, 200)
}
</script>

<template>
  <medium-import-wizard v-bind="$attrs" v-model:show="show" @close="onClose" />
</template>
