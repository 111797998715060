import { PipeType, PipeTypeId, ProjectId } from '@/model'
import { HttpClient, PaginatedResponse, useHttpClient } from '@/api/useHttpClient'
import { WebSocket } from '@/api'
import { HSBApi } from '@/api/HSBApiBase'

const client = useHttpClient()
const pathProject = (projectId: ProjectId, pipeTypeId?: PipeTypeId) => {
  return `pipeTypes/project/${projectId}` + (pipeTypeId ? `/${pipeTypeId}` : '')
}
const path = (pipeTypeId?: PipeTypeId) => {
  return `pipeTypes` + (pipeTypeId ? `/${pipeTypeId}` : '')
}

export class PipeTypeApiClass implements HSBApi {
  constructor(public httpClient: HttpClient, public webSocket: WebSocket) {}
  async delete(id: PipeTypeId): Promise<void> {
    await client.delete(path(id))
  }

  async getAll(projectId?: ProjectId): Promise<PipeType[]> {
    const url = projectId ? pathProject(projectId) : path()
    const { data } = await client.get<PaginatedResponse<PipeType>>(url)
    return data.results || []
  }

  async save(pipeType: PipeType): Promise<PipeType> {
    const { data } = await client.put<PipeType>(path(pipeType.id), {
      data: pipeType
    })
    return data
  }
}
