import { ConductorType, ConductorTypeId, ProjectId } from '@/model'
import { HttpClient, PaginatedResponse } from '@/api/useHttpClient'
import { WebSocket } from '@/api/useWebSocket'
import { HSBApi } from '@/api/HSBApiBase'

const pathProject = (projectId: ProjectId, conductorTypeId?: ConductorTypeId) => {
  return `conductorTypes/project/${projectId}` + (conductorTypeId ? `/${conductorTypeId}` : '')
}
const path = (conductorTypeId?: ConductorTypeId) => {
  return `conductorTypes` + (conductorTypeId ? `/${conductorTypeId}` : '')
}

export class ConductorTypeApiClass implements HSBApi {
  constructor(public httpClient: HttpClient, public webSocket: WebSocket) {}
  async delete(id: ConductorTypeId): Promise<void> {
    await this.httpClient.delete(path(id))
  }

  async getAll(projectId?: ProjectId): Promise<ConductorType[]> {
    const url = projectId ? pathProject(projectId) : path()
    const { data } = await this.httpClient.get<PaginatedResponse<ConductorType>>(url)
    return data.results || []
  }

  async save(conductorType: ConductorType): Promise<ConductorType> {
    const { data } = await this.httpClient.put<ConductorType>(path(conductorType.id), {
      data: conductorType
    })
    return data
  }
}
