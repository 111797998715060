<script lang="ts" setup>
import { Feature } from 'ol'
import { GeoJSON } from 'ol/format'
import { Vector as VectorSource } from 'ol/source'
import { storeToRefs } from 'pinia'
import { inject, ref, watch } from 'vue'

import { useCorridorStore } from '@/stores/corridor'
import { LayerId } from '@/components/map'
import { UseMapItems } from '@/components/map/composables/useMap'
import { useProject } from '@/composables/useProject'

const { view } = inject(UseMapInjectKeys.useMap) as UseMapItems

const source = ref<VectorSource>()
const { project } = useProject()
import { corridorStyle } from './corridorStyle'
import { UseMapInjectKeys } from '@/components/map/composables/useMapInjectKeys'
import LayerVector from '@/components/map/layer/LayerVector.vue'

const corridorStore = useCorridorStore()
const { corridorGeoJSON } = storeToRefs(corridorStore)
watch(corridorGeoJSON, updateSource, { immediate: true })

function updateSource() {
  if (!project.value || !corridorGeoJSON.value) {
    return
  }

  const geoJsonOptions = {
    dataProjection: project.value.crs,
    featureProjection: view.value.getProjection()
  }

  // create or update source
  if (!source.value) {
    source.value = new VectorSource({
      features: new GeoJSON(geoJsonOptions).readFeatures(corridorGeoJSON.value) as Feature[]
    })
  } else {
    const features = new GeoJSON(geoJsonOptions).readFeatures(corridorGeoJSON.value) as Feature[]
    source.value.clear()
    source.value.addFeatures(features)
  }
}
</script>

<template>
  <LayerVector
    v-if="source"
    v-bind="$attrs"
    :id="LayerId.CORRIDOR"
    :layer-style="corridorStyle"
    :source="source"
  />
</template>
