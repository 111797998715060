<script lang="ts">
import CoordinateList from '@/components/form/CoordinateList.vue'
import MediumProperties from '@/components/medium/MediumProperties.vue'
import { medium } from '@/config/fields'
import { Medium, MediumId, MediumPoint, PipeMedium, PipeTypeId, TowerTypeId } from '@/model'
import { ElMessage } from 'element-plus'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'MediumForm',
  components: { MediumProperties, CoordinateList },
  emits: ['submit'],

  props: {
    items: {
      type: Array as PropType<Medium[]>,
      default: () => []
    },
    editPath: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    fieldConfig: medium,
    path: [] as MediumPoint[]
  }),

  computed: {
    create(): boolean {
      return !this.id
    },

    id(): MediumId {
      return this.$route.params.id as string
    },

    typeItems() {
      return ['pipe', 'wire'].map((type) => ({
        value: type,
        label: this.$t(`medium.type.${type}`)
      }))
    }
  },

  watch: {
    items: {
      handler() {
        if (this.items.length === 1) {
          this.path = this.items[0].path
        }
      },
      immediate: true
    }
  },

  methods: {
    async onSubmit(items: Medium[]) {
      if (items.length === 1) {
        items[0].path = this.path
      }
      this.$emit('submit', items)
    },
    asMedium(item: any): PipeMedium {
      return item
    }
  }
})
</script>

<template>
  <p-multi-form v-bind="$attrs" :items="items" @submit="onSubmit" keep-values>
    <template #default="{ values, items }">
      <p-field v-bind="fieldConfig.name" />
      <p-field
        class="hidden"
        v-bind="fieldConfig.type"
        :items="typeItems"
        disabled
        title="Der Medientyp entspricht der Einstellung im Projekt und kann nicht geändert werden."
      />

      <p-form-section title="Medium-Eigenschaften">
        <MediumProperties
          :is-pipe="asMedium(values).type === 'pipe'"
          dense
          :model-value="asMedium(values).pipeType"
        />
      </p-form-section>

      <p-form-section v-if="editPath" title="Verlauf">
        <CoordinateList v-if="items.length < 2" ref="coordinateList" v-model="path" :min-rows="2" />
        <div v-else class="text-center text-gray-500 p-8 text-sm">
          Sie können nur den Verlauf eines einzelnen Mediums bearbeiten.
        </div>
      </p-form-section>
    </template>
  </p-multi-form>
</template>
