<script setup lang="ts">
import { formatNumber } from '@/util/helpers'
import { Feature } from 'ol'

import { computed, PropType } from 'vue'

type FeatureCalculationResultProperties = {
  RE: number | null
  id: number | null
  km: number | null
  IDE: number | null
  obj: number | null
  UR_im: number | null
  UR_re: number | null
  color: string | null
  LFNODE: string | null
  UR_abs: number | null
  UR_arg: number | null
  UR_abs_pu: number | null
}
type FeatureCalculationResult = FeatureCalculationResultProperties & Record<string, any>

const props = defineProps({
  feature: {
    type: Object as PropType<Feature>,
    required: true
  }
})

const result = computed((): FeatureCalculationResult => {
  const requiredProps: FeatureCalculationResultProperties = {
    RE: null,
    id: null,
    km: null,
    IDE: null,
    obj: null,
    UR_im: null,
    UR_re: null,
    color: null,
    LFNODE: null,
    UR_abs: null,
    UR_arg: null,
    UR_abs_pu: null
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { _type, _draft, geometry, ...featurePropsFiltered } = props.feature.getProperties()

  return {
    ...requiredProps,
    ...featurePropsFiltered
  }
})
</script>

<template>
  <div>
    <h3>Lastfluss bei {{ formatNumber(result.km, 'km') }}</h3>
    <div class="font-bold text-xl flex items-center !text-base mb-2">
      <div class="w-4 h-4 rounded inline-block" :style="{ backgroundColor: result.color || '' }" />
      <span class="pl-2">{{ formatNumber(result.UR_abs, 'V') }}</span>
    </div>
    <div class="grid" style="grid-template-columns: auto 1fr auto 1fr">
      <template v-for="(value, prop) in result" :key="prop">
        <div class="label capitalize">{{ prop }}</div>
        <div class="value">{{ formatNumber(value) }}</div>
      </template>
    </div>
  </div>
</template>

<style scoped lang="css"></style>
