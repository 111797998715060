<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DetailPanel',

  setup() {
    return {}
  },

  props: {
    title: String
  },

  emits: ['close']
})
</script>

<template>
  <div class="overflow-auto max-h-full" tabindex="0" @keydown.esc="$emit('close')">
    <div class="z-10 flex items-center sticky top-0 py-8 px-10 bg-white">
      <h2 class="flex-1 text-xl text-gray-600 font-medium">
        {{ title }}
      </h2>
      <el-button circle text @click="$emit('close')" class="w-8">
        <el-icon size="32px">
          <CloseIcon />
        </el-icon>
      </el-button>
    </div>
    <div class="px-10 pb-8">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="css"></style>
