import { isRef, Ref, ref } from 'vue'

export default function useFileReader(
  onload: (data: string | ArrayBuffer | null) => any,
  errorBag: Ref<(string | Error)[]>,
  filetype?: string | '.json' | '.csv'
) {
  const fileReader = new FileReader()
  fileReader.onload = (ev) => {
    errorBag.value = []
    onload(ev.target ? ev.target.result : null)
  }
  fileReader.onerror = (ev) => {
    errorBag.value.push(JSON.stringify(ev))
  }

  function onFileChange(file: File): any {
    errorBag.value = []
    if (filetype && !file.name.endsWith('.json')) {
      errorBag.value.push('Invalid file format. Only .json files are supported.')
      return
    }
    fileReader.readAsText(file)
  }

  return { onFileChange }
}
