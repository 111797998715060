<script lang="ts">
import { useProject } from '@/composables/useProject'
import { defineComponent } from 'vue'
import { OperationalModeId } from '@/model'
import { useCalculationStore } from '@/stores/calculation'
import { useOperationalModeStore } from '@/stores/operational-mode'
import { CalculationApi } from '@/api'
import { CalculationStatusValue } from '@/model/calculation'
import { PDialog } from '@prionect/ui'
import { SelectItem } from '@/components/form/Field.vue'

export default defineComponent({
  name: 'CalculationControl',
  components: { PDialog },

  data: () => ({
    selectedOperationalMode: undefined as OperationalModeId | undefined,
    clearingCalculation: false,
    showClearConfirm: false
  }),

  computed: {
    calculationOpMode(): OperationalModeId | undefined {
      return (
        this.calculationStore.calculationStatus?.operationalMode ||
        this.calculationStore.result?.operationalMode
      )
    },

    isProcessing(): boolean {
      return ['PENDING', 'INITIALIZING', 'RUNNING'].includes(this.status || 'INACTIVE')
    },

    operationalModeItems(): SelectItem[] {
      const items = this.operationalModeStore.items
      return items.map((item) => ({
        value: item.id,
        label: item.name
      }))
    },

    isRecalculation(): boolean {
      return (
        !!this.calculationStore.result && this.selectedOperationalMode === this.calculationOpMode
      )
    },

    status(): CalculationStatusValue | undefined {
      return this.calculationStore.calculationStatus?.status
    }
  },

  setup() {
    const calculationStore = useCalculationStore()
    const operationalModeStore = useOperationalModeStore()
    const { projectId } = useProject()

    calculationStore.ensureLoaded(projectId.value)
    operationalModeStore.ensureLoaded(projectId.value)

    return { projectId, calculationStore, operationalModeStore }
  },

  mounted() {
    if (this.calculationOpMode) {
      this.selectedOperationalMode = this.calculationOpMode
    }
  },

  watch: {
    calculationOpMode() {
      if (this.calculationOpMode) {
        this.selectedOperationalMode = this.calculationOpMode
      }
    },
    operationalModeItems() {
      if (this.operationalModeItems[0] && this.selectedOperationalMode === undefined) {
        this.selectedOperationalMode = this.operationalModeItems[0].value
      } else if (this.calculationOpMode) {
        this.selectedOperationalMode = this.calculationOpMode
      }
    }
  },

  methods: {
    async cancel() {
      await CalculationApi.cancel({ project: this.projectId })
      await this.calculationStore.ensureLoaded(this.projectId)
    },

    async clear() {
      this.clearingCalculation = true
      await CalculationApi.clear({ project: this.projectId })
      await this.calculationStore.ensureLoaded(this.projectId)
      this.clearingCalculation = false
    },

    async clearAndStart() {
      await this.clear()
      await this.start()
    },

    onOperationalModeChanged() {
      if (this.$route.name === 'project-map-operationalmode-edit') {
        this.$router.push({
          name: 'project-map-operationalmode-edit',
          params: { id: this.selectedOperationalMode }
        })
      }
    },

    async start() {
      this.calculationStore.showResult = false

      if (!this.selectedOperationalMode) {
        throw new Error('Could not start calculation, no operational mode selected.')
      }

      await this.calculationStore.start({
        project: this.projectId,
        operationalMode: this.selectedOperationalMode
      })
    }
  }
})
</script>

<template>
  <div class="flex items-top">
    <p-field
      class="my-1"
      type="select"
      label="Betriebsfall"
      name="mode"
      :items="operationalModeItems"
      v-model="selectedOperationalMode"
      @update:modelValue="onOperationalModeChanged"
      dense
    />
    <p-btn
      class="mt-[34px] !h-9 ml-2"
      icon
      size="small"
      title="Betriebsfall bearbeiten"
      @click="
        $router.push({
          name: 'project-map-operationalmode-edit',
          params: { id: selectedOperationalMode }
        })
      "
    >
      <el-icon size="20"><EditIcon /></el-icon>
    </p-btn>
  </div>

  <template v-if="isProcessing">
    <div class="my-6">
      <el-progress
        :percentage="100"
        status="success"
        :indeterminate="true"
        :duration="1"
        :show-text="false"
      />
      <div class="text-center mt-2 mb-2 text-sm font-semibold text-gray-500">
        <span v-if="status === 'PENDING'">warten...</span>
        <span v-if="status === 'INITIALIZING'">initialisieren...</span>
        <span v-if="status === 'RUNNING'">berechnen...</span>
      </div>
    </div>
    <p-btn class="w-full" type="warning" @click="cancel">Berechnung abbrechen</p-btn>
  </template>
  <template v-else>
    <p-btn
      :disabled="!selectedOperationalMode"
      type="primary"
      @click="start"
      @click.shift="clearAndStart"
    >
      Berechnung
      <template v-if="isRecalculation">neu</template>
      starten
    </p-btn>
  </template>
  <div class="flex m-2">
    <el-button
      :loading="clearingCalculation"
      text
      size="small"
      class="grow"
      @click="showClearConfirm = true"
    >
      Berechnung zurücksetzen
    </el-button>
  </div>
  <Teleport to="body">
    <p-dialog
      :show="showClearConfirm"
      title="Berechnung zurücksetzen"
      confirm-label="Fortsetzen"
      @confirm="clear"
      @close="showClearConfirm = false"
    >
      Es werden alle Ergebnisse entfernt, HSBlib-Worker angehalten und/oder neu initialisiert.
    </p-dialog>
  </Teleport>
</template>

<style scoped lang="css"></style>
