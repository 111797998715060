<script lang="ts">
import { defineComponent } from 'vue'
import PageTitle from '@/components/common/PageTitle.vue'

export default defineComponent({
  name: 'CenteredPage',
  components: { PageTitle },
  props: {
    title: String,
    maxWidth: {
      type: String,
      default: '80rem'
    }
  }
})
</script>

<template>
  <div class="overflow-auto w-full">
    <div class="px-8 mx-auto my-8" :style="{ maxWidth: maxWidth }">
      <div class="flex items-start justify-between">
        <PageTitle v-if="title">{{ title }}</PageTitle>
        <div>
          <slot name="top-right"></slot>
        </div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="css"></style>
