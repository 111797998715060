import Papa, { ParseError, ParseResult } from 'papaparse'
import { reactive, toRef, unref } from 'vue'
import z from 'zod'

export type ParsedItem<Z> = {
  errors: null | { [key in keyof Z]?: string }
  raw: any
  validated?: Z
}

/**
 * Uses Papaparse and Zod to read a csv and validate the rows to a specific type
 */
export function useCSVParser<Z>(zodSchema: z.ZodType<Z>) {
  const parsingResult = reactive({
    pending: false,
    parsedItems: [] as ParsedItem<Z>[],
    parseErrors: [] as (string | Error | ParseError)[]
  })
  const parsingSettings = reactive({
    /**
     * Papaparse accepted options
     * See: https://www.papaparse.com/docs
     */
    csvDelimiter: '',
    csvDelimiterItems: [
      { label: 'Autodetect (Standard)', value: '' },
      { label: 'Semikolon (;)', value: ';' },
      { label: 'Komma (,)', value: ',' },
      { label: 'Tabstopp (\\t)', value: '\t' }
    ],
    /**
     * To add more:
     * - https://html.spec.whatwg.org/multipage/parsing.html#character-encodings
     * - https://encoding.spec.whatwg.org/#names-and-labels
     */
    csvEncoding: 'UTF-8',
    csvEncodingItems: [
      {
        label: 'Westeuropäisch (ISO-8859-15)',
        value: 'ISO-8859-15'
      },
      {
        label: 'Unicode (UTF-8) (Standard)',
        value: 'UTF-8'
      }
    ]
  })

  /**
   * Read in CSV
   */
  function parseCSV(file: File) {
    parsingResult.pending = true
    parsingResult.parseErrors = []
    parsingResult.parsedItems = []
    Papa.parse<any, File>(file, {
      complete: onCSVParseComplete,
      error: (error) => parsingResult.parseErrors.push(error),
      worker: true,
      encoding: parsingSettings.csvEncoding,
      delimiter: parsingSettings.csvDelimiter,
      dynamicTyping: true,
      skipEmptyLines: true,
      header: true // makes result row to be an object instead of array of strings
    })
  }

  /**
   * Call Zod schema to parse each row
   */
  function onCSVParseComplete(csvResult: ParseResult<Partial<Z>>) {
    if (csvResult.errors.length) {
      parsingResult.parseErrors.push(...csvResult.errors)
    }
    for (const row of csvResult.data) {
      // CSV parser treats empty fields as "null", but because we use "undefined"
      // for empty values we need to transform them for the schema validator
      for (const key in row) {
        if (row[key as keyof typeof row] === null) {
          row[key as keyof typeof row] = undefined
        }
      }
      const res = zodSchema.safeParse(row)
      const parsedItem: ParsedItem<Z> = {
        errors: null,
        raw: row
      }

      if (!res.success) {
        parsedItem.errors = {}
        for (const err of res.error.errors) {
          parsedItem.errors[err.path[0] as keyof Z] = err.message
        }
      } else {
        parsedItem.validated = res.data
      }
      parsingResult.parsedItems.push(unref(toRef(parsedItem)))
    }
    parsingResult.pending = false
  }

  /**
   * Extract the error message for a key of a given item
   */
  function getErrorMessage(item: ParsedItem<Z>, key: keyof Z | string) {
    if (item.errors && key in item.errors) {
      return item.errors[key as keyof Z]
    }
    return ''
  }

  function typeHelper(row: any): ParsedItem<Z> {
    return row as ParsedItem<Z>
  }

  return {
    parseCSV,
    parsingResult,
    parsingSettings,
    getErrorMessage,
    typeHelper
  }
}
