<script lang="ts" setup>
import logo from '@/assets/logo.svg?url'
import backgroundImage from '@/assets/powerline-background.jpg'
import useAppHealth from '@/composables/useAppHealth'

const { healthy, errorMessage } = useAppHealth()
</script>

<template>
  <p-auth-layout
    :appLogo="logo"
    :appTitle="''"
    bg-attribution="Foto: Unsplash / Thomas Despeyroux"
    bg-attribution-link="https://unsplash.com/photos/Dh5pUa2PwrY"
    :bg-image="backgroundImage"
  >
    <template #header>
      <img :src="logo" class="h-24 mx-auto" />
    </template>

    <template #logo>
      <h1 class="text-3xl font-medium text-gray-600 text-center mb-12">
        <span class="font-semibold">HSB</span>
        GUI
      </h1>
    </template>

    <template #footer>
      <div class="text-center">
        <a href="https://gridside.de" target="_blank">© GRIDSIDE Energy Consult GmbH</a>
      </div>
    </template>
    <template #default>
      <template v-if="healthy">
        <slot></slot>
      </template>
      <div v-else class="bg-error-100 text-error-600 p-4 rounded">
        <p class="font-semibold">
          Leider stehen die HSB-Systeme technisch zurzeit nicht zur Verfügung.
        </p>
        <p v-if="errorMessage" class="mt-2 text-sm text-error-400">
          Fehlermeldung: {{ errorMessage }}
        </p>
      </div>
    </template>
  </p-auth-layout>
</template>

<style scoped lang="css"></style>
