<script lang="ts">
import { useProjectStore } from '@/stores/project'
import { FeatureCollection } from 'geojson'
import { defineComponent } from 'vue'
import MediumImportStep from './MediumImportStep.vue'
import MediumSelectFileStep from './SelectMediumFileStep.vue'
import { Medium } from '@/model'
import MediumPropertiesMapped from '@/components/medium/MediumPropertiesMapped.vue'

export default defineComponent({
  name: 'MediumImportWizard',
  components: { MediumPropertiesMapped, MediumImportStep, MediumSelectFileStep },

  data: () => ({
    fileIsValid: false,
    geoJson: null as FeatureCollection | null,
    importFile: undefined as File | undefined,
    media: [] as Medium[]
  }),

  setup() {
    return {
      projectStore: useProjectStore()
    }
  },

  computed: {
    steps() {
      return [
        {
          id: 'file',
          title: 'Schritt 1/3: Importdatei für Medien auswählen',
          nextButtonDisabled: !this.fileIsValid
        },
        {
          id: 'mediumProperties',
          title: 'Schritt 2/3: Medieneigenschaften festlegen'
        },
        { id: 'import', title: 'Schritt 3/3: Import durchführen', nextButtonLabel: 'Schließen' }
      ]
    }
  }
})
</script>

<template>
  <p-wizard :steps="steps" class="!w-[80rem]">
    <template #step:file>
      <MediumSelectFileStep
        v-model="geoJson"
        v-model:file="importFile"
        v-model:isValid="fileIsValid"
      />
    </template>
    <template #step:mediumProperties>
      <MediumPropertiesMapped
        v-if="geoJson"
        v-model="media"
        :geo-json="geoJson"
        class="gap-x-12 gap-y-0 max-w-[80ch] mx-auto"
      />
    </template>
    <template #step:import>
      <MediumImportStep :geoJson="geoJson" :media="media" />
    </template>
  </p-wizard>
</template>

<style lang="css" scoped></style>
