import { LayerId } from '@/components/map'
import { UseMapItems } from '@/components/map/composables/useMap'
import { ContextMenuItem } from '@/components/map/ContextMenu.vue'
import { inject, onMounted, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { TowerId } from '@/model'
import useContextMenu, { CollectorFunction } from '@/components/map/composables/useContextMenu'
import { SourceLayerContext } from '../util'

import { createTowerDraft, deleteTowers } from './functions'

import { UseMapInjectKeys } from '@/components/map/composables/useMapInjectKeys'

export default function useSourceContextMenu(ctx: SourceLayerContext) {
  const { registerCollector, unregisterCollector } = useContextMenu()
  const { showLayer } = inject(UseMapInjectKeys.useMap) as UseMapItems

  const router = useRouter()

  const collectorFunction: CollectorFunction = (coordinate) => {
    const items: ContextMenuItem[] = [
      {
        id: 'towerCreate',
        label: 'Neuer Mast... ',
        handler: () => {
          showLayer(LayerId.SOURCE)
          createTowerDraft({ x: coordinate[0], y: coordinate[1] }, ctx)
          router.push({ name: 'project-map-tower-create' })
        },
        priority: 10,
        icon: 'TowerAdd'
      }
    ]

    const selectedTowers = ctx.selectedTowers.value
    const towerCount = selectedTowers.length

    if (towerCount > 0) {
      items.push({
        id: 'towerDelete',
        label: towerCount === 1 ? 'Mast löschen' : `${towerCount} Masten löschen`,
        icon: 'TowerDelete',
        priority: 101,
        handler: () => {
          const towerIds = selectedTowers
            .filter((feature) => !feature.get('_draft'))
            .map((feature) => feature.getId() as TowerId)
          deleteTowers(towerIds)
        }
      })
    }

    return items
  }

  onMounted(() => registerCollector(collectorFunction))

  onUnmounted(() => unregisterCollector(collectorFunction))
}
