import { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '/library',
    component: () => import('@/views/library/LibraryView.vue'),
    children: [
      {
        path: '',
        name: 'library',
        redirect: { name: 'towertypes' }
      },
      {
        path: 'tower-types',
        component: () => import('@/layouts/pages/SplitView.vue'),
        meta: {
          title: 'Masttypen'
        },
        children: [
          {
            path: '',
            name: 'towertypes',
            components: {
              default: () => import('@/views/library/tower-type/TowerTypeListView.vue')
            }
          },
          {
            path: 'create',
            name: 'towertypes-create',
            meta: { create: true },
            components: {
              default: () => import('@/views/library/tower-type/TowerTypeListView.vue'),
              detail: () => import('@/views/library/tower-type/TowerTypeForm.vue')
            }
          },
          {
            path: 'import',
            name: 'towertypes-import',
            components: {
              default: () => import('@/views/library/tower-type/TowerTypeListView.vue'),
              overlay: () => import('@/views/library/tower-type/TowerTypeImport.vue')
            }
          },
          {
            path: ':id',
            name: 'towertypes-edit',
            components: {
              default: () => import('@/views/library/tower-type/TowerTypeListView.vue'),
              detail: () => import('@/views/library/tower-type/TowerTypeForm.vue')
            }
          }
        ]
      },
      {
        path: 'conductor-types',
        component: () => import('@/layouts/pages/SplitView.vue'),
        meta: {
          title: 'Leitertypen'
        },
        children: [
          {
            path: '',
            name: 'conductortypes',
            components: {
              default: () => import('@/views/library/conductor-type/ConductorTypeListView.vue')
            }
          },
          {
            path: 'create',
            name: 'conductortypes-create',
            meta: { create: true },
            components: {
              default: () => import('@/views/library/conductor-type/ConductorTypeListView.vue'),
              detail: () => import('@/views/library/conductor-type/ConductorTypeForm.vue')
            }
          },
          {
            path: 'import',
            name: 'conductortypes-import',
            components: {
              default: () => import('@/views/library/conductor-type/ConductorTypeListView.vue'),
              overlay: () => import('@/views/library/conductor-type/ConductorTypeImport.vue')
            }
          },
          {
            path: ':id',
            name: 'conductortypes-edit',
            components: {
              default: () => import('@/views/library/conductor-type/ConductorTypeListView.vue'),
              detail: () => import('@/views/library/conductor-type/ConductorTypeForm.vue')
            }
          }
        ]
      },
      {
        path: 'pipe-types',
        component: () => import('@/layouts/pages/SplitView.vue'),
        meta: {
          title: 'Rohrleitungstypen'
        },
        children: [
          {
            path: '',
            name: 'pipetypes',
            components: {
              default: () => import('@/views/library/pipe-type/PipeTypeListView.vue')
            }
          },
          {
            path: 'create',
            name: 'pipetypes-create',
            meta: { create: true },
            components: {
              default: () => import('@/views/library/pipe-type/PipeTypeListView.vue'),
              detail: () => import('@/views/library/pipe-type/PipeTypeForm.vue')
            }
          },
          {
            path: 'import',
            name: 'pipetypes-import',
            components: {
              default: () => import('@/views/library/pipe-type/PipeTypeListView.vue'),
              overlay: () => import('@/views/library/pipe-type/PipeTypeImport.vue')
            }
          },
          {
            path: ':id',
            name: 'pipetypes-edit',
            components: {
              default: () => import('@/views/library/pipe-type/PipeTypeListView.vue'),
              detail: () => import('@/views/library/pipe-type/PipeTypeForm.vue')
            }
          }
        ]
      }
    ]
  }
  /*children: [
      {
        path: '',
        name: 'library',
        //redirect: { name: 'towertypes' },
        component: () => import('@/layouts/pages/SplitView.vue')
      },
      {
        path: 'tower-types',
        component: () => import('@/layouts/pages/SplitView.vue'),
        children: [
          {
            path: '',
            name: 'towertypes',
            components: {
              navigation: () => import('@/components/navigation/LibraryNavigation.vue'),
              default: () => import('@/views/library/tower-type/TowerTypeListView.vue')
            }
          },
          {
            path: 'create',
            name: 'towertypes-create',
            components: {
              navigation: () => import('@/components/navigation/LibraryNavigation.vue'),
              default: () => import('@/views/library/tower-type/TowerTypeListView.vue'),
              right: () => import('@/views/library/tower-type/TowerTypeForm.vue')
            }
          },
          {
            path: ':id',
            name: 'towertypes-edit',
            components: {
              default: () => import('@/views/library/tower-type/TowerTypeListView.vue'),
              right: () => import('@/views/library/tower-type/TowerTypeForm.vue')
            }
          }
        ]
      },
      {
        path: 'conductor-types',
        name: 'conductortypes',
        components: {
          default: () => import('@/views/library/conductor-type/ConductorTypeListView.vue'),
          right: () => import('@/views/library/conductor-type/ConductorTypeForm.vue')
        }
      }
    ]
  }*/
]

export default routes
