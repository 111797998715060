import { uuid } from '.'

export enum UserRole {
  ADMIN = 'admin',
  USER = 'user',
  GUEST = 'guest'
}

export type User = {
  id: uuid
  email?: string
  lastLogin?: Date
  name: string
  provider?: string
  providerId?: string
  role: UserRole
}

export type UserId = User['id']
