<script lang="ts">
import { defineComponent } from 'vue'
import EntityTableView from '@/components/common/EntityTableView.vue'
import { useOperationalModeStore } from '@/stores/operational-mode'
import { ProjectId, SystemId, OperationalModeId, OperationalMode } from '@/model'
import { v4 } from 'uuid'
import { useSystemStore } from '@/stores/system'

export default defineComponent({
  name: 'OperationalModeListView',
  components: { EntityTableView },

  setup() {
    const store = useOperationalModeStore()
    const systemStore = useSystemStore()
    return { store, systemStore }
  },

  computed: {
    projectId(): ProjectId {
      return this.$route.params['projectId'] as string
    },
    searchStrings(): string[] {
      return this.store.items.map((item) => item.name)
    },
    operationalModeId(): OperationalModeId {
      return this.$route.params['id'] as string
    }
  },

  watch: {
    operationalModeId() {
      this.store.selection = this.operationalModeId ? [this.operationalModeId] : []
    }
  },

  mounted() {
    this.store.load(this.projectId)
    this.store.selection = this.operationalModeId ? [this.operationalModeId] : []
    this.systemStore.ensureLoaded(this.projectId)
  },

  methods: {
    formatSystemName(systemId: SystemId): string {
      return this.systemStore.findById(systemId)?.name || ''
    },

    async onCreate() {
      this.store.selection = []
      this.$router.push({ name: 'project-operational-mode-create' })
    },

    async onDelete(id: OperationalModeId) {
      await this.store.delete(id)
      if (id === this.$route.params.id) {
        this.$router.push({ name: 'project-operational-modes' })
      }
    },

    onDuplicate(id: OperationalModeId) {
      const itemToDuplicate = this.store.findById(id)
      if (itemToDuplicate) {
        const newItem = {
          ...itemToDuplicate,
          id: v4(),
          name: itemToDuplicate.name + ' - Kopie'
        }
        this.store.save(newItem)
      }
    },
    asOpMode(row: any): OperationalMode {
      return row
    },
    efString(opMode: OperationalMode): string {
      return opMode.expectationFactor ? this.$n(opMode.expectationFactor) : '-'
    }
  }
})
</script>

<template>
  <EntityTableView
    v-model:selection="store.selection"
    :allow-edit="false"
    allow-duplicate
    create-label="Neuer Betriebsfall"
    confirm-delete-label="Betriebsfall löschen"
    default-sort="name"
    :items="store.items"
    :loading="store.loading"
    :search-strings="searchStrings"
    title="Betriebsfälle"
    @create="onCreate"
    @edit="$router.push({ name: 'project-operational-mode-edit', params: { id: $event } })"
    @delete="onDelete"
    @duplicate="onDuplicate"
  >
    <template #columns>
      <!-- Name -->
      <el-table-column prop="name" label="Name" sortable class-name="font-semibold" />

      <!-- Typ -->
      <el-table-column prop="type" label="Typ" sortable width="200">
        <template #default="{ row }">
          <span v-if="asOpMode(row).type">
            {{ $t(`operationalMode.type.${asOpMode(row).type}`) }}
          </span>
          <template v-if="asOpMode(row).type === 'failure'">
            <span title="Erwartungsfaktor">&nbsp;(EF {{ efString(asOpMode(row)) }})</span>
          </template>
        </template>
      </el-table-column>

      <!-- Frequenz -->
      <el-table-column prop="type" label="Frequenz" sortable width="150">
        <template #default="{ row }">
          <span v-if="asOpMode(row).frequency">{{ $n(asOpMode(row).frequency) }} Hz</span>
        </template>
      </el-table-column>

      <!-- Grenzwert -->
      <el-table-column prop="limitVoltage" label="Grenzwert" sortable width="150">
        <template #default="{ row }">
          <span v-if="asOpMode(row).limitVoltage">{{ $n(asOpMode(row).limitVoltage) }} V</span>
        </template>
      </el-table-column>

      <!-- Betriebszustand -->
      <el-table-column prop="offset" label="Betriebszustand" sortable>
        <template v-slot:default="{ row }">
          <div
            class="grid"
            :style="`grid-template-columns: repeat(${
              Object.keys(asOpMode(row).conductorStates).length
            }, 1fr)`"
          >
            <div
              v-for="systemId in Object.keys(asOpMode(row).conductorStates)"
              :key="systemId"
              class="text-xs"
            >
              <div class="font-medium">{{ formatSystemName(systemId) }}</div>
              <div v-for="n in asOpMode(row).conductorStates[systemId].length" :key="n">
                L{{ n }}: {{ $n(asOpMode(row).conductorStates[systemId][n - 1].current || 0) }} A /
                {{ $n(asOpMode(row).conductorStates[systemId][n - 1].angle || 0) }} °
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
    </template>

    <!-- Empty hint -->
    <template #empty>
      <p class="text-gray-500 text-xl">Sie haben bisher keine Betriebsfälle angelegt.</p>
      <p class="mt-8 mb-12 font-normal text-gray-400 text-lg leading-relaxed"></p>
      <p-btn @click="onCreate">Neuen Betriebsfall anlegen</p-btn>
    </template>

    <!-- Delete -->
    <template #confirm-delete="{ item }">
      Wollen Sie den Betriebsfall
      <b>{{ asOpMode(item).name }}</b>
      wirklich löschen?
    </template>
  </EntityTableView>
</template>
