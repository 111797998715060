import useMapInteraction from '@/components/map/composables/useMapInteraction'
import { useMapSelection } from '@/components/map/composables/useMapSelection'
import { storeToRefs } from 'pinia'
import { HitTolerance, MediaLayerContext } from '../util'
import { mediumSelectStyle } from '@/components/map/style/media'
import { useMediumStore } from '@/stores/medium'
import { click } from 'ol/events/condition'
import { Select } from 'ol/interaction'
import { onBeforeUnmount, watch, watchEffect } from 'vue'
import { useRouter } from 'vue-router'

export function useMediaSelect(ctx: MediaLayerContext) {
  const { registerInteraction } = useMapSelection()
  const router = useRouter()
  const mediumStore = useMediumStore()

  // Clear map selection when the store selection was emptied
  const { selection } = storeToRefs(mediumStore)
  watch(selection, (value, oldValue) => {
    if (value.length === 0 && value.length !== oldValue.length) {
      useMapSelection().clear()
    }
  })

  const selectInteraction = new Select({
    condition: (e) => click(e) && !mediumStore.drawing,
    style: mediumSelectStyle,
    layers: [ctx.layer],
    hitTolerance: HitTolerance
  })

  useMapInteraction(selectInteraction)

  registerInteraction(selectInteraction)

  watchEffect(() => {
    const projectId = router.currentRoute.value.params.projectId
    if (!projectId) {
      return
    }

    const selectedMedia = ctx.selectedMedia.value
    if (selectedMedia.length > 0) {
      useMediumStore().selection = selectedMedia.map((item) => item.getId() as string)
      router.push({ name: 'project-map-medium-edit', params: { id: selectedMedia[0].getId() } })
    } else {
      useMediumStore().selection = []
    }
  })

  onBeforeUnmount(() => {
    useMapSelection().clear()
  })

  return { selectInteraction }
}
