import { Manager } from 'socket.io-client'
import { ref } from 'vue'
import { getEnv, isDevelopment, runningVitest } from '@/util'
import { ServerResponse, WebsocketError } from '@/api'

/**
 * Setup Manager and check environment for settings
 * See: https://socket.io/docs/v4/client-api/#manager
 */
const socketIoUrl = getEnv('VITE_SOCKETIO_URL')
const socketIoPath = getEnv('VITE_SOCKETIO_PATH')
const settingsVitest = {
  forceNew: true,
  reconnectionDelayMax: 10000,
  path: socketIoPath
}
const settingsDefault = {
  autoConnect: false,
  path: socketIoPath
}
const manager = ref(new Manager(socketIoUrl, runningVitest ? settingsVitest : settingsDefault))
manager.value.on('error', (error) => {
  console.log('SocketIO Manager error', error)
})

/**
 * Setup Socket values
 * See: https://socket.io/docs/v4/client-api/#socket
 */
const socket = ref(manager.value.socket('/'))
const connected = ref(false)
socket.value.on('connect', () => {
  connected.value = true
})

socket.value.on('disconnect', () => {
  connected.value = false
})

socket.value.on('connect_error', () => {
  connected.value = false
})

/**
 * Composable to interact with Socket
 */
export type WebSocket = ReturnType<typeof useWebSocket>
export const useWebSocket = () => {
  /**
   * Establish connection
   */
  const connect = (auth: { email?: string; token?: string } = {}) => {
    return new Promise((resolve, reject) => {
      const s = socket.value

      if (s.connected) {
        disconnect()
      }

      // provide credentials
      s.auth = auth

      s.once('connect', () => resolve(true))
      s.once('connect_error', (error) => reject(error))
      s.connect()
    })
  }

  /**
   * Disconnect from server
   */
  function disconnect() {
    socket.value.disconnect()
  }

  /**
   * Request wrapper for WS emit
   */
  function request<T = any>(messageId: string, payload?: any): Promise<T> {
    return new Promise((resolve, reject) => {
      socket.value.emit(messageId, payload, (response: ServerResponse) => {
        // Log in Debug mode
        if (isDevelopment()) {
          wsEmitInfoLog(messageId, payload, response)
        }
        if (response.status === 200) {
          resolve(response.data)
        } else {
          response.error!.request = { messageId, payload }
          reject(new WebsocketError(response))
        }
      })
    })
  }

  function subscribe<T = any>(messageId: string, handler: (data: T) => void) {
    return socket.value.on(messageId, (d: T) => {
      if (isDevelopment()) {
        wsOnInfoLog(messageId, d)
      }
      handler(d)
    })
  }

  return { connect, connected, disconnect, request, subscribe, socket }
}

/**
 * Highlighted console info about WS request and response
 */
function wsEmitInfoLog(messageId: string, payload: any, response: ServerResponse<any>) {
  console.debug(
    `%c[Websocket.emit] %c ${messageId} `,
    'color: #aaa',
    'color: #335; background: #ddf;',
    'Request:',
    payload,
    'Response:',
    response.status,
    response.data
  )
}

function wsOnInfoLog(messageId: string, data: any) {
  console.debug(
    `%c[Websocket.on] %c ${messageId} `,
    'color: #aaa',
    'color: #353; background: #ded;',
    data
  )
}
