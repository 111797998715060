import { FieldConfigs } from '@prionect/ui'

const fields: FieldConfigs = {
  corridor: {
    name: 'corridor',
    type: 'number',
    min: 0.01,
    step: 100,
    unit: 'm',
    label: 'Beeinflussungskorridor',
    required: 'Bitte geben Sie die Korridorbreite ein'
  },
  sag: {
    name: 'sag',
    type: 'number',
    label: 'Durchhang',
    min: 0,
    unit: 'm'
  },
  rx: {
    name: 'rx',
    type: 'number',
    min: 0,
    max: 1,
    step: 0.1,
    label: 'Umgebungsreduktionsfaktor rx'
  },
  rs: {
    name: 'rs',
    type: 'number',
    min: 0,
    max: 1,
    step: 0.1,
    label: 'Schienenreduktionsfaktor rs'
  },
  rk: {
    name: 'rk',
    type: 'number',
    min: 0,
    max: 1,
    step: 0.1,
    label: 'Kabelreduktionsfaktor rk'
  }
}

export default fields
