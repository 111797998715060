<script lang="ts">
import { defineComponent } from 'vue'
import MenuSection from '@/components/navigation/MenuSection.vue'
import MenuItem from '@/components/navigation/MenuItem.vue'

export default defineComponent({
  name: 'LibraryNavigation',
  components: { MenuItem, MenuSection }
})
</script>

<template>
  <div class="px-8 pt-6 pb-4 border-b border-gray-300 text-gray-800">
    <div class="overflow-hidden">
      <div class="font-semibold">Typbibliothek</div>
    </div>
  </div>
  <div class="py-6 px-6">
    <MenuSection>
      <MenuItem :to="{ name: 'towertypes' }">Masttypen</MenuItem>
      <MenuItem :to="{ name: 'conductortypes' }">Leitertypen</MenuItem>
      <MenuItem :to="{ name: 'pipetypes' }">Rohrleitungstypen</MenuItem>
    </MenuSection>
  </div>
</template>
