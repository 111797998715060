import { FeatureCollection } from 'geojson'
import { OperationalModeId } from './operational-mode'
import { ProjectId } from './project'
import { LogLevelEnum } from '@/model'

export type CalculationResult = {
  project: ProjectId
  operationalMode: OperationalModeId
  result: {
    emf: string
    emfResult: FeatureCollection
    LoadFlow: any
    LoadFlowGeojson: FeatureCollection
    relations: FeatureCollection
    resultVoltage: number
    resultVoltageCoordinate: { x: number; y: number }
  }
}

export type CalculationResultTypo = Pick<CalculationResult, 'project' | 'result'> & {
  operationlmode: OperationalModeId
}

export type CalculationStatusValue =
  | 'INACTIVE'
  | 'PENDING'
  | 'INITIALIZING'
  | 'RUNNING'
  | 'FINISHED'
  | 'CANCELED'
  | 'ERROR'

export type CalculationStatus = {
  message?: string
  operationalMode?: OperationalModeId
  progress?: number
  project?: ProjectId
  status: CalculationStatusValue
}

export type CalculationStatusTypo = {
  message?: string
  operationlmode?: OperationalModeId
  progress?: number
  project?: ProjectId
  status: CalculationStatusValue
}

export type CalculationLogMsg = {
  project_id: ProjectId
  data: string
  level: LogLevelEnum
}

export enum HsbLibObjectEnum {
  MESHED_MEDIUM_SIMULATION = 'MESHED_MEDIUM_SIMULATION',
  OVERHEADLINE = 'OVERHEADLINE'
}
