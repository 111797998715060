<script lang="ts">
import { defineComponent, PropType } from 'vue'

type Id = number | string

export type TreeItem = {
  value: Id
  label: string
  children?: TreeItem[]
}

export default defineComponent({
  name: 'TwoColumnSelectList',

  props: {
    filter: {
      type: String
    },
    items: {
      type: Array as PropType<TreeItem[]>,
      required: true
    },
    modelValue: {
      type: [Number, String]
    }
  },
  emits: ['update:modelValue'],

  data: () => ({
    selectedCategory: undefined as Id | undefined,
    selectedItem: undefined as Id | undefined
  }),

  computed: {
    categories(): TreeItem[] {
      return this.items.map((item) => ({
        ...item,
        children: undefined,
        label: `${item.label} (${item.children?.length ?? 0})`
      }))
    },

    itemsByCategory(): Record<Id, TreeItem[]> {
      const itemsByCategory: Record<Id, TreeItem[]> = {}
      this.categories.forEach((category) => {
        itemsByCategory[category.value] =
          this.items.find((item) => item.value === category.value)?.children || []
      })
      return itemsByCategory
    }
  },

  mounted() {
    if (this.modelValue) {
      this.selectItem(this.modelValue)
    }
    if (this.categories.length > 0 && !this.selectedCategory) {
      this.selectedCategory = this.categories[0].value
      ;(this.$refs.categoryTree as any).setCurrentKey(this.selectedCategory)
    }
  },

  methods: {
    selectItem(id: Id) {
      let found = false
      for (const category of this.categories) {
        for (const item of this.itemsByCategory[category.value]) {
          if (item.value === id) {
            found = true
            this.selectedItem = id
            break
          }
        }
        if (found) {
          this.selectedCategory = category.value
          ;(this.$refs.categoryTree as any).setCurrentKey(this.selectedCategory)
          this.$nextTick(() => {
            ;(this.$refs.itemTree as any).setCurrentKey(this.selectedItem)
          })
          break
        }
      }
    }
  },

  watch: {
    selectedCategory(cur, prev) {
      if (prev !== undefined) {
        this.$emit('update:modelValue', undefined)
      }
    },
    selectedItem() {
      this.$emit('update:modelValue', this.selectedItem)
    },
    items() {
      this.$nextTick(() => {
        if (this.selectedCategory) {
          ;(this.$refs.categoryTree as any).setCurrentKey(this.selectedCategory)
        }
      })
    },
    modelValue() {
      if (this.modelValue) {
        this.selectItem(this.modelValue)
      }
    }
  }
})
</script>

<template>
  <div class="border p-4 flex">
    <el-tree
      class="categories pr-2 border-r mr-4"
      :data="categories"
      node-key="value"
      ref="categoryTree"
      tabindex="1"
      @current-change="selectedCategory = $event.value"
    />
    <el-tree
      class="flex-1 items"
      :data="(selectedCategory && itemsByCategory[selectedCategory]) || []"
      node-key="value"
      ref="itemTree"
      tabindex="2"
      @current-change="selectedItem = $event.value"
    />
  </div>
</template>

<style scoped lang="css">
.categories :deep(.el-tree-node) {
  font-weight: bold;
}

.categories :deep(.el-tree-node__expand-icon),
.items :deep(.el-tree-node__expand-icon) {
  display: none;
}

:deep(.el-tree-node__content) {
  @apply py-4 px-4;
}

:deep(.el-tree-node.is-current) {
  @apply text-primary font-bold;
}
</style>
