<script lang="ts" setup>
import { UseMapItems } from '@/components/map/composables/useMap'

/**
 * BaseLayer is the abstract base component used by concrete Layer implementations
 */
import { getCurrentInstance, inject, onBeforeUnmount, PropType, toRefs, watch } from 'vue'
import { Group as LayerGroup, Layer } from 'ol/layer'
import { UseMapInjectKeys } from '@/components/map/composables/useMapInjectKeys'
import { findLayer } from '@/components/map/layer/util'
import useHoverInformation from '@/components/map/composables/useHoverInformation'
import { FilterFunction } from 'ol/interaction/Select'

export type InformationOnHoverProp = FilterFunction | boolean
const props = defineProps({
  id: { type: String, required: true },
  name: String,
  layer: {
    type: Object as PropType<Layer>,
    required: true
  },
  informationOnHover: {
    type: [Boolean, Function] as PropType<InformationOnHoverProp>,
    default: false
  },
  opacity: {
    type: Number,
    default: 1
  },
  visible: {
    type: Boolean,
    default: true
  },
  zIndex: {
    type: Number,
    default: 0
  }
})

const { map } = inject(UseMapInjectKeys.useMap) as UseMapItems

const { layer, opacity, visible } = toRefs(props)
// Apply component props to layer
watch(
  props,
  () => {
    layer.value.setProperties({ ...props, layer: undefined })
  },
  { immediate: true }
)

watch(visible, (value) => {
  layer.value.setVisible(value)
})

watch(opacity, (value) => {
  layer.value.setOpacity(value)
})

/**
 * Add layer either to a parent LayerGroup or to the root map
 */
const groupId = findLayerGroupId()
if (groupId) {
  // Add to a LayerGroup
  const groupLayer = findLayer<LayerGroup>(map.value, groupId)

  if (groupLayer) {
    groupLayer.getLayers().push(props.layer)
    layer.value.set('group', groupLayer)
    onBeforeUnmount(() => {
      groupLayer.getLayers().remove(layer.value)
    })
  } else {
    throw new Error(
      `Map: Could not add Layer "${props.id}" to layer group "${groupId}" - the layer group does not exist.`
    )
  }
} else {
  // Add to root map
  map.value.addLayer(props.layer)
  onBeforeUnmount(() => {
    map.value.removeLayer(props.layer)
  })
}

/**
 * Apply hover information feature
 */
if (props.informationOnHover) {
  const { registerLayerFeaturesForHover } = useHoverInformation()
  registerLayerFeaturesForHover(
    props.id,
    layer.value,
    typeof props.informationOnHover === 'function' ? props.informationOnHover : undefined
  )
}

/**
 * Find a parent LayerGroup, if any
 */
function findLayerGroupId(): string | false {
  // check whether there is a LayerGroup parent
  const me = getCurrentInstance()
  let parent = me?.parent
  while (parent) {
    const name = parent.type.__name || parent.type.name
    const id = (parent.props.id || parent.attrs.id || '') as string
    if (name === 'LayerGroup' && id !== '') {
      return id
    }
    if (name === 'Map') {
      return false
    }
    parent = parent?.parent
  }
  return false
}

/*onMounted(() => {
  map.value.addLayer(layer.value)
})

onBeforeUnmount(() => {
  map.value.removeLayer(layer.value)
})*/
</script>

<template>
  <div></div>
</template>
