<script lang="ts">
import LayerControl from '@/components/map/control-panel/LayerControl.vue'
import CalculationControl from '@/components/map/control-panel/calculation/CalculationControl.vue'
import { defineComponent } from 'vue'
import ZoomControl from './ZoomControl.vue'
import ControlPanelSection from './ControlPanelSection.vue'
import BaseMapSelect from './BaseMapSelect.vue'

export default defineComponent({
  name: 'MapControlPanel',
  components: {
    CalculationControl,
    LayerControl,
    ZoomControl,
    ControlPanelSection,
    BaseMapSelect
  },

  data: () => ({
    expanded: true
  })
})
</script>

<template>
  <div
    class="control-panel flex flex-col py-2 bg-white bg-opacity-80 backdrop-blur overflow-hidden inset-y-4 shadow-lg rounded-lg"
    :class="{ 'bottom-auto': !expanded }"
  >
    <!-- Header -->
    <div class="flex items-center text-gray-400 font-medium px-6">
      <div class="flex-1 uppercase text-xs">Kartenoptionen</div>
      <div class="cursor-pointer hover:text-gray-600 mt-1" @click="expanded = !expanded">
        <el-icon size="20">
          <ChevronUpIcon v-if="expanded" />
          <ChevronDownIcon v-else />
        </el-icon>
      </div>
    </div>

    <div
      v-if="expanded"
      class="flex-1 flex flex-col px-6 align-left items-stretch overflow-y-auto mt-2"
    >
      <ControlPanelSection title="Ebenen" id="layers" default-open>
        <LayerControl />
      </ControlPanelSection>

      <ControlPanelSection title="Berechnung" id="calculation" default-open>
        <CalculationControl />
      </ControlPanelSection>

      <div class="flex-1"></div>
    </div>
    <div v-if="expanded" class="px-6 mt-4">
      <ControlPanelSection title="Basiskarte" id="basemap" animate-from-bottom>
        <BaseMapSelect />
      </ControlPanelSection>

      <ZoomControl />
    </div>
  </div>
</template>

<style scoped lang="css">
.section-heading {
  @apply mt-6 mb-2 text-xs font-semibold text-gray-500 uppercase;
}
:deep(.el-tree-node__content) {
  @apply py-4;
}

:deep(.el-tree-node) {
  @apply font-semibold;
}

:deep(.el-tree-node .el-tree-node) {
  @apply font-normal;
}
</style>
