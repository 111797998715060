import { User, UserId } from '@/model'
import { HttpClient, PaginatedResponse } from '@/api/useHttpClient'
import { WebSocket } from '@/api/useWebSocket'
import { HSBApi } from '@/api/HSBApiBase'

const path = (userId?: UserId) => {
  return 'users' + (userId ? `/${userId}` : '')
}

export class UserApiClass implements HSBApi {
  constructor(public httpClient: HttpClient, public webSocket: WebSocket) {}
  async delete(id: UserId) {
    return await this.httpClient.delete(path(id))
  }

  async getAll(): Promise<User[]> {
    const res = await this.httpClient.get<PaginatedResponse<User>>(path())
    return res.data.results || []
  }
  async save(user: User): Promise<User> {
    const res = await this.httpClient.put<User>(path(user.id), { data: user })
    return res.data
  }

  async getDisplayNames(): Promise<Record<UserId, string>> {
    const res = await this.httpClient.get<Record<UserId, string>>(`${path()}/displayNames`)
    return res.data
  }

  async getMe() {
    return await this.httpClient.get<User>(`${path()}/me`)
  }

  //  TODO: implement REST endpoint in Backend
  // async getSettings() {
  //   const response = await request('User.listSettings')
  //   const settings: Record<string, any> = {}
  //   response.settings.forEach((setting: any) => {
  //     settings[setting.key] = JSON.parse(setting.value)
  //   })
  //   return settings
  // },
  //
  // TODO: implement REST endpoint in Backend
  // async saveSetting(key: string, value: any) {
  //   await request('User.saveSetting', { key, value: JSON.stringify(value) })
  // },

  onUserDeleted(handler: (id: UserId) => void) {
    this.webSocket.subscribe('User.deleted', (data) => {
      handler(data.id)
    })
  }

  onUserUpdated(handler: (user: User) => void) {
    this.webSocket.subscribe('User.updated', handler)
  }
}
