<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { AxiosError } from 'axios'
import { useProgress } from '@/composables/useProgress'

export default defineComponent({
  name: 'RequestsProgress',
  props: {
    progress: {
      type: Object as PropType<ReturnType<typeof useProgress>>,
      required: true
    }
  },
  methods: {
    renderError(err: string | Error | AxiosError): string {
      if (err instanceof AxiosError) {
        return `${err.message}: ${err.response?.data.message ?? JSON.stringify(err.response?.data)}`
      }
      return err instanceof Error ? `${err.name}: ${err.message}` : err
    }
  }
})
</script>

<template>
  <div class="flex self-center gap-2">
    <el-popover :disabled="!progress.errors.length" placement="top-end" show-arrow width="400px">
      <table>
        <tbody>
          <tr>
            <th>Es sind fehler beim Import aufgetreten:</th>
          </tr>
          <tr v-for="(error, key) in progress.errors" :key="key">
            <td>{{ renderError(error) }}</td>
          </tr>
        </tbody>
      </table>

      <template #reference>
        <el-progress
          :stroke-width="8"
          :percentage="progress.percent"
          :show-text="false"
          :status="progress.status"
          class="flex-grow"
        />
      </template>
    </el-popover>
  </div>
</template>

<style scoped lang="css"></style>
