<script lang="ts">
import { UseMapItems } from '@/components/map/composables/useMap'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { defineComponent, inject } from 'vue'
import { UseMapInjectKeys } from '@/components/map/composables/useMapInjectKeys'

export default defineComponent({
  name: 'ControlPanelSection',
  components: { Disclosure, DisclosureButton, DisclosurePanel },
  props: {
    animateFromBottom: Boolean,
    title: String,
    id: { type: String, required: true },
    defaultOpen: Boolean
  },

  setup() {
    const { mapState } = inject(UseMapInjectKeys.useMap) as UseMapItems
    return { mapState }
  },
  computed: {
    animateOrigin() {
      return this.animateFromBottom ? 'origin-bottom' : 'origin-top'
    },
    isExpanded(): boolean {
      return this.mapState.controls[this.id]?.visible === true
    },
    /**
     * Force re-render once the map state preset is loaded
     */
    hasStateItem(): boolean {
      return !!this.mapState.controls[this.id]
    }
  },
  methods: {
    onClick(open: boolean) {
      this.mapState.controls[this.id] = { visible: open }
    }
  }
})
</script>

<template>
  <Disclosure
    as="div"
    class="w-full my-1 first:mt-0 border-top"
    :default-open="hasStateItem ? isExpanded : defaultOpen"
    :key="
      // Dirty hack to keep component reactive on late store loading
      hasStateItem.toString()
    "
    v-slot="{ open }"
  >
    <DisclosureButton class="w-full" @click="onClick(!open)">
      <div class="section-heading">
        <div class="flex-1 pt-2 pb-1">{{ title }}</div>
        <div class="pt-3">
          <el-icon size="20">
            <ChevronRightIcon
              class="transition-transform duration-100 ease-in"
              :class="{ 'rotate-90': open }"
            />
          </el-icon>
        </div>
      </div>
    </DisclosureButton>
    <transition
      :enter-active-class="`transition duration-100 ease-out ${animateOrigin}`"
      enter-from-class="transform scale-y-50 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      :leave-active-class="`transition duration-75 ease-out ${animateOrigin}`"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-y-50 opacity-0"
    >
      <DisclosurePanel class="pt-2 pb-4">
        <slot></slot>
      </DisclosurePanel>
    </transition>
  </Disclosure>
</template>

<style scoped lang="css">
.section-heading {
  @apply flex items-center text-sm font-semibold text-gray-600 uppercase text-left border-t border-gray-300 hover:text-gray-800 transition-colors;
}
</style>
>
