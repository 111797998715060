import { useCorridorStore } from '@/stores/corridor'
import { defineStore } from 'pinia'
import { v4, v4 as uuid } from 'uuid'
import { Project, ProjectId, Tower, TowerId } from '@/model'
import { TowerApi } from '@/api'
import { Feature, FeatureCollection } from 'geojson'
import { useTowerTypeStore } from '@/stores/tower-type'

export const useTowerStore = defineStore('tower', {
  state: () => ({
    draft: undefined as Partial<Tower> | undefined,
    loadedProject: undefined as ProjectId | undefined,
    itemsById: {} as Record<TowerId, Tower>,
    loaded: false,
    loading: false,
    selection: [] as TowerId[]
  }),

  getters: {
    findById(state) {
      return (id: TowerId): undefined | Tower => state.itemsById[id]
    },
    geoJSON(): FeatureCollection {
      const features: Feature[] = []
      this.items.forEach((tower) => {
        features.push({
          id: tower.id,
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [tower.x, tower.y]
          },
          properties: {
            _type: 'tower',
            name: tower.name,
            position: tower.position
          }
        })
      })

      return {
        type: 'FeatureCollection',
        features
      }
    },

    items(): Tower[] {
      return Object.values(this.itemsById)
    },
    lastTower(): Tower | undefined {
      return Object.values(this.itemsById).sort((a, b) => b.position - a.position)[0]
    }
  },

  actions: {
    createDraft(data: Partial<Tower>) {
      let defaultData = {}
      // Use last tower for defaults
      if (this.items.length > 0) {
        const lastTower = this.items.slice(-1)[0]
        defaultData = { ...lastTower, name: undefined, position: undefined }
      }
      this.draft = { ...defaultData, id: v4(), ...data }
      return this.draft
    },

    async delete(id: TowerId) {
      const tower = this.findById(id)
      if (tower) {
        await TowerApi.delete(tower)
        // of not last tower refresh because of tower positions
        if (tower.id === this.lastTower?.id) {
          delete this.itemsById[id]
        } else {
          await this.load(this.loadedProject as ProjectId)
        }

        // Update corridor
        if (this.loadedProject) {
          const corridorStore = useCorridorStore()
          corridorStore.load(this.loadedProject)
        }
      }
    },

    async ensureLoaded(projectId: ProjectId) {
      const projectChanged = this.loadedProject !== projectId
      if (!(this.loaded || this.loading) || projectChanged) {
        await this.load(projectId)
      }
    },

    async load(projectId: ProjectId) {
      if (this.loadedProject !== projectId) {
        this.loaded = false
        this.draft = undefined
      }
      this.loading = true
      const itemsById: Record<TowerId, Tower> = {}
      this.itemsById = {}

      try {
        if (!projectId) {
          throw new Error('TowerStore.load: ProjectId must not be empty.')
        }

        const towers = await TowerApi.getAllByProject(projectId)
        towers.forEach((item) => {
          itemsById[item.id] = item
        })
        this.itemsById = { ...itemsById }
        this.loadedProject = projectId
        this.loaded = true
      } finally {
        this.loading = false
      }
    },

    /**
     * Replaces all towers of project
     */
    async replaceAll(payload: Tower[], project: Project) {
      for (const tower of payload) {
        if (!tower.id) {
          tower.id = uuid()
        }
      }
      const towers = await TowerApi.saveList({
        project: project.id,
        list: payload,
        spanDefaults: { corridor: 1000 }
      })
      this.itemsById = {}
      for (const tower of towers) {
        this.itemsById[tower.id] = tower
      }
      return towers
    },

    async save(item: Tower) {
      if (!item.id) {
        item.id = uuid()
      }

      // 1. Sync lengths of earthwires
      // See: https://app.clickup.com/t/24584978/HSB-659
      const earthwiresCountIn = useTowerTypeStore().earthwireCountForType(item.in.type)
      const earthwiresCountOut = useTowerTypeStore().earthwireCountForType(item.out?.type)
      item.in.earthwires.length = earthwiresCountIn
      if (item.out?.earthwires) {
        item.out.earthwires.length = earthwiresCountOut
      }

      // 2. Perform API Call & store result
      const updatedItem = await TowerApi.save({ ...item, project: this.loadedProject as ProjectId })
      this.itemsById = { ...this.itemsById, [item.id]: updatedItem }

      // 3. Update tower position indices from server (may have changed after duplicate or move)
      this.updatePositions()

      this.draft = undefined

      // 4. Update corridor data
      if (this.loadedProject) {
        const corridorStore = useCorridorStore()
        corridorStore.load(this.loadedProject)
      }

      return updatedItem
    },

    async updatePositions() {
      const towers = this.loadedProject ? await TowerApi.getAllByProject(this.loadedProject) : []
      towers.forEach((item) => {
        const towerInStore = this.itemsById[item.id]
        if (towerInStore) {
          towerInStore.position = item.position
        }
      })
    }
  }
})
