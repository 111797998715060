<script lang="ts" setup>
import { useAppAbility } from '@/permissions/useAppAbility'
import { useUserStore } from '@/stores/user'
import { subject } from '@casl/ability'
import { computed, ref } from 'vue'
import { useProjectStore } from '@/stores/project'

const props = defineProps({
  projectId: {
    type: String,
    required: true
  },
  unlocking: Boolean
})

defineEmits(['requestLock'])

const userStore = useUserStore()

const projectStore = useProjectStore()
const project = computed(() => projectStore.findById(props.projectId))

const { can } = useAppAbility()
const canOvertake = computed(() => {
  if (project.value) {
    return can('update', subject('Project', project.value), 'locked')
  }
  return false
})
</script>

<template>
  <div
    :data-can-overtake="canOvertake"
    class="absolute bg-gray-200 inset-0 bg-opacity-90 flex items-center justify-center"
    data-component="ProjectLockedOverlay"
  >
    <div class="bg-white p-8 shadow-lg rounded-lg w-3/4 max-w-[70ch] flex">
      <div class="mr-6 text-warning-300">
        <LockedIcon class="w-10 h-10" />
      </div>
      <div>
        <p class="font-semibold">
          Dieses Projekt wird gerade durch
          {{ userStore.findNameById(project?.lockedBy) }} bearbeitet.
        </p>
        <p class="pt-4 text-sm text-gray-500">
          <template v-if="canOvertake">
            Als Administrator können Sie das Projekt für
            {{ userStore.findNameById(project?.lockedBy) }} schließen und dann selbst bearbeiten.
          </template>
          <template v-else>
            Damit Sie das Projekt bearbeiten können, muss
            {{ userStore.findNameById(project?.lockedBy) }} das Projekt zunächst schließen.
          </template>
        </p>
        <div class="pt-6">
          <p-btn
            v-if="canOvertake"
            :loading="unlocking"
            class="mr-2"
            data-test="overtake"
            type="warning"
            @click="$emit('requestLock')"
          >
            Projekt schließen und für mich öffnen
          </p-btn>
          <p-btn data-test="close" @click="$router.push({ name: 'projects' })">
            zur Projektübersicht
          </p-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="css" scoped></style>
