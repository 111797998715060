import { z } from 'zod'
import { HSBLibItemsEnum } from '@/config/schemas/hsb-lib/util'

/**
 * https://gridside.gitlab.io/hsb-backend/hsblib_api.html#hsblib.Conductor
 */
export const HSBLibConductorSchema = z.object({
  objclass: z.literal(HSBLibItemsEnum.Conductor),
  id: z.number(),
  skin_effect: z.boolean(),
  type: z.string(),
  temperature: z.number(),
  current: z.string(),
  has_sag: z.boolean()
})
export type HSBLibConductor = z.infer<typeof HSBLibConductorSchema>
