<template>
  <div class="table-simple table-simple--striped table-simple--fixed-header">
    <table class="w-full">
      <thead>
        <tr>
          <th>Pfad</th>
          <th>Fehlermeldung</th>
          <th>Erkannt</th>
          <th>Erwartet</th>
          <!--          <th>Fehler-Code</th>-->
        </tr>
      </thead>
      <tbody>
        <tr v-for="(issue, index) in error.issues" :key="index">
          <td>
            <pre>{{ renderPath(issue) }}</pre>
          </td>
          <td>{{ issue.message }}</td>
          <td>{{ renderReceived(issue) }}</td>
          <td>{{ renderExpected(issue) }}</td>
          <!--          <td>{{ issue.code }}</td>-->
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ZodError, ZodIssue } from 'zod'

export default defineComponent({
  name: 'ZodErrorTable',
  props: {
    error: {
      required: true,
      type: Object as PropType<ZodError>
    }
  },
  methods: {
    renderExpected(iss: ZodIssue) {
      if ('expected' in iss) {
        return String(iss.expected)
      }
    },
    renderReceived(iss: ZodIssue) {
      if ('received' in iss) {
        return String(iss.received)
      }
    },
    renderPath(iss: ZodIssue) {
      return iss.path.join('.')
    }
  }
})
</script>

<style scoped></style>
