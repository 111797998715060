import { z } from 'zod'
import { HSBLibItemsEnum, xyPositionTupleArray } from '@/config/schemas/hsb-lib/util'

/**
 * https://gridside.gitlab.io/hsb-backend/hsblib_api.html#hsblib.Corridor
 */
export const HSBLibCorridorSchema = z.object({
  objclass: z.literal(HSBLibItemsEnum.Corridor),
  source_coordinates: xyPositionTupleArray,
  width: z.array(z.number())
})

export type HSBLibCorridor = z.infer<typeof HSBLibCorridorSchema>
