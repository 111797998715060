<script lang="ts">
import useAppHealth from '@/composables/useAppHealth'
import { computed, defineComponent } from 'vue'
import { useWebSocket } from '@/api'

export default defineComponent({
  name: 'WebSocketStatus',

  setup() {
    const { socket, connected } = useWebSocket()
    const { healthy, errorMessage } = useAppHealth()

    const ok = computed(() => connected.value && healthy.value)

    return { socket, connected, ok, healthy, errorMessage }
  }
})
</script>

<template>
  <div v-if="!ok" class="flex items-center px-6 bg-white">
    <div class="w-3 h-3 rounded-lg mr-2" :class="ok ? 'bg-success-500' : 'bg-error-500'"></div>
    <div class="text-xs">
      <div v-if="healthy">
        WebSocket
        <span v-if="connected">verbunden</span>
        <span v-else class="font-bold">getrennt</span>
      </div>
      <div v-else class="leading-tight">
        <div class="font-bold">HSB-Server gestört</div>
        <div
          v-if="errorMessage"
          class="text-gray-500 max-w-[40ch] inline-block overflow-hidden overflow-ellipsis whitespace-nowrap"
          :title="errorMessage"
        >
          {{ errorMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="css"></style>
