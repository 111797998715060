import z from 'zod'
import { ConductorAllocation, SystemWithConductorPositionInfo, Tower, TowerType } from '@/model'

export const xyPositionTupleArray = z.tuple([z.number(), z.number()]).array()

export enum HSBLibItemsEnum {
  Conductor = 'Conductor',
  ConductorType = 'ConductorType',
  Corridor = 'Corridor',
  Current = 'Current',
  Overheadline = 'OverheadLine', // L is capitalized
  Tower = 'Tower'
}

export enum HSBGuiItemsEnum {
  conductorTypes = 'conductorTypes',
  systems = 'systems',
  allocations = 'allocations',
  towerTypes = 'towerTypes',
  towers = 'towers',
  spans = 'spans'
}

export type Container<T> = {
  [key: string]: T
}
