<script lang="ts">
import { defineComponent, PropType } from 'vue'
import ZodErrorTable from '@/components/common/ZodErrorTable.vue'
import { ZodError } from 'zod'

enum ErrorTabs {
  VALIDATION = 'Validierungsfehler',
  DEFAULT = 'Fehler'
}

export default defineComponent({
  name: 'ProjectImportErrorTabs',
  props: {
    errors: {
      type: Array as PropType<(string | Error | ZodError)[]>,
      required: true
    }
  },
  data() {
    return {
      ErrorTabs,
      tab: ErrorTabs.DEFAULT as string | ErrorTabs
    }
  },
  watch: {
    errorsGrouped: {
      immediate: true,
      handler(val) {
        this.tab = val.defaultErrors.length ? ErrorTabs.DEFAULT : `${ErrorTabs.VALIDATION}_${0}`
      }
    }
  },
  computed: {
    /**
     * Grouped by ZodErrors and default errors
     */
    errorsGrouped() {
      const defaultErrors: (string | Error)[] = []
      const zodErrors: ZodError[] = []
      for (const err of this.errors) {
        if (err instanceof ZodError) {
          zodErrors.push(err)
        } else {
          defaultErrors.push(err)
        }
      }
      return { defaultErrors, zodErrors }
    }
  },

  components: { ZodErrorTable },

  methods: {
    renderError(err: string | Error): string {
      return err instanceof Error ? `${err.name}: ${err.message}` : err
    }
  }
})
</script>

<template>
  <el-tabs v-model="tab" class="h-full flex flex-col">
    <!-- Default Errors -->
    <el-tab-pane v-if="errorsGrouped.defaultErrors.length" :name="ErrorTabs.DEFAULT">
      <template #label>
        <span class="text-danger-500">{{ ErrorTabs.DEFAULT }}</span>
      </template>
      <p v-for="(err, index) in errorsGrouped.defaultErrors" :key="index">
        {{ renderError(err) }}
      </p>
    </el-tab-pane>

    <!-- Zod Errors -->
    <template v-if="errorsGrouped.zodErrors.length">
      <el-tab-pane
        v-for="(err, key) in errorsGrouped.zodErrors"
        :key="key"
        :label="`${ErrorTabs.VALIDATION} ${key + 1}`"
        :name="`${ErrorTabs.VALIDATION}_${key}`"
        class="h-full"
      >
        <template #label>
          <span class="text-danger-500">{{ ErrorTabs.VALIDATION }}</span>
        </template>
        <ZodErrorTable :error="err" class="bg-gray-200 rounded-lg h-full" />
      </el-tab-pane>
    </template>
  </el-tabs>
</template>

<style scoped lang="css"></style>
