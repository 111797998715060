<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ImportDialog',
  emits: ['close'],
  props: {
    show: {
      type: Boolean,
      default: true
    },
    title: {
      required: true,
      type: String
    },
    panelHeight: {
      required: false,
      type: [Number, String],
      default: 25,
      validator(val: any) {
        if (typeof val === 'string') {
          return val === 'auto'
        }
        return (typeof val === 'number' && val > 0) || val < 100
      }
    }
  }
})
</script>

<template>
  <p-dialog :show="show" :title="title" style="max-width: none" @close="$emit('close')">
    <div
      :style="{
        '--panel-height': typeof panelHeight === 'string' ? panelHeight : `${panelHeight}%`
      }"
      class="import-dialog"
    >
      <div class="import-dialog__panel">
        <slot name="panel"></slot>
      </div>
      <el-divider v-if="$slots.panel" class="import-dialog__divider"></el-divider>
      <div class="import-dialog__content">
        <slot name="default"></slot>
      </div>
    </div>
    <template #footer>
      <slot name="actions"></slot>
    </template>
  </p-dialog>
</template>

<style scoped lang="scss">
.import-dialog {
  --panel-height: 25%;
  display: grid;
  width: 80vw;
  max-height: 70vh;
  height: 70vh;
  grid-template-rows: var(--panel-height) auto 1fr;
}

.import-dialog__panel {
  min-height: 0;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 0.5rem;
}

.import-dialog__panel > * {
  min-height: 0;
}

.import-dialog__divider {
}

.import-dialog__content {
  overflow: auto; /* important */
}
</style>
