<script setup lang="ts">
import { useTowerStore } from '@/stores/tower'
import { Feature } from 'ol'
import { computed, inject, ref, watch, watchEffect } from 'vue'
import { GeoJSON } from 'ol/format'
import { Vector as VectorLayer } from 'ol/layer'
import { Vector as VectorSource } from 'ol/source'

import useCoordinates from '@/composables/useCoordinates'
import { useProject } from '@/composables/useProject'
import { LayerId } from '@/components/map'
import { UseMapItems } from '@/components/map/composables/useMap'
import { useSourceGeoJSON } from '@/components/map/layer/source/useSourceGeoJSON'
import { SourceLayerContext } from '@/components/map/layer/util'
import { sourceStyle } from '@/components/map/style/source'
import { useMapSelection } from '@/components/map/composables/useMapSelection'

import { deleteDraftFeatures } from './functions'
import useSourceContextMenu from './useSourceContextMenu'
import useSourceHover from './useSourceHover'
import useSourceModify from './useSourceModify'
import useSourceSelect from './useSourceSelect'
import CorridorLayer from './corridor/CorridorLayer.vue'
import { UseMapInjectKeys } from '@/components/map/composables/useMapInjectKeys'
import LayerBase from '@/components/map/layer/LayerBase.vue'

const { map, view, layers } = inject(UseMapInjectKeys.useMap) as UseMapItems
const { selectedFeatures } = useMapSelection()
const { project } = useProject()

const towerStore = useTowerStore()

const { mapToProject, projectToMap } = useCoordinates(map.value, project.value?.crs)

const geoJSON = useSourceGeoJSON()
const source = ref<VectorSource>()
const layer = new VectorLayer({
  source: source.value,
  style: sourceStyle,
  properties: { id: LayerId.SOURCE }
})

// Build context object that is handed to functions
const ctx: SourceLayerContext = {
  layer,
  map: map.value,
  mapToProject,
  projectToMap,
  selectedFeatures,
  selectedSpans: computed(() => selectedFeatures.value.filter((f) => f.get('_type') === 'span')),
  selectedTowers: computed(() => selectedFeatures.value.filter((f) => f.get('_type') === 'tower')),
  showCorridor: computed(() => layers.value[LayerId.SOURCE].options.showCorridor || false),
  showTowers: computed(() => layers.value[LayerId.SOURCE].options.showTowers || false),
  showSpans: computed(() => layers.value[LayerId.SOURCE].options.showSpans || false)
}

watchEffect(() => {
  const showTowers = ctx.showTowers.value
  const showSpans = ctx.showSpans.value
  layer
    .getSource()
    ?.getFeatures()
    .forEach((feature) => {
      if (feature.get('_type') === 'tower') {
        feature.set('_hidden', !showTowers)
      }
      if (feature.get('_type') === 'span') {
        feature.set('_hidden', !showSpans)
      }
    })
})

const { selectInteraction } = useSourceSelect(ctx)
ctx.selectInteraction = selectInteraction

useSourceHover(ctx)
useSourceContextMenu(ctx)
useSourceModify(ctx)

// Delete all draft features when another or no item is selected
watch(selectedFeatures, () => {
  const selectedDraft = !!selectedFeatures.value.find((f) => f.get('_draft'))
  const selectedOtherType = !!selectedFeatures.value.find(
    (f) => !['tower', 'span'].includes(f.get('_type'))
  )
  if (!selectedDraft || selectedOtherType) {
    deleteDraftFeatures(ctx)
  }
})

watch(source, () => layer.setSource(source.value || null))

watch(
  geoJSON,
  () => {
    if (!project.value) {
      return
    }
    const geoJsonOptions = {
      dataProjection: project.value.crs,
      featureProjection: view.value.getProjection()
    }

    // create or update source
    if (!source.value) {
      source.value = new VectorSource({
        features: new GeoJSON(geoJsonOptions).readFeatures(geoJSON.value) as Feature[]
      })
    } else {
      const features = new GeoJSON(geoJsonOptions).readFeatures(geoJSON.value) as Feature[]
      source.value.clear()
      source.value.addFeatures(features)

      // restore selection despite changed source
      const selectedIds = selectedFeatures.value.map((feature) => feature.getId())

      const selectInteractionFeatures = ctx.selectInteraction?.getFeatures()
      if (selectInteractionFeatures) {
        features.forEach((feature) => {
          if (selectedIds.includes(feature.getId())) {
            const previousFeature = selectInteractionFeatures
              .getArray()
              .find((f) => f.getId() === feature.getId())
            previousFeature && selectInteractionFeatures.remove(previousFeature)
            selectInteractionFeatures.push(feature)
          }
        })
        towerStore.selection = selectInteractionFeatures
          .getArray()
          .map((feature) => feature.getId() as string)
      }
    }
  },
  { immediate: true }
)
</script>

<template>
  <LayerBase
    v-if="source"
    v-bind="$attrs"
    :id="LayerId.SOURCE"
    :layer="layer"
    information-on-hover
    :z-index="11"
  />
  <CorridorLayer :visible="ctx.showCorridor.value" :z-index="1" />
</template>
