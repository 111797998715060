import { TowerBase, UserId, uuid } from '.'

import {
  ConductorAllocation,
  ConductorType,
  Medium,
  OperationalMode,
  PipeType,
  Span,
  System,
  TowerType
} from '@/model'
import { HSBLibGlobals } from '@/config/schemas/hsb-lib/globals'
import { ProjectionTypesEnum } from '@/config/projections'

export enum CorridorModeEnum {
  FLAT = 'flat',
  ROUND = 'round'
}

export enum LogLevelEnum {
  CRITICAL = 'CRITICAL',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  INFO = 'INFO',
  DEBUG = 'DEBUG',
  NOTSET = 'NOTSET'
}

export type ProjectId = uuid
export type Project = {
  id: ProjectId
  API_VERSION?: string // set by backend
  crs?: ProjectionTypesEnum | string
  createdAt?: Date
  createdBy?: UserId
  customer?: string
  locked?: boolean
  lockedBy?: UserId | null
  mediaType?: 'telecom' | 'pipe'
  name: string
  updatedAt?: Date
  updatedBy?: UserId
  users: UserId[]
  hsblibParameter: null | HSBLibParameter
}

export type HSBLibParameter = {
  corridor_flatend: null | boolean
  corridor_flatstart: null | boolean
  corridor_mode: null | CorridorModeEnum
  logging_traceback: null | boolean
  log_level: null | LogLevelEnum
  FLAG_Inner_Impedance_Bessel_Function: null | boolean
  Flag_Debugging: null | boolean
  PipelineSegmentation: null | number
  Rel_abstol: null | number
  Rel_angletol: null | number
  Rel_max_angle: null | number
  Rel_min_segment_length: null | number
  Rel_simplifyMedium: null | boolean
  Trim_till_decimal_points: null | number
  UTM_Reimport_Tolerance: null | number
  UTM_trim_mode: null | boolean
}

export type ProjectExport = Omit<Project, 'hsblibParameter'> & {
  hsblibParameter: null | Partial<HSBLibParameter>
} & {
  data?: {
    ConductorAllocation?: ConductorAllocation[]
    ConductorType?: ConductorType[]
    Medium?: Medium[]
    OperationalMode?: OperationalMode[]
    PipeType?: PipeType[]
    Span?: Span[]
    System?: System[]
    Tower?: TowerBase[]
    TowerType?: TowerType[]
  }
}

export function fromHSBLibGlobals(globals: HSBLibGlobals): HSBLibParameter {
  return {
    corridor_flatend: globals.corridor_flatend ?? null,
    corridor_flatstart: globals.corridor_flatstart ?? null,
    corridor_mode: globals.corridor_mode ?? null,
    logging_traceback: globals.logging_traceback ?? null,
    log_level: null,
    FLAG_Inner_Impedance_Bessel_Function: globals.FLAG_Inner_Impedance_Bessel_Function ?? null,
    Flag_Debugging: null,
    PipelineSegmentation: globals.PipelineSegmentation ?? null,
    Rel_abstol: globals.Rel_abstol ?? null,
    Rel_angletol: globals.Rel_angletol ?? null,
    Rel_max_angle: globals.Rel_max_angle ?? null,
    Rel_min_segment_length: globals.Rel_min_segment_length ?? null,
    Rel_simplifyMedium: globals.Rel_simplifyMedium ?? null,
    Trim_till_decimal_points: globals.Trim_till_decimal_points ?? null,
    UTM_Reimport_Tolerance: globals.UTM_Reimport_Tolerance ?? null,
    UTM_trim_mode: globals.UTM_trim_mode ?? null
  }
}
