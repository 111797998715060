import { ProjectId } from '@/model'
import { Ref, reactive, toRefs } from 'vue'
import { Map } from 'ol'
import { watchDebounced } from '@vueuse/core'
import { UseMapPositionItems } from './useMapPosition'
import { MapState } from './useMapState'

const LOCAL_STORAGE_KEY = 'hsb-map-state'

export type PersistentMapStateOptions = {
  map: Ref<Map>
  mapState: Ref<MapState>
  projectId: ProjectId
  reset: UseMapPositionItems['reset']
  setPosition: UseMapPositionItems['setPosition']
}

/**
 * Persists the map state / map settings in LocalStorage
 *
 * @param map
 */
export function usePersistentMapState(options: PersistentMapStateOptions) {
  const { map, mapState, projectId, reset, setPosition } = options

  // Flag to prevent writing to localStorage before the state was restored from it
  let wasRestored = false

  const restore = () => {
    const state = loadFromLocalStorage()
    const currentProjectId = projectId
    if (!currentProjectId) {
      return
    }
    if (state && currentProjectId === state.project) {
      // project stayed the same, restore previous position
      setPosition(state.center, state.zoom)
    } else {
      // Project changed, reset map position
      reset()
    }

    delete state.zoom
    delete state.center
    mapState.value = reactive({ ...toRefs(mapState.value), ...state, project: currentProjectId })
    wasRestored = true
  }

  restore()

  watchDebounced(
    mapState,
    () => {
      if (wasRestored && projectId) {
        writeToLocalStorage(mapState.value)
      }
    },
    { debounce: 500, maxWait: 1000, deep: true }
  )

  return { mapState }
}

function loadFromLocalStorage(): Partial<MapState> {
  try {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}')
  } catch (e) {
    console.info(`Could not parse localStorage item "${LOCAL_STORAGE_KEY}" as JSON`)
    return {}
  }
}

function writeToLocalStorage(payload: object) {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(payload))
}
