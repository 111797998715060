<script lang="ts">
import { useAppAbility } from '@/permissions/useAppAbility'
import { useAuthentication } from '@prionect/ui'
import { defineComponent } from 'vue'
import CenteredPage from '@/layouts/pages/CenteredPage.vue'
import { useProjectStore } from '@/stores/project'
import { useUserStore } from '@/stores/user'
import { Project, ProjectId } from '@/model'
import { ElMessage } from 'element-plus'
import { project } from '@/config/fields'
import ProjectHSBLibParameterFieldRow from '@/components/project/ProjectHSBLibParameterFieldRow.vue'

enum ProjectTabs {
  general = 'general',
  hsb_lib_parameters = 'hsb_lib_parameters'
}

export default defineComponent({
  name: 'BaseView',
  components: { ProjectHSBLibParameterFieldRow, CenteredPage },

  data: () => ({
    ProjectTabs,
    activeTab: ProjectTabs.general,
    fieldConfig: project,
    saving: false
  }),

  setup() {
    const { can } = useAppAbility()
    const { currentUser } = useAuthentication()
    const projectStore = useProjectStore()
    const userStore = useUserStore()

    projectStore.ensureLoaded()
    userStore.loadDisplayNames()

    return { can, currentUser, projectStore, userStore }
  },

  mounted() {
    const nameInput: HTMLInputElement = this.$el.querySelector('[data-field="name"] input')
    nameInput?.focus()
  },

  computed: {
    create(): boolean {
      return !!this.$route.meta.create
    },
    project(): Partial<Project> | undefined {
      return this.projectId
        ? this.projectStore.findById(this.projectId)
        : { mediaType: 'telecom', crs: 'EPSG:3857', users: [this.currentUser.id] }
    },
    projectId(): ProjectId | undefined {
      return this.$route.params.projectId?.toString()
    },
    hsbLibSettingFields() {
      return [
        {
          initialValue: this.project?.hsblibParameter?.corridor_flatend,
          config: this.fieldConfig.corridor_flatend
        },
        {
          initialValue: this.project?.hsblibParameter?.corridor_flatstart,
          config: this.fieldConfig.corridor_flatstart
        },
        {
          initialValue: this.project?.hsblibParameter?.corridor_mode,
          config: this.fieldConfig.corridor_mode
        },
        {
          initialValue: this.project?.hsblibParameter?.logging_traceback,
          config: this.fieldConfig.logging_traceback
        },
        {
          initialValue: this.project?.hsblibParameter?.log_level,
          config: this.fieldConfig.log_level
        },
        {
          initialValue: this.project?.hsblibParameter?.FLAG_Inner_Impedance_Bessel_Function,
          config: this.fieldConfig.FLAG_Inner_Impedance_Bessel_Function
        },
        {
          initialValue: this.project?.hsblibParameter?.Flag_Debugging,
          config: this.fieldConfig.Flag_Debugging
        },
        {
          initialValue: this.project?.hsblibParameter?.PipelineSegmentation,
          config: this.fieldConfig.PipelineSegmentation
        },
        {
          initialValue: this.project?.hsblibParameter?.Rel_abstol,
          config: this.fieldConfig.Rel_abstol
        },
        {
          initialValue: this.project?.hsblibParameter?.Rel_angletol,
          config: this.fieldConfig.Rel_angletol
        },
        {
          initialValue: this.project?.hsblibParameter?.Rel_max_angle,
          config: this.fieldConfig.Rel_max_angle
        },
        {
          initialValue: this.project?.hsblibParameter?.Rel_min_segment_length,
          config: this.fieldConfig.Rel_min_segment_length
        },
        {
          initialValue: this.project?.hsblibParameter?.Rel_simplifyMedium,
          config: this.fieldConfig.Rel_simplifyMedium
        },
        {
          initialValue: this.project?.hsblibParameter?.Trim_till_decimal_points,
          config: this.fieldConfig.Trim_till_decimal_points
        },
        {
          initialValue: this.project?.hsblibParameter?.UTM_Reimport_Tolerance,
          config: this.fieldConfig.UTM_Reimport_Tolerance
        },
        {
          initialValue: this.project?.hsblibParameter?.UTM_trim_mode,
          config: this.fieldConfig.UTM_trim_mode
        }
      ]
    }
  },

  methods: {
    async save(values: Project, submissionContext: any) {
      // Check whether the user removed himself
      const isAdmin = this.can('manage', 'all')
      const userIsAssignedToProject = values.users.includes(this.currentUser.id)
      if (!isAdmin && !userIsAssignedToProject) {
        submissionContext.setFieldError(
          'users',
          'Sie müssen sich selbst dem Projekt zuordnen, um zu speichern.'
        )
        return
      }

      this.saving = true
      try {
        const savedProject = await this.projectStore.save(values)
        if (this.create) {
          await this.$router.push({
            name: 'project-edit',
            params: { projectId: savedProject.id }
          })
          ElMessage.success('Das Projekt wurde angelegt. Sie können es nun konfigurieren')
        } else {
          ElMessage.success('Projekt wurde erfolgreich gespeichert.')
        }
      } finally {
        this.saving = false
      }
    }
  }
})
</script>

<template>
  <CenteredPage :title="create ? 'Neues Projekt' : 'Projektdaten'" max-width="80rem">
    <div v-if="project" class="max-w-[45rem] mx-auto">
      <p-form
        :cancelable="create"
        :item="project"
        :saving="saving"
        @cancel="$router.back()"
        @submit="save"
      >
        <el-tabs v-model="activeTab">
          <!-- Allgemein -->
          <el-tab-pane :name="ProjectTabs.general" label="Allgemein">
            <p-field v-bind="fieldConfig.name" />
            <p-field v-bind="fieldConfig.customer" />
            <p-field :filterable="false" v-bind="fieldConfig.mediaType" />
            <p-field :filterable="false" v-bind="fieldConfig.crs" />
            <p-field v-bind="{ ...fieldConfig.users, items: userStore.userItems }" />
          </el-tab-pane>

          <!-- HSBLib-Parameters -->
          <el-tab-pane :name="ProjectTabs.hsb_lib_parameters" label="HSBLib-Parameter">
            <div class="table-simple table-simple--striped overflow-auto">
              <table style="width: 100%">
                <thead>
                  <tr>
                    <th>Parameter</th>
                    <th class="w-20">HSBLib-Standard benutzen</th>
                    <th style="max-width: 150px">Benutzerdefinierter Wert</th>
                  </tr>
                </thead>
                <tbody>
                  <ProjectHSBLibParameterFieldRow
                    v-for="(field, index) in hsbLibSettingFields"
                    :key="index"
                    :field-config="field.config"
                    :initial-value="field.initialValue"
                  />
                </tbody>
              </table>
            </div>
          </el-tab-pane>
        </el-tabs>
      </p-form>
    </div>
  </CenteredPage>
</template>

<style lang="css" scoped>
:deep([data-field='name'] input) {
  font-weight: 600 !important;
}
</style>
