import z from 'zod'
import { HSBLibItemsEnum, xyPositionTupleArray } from '@/config/schemas/hsb-lib/util'
import { arrayEquals } from '@/util/helpers'

export type HSBLibCoordinate = [number, number]
/**
 * Schema used for CSV string parsing
 * Only pick keys important to create a Tower for GUI
 * https://gridside.gitlab.io/hsb-backend/hsblib_api.html#hsblib.Tower
 */
export const HSBLibTowerSchema = z.object({
  objclass: z.literal(HSBLibItemsEnum.Tower),
  name: z.string(),
  // conductor_positions: HSBLibCoordinate[] | [HSBLibCoordinate, HSBLibCoordinate][]
  // earthwire_positions: HSBLibCoordinate[] | [HSBLibCoordinate, HSBLibCoordinate][]
  // conductors: string[]
  // earthwires: string[]
  earth_resistivity: z.number(),
  // is_transposition_tower: boolean
  // tower_object_before_merging: null | any
  // tower_object_to_be_merged: null | any
  // merged_tower_object: null | any
  incoming_phase_conductors_positions: xyPositionTupleArray,
  outgoing_phase_conductors_positions: xyPositionTupleArray,
  incoming_earthwire_positions: xyPositionTupleArray,
  outgoing_earthwire_positions: xyPositionTupleArray,
  incoming_phase_conductors_objects: z.string().array(),
  outgoing_phase_conductors_objects: z.string().array(),
  incoming_earthwire_objects: z.string().array(),
  outgoing_earthwire_objects: z.string().array()
})

export type HSBLibTower = z.infer<typeof HSBLibTowerSchema>

/**
 * True when ingoing positions of earthwire or conductor are not equal to their outgoing part
 */
export function hasChangingAllocationPositions(towerData: HSBLibTower) {
  return (
    !equalCoordinates(
      towerData.outgoing_earthwire_positions,
      towerData.incoming_earthwire_positions
    ) ||
    !equalCoordinates(
      towerData.outgoing_phase_conductors_positions,
      towerData.incoming_phase_conductors_positions
    )
  )
}

/**
 * True if conductor types are not the same
 */
export function hasChangingAllocationConductors(towerData: HSBLibTower) {
  return !equalConductors(
    towerData.incoming_phase_conductors_objects,
    towerData.outgoing_phase_conductors_objects
  )
}

function equalCoordinates(a: HSBLibCoordinate[], b: HSBLibCoordinate[]) {
  return a.length === b.length && a.every((val, index) => arrayEquals(val, b[index]))
}

function equalConductors(a: string[], b: string[]) {
  return a.length === b.length && arrayEquals(a, b)
}
