import { ProjectId, TowerType, TowerTypeId } from '@/model'
import { HttpClient, PaginatedResponse } from '@/api/useHttpClient'
import { WebSocket } from '@/api/useWebSocket'
import { HSBApi } from '@/api/HSBApiBase'

const path = (towerTypeId?: TowerTypeId) => {
  return `towerTypes` + (towerTypeId ? `/${towerTypeId}` : '')
}
const pathProject = (projectId: ProjectId, towerTypeId?: TowerTypeId) => {
  return `towerTypes/project/${projectId}` + (towerTypeId ? `/${towerTypeId}` : '')
}

export class TowerTypeApiClass implements HSBApi {
  constructor(public httpClient: HttpClient, public webSocket: WebSocket) {}
  async delete(towerType: TowerType) {
    return await this.httpClient.delete(path(towerType.id))
  }

  async getAll(projectId?: ProjectId): Promise<TowerType[]> {
    const res = await this.httpClient.get<PaginatedResponse<TowerType>>(
      projectId ? pathProject(projectId) : path()
    )
    return res.data.results || []
  }

  async save(towerType: TowerType): Promise<TowerType> {
    const res = await this.httpClient.put<TowerType>(path(towerType.id), { data: towerType })
    return res.data
  }
}
