<script lang="ts" setup>
import { Tile as TileLayer } from 'ol/layer'
import { TileJSON } from 'ol/source'
import { getEnv } from '@/util'
import LayerBase from '../LayerBase.vue'

defineProps({ id: { type: String, required: true } })

const maptilerKey = getEnv('VITE_MAPTILER_API_KEY')
const layer = new TileLayer({
  source: new TileJSON({
    url: `https://api.maptiler.com/maps/hybrid/tiles.json?key=${maptilerKey}`,
    tileSize: 512,
    crossOrigin: 'anonymous'
  })
})
</script>

<template>
  <LayerBase v-bind="$attrs" :id="id" :layer="layer" />
</template>
