<script lang="ts">
import { UseMapItems } from '@/components/map/composables/useMap'
import { useEventListener } from '@vueuse/core'
import { defineComponent, inject } from 'vue'
import { UseMapInjectKeys } from '@/components/map/composables/useMapInjectKeys'

export default defineComponent({
  name: 'ZoomControl',

  setup() {
    const { fullscreen, zoomIn, zoomOut, zoomToFit } = inject(
      UseMapInjectKeys.useMap
    ) as UseMapItems

    useEventListener(document, 'fullscreenchange', () => {
      fullscreen.value = !!document.fullscreenElement
    })

    return { fullscreen, zoomIn, zoomOut, zoomToFit }
  },

  methods: {
    toggleFullscreen() {
      let elem = document.documentElement

      if (!document.fullscreenElement) {
        elem.requestFullscreen().then(() => {
          this.fullscreen = true
        })
      } else {
        document.exitFullscreen()
        this.fullscreen = false
      }
    }
  }
})
</script>

<template>
  <div class="w-full flex mt-3 mb-4">
    <el-button-group class="flex-1 !flex">
      <p-btn @click="zoomOut" title="verkleinern" class="flex-1">
        <el-icon size="large"><ZoomOutIcon /></el-icon>
      </p-btn>
      <p-btn @click="zoomToFit" title="Karte einpassen" class="flex-1">
        <el-icon size="large"><ZoomFitIcon /></el-icon>
      </p-btn>
      <p-btn @click="zoomIn" title="vergrößern" class="flex-1">
        <el-icon size="large"><ZoomInIcon /></el-icon>
      </p-btn>
    </el-button-group>
    <p-btn v-if="!fullscreen" @click="toggleFullscreen" title="Vollbild" class="ml-2">
      <el-icon size="large">
        <FullscreenIcon />
      </el-icon>
    </p-btn>
    <p-btn v-else @click="toggleFullscreen" title="Vollbild" class="ml-2">
      <el-icon size="large">
        <ExitFullscreenIcon />
      </el-icon>
    </p-btn>
  </div>
</template>

<style scoped lang="css"></style>
