/**
 * Symbols **must** be in their own files
 */

import { InjectionKey, Ref } from 'vue'
import { Feature, Map } from 'ol'
import { UseMapItems } from '@/components/map/composables/useMap'

/**
 * Type provide and inject
 * See: https://vuejs.org/guide/typescript/composition-api.html#typing-provide-inject
 */
export const UseMapInjectKeys = Object.freeze({
  map: Symbol() as InjectionKey<Map>,
  useMap: Symbol() as InjectionKey<UseMapItems>,
  selectedFeatures: Symbol() as InjectionKey<Ref<Feature[]>>
})
