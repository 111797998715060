import { useHttpClient } from '@/api/useHttpClient'
import { useWebSocket } from '@/api/useWebSocket'

import { ConductorAllocationApiClass } from './classes/ConductorAllocationApi'
import { CalculationApiClass } from './classes/CalculationApi'
import { ConductorTypeApiClass } from './classes/ConductorTypeApi'
import { EpsgApiClass } from './classes/EpsgApi'
import { HealthApiClass } from '@/api/classes/HealthApi'
import { MediumApiClass } from './classes/MediumApi'
import { OperationalModeApiClass } from './classes/OperationalModeApi'
import { PipeTypeApiClass } from './classes/PipeTypeApi'
import { ProjectApiClass } from './classes/ProjectApi'
import { ResultApiClass } from '@/api/classes/ResultApi'
import { SpanApiClass } from './classes/SpanApi'
import { SystemApiClass } from './classes/SystemApi'
import { TowerApiClass } from './classes/TowerApi'
import { TowerTypeApiClass } from './classes/TowerTypeApi'
import { UserApiClass } from './classes/UserApi'

export * from './useWebSocket'
export * from './WebsocketError'

const httpClient = useHttpClient()
const webSocket = useWebSocket()

/**
 * Provide APIs using default HttpClient and WebSocket
 */
export const ConductorAllocationApi = new ConductorAllocationApiClass(httpClient, webSocket)
export const CalculationApi = new CalculationApiClass(httpClient, webSocket)
export const ConductorTypeApi = new ConductorTypeApiClass(httpClient, webSocket)
export const EpsgApi = new EpsgApiClass(httpClient, webSocket)
export const HealthApi = new HealthApiClass(httpClient, webSocket)
export const MediumApi = new MediumApiClass(httpClient, webSocket)
export const OperationalModeApi = new OperationalModeApiClass(httpClient, webSocket)
export const PipeTypeApi = new PipeTypeApiClass(httpClient, webSocket)
export const ProjectApi = new ProjectApiClass(httpClient, webSocket)
export const ResultApi = new ResultApiClass(httpClient, webSocket)
export const SpanApi = new SpanApiClass(httpClient, webSocket)
export const SystemApi = new SystemApiClass(httpClient, webSocket)
export const TowerApi = new TowerApiClass(httpClient, webSocket)
export const TowerTypeApi = new TowerTypeApiClass(httpClient, webSocket)
export const UserApi = new UserApiClass(httpClient, webSocket)

export type ServerResponse<T = any> = {
  status: 200 | 400 | 403 | 404 | 409
  data: T
  error?: {
    errorData?: any
    message: string
    request?: any
  }
}
