<template>
  <el-skeleton animated>
    <template #template>
      <div class="relative flex space-x-16 shadow rounded px-4 bg-white">
        <div v-for="(w, index) in [4, 2, 1, 2]" :key="index" :style="{ width: w, flex: w }">
          <el-skeleton-item variant="text" class="my-3 !h-4 !w-1/2" />
          <div v-for="i in 4" :key="i" class="my-7">
            <el-skeleton-item
              variant="h1"
              :style="`width: ${50 + Math.random() * 40}%; height: 14px`"
            />
          </div>
        </div>
        <div class="absolute inset-x-0 top-0 !m-0 h-10 bg-gray-50 border-b border-b-gray-200"></div>
      </div>
    </template>
  </el-skeleton>
</template>
