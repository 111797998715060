import { MediaLayerContext } from '@/components/map/layer/util'
import { MediumId } from '@/model'
import { Collection, Feature } from 'ol'
import { singleClick } from 'ol/events/condition'
import { LineString } from 'ol/geom'
import { Modify, Snap } from 'ol/interaction'
import { ModifyEvent } from 'ol/interaction/Modify'
import { onBeforeUnmount, watch } from 'vue'
import { updateMediumPath } from './functions'

let modifyInteraction: Modify | undefined
let snapInteraction: Snap | undefined

export default function useMediaModify(ctx: MediaLayerContext) {
  function removeInteraction() {
    if (modifyInteraction) {
      ctx.map.removeInteraction(modifyInteraction)
      modifyInteraction = undefined
    }
    if (snapInteraction) {
      ctx.map.removeInteraction(snapInteraction)
      snapInteraction = undefined
    }
  }

  watch(
    ctx.selectedMedia,
    () => {
      removeInteraction()
      modifyInteraction = new Modify({
        features: new Collection<Feature>(ctx.selectedMedia.value),
        deleteCondition: singleClick
      })
      modifyInteraction.on('modifyend', (e: ModifyEvent) => {
        const feature = e.features.item(0)
        updateMediumPath(
          feature.getId() as MediumId,
          (feature.getGeometry() as LineString).getCoordinates(),
          ctx
        )
        removeInteraction()
      })
      ctx.map.addInteraction(modifyInteraction)

      const source = ctx.layer.getSource()
      if (source) {
        snapInteraction = new Snap({ source })
        ctx.map.addInteraction(snapInteraction)
      }
    },
    { immediate: true }
  )

  onBeforeUnmount(removeInteraction)

  return { removeInteraction }
}
