import { LogLevelEnum, OperationalModeId, ProjectId } from '@/model'
import {
  CalculationResult,
  CalculationStatus,
  CalculationStatusTypo,
  HsbLibObjectEnum,
  CalculationLogMsg,
  CalculationResultTypo
} from '@/model/calculation'

import { HSBApiBase } from '@/api/HSBApiBase'
import { GeoJSON } from 'geojson'

function path(projectId: string, endpoint?: string) {
  return `projects/${projectId}/calculation/${endpoint}`
}

export class CalculationApiClass extends HSBApiBase {
  async cancel(payload: { project: ProjectId }) {
    await this.httpClient.post<{}>(path(payload.project, 'cancel'))
  }

  async clear(payload: { project: ProjectId }) {
    await this.httpClient.post<{}>(path(payload.project, 'clear'))
  }

  async getCorridor(projectId: ProjectId) {
    return (await this.httpClient.get<GeoJSON>(path(projectId, 'corridor'))).data
  }

  async getLog(projectId: ProjectId, asList: boolean = false) {
    return (
      (
        await this.httpClient.get<CalculationLogMsg[]>(
          path(projectId, `log${asList ? '?as_list' : ''}`)
        )
      ).data || []
    )
  }

  async getResult(projectId: ProjectId): Promise<CalculationResult | null> {
    const response = await this.httpClient.get<CalculationResultTypo>(path(projectId, 'result'))
    if (response.status === 204) {
      // no result available
      return null
    } else {
      // TODO: fix typo in API
      return { operationalMode: response.data.operationlmode, ...response.data }
    }
  }

  async hsbLibObject(projectId: ProjectId, type: HsbLibObjectEnum) {
    return (await this.httpClient.get<string>(path(projectId, type))).data
  }

  async getStatus(projectId: ProjectId) {
    const response = (await this.httpClient.get<CalculationStatusTypo>(path(projectId, 'status')))
      .data
    // TODO: fix typo in API
    return { operationalMode: response.operationlmode, ...response } as CalculationStatus
  }

  onLogUpdated(
    handler: (log: { project_id: ProjectId; data: string; level: LogLevelEnum }) => void
  ) {
    this.webSocket.subscribe('Calculation.logUpdated', handler)
    return this.makeUnsubscribeFn('Calculation.logUpdated', handler)
  }

  onStatusUpdated(handler: (status: CalculationStatus) => void) {
    // TODO fix typo in Backend API
    const handlerFixed = (status: CalculationStatusTypo) => {
      const fixedStatus = {
        operationalMode: status.operationlmode,
        ...status
      }
      return handler(fixedStatus)
    }
    this.webSocket.subscribe('Calculation.statusUpdated', handlerFixed)
    return this.makeUnsubscribeFn('Calculation.statusUpdated', handlerFixed)
  }

  async start(payload: { project: ProjectId; operationalMode: OperationalModeId }) {
    // Uncomment once the API is implemented correctly
    // await this.httpClient.post(path(payload.project, 'start'), { data: payload })

    await this.webSocket.request('Calculation.start', payload)
  }
}
