import { computed, Ref, ref } from 'vue'
import { Feature } from 'ol'
import { Select } from 'ol/interaction'

const selectedFeatures = ref<Feature[]>([])
const selectedIds = computed(() => selectedFeatures.value.map((f) => f.getId()))
const interactions: Select[] = []

/**
 * Deselects all features
 */
function clear() {
  selectedFeatures.value = []
  interactions.forEach((interaction) => interaction.getFeatures().clear())
}

/**
 * Deselects a feature
 */
function deselect(feature: Feature | string) {
  const featureId = typeof feature === 'string' ? feature : feature.getId()
  selectedFeatures.value = selectedFeatures.value.filter((item) => item.getId() !== featureId)
}

/**
 * Selects a feature
 */
function select(feature: Feature) {
  if (!selectedIds.value.includes(feature.getId())) {
    selectedFeatures.value = [...selectedFeatures.value, feature]
  }
}

/**
 * Registers an OpenLayers Select interaction to let it update the selectedFeatures list
 *
 * @param interaction
 */
function registerInteraction(interaction: Select) {
  if (!interactions.includes(interaction)) {
    interactions.push(interaction)
    interaction.getFeatures().on('add', (event) => {
      select(event.element)
    })
    interaction.getFeatures().on('remove', (event) => {
      deselect(event.element)
    })
  }
}

/**
 * Composable to manage the global map selection state of Features
 */
export function useMapSelection() {
  return {
    clear,
    deselect,
    registerInteraction,
    select,
    selectedFeatures: selectedFeatures as Ref<Feature[]>
  }
}
