<script lang="ts">
import { defineComponent } from 'vue'
import { useAuthentication } from '@prionect/ui'

export default defineComponent({
  name: 'UserMenu',

  computed: {
    email(): string {
      return this.currentUser?.email || ''
    },

    userName(): string {
      return this.currentUser?.name || ''
    }
  },

  setup() {
    const { currentUser, logout } = useAuthentication()
    return { currentUser, logout }
  }
})
</script>

<template>
  <el-dropdown trigger="click" placement="bottom-end" data-component="user-menu">
    <el-button text>
      {{ userName }}
      <el-icon class="el-icon--right">
        <UserIcon />
      </el-icon>
    </el-button>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item disabled class="text-base">
          <span class="text-sm">{{ email }}</span>
        </el-dropdown-item>
        <el-dropdown-item divided @click="logout" icon="LogoutIcon">Abmelden</el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<style scoped lang="css"></style>
