import useMapInteraction from '@/components/map/composables/useMapInteraction'
import { useMapSelection } from '@/components/map/composables/useMapSelection'
import { HitTolerance, SourceLayerContext } from '@/components/map/layer/util'
import { sourceEditStyle } from '@/components/map/style/source'
import { SpanId, TowerId } from '@/model'
import { useSpanStore } from '@/stores/span'
import { useTowerStore } from '@/stores/tower'
import { click } from 'ol/events/condition'
import { Select } from 'ol/interaction'
import { storeToRefs } from 'pinia'
import { watch, watchEffect } from 'vue'
import { useRouter } from 'vue-router'

export default function useSourceSelect(ctx: SourceLayerContext) {
  const router = useRouter()
  const towerStore = useTowerStore()
  const spanStore = useSpanStore()
  const { clear } = useMapSelection()

  // Clear map selection when the store selection was emptied
  const { selection: towerSelection } = storeToRefs(towerStore)
  const { selection: spanSelection } = storeToRefs(spanStore)

  watch(towerSelection, (value, oldValue) => {
    if (value.length === 0 && value.length !== oldValue.length) {
      clear()
    }
  })
  watch(spanSelection, (value, oldValue) => {
    if (value.length === 0 && value.length !== oldValue.length) {
      clear()
    }
  })

  const selectInteraction = new Select({
    condition: click,
    style: sourceEditStyle,
    layers: [ctx.layer],
    hitTolerance: HitTolerance
  })

  useMapSelection().registerInteraction(selectInteraction)
  useMapInteraction(selectInteraction)

  watchEffect(async () => {
    const projectId = router.currentRoute.value.params.projectId
    if (!projectId) {
      return
    }

    const { selectedTowers, selectedSpans } = ctx

    if (selectedTowers.value.length > 0) {
      const isDraft = !!towerStore.draft
      if (isDraft) {
        towerStore.selection = towerStore.draft ? [towerStore.draft.id!] : []
        await router.push({ name: 'project-map-tower-create' })
      } else {
        towerStore.selection = selectedTowers.value.map((feature) => feature.getId() as TowerId)
        await router.push({
          name: 'project-map-tower-edit',
          params: { id: selectedTowers.value[0].getId() }
        })
      }
    } else if (selectedSpans.value.length > 0) {
      spanStore.selection = selectedSpans.value.map((feature) => feature.getId() as SpanId)
      await router.push({
        name: 'project-map-span-edit',
        params: { id: selectedSpans.value[0].getId() }
      })
    } else {
      towerStore.selection = []
      spanStore.selection = []
    }
  })

  return { selectInteraction }
}
