<script setup lang="ts">
import { UseMapItems } from '@/components/map/composables/useMap'
import { useMediumStore } from '@/stores/medium'
import { useSpanStore } from '@/stores/span'
import { useTowerStore } from '@/stores/tower'
import { inject } from 'vue'
import { UseMapInjectKeys } from '@/components/map/composables/useMapInjectKeys'

const { layers, mapState, selectedFeatures } = inject(UseMapInjectKeys.useMap) as UseMapItems
const mediumStore = useMediumStore()
const towerStore = useTowerStore()
const spanStore = useSpanStore()
</script>

<template>
  <div class="absolute top-4 left-80 z-50 w-96 bg-white p-4 text-xs rounded shadow overflow-y-auto">
    <h3 class="uppercase font-bold">MAP DEBUG PANEL</h3>
    <hr />
    <h4 class="uppercase font-medium">Selection</h4>
    <div>selectedFeatures: {{ selectedFeatures.map((feature) => feature.get('_type')) }}</div>

    <h4 class="uppercase font-medium mt-4">TowerStore</h4>
    <div>
      <em>selection count:</em>
      {{ towerStore.selection.length }}
      <br />
      <em>draft:</em>
      {{ towerStore.draft }}
    </div>

    <h4 class="uppercase font-medium mt-2">SpanStore</h4>
    <div>
      <em>selection count:</em>
      {{ spanStore.selection.length }}
      <br />
    </div>

    <h4 class="uppercase font-medium mt-2">MediumStore</h4>
    <div>
      <em>selection count:</em>
      {{ mediumStore.selection.length }}
      <br />
      <em>draft:</em>
      {{ mediumStore.draft }}
    </div>

    <h4 class="uppercase font-medium mt-4">LayerState</h4>
    <div>{{ layers }}</div>

    <h4 class="uppercase font-medium mt-4">MapState</h4>
    <div>{{ mapState }}</div>
  </div>
</template>

<style scoped lang="css"></style>
