<script lang="ts">
import EntityTable, { ActionItem, EntityItem } from '@/components/common/EntityTable.vue'
import EntityTableViewPlaceholder from '@/components/common/EntityTableViewPlaceholder.vue'
import { fieldLabel, fieldUnit } from '@/config/fields'
import CenteredPage from '@/layouts/pages/CenteredPage.vue'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'EntityTableView',
  components: { EntityTableViewPlaceholder, EntityTable, CenteredPage },
  inheritAttrs: false,

  props: {
    allowCreate: {
      type: Boolean,
      default: true
    },
    allowDelete: {
      type: Boolean,
      default: true
    },
    allowDuplicate: {
      type: Boolean,
      default: false
    },
    allowEdit: {
      type: Boolean,
      default: true
    },
    confirmDeleteLabel: {
      type: String,
      default: 'Löschen'
    },
    createLabel: {
      type: String,
      default: 'Neu'
    },
    defaultSort: {
      type: [String, Object]
    },
    emptyText: { type: String, default: 'Sie haben bisher keine Einträge erstellt.' },
    extraActions: {
      type: Array as PropType<ActionItem[]>,
      default: undefined
    },
    items: {
      type: Array as PropType<EntityItem[]>,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    multiselect: {
      type: Boolean,
      default: false
    },
    searchProperties: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    searchStrings: {
      type: Array as PropType<string[]>
    },
    selection: {
      type: Array,
      default: () => []
    },
    title: String
  },
  emits: ['create', 'edit', 'delete', 'duplicate', 'selection-change', 'update:selection'],

  data: () => ({
    fieldLabel,
    fieldUnit,
    itemToDelete: undefined as undefined | EntityItem,
    search: ''
  }),

  computed: {
    defaultSearchStrings(): string[] {
      if (this.searchProperties?.length > 0) {
        return this.items.map((item: any) => {
          return this.searchProperties
            .map((prop) => item[prop])
            .join(' ')
            .toLowerCase()
        })
      } else {
        return this.items.map((item) => {
          return Object.keys(item)
            .filter((key) => !['id', 'project'].includes(key))
            .map((key) => (item as any)[key])
            .map(
              (column: any) =>
                (typeof column !== 'object' && column?.toString().toLowerCase()) || ''
            )
            .join(' ')
        })
      }
    },

    filteredItems(): EntityItem[] {
      const needle = this.search.trim().toLowerCase()
      let items: EntityItem[] = []

      if (needle === '') {
        items = this.items
      } else {
        items = this.items.filter((item, index) => {
          return (
            (this.searchStrings && this.searchStrings[index]?.includes(needle)) ||
            this.defaultSearchStrings![index]?.includes(needle)
          )
        })
      }
      return items
    }
  },

  methods: {
    async confirmDelete(item: EntityItem) {
      this.itemToDelete = item
    },
    emitSelectionChange(value: any[]) {
      this.$emit(
        'selection-change',
        value.map((item: any) => item.id)
      )
    }
  }
})
</script>

<template>
  <CenteredPage :title="title">
    <template #top-right>
      <div class="flex space-x-4 items-center">
        <slot name="extra-tools"></slot>
        <el-input
          v-model="search"
          style="width: 30ch"
          placeholder="Suche"
          prefix-icon="SearchIcon"
        />
        <el-button
          v-if="allowCreate"
          type="primary"
          @click="$emit('create')"
          data-test="create-btn"
        >
          {{ createLabel }}
        </el-button>
      </div>
    </template>

    <EntityTable
      v-if="items"
      v-show="items.length > 0"
      :selection="selection"
      @update:selection="$emit('update:selection', $event)"
      :extra-actions="extraActions"
      :allowDelete="allowDelete"
      :allowDuplicate="allowDuplicate"
      :allowEdit="allowEdit"
      empty-text="keine Einträge gefunden"
      :default-sort="
        typeof defaultSort === 'string' ? { prop: defaultSort, order: 'ascending' } : defaultSort
      "
      :items="filteredItems"
      :multiselect="multiselect"
      data-component="entity-table"
      @delete="confirmDelete"
      @duplicate="$emit('duplicate', $event.id)"
      @edit="$emit('edit', $event.id)"
      @selection-change="emitSelectionChange"
    >
      <slot name="columns" v-bind="{ fieldLabel, fieldUnit }"></slot>
    </EntityTable>

    <!-- loading placeholder -->
    <div v-if="loading" v-show="!loading || items?.length === 0">
      <EntityTableViewPlaceholder />
    </div>

    <!-- empty view -->
    <div
      v-else-if="items?.length === 0"
      class="border-2 border-dashed rounded-lg py-32 px-[20%] text-center text-lg font-medium text-gray-400"
    >
      <slot name="empty">
        {{ emptyText }}
      </slot>
    </div>
  </CenteredPage>

  <p-dialog
    confirmLabel="Löschen"
    danger
    :show="!!itemToDelete"
    @update:show="itemToDelete = undefined"
    @confirm="itemToDelete && $emit('delete', itemToDelete.id)"
    :title="confirmDeleteLabel"
  >
    <slot name="confirm-delete" :item="itemToDelete" v-if="itemToDelete">
      Sind Sie sicher, dass Sie
      <b v-if="itemToDelete.name">{{ itemToDelete.name }}</b>
      <template v-else>den Eintrag</template>
      löschen möchten?
    </slot>
  </p-dialog>
</template>

<style scoped lang="css"></style>
