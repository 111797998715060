<script setup lang="ts">
/**
 * MediaLayer shows and controls media data in the map view
 */

import { Feature } from 'ol'
import { computed, inject, ref, watch } from 'vue'
import { GeoJSON } from 'ol/format'
import { LayerId } from '@/components/map'
import { UseMapItems } from '@/components/map/composables/useMap'
import { useMapSelection } from '@/components/map/composables/useMapSelection'
import { mediumStyle } from '@/components/map/style/media'
import useCoordinates from '@/composables/useCoordinates'
import { useProject } from '@/composables/useProject'
import { useMediumStore } from '@/stores/medium'
import useMediaContextMenu from './useMediaContextMenu'
import { deleteDraftFeatures } from './functions'
import useMediaHover from './useMediaHover'
import useMediaModify from './useMediaModify'
import { useMediaSelect } from './useMediaSelect'
import { MediaLayerContext } from '../util'
import { UseMapInjectKeys } from '@/components/map/composables/useMapInjectKeys'
import LayerBase from '@/components/map/layer/LayerBase.vue'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'

const { map, view } = inject(UseMapInjectKeys.useMap) as UseMapItems
const { selectedFeatures } = useMapSelection()

const { project } = useProject()
const mediumStore = useMediumStore()
const geoJSON = computed(() => mediumStore.geoJSON)

const source = ref<VectorSource>()

// Define OpenLayers layer
const layer = new VectorLayer({
  source: source.value,
  style: mediumStyle,
  properties: { id: LayerId.MEDIA }
})

const { mapToProject, projectToMap } = useCoordinates(map.value, project.value?.crs)
const ctx: MediaLayerContext = {
  map: map.value,
  layer,
  mapToProject,
  projectToMap,
  selectedFeatures,
  selectedMedia: computed(() => selectedFeatures.value.filter((f) => f.get('_type') === 'medium'))
}

const { selectInteraction } = useMediaSelect(ctx)
ctx.selectInteraction = selectInteraction

useMediaHover(ctx)
useMediaModify(ctx)
useMediaContextMenu(ctx)

// Delete all draft features when another or no item is selected
watch(selectedFeatures, () => {
  const selectedDraft = !!selectedFeatures.value.find((f) => f.get('_draft'))
  const selectedOtherType = !!selectedFeatures.value.find((f) => f.get('_type') !== 'medium')
  if (!selectedDraft || selectedOtherType) {
    deleteDraftFeatures(ctx)
  }
})

// Update layer source whenever the source ref updates
watch(source, () => layer.setSource(source.value || null))

// Re-read data on GeoJSON update
watch(
  geoJSON,
  () => {
    if (!project.value) {
      return
    }
    const geoJsonOptions = {
      dataProjection: project.value.crs,
      featureProjection: view.value.getProjection()
    }

    // create or update source
    if (!source.value) {
      source.value = new VectorSource({
        features: new GeoJSON(geoJsonOptions).readFeatures(geoJSON.value) as Feature[]
      })
    } else {
      const features = new GeoJSON(geoJsonOptions).readFeatures(geoJSON.value) as Feature[]
      source.value.clear()
      source.value.addFeatures(features)

      // restore selection
      const draftId = mediumStore.draft?.id
      const draftFeature = features.find((item) => item.getId() === draftId)
      if (draftFeature) {
        // We have a draft - select this one
        selectInteraction.getFeatures().clear()
        selectInteraction.getFeatures().push(draftFeature)
      } else {
        const selectedIds = selectedFeatures.value.map((feature) => feature.getId())
        useMapSelection().clear()

        features.forEach((feature) => {
          // replace each previous selected feature with the updated one
          if (selectedIds.includes(feature.getId())) {
            const previousFeature = selectedFeatures.value.find(
              (f) => f.getId() === feature.getId()
            )
            previousFeature && selectInteraction.getFeatures().remove(previousFeature)
            selectInteraction.getFeatures().push(feature)
          }
        })
      }
      selectedFeatures.value = selectInteraction.getFeatures().getArray()
    }
  },
  { immediate: true }
)
</script>

<template>
  <LayerBase
    v-if="source"
    v-bind="$attrs"
    :id="LayerId.MEDIA"
    :layer="layer"
    information-on-hover
    :z-index="5"
  />
</template>
