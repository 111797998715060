import z from 'zod'
import { ProjectExport } from '@/model'
import { ConductorAllocationSchema } from '@/config/schemas/conductor-allocation-schema'
import { TowerTypeSchema } from '@/config/schemas/tower-type-schema'
import { ConductorTypeSchema } from '@/config/schemas/conductor-type-schema'
import { MediumSchema } from '@/config/schemas/medium-schema'
import { OperationalModeSchema } from '@/config/schemas/operational-mode-schema'
import { PipeTypeSchema } from '@/config/schemas/pipe-type-schema'
import { SpanSchema } from '@/config/schemas/span-schema'
import { SystemSchema } from '@/config/schemas/system-schema'
import { TowerBaseSchema } from '@/config/schemas/tower-schema'
import { ProjectBase } from '@/config/schemas/project-schema'

const ProjectExportDataSchema: z.ZodType<ProjectExport['data']> = z.object({
  ConductorAllocation: z.array(ConductorAllocationSchema).optional(),
  ConductorType: z.array(ConductorTypeSchema).optional(),
  Medium: z.array(MediumSchema).optional(),
  OperationalMode: z.array(OperationalModeSchema).optional(),
  PipeType: z.array(PipeTypeSchema).optional(),
  Span: z.array(SpanSchema).optional(),
  System: z.array(SystemSchema).optional(),
  Tower: z.array(TowerBaseSchema).optional(),
  TowerType: z.array(TowerTypeSchema).optional()
})

export const ProjectExportSchema: z.ZodType<ProjectExport> = ProjectBase.extend({
  id: z.string(),
  data: ProjectExportDataSchema.optional()
})
