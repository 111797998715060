import { defineStore } from 'pinia'
import { v4 as uuid } from 'uuid'
import { ProjectId, OperationalMode, OperationalModeId } from '@/model'
import { OperationalModeApi } from '@/api'

export const useOperationalModeStore = defineStore('operationalMode', {
  state: () => ({
    itemsById: {} as Record<OperationalModeId, OperationalMode>,
    loaded: false,
    loading: false,
    loadedProject: undefined as ProjectId | undefined,
    selection: [] as OperationalModeId[]
  }),

  getters: {
    findById(state) {
      return (id: OperationalModeId): OperationalMode | undefined => state.itemsById[id]
    },
    items(): OperationalMode[] {
      return Object.values(this.itemsById)
    }
  },

  actions: {
    async delete(id: OperationalModeId) {
      const opMode = this.findById(id)
      if (opMode) {
        await OperationalModeApi.delete(opMode)
        delete this.itemsById[id]
      }
    },

    async ensureLoaded(projectId: ProjectId) {
      const projectChanged = this.loadedProject !== projectId
      if (!(this.loaded || this.loading) || projectChanged) {
        await this.load(projectId)
      }
    },
    async load(projectId: ProjectId) {
      this.loading = true
      const itemsById: Record<OperationalModeId, OperationalMode> = {}
      this.itemsById = {}

      try {
        if (!projectId) {
          throw new Error('OperationalModeStore.load: ProjectId must not be empty.')
        }

        this.loadedProject = projectId
        const operationalModes = await OperationalModeApi.getAllByProject(projectId)
        operationalModes.forEach((item) => {
          itemsById[item.id] = item
        })
        this.itemsById = { ...itemsById }
        this.loaded = true
      } finally {
        this.loading = false
      }
    },

    async save(item: OperationalMode) {
      if (!item.id) {
        item.id = uuid()
      }
      const updatedItem = await OperationalModeApi.save(item)
      this.itemsById = { ...this.itemsById, [item.id]: updatedItem }
      return updatedItem
    }
  }
})
