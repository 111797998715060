import { useCorridorStore } from '@/stores/corridor'
import { defineStore } from 'pinia'
import {
  CalculationResult,
  CalculationStatus,
  CalculationStatusValue,
  CalculationLogMsg
} from '@/model/calculation'
import { OperationalModeId, ProjectId, LogLevelEnum } from '@/model'
import { FeatureCollection } from 'geojson'
import { csvToJson } from '@/util'
import { useOperationalModeStore } from '@/stores/operational-mode'
import { CalculationApi, ResultApi } from '@/api'

type CalculationStoreState = {
  calculationStatus: CalculationStatus
  initialized: boolean
  limitVoltage?: number
  loadingResult: boolean
  log: CalculationLogMsg[]
  projectId?: ProjectId
  result?: CalculationResult
  showLog: boolean
  showResult: boolean
}

type CalculationStartPayload = {
  project: ProjectId
  operationalMode: OperationalModeId
}

export const useCalculationStore = defineStore('calculation', {
  state: (): CalculationStoreState => {
    return {
      calculationStatus: {
        operationalMode: undefined,
        progress: undefined,
        project: undefined,
        status: 'INACTIVE'
      },
      initialized: false,
      limitVoltage: undefined,
      loadingResult: false,
      log: [],
      projectId: undefined,
      result: undefined as CalculationResult | undefined,
      showLog: false,
      showResult: false
    }
  },

  getters: {
    isProcessing(): boolean {
      return ['PENDING', 'INITIALIZING', 'RUNNING'].includes(
        this.calculationStatus?.status || 'INACTIVE'
      )
    },

    operationalMode(): OperationalModeId | undefined {
      return this.result?.operationalMode || this.calculationStatus?.operationalMode
    },

    resultEmf(): any {
      return csvToJson(this.result?.result.emf || '')
    },

    resultEmfGeoJSON(): FeatureCollection | undefined {
      return this.result?.result.emfResult
    },

    resultLoadFlow(): any {
      return csvToJson(this.result?.result.LoadFlow || '')
    },

    resultLoadFlowGeoJSON(): FeatureCollection | undefined {
      const geojson = this.result?.result.LoadFlowGeojson
      if (!geojson) {
        return undefined
      }

      return geojson
    },

    resultRelations(): FeatureCollection | undefined {
      return this.result?.result.relations
    },

    resultVoltage(): number | undefined {
      return this.result?.result.resultVoltage
    },

    status(): CalculationStatusValue {
      return this.calculationStatus?.status || 'INACTIVE'
    }
  },

  actions: {
    reset() {
      const wasInitialized = this.initialized
      this.$reset()
      this.initialized = wasInitialized
    },

    init() {
      if (!this.initialized) {
        // Register listener functions on websocket
        CalculationApi.onStatusUpdated(async (status) => {
          // ignore updates for other projects
          if (status.project !== this.projectId) {
            return
          }

          this.calculationStatus = status
          if (this.status === 'FINISHED') {
            await this.loadResult()
          }

          if (
            this.projectId &&
            status.status === 'INACTIVE' &&
            status.message === 'container running'
          ) {
            // corridor is now available (the worker container was not started before)
            useCorridorStore().load(this.projectId)
          }
        })

        CalculationApi.onLogUpdated((entry) => {
          // ignore updates for other projects
          if (entry.project_id === this.projectId) {
            this.log.push(entry)
          }
        })

        ResultApi.onInvalidated((data) => {
          if (data.project === this.projectId) {
            this.reset()
            // project stays the same
            this.projectId = data.project
          }
        })

        this.initialized = true
      }
    },

    async loadResult() {
      const projectId = this.calculationStatus?.project

      if (projectId) {
        this.result = (await CalculationApi.getResult(projectId)) || undefined
      }
    },

    async start(payload: CalculationStartPayload) {
      this.log = []
      this.showLog = true
      this.result = undefined

      const opStore = useOperationalModeStore()
      await opStore.ensureLoaded(payload.project)
      const opMode = opStore.findById(payload.operationalMode)
      if (opMode) {
        this.limitVoltage = opMode.limitVoltage
      }

      this.calculationStatus.status = 'PENDING'
      await CalculationApi.start(payload)
    },

    async ensureLoaded(project: ProjectId) {
      this.projectId = project

      if (project === this.calculationStatus?.project) {
        return
      }

      // Load results independently of the calculation status (which is worker-dependent)
      if (!this.loadingResult) {
        // avoid race conditions
        this.loadingResult = true

        CalculationApi.getResult(project)
          .then((data) => {
            if (data !== null) {
              this.result = data
            } else {
              this.result = undefined
            }
          })
          .finally(() => (this.loadingResult = false))
      }

      this.calculationStatus = await CalculationApi.getStatus(project)

      const opStore = useOperationalModeStore()
      await opStore.ensureLoaded(project)
      const operationalModeId =
        this.calculationStatus?.operationalMode || this.result?.operationalMode

      if (operationalModeId) {
        const opMode = opStore.findById(operationalModeId)
        if (opMode) {
          this.limitVoltage = opMode.limitVoltage
        }
      }

      this.log = await CalculationApi.getLog(project, true)
    }
  }
})
