<script lang="ts">
import { span } from '@/config/fields'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SpanPropertiesStep',
  data: () => ({
    fieldConfig: span
  })
})
</script>

<template>
  <div class="flex space-x-12">
    <div class="flex-1">
      <p-form-section title="Allgemeine Angaben">
        <p-field v-bind="fieldConfig.corridor" name="span.corridor" />
        <p-field v-bind="fieldConfig.sag" name="span.sag" />
      </p-form-section>
    </div>

    <div class="flex-1">
      <p-form-section title="Reduktionsfaktoren">
        <p-field v-bind="fieldConfig.rx" name="span.rx" required />
        <p-field v-bind="fieldConfig.rs" name="span.rs" required />
        <p-field v-bind="fieldConfig.rk" name="span.rk" required />
      </p-form-section>
    </div>
  </div>
</template>
