<script lang="ts">
import { defineComponent } from 'vue'
import { PFileInput } from '@prionect/ui'

export default defineComponent({
  name: 'FileInputResettable',
  props: {
    accept: {
      required: true,
      type: String
    },
    dropzoneClass: {
      required: false,
      default: '',
      type: [Object, Array, String]
    },
    dropzoneStyle: {
      required: false,
      default: '',
      type: [Object, Array, String]
    }
  },
  data() {
    return {
      file: null as null | File
    }
  },
  emits: ['change'],
  methods: {
    handleFileChange(file: File) {
      this.file = file
      this.$emit('change', file)
    },
    resetFile() {
      this.file = null
      this.$emit('change', undefined)
      this.$nextTick(() => {
        ;(this.$refs.input as InstanceType<typeof PFileInput>).$el.click()
      })
    }
  }
})
</script>

<template>
  <div>
    <div v-if="file" class="text-center text-sm h-44 border rounded p-4">
      <div>Ausgewählte Datei:</div>
      <div class="font-bold mb-8">{{ file.name }}</div>
      <p-btn style="white-space: normal; word-break: break-word; height: auto" @click="resetFile">
        andere Datei wählen
      </p-btn>
    </div>
    <template v-else>
      <p-file-input
        v-bind="$attrs"
        class="h-44"
        ref="input"
        :accept="accept"
        :class="dropzoneClass"
        :style="dropzoneStyle"
        @change="handleFileChange"
      ></p-file-input>
      <slot></slot>
    </template>
  </div>
</template>

<style lang="css" scoped></style>
