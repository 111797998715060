import { ProjectionSpecs } from '@/api/classes/EpsgApi'

/**
 * List of all known geographic map projections, so we don't need to fetch every projection from EPGS.io
 */
export enum ProjectionTypesEnum {
  EPSG3857 = 'EPSG:3857',
  EPSG5652 = 'EPSG:5652',
  EPSG5653 = 'EPSG:5653',
  EPSG25832 = 'EPSG:25832',
  EPSG25833 = 'EPSG:25833',
  EPSG32632 = 'EPSG:32632',
  EPSG32633 = 'EPSG:32633'
}
const Projections: ProjectionSpecs = {
  [ProjectionTypesEnum.EPSG3857]: {
    name: 'Pseudo-Mercator (Google Maps, OpenStreetMap, Bing) / WGS 84',
    bbox: [85.06, -180, -85.06, 180],
    proj4:
      '+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs +type=crs'
  },
  [ProjectionTypesEnum.EPSG5652]: {
    name: 'ETRS89 / UTM zone 32N (N-zE)',
    bbox: [55.47, 6, 47.27, 12],
    proj4:
      '+proj=tmerc +lat_0=0 +lon_0=9 +k=0.9996 +x_0=32500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs'
  },
  [ProjectionTypesEnum.EPSG5653]: {
    name: 'ETRS89 / UTM zone 33N (N-zE)',
    bbox: [55.03, 11.57, 47.46, 15.04],
    proj4:
      '+proj=tmerc +lat_0=0 +lon_0=15 +k=0.9996 +x_0=33500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs'
  },
  [ProjectionTypesEnum.EPSG25832]: {
    name: 'ETRS89 / UTM zone 32N',
    bbox: [84.33, 6, 38.76, 12.01],
    proj4: '+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs'
  },
  [ProjectionTypesEnum.EPSG25833]: {
    name: 'ETRS89 / UTM zone 33N',
    bbox: [84.42, 12, 46.4, 18.01],
    proj4: '+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs'
  },
  [ProjectionTypesEnum.EPSG32632]: {
    name: 'WGS 84 / UTM zone 32N',
    bbox: [84, 6, 0, 12],
    proj4: '+proj=utm +zone=32 +datum=WGS84 +units=m +no_defs +type=crs'
  },
  [ProjectionTypesEnum.EPSG32633]: {
    name: 'WGS 84 / UTM zone 33N',
    bbox: [84, 12, 0, 18],
    proj4: '+proj=utm +zone=33 +datum=WGS84 +units=m +no_defs +type=crs'
  }
}
export default Projections
