<script lang="ts">
import { useProjectStore } from '@/stores/project'
import { unifyProjectionCode } from '@/util'
import deepmerge from 'deepmerge'
import { Feature, FeatureCollection, LineString } from 'geojson'
import { defineComponent, PropType } from 'vue'
import PreviewMap from '@/components/map/PreviewMap.vue'
import { mediumStyle } from '@/components/map/style/media'

export default defineComponent({
  name: 'SelectMediumFileStep',
  components: { PreviewMap },

  props: {
    isValid: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: Object as PropType<FeatureCollection | null>
    },
    file: {
      type: Object as PropType<File>
    }
  },

  data: () => ({
    errors: [] as string[],
    geoJSON: undefined as FeatureCollection | undefined,
    mediumStyle,
    selectedFile: undefined as File | undefined,
    validated: false
  }),

  computed: {
    crs(): string | undefined {
      const crsName = (this.geoJSON as any)?.crs?.properties?.name || undefined
      return unifyProjectionCode(crsName)
    },

    featureCount() {
      const features = this.geoJSON?.features || []
      return features.length
    },

    fileIsValid() {
      return this.selectedFile && this.validated && this.errors.length === 0
    },

    geoJsonWithPoints() {
      if (!this.geoJSON) {
        return undefined
      }
      const json = deepmerge({}, this.geoJSON)

      // Add towers as points
      const lineCoordinates = (json.features[0].geometry as LineString).coordinates
      lineCoordinates.forEach((coord) => {
        json.features.push({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: coord as [number, number]
          },
          properties: {}
        })
      })

      return json
    },

    projectCrs() {
      const projectId = this.$route.params.projectId as string
      const project = useProjectStore().findById(projectId)
      return project?.crs
    },

    pointCount() {
      const features = this.geoJSON?.features || []
      return features.reduce(
        (sum, item) => sum + (item.geometry ? (item.geometry as LineString).coordinates.length : 0),
        0
      )
    }
  },

  mounted() {
    if (this.modelValue) {
      this.geoJSON = this.unifyGeoJson(this.modelValue)
    }
    if (this.file) {
      this.onFileSelected(this.file)
    }
  },

  watch: {
    fileIsValid() {
      this.$emit('update:isValid', this.fileIsValid)
    },
    geoJSON() {
      this.$emit('update:modelValue', this.geoJSON)
    }
  },

  methods: {
    async onFileSelected(file: File) {
      if (!file) {
        return
      }

      this.selectedFile = file
      this.geoJSON = undefined
      this.errors = []

      // Read actual data from file
      const data = await file.text()
      let rawJson: FeatureCollection | undefined = undefined

      try {
        rawJson = JSON.parse(data)
        this.geoJSON = this.unifyGeoJson(rawJson as FeatureCollection)
      } catch (e) {
        this.errors.push('Datei ist nicht im GeoJSON-Format')
        return
      }

      this.$emit('update:file', file)
      this.validateJson()
    },

    unifyGeoJson(rawJson: FeatureCollection) {
      if (!rawJson) {
        return undefined
      }

      const validFeatures: Feature[] = []
      rawJson.features.forEach((feature) => {
        // skip if feature has no geometry
        if (!feature.geometry) {
          return
        }

        // convert MultiLineString to LineString
        if (feature.geometry.type === 'MultiLineString') {
          validFeatures.push({
            ...feature,
            geometry: {
              ...feature.geometry,
              type: 'LineString',
              coordinates: feature.geometry.coordinates[0]
            }
          })
          return
        }

        // accept LineStrings only
        if (feature.geometry.type !== 'LineString') {
          return
        }

        validFeatures.push(feature)
      })

      return { ...rawJson, features: validFeatures }
    },

    validateJson() {
      this.validated = false
      this.errors = []

      let featuresNonLineString = 0

      const json = this.geoJSON

      if (json?.type !== 'FeatureCollection') {
        this.errors.push('Datei ist keine gültige GeoJSON-Datei.')
        return
      }

      if (!(json?.features.length > 0 && json?.features[0].geometry.type === 'LineString')) {
        this.errors.push(
          'Quellverlauf kann nicht gelesen werden. Erwartet wird ein Feature vom Typ "LineString".'
        )
        return
      }

      json.features.forEach((f) => {
        if (f.geometry?.type !== 'LineString') {
          featuresNonLineString++
        }
      })

      if (featuresNonLineString > 0) {
        this.errors.push(
          `${featuresNonLineString} von ${json.features.length} Features sind nicht vom Typ "LineString".`
        )
      }

      this.validated = true
    }
  }
})
</script>

<template>
  <div class="flex space-x-8 min-h-[60vh]">
    <div class="flex-1">
      <p-form-section title="Datei auswählen">
        <p-file-input label="GeoJSON-Datei hierher ziehen oder klicken" @change="onFileSelected" />
      </p-form-section>

      <p-form-section v-if="selectedFile" :title="selectedFile && selectedFile.name">
        <div v-if="fileIsValid" class="flex space-x-6 px-6">
          <p-success-icon class="w-12 h-12 text-success-500" />
          <div class="mt-1">
            <p class="font-semibold">Das Dateiformat ist gültig.</p>
            <p>
              {{ $n(featureCount) }} {{ featureCount === 1 ? 'Medium' : 'Medien' }} mit
              {{ $n(pointCount) }} Punkten
            </p>
            <p>Koordinaten-Referenzsystem: {{ crs }}</p>

            <div
              v-if="projectCrs && crs && projectCrs !== crs"
              class="mt-4 text-sm p-4 rounded bg-warning-200 text-warning-800"
            >
              <p>
                Das Referenzsystem der GeoJSON-Datei unterscheidet sich von den Projekteinstellungen
                ({{ projectCrs }}).
              </p>

              <p class="mt-2">
                Wenn Sie den Import durchführen, wird das Referenzsystem des Projektes auf
                <b>{{ crs }}</b>
                gesetzt. Vorhandene Koordinaten werden ungültig.
              </p>
            </div>
          </div>
        </div>
        <div v-else-if="selectedFile && !fileIsValid" class="flex space-x-6 px-6">
          <p-error-icon class="w-12 h-12 text-error-500" />
          <div class="space-y-2">
            <p class="font-semibold">Fehler beim Lesen der Datei:</p>
            <p v-for="(error, index) in errors" :key="index">{{ error }}</p>
          </div>
        </div>
      </p-form-section>
    </div>
    <div class="flex-1 flex flex-col">
      <p-form-section class="!my-0" title="Vorschau" />
      <preview-map
        v-if="fileIsValid"
        :crs="crs"
        :geojson="geoJSON"
        :mapStyle="mediumStyle"
        class="flex-1"
      />
      <div v-else class="bg-gray-100 rounded flex-1 flex justify-center items-center">
        <p class="w-2/3 text-center text-gray-400 text-sm">
          Bitte wählen Sie zunächst links eine Importdatei,
          <br />
          um die Kartenvorschau zu sehen.
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="css" scoped></style>
