<script lang="ts">
import { defineComponent } from 'vue'
import LibraryNavigation from '@/components/navigation/LibraryNavigation.vue'
import SecondaryNavigation from '@/components/navigation/SecondaryNavigation.vue'

export default defineComponent({
  name: 'LibraryView',
  components: { SecondaryNavigation, LibraryNavigation },
  setup() {
    return {}
  }
})
</script>

<template>
  <SecondaryNavigation class="w-[12rem]">
    <LibraryNavigation />
  </SecondaryNavigation>
  <div class="flex-1 overflow-auto bg-white">
    <router-view />
  </div>
</template>

<style scoped lang="css"></style>
