import { constrainedSize, lineWidth, State } from '@/components/map/style/util'
import { Feature } from 'ol'
import { Circle, Fill, Stroke, Style, Text } from 'ol/style'
import constants from './constants'
import { StyleFunction } from 'ol/style/Style'
import { FeatureLike } from 'ol/Feature'

function baseTextStyle(feature: FeatureLike, resolution: number, state: State = 'default') {
  const type = feature.get('_type')
  return resolution < 10 || state === 'select'
    ? new Text({
        placement: 'point',
        textAlign: 'start',
        text: type === 'tower' ? feature.get('name') || feature.get('position')?.toString() : '',
        offsetX: 1.4 * pointRadius(resolution),
        offsetY: -1.4 * pointRadius(resolution),
        font: `bold ${constrainedSize(13, 14, resolution)}px ${constants.fontFamily}`,
        fill: new Fill({ color: colorsByState[state] }),
        stroke: new Stroke({ color: 'rgba(255,255,255,0.8)', width: 4 })
      })
    : undefined
}

function pointRadius(resolution: number) {
  return constrainedSize(4, 12, resolution)
}

const colorsByState: Record<State, string> = {
  default: constants.source.color(),
  hover: constants.source.hoverColor(),
  select: constants.selected.color()
}

function strokeStyle(resolution: number, state: State = 'default') {
  return new Stroke({ width: lineWidth(resolution), color: colorsByState[state] })
}

function imageStyle(resolution: number, state: State = 'default') {
  return new Circle({
    radius: pointRadius(resolution),
    fill: new Fill({ color: 'white' }),
    stroke: new Stroke({
      color: colorsByState[state],
      width: 0.75 * lineWidth(resolution)
    })
  })
}

export const sourceStyle: StyleFunction = (feature, resolution) => {
  if (feature.get('_hidden')) {
    return undefined
  }
  return new Style({
    image: imageStyle(resolution),
    stroke: strokeStyle(resolution),
    text: baseTextStyle(feature, resolution)
  })
}

export const sourceHoverStyle: StyleFunction = (feature, resolution) => {
  return [
    new Style({
      image: imageStyle(resolution, 'hover'),
      stroke: strokeStyle(resolution, 'hover'),
      text: baseTextStyle(feature, resolution, 'hover')
    })
  ]
}

export const sourceEditStyle: StyleFunction = (feature, resolution) => {
  return [
    new Style({
      stroke: new Stroke({
        color: 'white',
        width: lineWidth(resolution) + 4
      })
    }),
    new Style({
      image: imageStyle(resolution, 'select'),
      stroke: strokeStyle(resolution, 'select'),
      text: baseTextStyle(feature, resolution, 'select')
    })
  ]
}

export function sourceModifyStyle(feature: Feature, resolution: number) {
  return new Style({
    image: new Circle({
      radius: pointRadius(resolution) + 4,
      stroke: new Stroke({
        color: constants.selected.color(),
        width: 2
      })
    })
  })
}
