import { inject, onBeforeUnmount, watch } from 'vue'

import { useTowerStore } from '@/stores/tower'
import { Point } from 'ol/geom'
import { Modify } from 'ol/interaction'

import { Collection, Feature, Map } from 'ol'
import { SourceLayerContext } from '../util'
import { sourceEditStyle } from '@/components/map/style/source'
import { UseMapInjectKeys } from '@/components/map/composables/useMapInjectKeys'

export default function useSourceModify(ctx: SourceLayerContext) {
  const map = inject(UseMapInjectKeys.map) as Map
  let modifyInteraction: Modify | undefined = undefined

  const selectedFeatures = ctx.selectedFeatures
  watch(selectedFeatures, () => {
    removeInteraction()
    const towers = selectedFeatures.value.filter((feature) => feature.get('_type') === 'tower')
    if (towers.length > 0) {
      modifyInteraction = new Modify({
        features: new Collection<Feature>(towers),
        style: sourceEditStyle
      })
      map.addInteraction(modifyInteraction)
      modifyInteraction.on('modifyend', (event) => {
        // save tower position
        const towerFeature = event.features.item(0) as Feature<Point>
        const towerStore = useTowerStore()
        const tower = towerStore.findById(towerFeature.getId() as string)
        const coordinates = towerFeature.getGeometry()?.getCoordinates()!
        const transformedCoordinates = ctx.mapToProject(coordinates)

        if (tower) {
          tower.x = transformedCoordinates[0]
          tower.y = transformedCoordinates[1]
          //towerStore.save(tower)
        } else if (towerStore.draft) {
          towerStore.draft.x = transformedCoordinates[0]
          towerStore.draft.y = transformedCoordinates[1]
        }
      })
    }
  })

  onBeforeUnmount(removeInteraction)

  function removeInteraction() {
    if (modifyInteraction) {
      map.removeInteraction(modifyInteraction)
    }
  }
}
