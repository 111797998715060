import { ProjectApi } from '@/api'
import { ProjectId } from '@/model'
import { ref } from 'vue'
import { ProjectionLike } from 'ol/proj'
import { Map } from 'ol'
import useCoordinates from '@/composables/useCoordinates'
import { useProject } from '@/composables/useProject'

export type MapPositionOptions = {
  map: Map
  featureCrs: ProjectionLike
}

export type UseMapPositionItems = ReturnType<typeof useMapPosition>

const defaultCenter = [1539039.7334659635, 6620523.960010368] // Gridside headquarter ;-)
const defaultZoom = 15

let previousProjectId: ProjectId

/**
 * useMapPosition()
 *
 * Composable to control position and zoom level of a map.
 *
 * This composable is intended to be used from useMap()
 *
 * @param map The map to use
 */
export function useMapPosition(options: MapPositionOptions) {
  const { map, featureCrs = 'EPSG:3857' } = options
  if (!map) {
    throw new Error('useMapPosition: Missing required option "map".')
  }

  /**
   * Initialize a new Open Layers View with needed listeners
   */
  const initializeView = () => {
    const view = map.getView()
    view.setZoom(zoom.value)
    view.setCenter(center.value)
    view.on('change:resolution', () => (zoom.value = view.getZoom() || zoom.value))
    view.on('change:center', () => (center.value = view?.getCenter() || center.value))
  }
  map.on('change:view', initializeView)

  const center = ref(defaultCenter)
  const zoom = ref(defaultZoom)

  // Zoom to fit the projects geographical extent
  const { project, projectId } = useProject()

  /**
   * Reset map position and zoom to default values
   */
  function reset() {
    setPosition(defaultCenter, defaultZoom)
  }

  /**
   * Sets both center position and zoom
   * @param newCenter
   * @param newZoom
   */
  function setPosition(newCenter?: number[], newZoom?: number) {
    const view = map.getView()
    if (newCenter) {
      center.value = newCenter
      view.setCenter(newCenter)
    }

    if (newZoom) {
      zoom.value = newZoom
      view.setZoom(newZoom)
    }
  }

  /**
   * Zoom into the map
   */
  function zoomIn() {
    zoomTo(zoom.value + 1)
  }

  /**
   * Zoom out of the map
   */
  function zoomOut() {
    zoomTo(zoom.value - 1)
  }

  /**
   * Zoom to a given level
   * @param newZoom
   */
  const zoomTo = (newZoom: number) => {
    zoom.value = newZoom
    map.getView().animate({
      zoom: zoom.value,
      duration: 250
    })
  }

  /**
   * Zoom to fit the project's extent
   */
  const zoomToFit = async () => {
    if (!project.value) {
      console.warn('[useMapPosition.zoomToFit()] Could not zoom to fit, no project loaded')
      return
    }

    const { projectToMap } = useCoordinates(map, project.value.crs)

    const extent = await ProjectApi.getExtent(project.value?.id)
    if (extent) {
      const transformedExtent = [
        ...projectToMap([extent[0], extent[1]]),
        ...projectToMap([extent[2], extent[3]])
      ]

      map.getView().fit(transformedExtent, {
        duration: 250,
        nearest: false,
        padding: [50, 150, 50, 350],
        maxZoom: 17
      })
    }
  }

  if (previousProjectId !== projectId.value) {
    zoomToFit()
    previousProjectId = projectId.value
  }

  return { center, reset, setPosition, zoom, zoomIn, zoomOut, zoomToFit }
}
