const SELECTOR = '.loading-screen'
const TEXT_SELECTOR = '.loading-screen__text'
export function hideLoadingScreen() {
  document.documentElement.classList.add('loaded')
  setTimeout(() => {
    document.querySelector(SELECTOR)?.remove()
  }, 150)
}

export function setLoadingMessage(message: string) {
  const el = document.querySelector(TEXT_SELECTOR)
  if (el) {
    el.textContent = message
  }
}
