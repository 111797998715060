<script lang="ts">
import EntityTableView from '@/components/common/EntityTableView.vue'
import { MediumId, ProjectId } from '@/model'
import { useMediumStore } from '@/stores/medium'
import { v4 } from 'uuid'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MediumListView',
  components: { EntityTableView },
  setup() {
    const store = useMediumStore()
    return { store }
  },

  computed: {
    projectId(): ProjectId {
      return this.$route.params['projectId'] as string
    },
    searchStrings(): string[] {
      return this.store.items.map((item) => [item.name].join(' ').toLowerCase())
    },
    MediumId(): MediumId {
      return this.$route.params['id'] as string
    }
  },

  mounted() {
    this.store.load(this.projectId)
    this.store.selection = this.MediumId ? [this.MediumId] : []
  },

  methods: {
    async onCreate() {
      this.store.selection = []
      this.$router.push({ name: 'project-medium-create' })
    },

    async onDelete(id: MediumId) {
      await this.store.delete(id)
      if (id === this.$route.params.id) {
        this.$router.push({ name: 'project-media' })
      }
    },

    onDuplicate(id: MediumId) {
      const itemToDuplicate = this.store.findById(id)
      if (!itemToDuplicate) {
        return
      }
      const newItem = {
        ...itemToDuplicate,
        id: v4(),
        name: itemToDuplicate.name + ' - Kopie'
      }
      this.store.save(newItem)
    },

    onSelectionChange(selectedIds: MediumId[]) {
      if (selectedIds.length === 0) {
        this.$router.push({ name: 'project-media' })
      } else {
        this.$router.push({ name: 'project-medium-edit', params: { id: selectedIds[0] } })
      }
    }
  }
})
</script>

<template>
  <EntityTableView
    v-model:selection="store.selection"
    :allow-edit="false"
    allow-duplicate
    confirm-delete-label="Medium löschen"
    create-label="Neues Medium"
    default-sort="name"
    :items="store.items"
    :loading="store.loading"
    multiselect
    :search-strings="searchStrings"
    title="Medien"
    @create="onCreate"
    @edit="$router.push({ name: 'project-medium-edit', params: { id: $event } })"
    @delete="onDelete"
    @duplicate="onDuplicate"
    @update:selection="onSelectionChange"
  >
    <template #extra-tools>
      <p-btn @click="$router.push({ name: 'project-medium-import' })">Import</p-btn>
    </template>
    <template #columns>
      <el-table-column prop="name" label="Name" sortable>
        <template #default="{ row }">
          <span :class="{ 'text-gray-400': !row.name }">{{ row.name }}</span>
        </template>
      </el-table-column>
    </template>

    <template #empty>
      <p class="text-gray-500 text-xl">Sie haben bisher keine Medien definiert.</p>

      <p class="mt-8 mb-12 font-normal text-gray-400 text-lg leading-relaxed">
        Ein Medium wird durch die als Quelle definierte Hochspannungsleitung beeinflusst. Sie können
        ein oder mehrere Medien erstellen, die jeweils aus mehreren Segmenten bestehen.
      </p>

      <p-btn @click="onCreate">Neues Medium anlegen</p-btn>
    </template>
  </EntityTableView>
</template>

<style scoped lang="css"></style>
