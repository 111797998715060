<script lang="ts">
import { defineComponent } from 'vue'
import EntityTableView from '@/components/common/EntityTableView.vue'
import { useConductorAllocationStore } from '@/stores/conductor-allocation'
import { ProjectId, ConductorAllocationId } from '@/model'
import { v4 } from 'uuid'

export default defineComponent({
  name: 'ConductorAllocationListView',
  components: { EntityTableView },
  setup() {
    const store = useConductorAllocationStore()
    return { store }
  },

  computed: {
    projectId(): ProjectId {
      return this.$route.params['projectId'] as string
    }
  },

  mounted() {
    this.store.load(this.projectId)
  },

  methods: {
    async onDelete(id: ConductorAllocationId) {
      await this.store.delete(id)
      if (id === this.$route.params.id) {
        this.$router.push({ name: 'project-conductor-allocations' })
      }
    },

    onDuplicate(id: ConductorAllocationId) {
      const itemToDuplicate = this.store.findById(id)
      if (itemToDuplicate) {
        const newItem = {
          ...itemToDuplicate,
          id: v4(),
          name: itemToDuplicate.name + ' - Kopie'
        }
        this.store.save(newItem)
      }
    }
  }
})
</script>

<template>
  <EntityTableView
    :allow-edit="false"
    allow-duplicate
    create-label="Neue Leiterzuordnung"
    default-sort="name"
    :items="store.items"
    :loading="store.loading"
    :search-properties="['name']"
    title="Leiterzuordnungen"
    @create="$router.push({ name: 'project-conductor-allocation-create' })"
    @edit="$router.push({ name: 'project-conductor-allocation-edit', params: { id: $event } })"
    @delete="onDelete"
    @duplicate="onDuplicate"
  >
    <template #columns>
      <el-table-column prop="name" label="Name" sortable class-name="font-semibold" />
      <el-table-column prop="wireCount" label="Leiteranzahl" sortable>
        <template #default="{ row }">
          {{ Object.keys(row.mapping).length }}
        </template>
      </el-table-column>
    </template>

    <template #empty>
      <p class="text-gray-500 text-xl">Sie haben bisher keine Leiterzuordnungen angelegt.</p>

      <p class="mt-8 mb-12 font-normal text-gray-400 text-lg leading-relaxed">
        Mit Leiterzuordnungen legen Sie fest, wie Systeme am Mast allokiert werden.
        <br />
        Sie können eine Leiterzuordnung einem Mast nur zuweisen, wenn dieser über die gleiche Anzahl
        Leiterpositionen verfügt wie die Zuordnung.
      </p>

      <p-btn @click="$router.push({ name: 'project-conductor-allocation-create' })">
        Neue Leiterzuordnung anlegen
      </p-btn>
    </template>

    <template #confirm-delete="{ item }">
      <p>
        Wollen Sie die Leiterzuordnung
        <b>{{ item.name }}</b>
        wirklich löschen?
      </p>
      <p class="!mt-2 text-gray-400 text-base">
        Wenn die Leiterzuordnung noch an Masten verwendet wird, ist ein Löschen nicht möglich.
      </p>
    </template>
  </EntityTableView>
</template>
