<script lang="ts">
import { defineComponent } from 'vue'
import { useAppAbility } from '@/permissions/useAppAbility'
import { AbilitySubject } from '@/permissions/ability'

export default defineComponent({
  name: 'PrimaryNavigation',

  setup() {
    const { can } = useAppAbility()
    return { can }
  },

  data: () => ({
    menuItems: [
      { label: 'Projekte', route: 'projects', abilitySubject: 'Project' as AbilitySubject },
      { label: 'Typenbibliothek', route: 'library', abilitySubject: 'Library' as AbilitySubject },
      { label: 'Nutzer', route: 'users', abilitySubject: 'User' as AbilitySubject }
    ]
  })
})
</script>

<template>
  <nav class="space-x-4">
    <template v-for="item in menuItems" :key="item.route">
      <router-link
        v-if="can('read', item.abilitySubject)"
        class="menu-item"
        :to="{ name: item.route }"
      >
        {{ item.label }}
      </router-link>
    </template>
  </nav>
</template>

<style scoped lang="css">
.menu-item {
  @apply text-gray-500 text-[16px] p-2 hover:text-black;
}

.menu-item.router-link-active {
  font-weight: bold;
  @apply text-primary;
}
</style>
