<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'ResultTable',
  props: {
    items: {
      type: Array as PropType<Record<string, any>[]>,
      default: () => []
    }
  },

  computed: {
    columns(): string[] {
      if (this.items.length > 0) {
        return Object.keys(this.items[0])
      } else {
        return []
      }
    }
  }
})
</script>

<template>
  <table>
    <thead>
      <tr>
        <th v-for="(column, index) in columns" :key="index">{{ column }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, rowIndex) in items" :key="rowIndex">
        <td v-for="(column, colIndex) in columns" :key="colIndex">
          {{ items[rowIndex][column] }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<style scoped lang="css">
table {
  width: 100%;
  overflow-x: auto;
  font-family: monospace;
  white-space: normal;
  font-size: 12px;
  position: relative;
  border-collapse: collapse;
}
td,
th {
  border: 1px solid #ccc;
  padding: 2px 4px;
}
</style>
