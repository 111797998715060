import { MapState } from '@/components/map/composables/useMapState'
import { useProject } from '@/composables/useProject'
import { Layer } from 'ol/layer'
import BaseLayer from 'ol/layer/Base'
import { ref, Ref, watch } from 'vue'
import { Map } from 'ol'
import { createLayerConfig, LayerConfig } from '@/components/map/LayerConfig'

type MapLayersOptions = {
  map: Ref<Map>
  mapState: Ref<MapState>
}

export type LayerStatus = {
  id: string
  getMapLayer: () => BaseLayer | undefined
  options: Record<string, any>
  visible: boolean
}

export type LayerState = Record<string, LayerStatus>

type LayerLike = string | Layer

function buildDefaultState(layerConfig: LayerConfig): LayerState {
  const state: LayerState = {}

  // retrieve option defaults
  layerConfig.forEach((layer) => {
    const layerOptions: LayerStatus['options'] = {}
    layer.options?.forEach((option) => {
      if (option.defaultValue !== undefined) {
        layerOptions[option.id] = option.defaultValue
      }
    })

    // build layer status object
    state[layer.id] = {
      id: layer.id,
      visible: layer.hiddenByDefault ?? true,
      options: layerOptions,
      getMapLayer: () => undefined
    }
  })
  return state
}

export default function useMapLayers(options: MapLayersOptions) {
  const { project, projectId } = useProject()
  if (!project.value) {
    throw new Error('useMapLayers: No project given')
  }
  const layerConfig = createLayerConfig(project.value)

  const defaultState = buildDefaultState(layerConfig)

  /**
   * Array of layer ids which are managed by the useMapLayer() composable. This is a subset of
   * all layers, since basemap and tool layers are not in this list
   */
  const layers = ref<LayerState>(defaultState)

  const managedLayers = layerConfig.map((definition) => definition.id)

  const { map } = options

  const mapLayerCollection = map.value.getLayers()
  mapLayerCollection.on('add', (event) => {
    const addedLayer = event.element
    const layerId = addedLayer.get('id')
    if (managedLayers.includes(layerId)) {
      layers.value[layerId] = { ...defaultState[layerId], getMapLayer: () => addedLayer }
      syncToMap()
    }
  })

  /**
   * Sync layer visibilities to the OpenLayers map
   *
   * Note that only base (root) layers are synced
   */
  function syncToMap() {
    const layerState = layers.value

    Object.keys(layerState).forEach((layerId) => {
      const layer = layerState[layerId].getMapLayer()
      layer?.setVisible(layerState[layerId].visible)
    })
  }

  function getLayerOption(layer: LayerLike, option: string) {
    const mapLayer = toLayer(layer)
    return (mapLayer && layers.value[mapLayer.get('id')].options[option]) || undefined
  }

  function setLayerOption(layer: LayerLike, option: string, value: any) {
    const mapLayer = toLayer(layer)
    if (mapLayer) {
      layers.value[mapLayer.get('id')].options[option] = value
    }
  }

  function showLayer(layer: string | Layer, show = true) {
    const mapLayer = toLayer(layer)
    if (mapLayer) {
      const layerId = mapLayer.get('id')
      mapLayer.setVisible(show)
      layers.value[layerId].visible = show
    } else {
      console.log('tried to access non-existent layer', layer)
    }
  }

  function toggleLayer(layer: string | Layer) {
    const layerId = typeof layer === 'string' ? layer : layer.get('id')
    showLayer(layer, !layers.value[layerId].getMapLayer()?.getVisible() ?? true)
  }

  function toLayer(layer: LayerLike) {
    return typeof layer === 'string' ? layers.value[layer]?.getMapLayer() : layer
  }

  return { getLayerOption, layers, setLayerOption, showLayer, toggleLayer }
}
