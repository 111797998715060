import { LineString, MultiPoint } from 'ol/geom'
import { Circle, Fill, Stroke, Style, Text } from 'ol/style'
import { lineWidth, State } from './util'
import constants from './constants'
import { StyleFunction } from 'ol/style/Style'
import { FeatureLike } from 'ol/Feature'

const colorsByState: Record<State, string> = {
  default: constants.medium.color(),
  hover: constants.medium.hoverColor(),
  select: constants.selected.color()
}

function strokeStyle(resolution: number, state: State = 'default') {
  return new Stroke({ width: lineWidth(resolution), color: colorsByState[state] })
}

function baseTextStyle(feature: FeatureLike, resolution: number, state: State = 'default') {
  return resolution < 5 || state === 'select'
    ? new Text({
        placement: 'line',
        text: feature.get('name'),
        font: `12px ${constants.fontFamily}`,
        fill: new Fill({ color: colorsByState['default'] }),
        stroke: new Stroke({ color: 'rgba(255,255,255,0.9)', width: 5 })
      })
    : undefined
}

export const mediumStyle: StyleFunction = (feature, resolution) => {
  return new Style({
    stroke: strokeStyle(resolution),
    text: baseTextStyle(feature, resolution)
  })
}

export const mediumSelectStyle: StyleFunction = (feature, resolution) => {
  return [
    new Style({
      stroke: new Stroke({
        color: 'white',
        width: lineWidth(resolution) + 4
      })
    }),
    // Vertices (points)
    new Style({
      image: new Circle({
        radius: lineWidth(resolution),
        fill: new Fill({ color: 'white' }),
        stroke: new Stroke({
          color: strokeStyle(resolution, 'select').getColor(),
          width: 0.75 * lineWidth(resolution)
        })
      }),
      geometry: function (feature) {
        // return the coordinates of the linestring
        // see https://openlayers.org/en/latest/examples/polygon-styles.html
        const geometry = feature.getGeometry() as LineString
        const coordinates = geometry?.getCoordinates()
        return new MultiPoint(coordinates)
      },
      zIndex: 1000
    }),
    new Style({
      stroke: strokeStyle(resolution, 'select'),
      zIndex: 500,
      image: new Circle({
        radius: lineWidth(resolution),
        fill: new Fill({ color: colorsByState['select'] })
      })
    })
  ]
}

export const mediumHoverStyle: StyleFunction = (feature, resolution) => {
  return new Style({
    stroke: strokeStyle(resolution, 'hover'),
    text: baseTextStyle(feature, resolution)
  })
}
