import * as config from './index'

export function fieldLabel(fieldName: string) {
  return findFieldAttribute(fieldName, 'label') || ''
}

export function fieldUnit(fieldName: string) {
  return findFieldAttribute(fieldName, 'unit') || ''
}

function findFieldAttribute(fieldName: string, attribute: string): any {
  const cfg: any = config
  const [entity, field] = fieldName.split('.')
  if (entity && field && cfg[entity] && cfg[entity][field]) {
    return cfg[entity][field][attribute]
  }
  return
}
