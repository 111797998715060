<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TypeSelectField',
  props: {
    text: {
      type: String
    }
  },

  data: () => ({
    showDialog: false
  }),

  methods: {
    hide() {
      this.showDialog = false
    }
  }
})
</script>

<template>
  <p-field v-bind="$attrs" data-component="TypeSelectField">
    <template #input="ctx">
      <div class="flex items-stretch" @click="showDialog = true">
        <PTextInput disabled :modelValue="text || ctx.field.value" />
        <PBtn class="ml-2" icon="EditIcon" data-test="select-btn"></PBtn>
      </div>
      <slot
        name="dialog"
        v-bind="{ show: showDialog, id: ctx.field.value, handleChange: ctx.handleChange, hide }"
      >
        <p-dialog title="Please implement your own dialog" v-model:show="showDialog">
          id:
          <span data-cy="modelValue">{{ ctx.field?.value }}</span>
        </p-dialog>
      </slot>
    </template>
  </p-field>
</template>

<style scoped lang="css">
:deep(.el-input),
:deep(input) {
  cursor: pointer !important;
}

button {
  height: var(--el-input-inner-height);
}
</style>
