import { z } from 'zod'
import { HSBLibItemsEnum } from '@/config/schemas/hsb-lib/util'

/**
 * https://gridside.gitlab.io/hsb-backend/hsblib_api.html#hsblib.Current
 */
export const HSBLibCurrentSchema = z.object({
  objclass: z.literal(HSBLibItemsEnum.Current),
  name: z.string(),
  rms_current: z.number(),
  angle: z.number(),
  frequency: z.number(),
  color: z.union([z.string(), z.null()])
})

export type HSBLibCurrent = z.infer<typeof HSBLibCurrentSchema>
