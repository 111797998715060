import { ProjectId, System, SystemId } from '@/model'
import { HttpClient, PaginatedResponse } from '@/api/useHttpClient'
import { WebSocket } from '@/api/useWebSocket'
import { HSBApi } from '@/api/HSBApiBase'

const path = (projectId: ProjectId, systemId?: SystemId) => {
  return `projects/${projectId}/systems` + (systemId ? `/${systemId}` : '')
}

export class SystemApiClass implements HSBApi {
  constructor(public httpClient: HttpClient, public webSocket: WebSocket) {}
  async delete(system: System) {
    return await this.httpClient.delete(path(system.project, system.id))
  }

  async getAllByProject(projectId: ProjectId): Promise<System[]> {
    const res = await this.httpClient.get<PaginatedResponse<System>>(path(projectId))
    return res.data.results || []
  }

  async save(system: System): Promise<System> {
    const res = await this.httpClient.put<System>(path(system.project, system.id), { data: system })
    return res.data
  }
}
