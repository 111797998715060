import { HttpClient } from '@/api/useHttpClient'
import { WebSocket } from '@/api/useWebSocket'

export type UnsubscribeFn = () => void
export type HSBApi = {
  httpClient: HttpClient
  webSocket: WebSocket
}

export class HSBApiBase implements HSBApi {
  constructor(public httpClient: HttpClient, public webSocket: WebSocket) {}

  /**
   * Convenience function to return an unsubscribe function
   */
  makeUnsubscribeFn(messageId: string, handler: (...args: any) => any): UnsubscribeFn {
    return () => {
      this.webSocket.socket.value.off(messageId, handler)
    }
  }
}
