<script lang="ts">
import useAppHealth from '@/composables/useAppHealth'
import { defineComponent } from 'vue'
import PrimaryNavigation from '@/components/navigation/PrimaryNavigation.vue'
import logo from '@/assets/gridside_icon.png'
import UserMenu from '@/components/app/UserMenu.vue'
import HealthStatus from '@/components/app/HealthStatus.vue'

export default defineComponent({
  name: 'AppBar',
  components: { HealthStatus, UserMenu, PrimaryNavigation },

  setup() {
    const { healthy } = useAppHealth()
    return { logo, healthy }
  }
})
</script>

<template>
  <!-- Use zIndex to paint shadow -->
  <div
    class="border-b py-2 px-4 pr-8 flex justify-between items-center border-gray-200 shadow bg-white"
    :style="{ zIndex: 1 }"
  >
    <router-link to="/" class="block flex items-center font-heading pl-2">
      <img :src="logo" alt="Logo" class="h-12 mr-2" />
      <!-- prettier-ignore -->
      <span class="text-xl text-gray-500">
        <span class="font-semibold mr-0.5">HSB</span>GUI
      </span>
    </router-link>
    <div>
      <PrimaryNavigation />
    </div>
    <div class="flex items-center">
      <HealthStatus />
      <UserMenu />
    </div>
  </div>
</template>

<style scoped lang="css"></style>
