<script lang="ts" setup>
import { Tile as TileLayer } from 'ol/layer'
import { TileWMS } from 'ol/source'
import LayerBase from '../LayerBase.vue'

defineProps({ id: { type: String, required: true } })

const layer = new TileLayer({
  source: new TileWMS({
    url: 'https://sgx.geodatenzentrum.de/wms_basemapde',
    params: { LAYERS: 'de_basemapde_web_raster_grau', TILED: true },
    serverType: 'geoserver',
    attributions: '© <a href="https://basemap.de" target="_blank">basemap.de</a> / BKG',
    attributionsCollapsible: false,
    hidpi: false
  })
})
</script>

<template>
  <LayerBase v-bind="$attrs" :key="id" :id="id" :layer="layer" />
</template>
