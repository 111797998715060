import { SystemId } from '@/model'
import { reactive, ref } from 'vue'

/**
 * Set of the first couple of colors
 */
const defaultColors = ref([
  'rgb(34,177,165)',

  'rgb(182,182,18)',
  'rgb(165,51,197)',
  'rgb(197,141,51)',

  'rgb(197,51,95)',
  'rgb(51 146 197)',
  'rgb(98 197 34)'
])

/**
 * Map for all colors assigned to systems
 * Important for the case when default colors are exceeded, and
 * we want to display a color for a system unified across the app
 */
const systemsColored = reactive<{ [key: SystemId]: string }>({
  null: 'rgb(255,255,255)'
})

/**
 * Returns CSS-able color
 */
function randomRGB() {
  return `rgb(${Math.random() * 255},${Math.random() * 255},${Math.random() * 255})`
}

/**
 * Assigns color to a systemId
 */
function getColor(systemId: SystemId | null) {
  const key = String(systemId)
  if (systemsColored[key]) {
    return systemsColored[key]
  }

  const color = defaultColors.value.pop() ?? randomRGB()
  systemsColored[key] = color

  return color
}

export function useSystemsColored() {
  return { systemsColored, getColor }
}
