import { useAppAbility } from '@/permissions/useAppAbility'
import { computed } from 'vue'

export function useCommonPermissions() {
  const { can } = useAppAbility()

  const canImportLibrary = computed(() => {
    return can('create', 'Library')
  })

  return {
    canImportLibrary
  }
}
