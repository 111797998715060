import z from 'zod'
import { ConductorType, ConductorTypeCreate, ConductorTypeEnum } from '@/model'
import { RefinementCtx } from 'zod/lib/types'

const ConductorTypeBase = z.object({
  name: z.string(),
  project: z.string().nullish(), // can be undefined or null
  type: z.nativeEnum(ConductorTypeEnum),
  innerRadius: z.number().optional(), // required true when for type: 'tubular'
  muR: z.number(),
  outerRadius: z.number(),
  rDC: z.number(),
  subconductorCount: z.number().min(1).max(6),
  subconductorSpacing: z.number().min(0).step(0.1).optional(), // required if subconductorCount > 1
  subconductorAngle: z.number().min(0).max(359).optional() // required if subconductorCount > 1
})

/**
 * Implement cross field validation
 * See: https://zod.dev/?id=superrefine
 */
const crossFieldValidationRefinement: (arg: ConductorTypeCreate, ctx: RefinementCtx) => void = (
  cond,
  ctx
) => {
  if (cond.type === ConductorTypeEnum.TUBULAR && cond.innerRadius === undefined) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      expected: 'number',
      received: 'undefined',
      path: ['innerRadius'],
      message: '"innerRadius" is required when type is "tubular"'
    })
  }
  if (cond.subconductorCount !== undefined && cond.subconductorCount > 1) {
    /**
     * subconductorSpacing cannot be empty on subconductorCount larger than 1
     */
    if (cond.subconductorSpacing === undefined) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_type,
        path: ['subconductorSpacing'],
        received: 'undefined',
        expected: 'number',
        message: "'subconductorSpacing' must be set if 'subconductorCount' > 1"
      })
    }
    /**
     * subconductorAngle cannot be empty on subconductorCount larger than 1
     */
    if (cond.subconductorAngle === undefined) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_type,
        path: ['subconductorAngle'],
        received: 'undefined',
        expected: 'number',
        message: "'subconductorAngle' must be set if 'subconductorCount' > 1"
      })
    }
  }
}

export const ConductorTypeCreateSchema: z.ZodType<ConductorTypeCreate> =
  ConductorTypeBase.superRefine(crossFieldValidationRefinement)

export const ConductorTypeSchema: z.ZodType<ConductorType> = ConductorTypeBase.extend({
  id: z.string()
}).superRefine(crossFieldValidationRefinement)
