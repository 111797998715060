<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MenuSection',

  props: {
    title: String
  }
})
</script>

<template>
  <div class="mb-6">
    <div class="flex">
      <div v-if="title" class="uppercase text-sm font-semibold text-gray-400 px-2 py-1">
        {{ title }}
      </div>
      <div class="grow"></div>
      <div>
        <slot name="actions"></slot>
      </div>
    </div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="css"></style>
