<script lang="ts">
import useContextMenu from '@/components/map/composables/useContextMenu'
import { UseMapItems } from '@/components/map/composables/useMap'
import { FeatureLike } from 'ol/Feature'
import { defineComponent, inject } from 'vue'
import useCoordinates from '@/composables/useCoordinates'
import { useProject } from '@/composables/useProject'
import { useEventListener } from '@vueuse/core'
import { UseMapInjectKeys } from '@/components/map/composables/useMapInjectKeys'
import useHoverInformation from '@/components/map/composables/useHoverInformation'

export type ContextMenuItem = {
  id: string
  label: string
  icon?: string
  priority: number
  handler: (coordinate: { x: number; y: number }) => void
}

export default defineComponent({
  name: 'ContextMenu',

  data: () => ({
    show: false,
    coordinate: [0, 0],
    menuPosition: { x: 0, y: 0 },
    menuItems: [] as ContextMenuItem[],
    featuresAtCoordinate: [] as FeatureLike[]
  }),

  setup() {
    const { map, selectedFeatures } = inject(UseMapInjectKeys.useMap) as UseMapItems
    const { disableHoverInformation } = useHoverInformation()
    const { project } = useProject()
    const { mapToProject } = useCoordinates(map.value, project.value?.crs)
    const { collectMenuItems } = useContextMenu()

    return {
      collectMenuItems,
      map,
      mapToProject,
      project,
      selectedFeatures,
      disableHoverInformation
    }
  },

  mounted() {
    const viewport = this.map.getViewport()
    // show on right click
    useEventListener(viewport, 'contextmenu', this.onRightClick)

    // hide on any other mouse event
    useEventListener(viewport, 'pointerdown', () => (this.show = false))
    useEventListener(viewport, 'wheel', () => (this.show = false))
  },

  unmounted() {
    this.disableHoverInformation = false
  },
  watch: {
    show(newValue: boolean) {
      this.disableHoverInformation = newValue
    }
  },

  methods: {
    onRightClick(e: MouseEvent) {
      e.preventDefault()

      let posX = e.offsetX
      let posY = e.offsetY

      // Fix bug where offsetX and offsetY does not get calculated correctly between browsers and browser versions
      const target = e.target as HTMLCanvasElement
      if (['absolute', 'fixed'].includes(getComputedStyle(target).position)) {
        const rect = target.getBoundingClientRect()
        posX = e.x - rect.left
        posY = e.y - rect.top
      }
      this.menuPosition = {
        x: posX,
        y: posY
      }

      const [x, y] = this.map.getEventCoordinate(e)
      const pixel = this.map.getEventPixel(e)
      this.featuresAtCoordinate = this.map.getFeaturesAtPixel(pixel)

      this.coordinate = this.mapToProject([x, y])

      this.menuItems = this.collectMenuItems(this.coordinate, {
        features: this.featuresAtCoordinate as FeatureLike[],
        selectedFeatures: this.selectedFeatures,
        mapCoordinate: [x, y],
        projectCoordinate: this.coordinate
      })

      this.show = true
    }
  }
})
</script>

<template>
  <div
    class="context-menu absolute h-0"
    :style="{ left: `${menuPosition.x}px`, top: `${menuPosition.y}px` }"
    :class="{ 'h-0 ': !show }"
    @click="show = false"
    @click.right="show = false"
  >
    <div v-if="show && featuresAtCoordinate.length === 0" class="create-marker"></div>
    <div
      v-bind="$attrs"
      class="bg-white p-2 shadow-lg rounded text-xs w-48 origin-top transition-transform"
      :class="{ 'scale-y-0': !show }"
    >
      <template v-for="(item, index) in menuItems" :key="item.label">
        <hr
          v-if="
            index > 0 &&
            Math.floor(item.priority / 100) !== Math.floor(menuItems[index - 1].priority / 100)
          "
          class="my-1"
        />
        <div class="menu-item flex" @click="item.handler({ x: coordinate[0], y: coordinate[1] })">
          <div class="w-8">
            <el-icon v-if="item.icon" size="20">
              <component :is="`${item.icon}Icon`" />
            </el-icon>
          </div>

          <div class="flex-1">{{ item.label }}</div>
        </div>
      </template>

      <div v-if="coordinate" class="p-2 text-xs text-gray-400">
        Koordinate:
        <br />
        X: {{ $n(coordinate[0], { useGrouping: false, maximumFractionDigits: 0 }) }}

        Y: {{ $n(coordinate[1], { useGrouping: false, maximumFractionDigits: 0 }) }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="css">
.context-menu {
  @apply absolute;
}
.menu-item {
  @apply px-2 py-1 min-h-[32px] text-xs rounded cursor-pointer transition-colors hover:bg-primary-50 flex items-center;
}

.create-marker {
  @apply w-8 h-8 -m-4 rounded-[100%] border-4;
  border-color: rgb(0, 153, 255);
}
</style>
