import { TowerType, WirePositionLabeled } from '@/model'
import { labelWirePositions } from '@/util'
import { computed, watch } from 'vue'
import { Ref } from 'vue/dist/vue'

export function earthwirePositionsLabeled(towerType: Partial<TowerType>): WirePositionLabeled[] {
  return labelWirePositions('earthwire', towerType.earthwirePositions)
}
export function conductorPositionsLabeled(towerType: Partial<TowerType>): WirePositionLabeled[] {
  return labelWirePositions('conductor', towerType.conductorPositions)
}

export function useTowerTypePositions(towerTypeRef: Ref<Partial<TowerType>>) {
  /**
   * Computed properties
   */
  const conductorPositionsLabeledComputed = computed((): WirePositionLabeled[] => {
    return conductorPositionsLabeled(towerTypeRef.value)
  })
  const earthwirePositionsLabeledComputed = computed((): WirePositionLabeled[] => {
    return earthwirePositionsLabeled(towerTypeRef.value)
  })
  const allPositionsFiltered = computed((): WirePositionLabeled[] => {
    const filterUndefinedCoords = (item: WirePositionLabeled) => {
      return item.y != undefined && item.x != undefined
    }
    return [
      ...earthwirePositionsLabeledComputed.value.filter(filterUndefinedCoords),
      ...conductorPositionsLabeledComputed.value.filter(filterUndefinedCoords)
    ]
  })
  return { allPositionsFiltered }
}
