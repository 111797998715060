import { defineStore } from 'pinia'
import { v4 as uuid } from 'uuid'
import { ProjectId, System, SystemId } from '@/model'
import { SystemApi } from '@/api'

export const useSystemStore = defineStore('system', {
  state: () => ({
    itemsById: {} as Record<SystemId, System>,
    loaded: false,
    loadedProject: undefined as ProjectId | undefined,
    loading: false
  }),

  getters: {
    findById(state) {
      return (id: SystemId): System | undefined => state.itemsById[id]
    },
    items(): System[] {
      return Object.values(this.itemsById).sort((a, b) => a.name.localeCompare(b.name))
    }
  },

  actions: {
    async delete(id: SystemId) {
      const system = this.findById(id)
      if (system) {
        await SystemApi.delete(system)
        delete this.itemsById[id]
      }
    },

    async ensureLoaded(projectId: ProjectId) {
      const projectChanged = this.loadedProject !== projectId
      if (!(this.loaded || this.loading) || projectChanged) {
        await this.load(projectId)
      }
    },

    async load(projectId: ProjectId) {
      this.loading = true
      this.itemsById = {}
      const itemsById: Record<SystemId, System> = {}

      try {
        if (!projectId) {
          throw new Error('SystemStore.load: ProjectId must not be empty.')
        }

        const systems = await SystemApi.getAllByProject(projectId)
        for (const system of systems) {
          itemsById[system.id] = system
        }
        this.itemsById = { ...itemsById }
        this.loaded = true
        this.loadedProject = projectId
      } finally {
        this.loading = false
      }
    },

    async save(item: System) {
      if (!item.id) {
        item.id = uuid()
      }
      const updatedItem = await SystemApi.save(item)
      this.itemsById = { ...this.itemsById, [item.id]: updatedItem }
      return updatedItem
    }
  }
})
