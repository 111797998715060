import { ProjectId } from '@/model/project'
import {
  ConductorAllocationId,
  ConductorAllocationMapping,
  ConductorType,
  ConductorTypeId
} from '@/model/conductor'
import { System } from '@/model/system'
import { RequiredProperties } from '@util/type-helpers'
import { HSBLibCoordinate, HSBLibTower } from '@/config/schemas/hsb-lib/Tower'
import { v4 } from 'uuid'
import { util } from 'zod'
import MakePartial = util.MakePartial

export type TowerId = string
export type TowerBase = {
  id: TowerId
  earthResistivity?: number
  in: TowerDirectionProperties
  out?: Partial<TowerDirectionProperties> | null
  name?: string | null
  position?: number // Position of tower in tower compound - 0-index based ( 0,1,2 ... n)
  project: ProjectId
  x: number
  y: number
}

export type TowerDirectionProperties = {
  allocation: ConductorAllocationId
  // index maps TowerType->earthwirePositions (E1, E2, E3 ... En)
  earthwires: ConductorTypeId[]
  offset: number | null
  type: TowerTypeId
}
/**
 * Object in DB
 */
export type Tower = RequiredProperties<TowerBase, 'position'> // position is "NOT NULL" in backend DB

export type WirePosition = { x: number; y: number }
export type TowerTypeId = string

export type TowerType = {
  id: TowerTypeId
  name: string
  project?: ProjectId
  conductorPositions: WirePosition[]
  earthwirePositions: WirePosition[]
  lineTowerType: LineTowerType
}

export enum LineTowerType {
  ANCHOR_TOWER = 'ANCHOR_TOWER', // default
  SUSPENSION_TOWER = 'SUSPENSION_TOWER'
}

export const LineTowerTypeTranslations: { [key in LineTowerType]: string } = {
  [LineTowerType.ANCHOR_TOWER]: 'Abspannmast',
  [LineTowerType.SUSPENSION_TOWER]: 'Tragmast'
}
export type WirePositionLabeled = WirePosition & { label: string; type: 'earthwire' | 'conductor' }
export type WirePositionLabeledConductors = WirePositionLabeled & {
  conductorSystemData: ConductorAllocationMapping & Partial<System> // Partial<> because sometimes the system data couldn't be got from the store
}
export type WirePositionLabeledEarthwire = WirePositionLabeled & {
  earthwireData: ConductorType | null
}
export type WirePoint =
  | WirePositionLabeled
  | WirePositionLabeledConductors
  | WirePositionLabeledEarthwire

export type TowerFromHSBPartial = RequiredProperties<MakePartial<TowerBase, 'x' | 'y'>, 'position'>

export function hasConductorSystemData(point: WirePoint): point is WirePositionLabeledConductors {
  return 'conductorSystemData' in point
}

/**
 * Depending on lineTowerType return the isolator length
 */
export function factorInIsolatorLength(
  lineTowerType: LineTowerType | undefined,
  point: WirePoint
): number {
  // only relevant on suspension tower
  if (lineTowerType === LineTowerType.SUSPENSION_TOWER && hasConductorSystemData(point)) {
    return point.conductorSystemData.isolatorLength || 0
  }
  return 0
}
export function formatTowerName(tower: Tower) {
  return tower.name || 'Mast #' + tower.position + 1
}

export function fromHSBLibTower(towerData: HSBLibTower, direction: 'in' | 'out'): TowerType {
  return {
    id: v4(),
    name: towerData.name.trim(),
    project: undefined,
    lineTowerType: LineTowerType.ANCHOR_TOWER,
    earthwirePositions: coordsToWirePositions(
      direction === 'in'
        ? towerData.incoming_earthwire_positions
        : towerData.outgoing_earthwire_positions
    ),
    conductorPositions: coordsToWirePositions(
      direction === 'in'
        ? towerData.incoming_phase_conductors_positions
        : towerData.outgoing_phase_conductors_positions
    )
  }
}

/**
 * Transforms [number,number][] to {x: number, y:number}[]
 */
function coordsToWirePositions(coordinatesList: HSBLibCoordinate[]): WirePosition[] {
  const wirePositions: WirePosition[] = []
  for (const coordinates of coordinatesList) {
    wirePositions.push({ x: coordinates[0], y: coordinates[1] })
  }
  return wirePositions
}
