import { ProjectId } from '@/model'
import { HSBApiBase } from '@/api/HSBApiBase'

type ResultInvalidatedEvent = { project: ProjectId }

export class ResultApiClass extends HSBApiBase {
  onInvalidated(handler: (data: ResultInvalidatedEvent) => void) {
    this.webSocket.subscribe('Result.invalidated', handler)
    return this.makeUnsubscribeFn('Result.invalidated', handler)
  }
}
