<script lang="ts" setup>
import { useCalculationStore } from '@/stores/calculation'
import ResultTable from './ResultTable.vue'
import { useProjectStore } from '@/stores/project'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import SectionHeading from '@/components/map/util/SectionHeading.vue'

const allTabs = {
  emf: { id: 'emf', label: 'Induzierte Längsspannungen' },
  loadflow: { id: 'loadflow', label: 'Rohrleitungspotenziale' },
  relations: { id: 'relations', label: 'Beeinflussungsabschnitte' },
  debug: { id: 'debug', label: 'Debug' }
}
const store = useCalculationStore()

const projectStore = useProjectStore()
const currentProject = computed(() =>
  projectStore.findById(useRoute().params['projectId'] as string)
)

const isPipeProject = computed(() => currentProject.value?.mediaType === 'pipe')

const tabs = computed(() => {
  if (isPipeProject.value === true) {
    return [allTabs.loadflow, allTabs.debug]
  } else {
    return [allTabs.emf]
  }
})
</script>

<template>
  <div v-if="store.showResult" class="p-4 bg-white rounded-lg shadow overflow-hidden">
    <SectionHeading class="!mt-0 !mb-0">Ergebnis</SectionHeading>
    <template v-if="store.result">
      <p-tabs :tabs="tabs" class="mt-4 flex flex-col overflow-hidden h-full">
        <template #tab:emf>
          <ResultTable :items="store.resultEmf" />
        </template>
        <template #tab:loadflow>
          <ResultTable :items="store.resultLoadFlow" />
        </template>
        <template #tab:relations>{{ store.result?.result.relations }}</template>
        <template #tab:debug>{{ store.result }}</template>
      </p-tabs>
    </template>
    <template v-else>Kein Ergebnis vorhanden.</template>
    <CloseIcon
      class="absolute right-2 top-2 0 w-6 h-6 bg-white text-gray-500 hover:text-gray-700 cursor-pointer"
      @click="store.showResult = false"
    />
  </div>
</template>

<style lang="css" scoped>
:deep(.el-tabs__content) {
  overflow: auto;
  margin: 16px 0px;
  flex: 1;
  font-family: monospace;
  white-space: pre;
  font-size: 13px;
}
</style>
