import { z } from 'zod'
import { OperationalMode } from '@/model/operational-mode'

const ConductorState = z.object({
  current: z.number(),
  angle: z.number()
})

const OperationalModeBase = z.object({
  expectationFactor: z.number().nullish(),
  frequency: z.number(),
  limitVoltage: z.number(),
  name: z.string(),
  project: z.string(),
  conductorStates: z.record(z.array(ConductorState)),
  type: z.union([z.literal('normal'), z.literal('failure')])
})

export const OperationalModeSchema: z.ZodType<OperationalMode> = OperationalModeBase.extend({
  id: z.string()
})
