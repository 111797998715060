<script setup lang="ts">
import { computed, PropType } from 'vue'
import { Feature } from 'ol'
import { useMediumStore } from '@/stores/medium'
import { usePipeTypeStore } from '@/stores/pipe-type'

const props = defineProps({
  feature: {
    type: Object as PropType<Feature>,
    required: true
  }
})

const mediumStore = useMediumStore()
const pipeTypeStore = usePipeTypeStore()

const medium = computed(() => mediumStore.findById(props.feature.getId() as string))
const pipeType = computed(
  () =>
    (medium.value?.type === 'pipe' && pipeTypeStore.findById(medium.value?.pipeType)?.name) ||
    'unbekannt'
)
</script>

<template>
  <div v-if="medium">
    <h3>
      {{ medium.type === 'wire' ? ' Telekommunikationsleitung' : 'Rohrleitung' }}:
      {{ medium.name }}
    </h3>
    <table>
      <tr v-if="medium.type === 'pipe'">
        <th>Rohrleitungstyp</th>
        <td>{{ pipeType }}</td>
      </tr>
      <tr>
        <th>Höhe / Tiefe</th>
        <td>{{ medium.height }} m</td>
      </tr>
      <tr v-if="medium.type === 'pipe'">
        <th>spez. Erdwiderstand (ρE)</th>
        <td>{{ medium.rhoE }} Ωm</td>
      </tr>
    </table>
  </div>
</template>

<style scoped lang="css"></style>
