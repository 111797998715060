import z from 'zod'
import { CorridorModeEnum, HSBLibParameter, LogLevelEnum, Project } from '@/model'

const HsbLibParameterSchema: z.ZodType<Partial<HSBLibParameter>> = z.object({
  corridor_flatend: z.boolean().nullable().optional(),
  corridor_flatstart: z.boolean().nullable().optional(),
  corridor_mode: z.nativeEnum(CorridorModeEnum).nullable().optional(),
  logging_traceback: z.boolean().nullable().optional(),
  log_level: z.nativeEnum(LogLevelEnum).nullable().optional(),
  FLAG_Inner_Impedance_Bessel_Function: z.boolean().nullable().optional(),
  Flag_Debugging: z.boolean().nullable().optional(),
  PipelineSegmentation: z.number().nullable().optional(),
  Rel_abstol: z.number().nullable().optional(),
  Rel_angletol: z.number().nullable().optional(),
  Rel_max_angle: z.number().nullable().optional(),
  Rel_min_segment_length: z.number().nullable().optional(),
  Rel_simplifyMedium: z.boolean().nullable().optional(),
  Trim_till_decimal_points: z.number().nullable().optional(),
  UTM_Reimport_Tolerance: z.number().nullable().optional(),
  UTM_trim_mode: z.boolean().nullable().optional()
})

export const ProjectBase = z.object({
  API_VERSION: z.string().optional(),
  crs: z.string().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  createdBy: z.string().optional(),
  customer: z.string().optional(),
  locked: z.boolean().optional(),
  lockedBy: z.string().optional().nullable(),
  mediaType: z.union([z.literal('telecom'), z.literal('pipe')]),
  name: z.string(),
  updatedBy: z.string().optional(),
  users: z.array(z.string()),
  hsblibParameter: HsbLibParameterSchema.nullable()
})

export const ProjectSchema: z.ZodType<Omit<Project, 'hsblibParameter'> & Partial<HSBLibParameter>> =
  ProjectBase.extend({ id: z.string() })
