<script lang="ts">
import EntityTableView from '@/components/common/EntityTableView.vue'
import {
  ConductorAllocationId,
  formatTowerName,
  LineTowerTypeTranslations,
  ProjectId,
  Tower,
  TowerId,
  TowerTypeId
} from '@/model'
import { useConductorAllocationStore } from '@/stores/conductor-allocation'
import { useTowerStore } from '@/stores/tower'
import { useTowerTypeStore } from '@/stores/tower-type'
import { v4 } from 'uuid'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TowerListView',
  components: { EntityTableView },
  setup() {
    const towerStore = useTowerStore()
    const towerTypeStore = useTowerTypeStore()
    const allocationStore = useConductorAllocationStore()
    return { towerStore, allocationStore, towerTypeStore }
  },

  computed: {
    projectId(): ProjectId {
      return this.$route.params['projectId'] as string
    },
    searchStrings(): string[] {
      return this.towerStore.items.map((item) =>
        [
          this.formatTowerName(item.id),
          this.formatTowerType(item.in.type),
          this.$n(item.earthResistivity || 0),
          this.$n(item.in.offset || 0)
        ]
          .join(' ')
          .toLowerCase()
      )
    },
    towerId(): TowerId {
      return this.$route.params['id'] as string
    }
  },

  mounted() {
    this.towerTypeStore.ensureLoaded()
    this.towerTypeStore.ensureLoadedByProject(this.projectId)
    this.allocationStore.ensureLoaded(this.projectId)
    this.towerStore.load(this.projectId)
    this.towerStore.selection = this.towerId ? [this.towerId] : []
  },

  methods: {
    async onDelete(id: TowerId) {
      await this.towerStore.delete(id)
      if (id === this.$route.params.id) {
        this.$router.push({ name: 'project-towers' })
      }
    },

    formatAllocation(id: ConductorAllocationId | undefined): string {
      const allocation = this.allocationStore.findById(id)
      return allocation ? allocation.name : '?'
    },

    formatTowerName(id: TowerId): string {
      const tower = this.towerStore.findById(id)
      return tower ? formatTowerName(tower) : ''
    },

    formatTowerType(id: TowerTypeId): string {
      return this.towerTypeStore.findById(id)?.name || '(unbekannt)'
    },

    formatLineTowerType(id: TowerTypeId): string {
      return this.towerTypeStore.findById(id)?.lineTowerType
        ? LineTowerTypeTranslations[this.towerTypeStore.findById(id)!.lineTowerType]
        : 'unbekannt'
    },

    async onCreate() {
      this.towerStore.selection = []
      this.$router.push({ name: 'project-tower-create' })
    },

    async onDuplicate(id: TowerId) {
      const itemToDuplicate = this.towerStore.findById(id)
      if (itemToDuplicate) {
        const newItem: Tower = {
          ...itemToDuplicate,
          id: v4(),
          name: itemToDuplicate.name ? itemToDuplicate.name + ' - Kopie' : undefined,
          position: itemToDuplicate.position + 1
        }
        await this.towerStore.save(newItem)
      }
    },

    onSelectionChange(selectedIds: TowerId[]) {
      if (selectedIds.length === 0) {
        this.$router.push({ name: 'project-towers' })
      } else {
        this.$router.push({ name: 'project-tower-edit', params: { id: selectedIds[0] } })
      }
    },
    asTower(item: any): Tower {
      return item
    },

    unitString(value: number | undefined, fieldUnit: string): string {
      return typeof value === 'number' ? this.$n(value) + ' ' + fieldUnit : '&ndash;'
    }
  }
})
</script>

<template>
  <EntityTableView
    v-model:selection="towerStore.selection"
    :allow-edit="false"
    allow-duplicate
    create-label="Neuer Mast"
    confirm-delete-label="Mast löschen"
    default-sort="position"
    :items="towerStore.items"
    :loading="towerStore.loading"
    multiselect
    :search-strings="searchStrings"
    title="Masten"
    @create="onCreate"
    @edit="$router.push({ name: 'project-tower-edit', params: { id: $event } })"
    @delete="onDelete"
    @duplicate="onDuplicate"
    @update:selection="onSelectionChange"
  >
    <template #extra-tools>
      <p-btn @click="$router.push({ name: 'project-tower-import' })">Import</p-btn>
    </template>
    <template #columns="{ fieldLabel, fieldUnit }">
      <el-table-column
        prop="position"
        label="Pos."
        sortable
        width="110px"
        class-name="font-semibold"
        v-slot="{ row }"
      >
        {{ asTower(row).position }}
      </el-table-column>
      <el-table-column prop="name" :label="fieldLabel('tower.name')" sortable>
        <template v-slot:default="{ row }">
          <span :class="{ 'text-gray-400': !asTower(row).name }">
            {{ formatTowerName(asTower(row).id) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="type" :label="fieldLabel('tower.type')" sortable>
        <template v-slot:default="{ row }">{{ formatTowerType(asTower(row).in.type) }}</template>
      </el-table-column>
      <el-table-column prop="type" label="Bauweise" sortable>
        <template v-slot:default="{ row }">
          {{ formatLineTowerType(asTower(row).in.type) }}
        </template>
      </el-table-column>
      <!--      <el-table-column prop="offset" :label="fieldLabel('tower.offset')" sortable>-->
      <!--        <template v-slot:default="{ row }">-->
      <!--          {{ unitString(asTower(row).in.offset, fieldUnit('tower.offset')) }}-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column
        prop="conductorAllocationIn"
        :label="fieldLabel('tower.allocation')"
        sortable
      >
        <template v-slot:default="{ row }">
          {{ formatAllocation(asTower(row).in.allocation) }}
          <template v-if="asTower(row).out">
            <br />
            {{ formatAllocation(asTower(row).out?.allocation) }}
          </template>
        </template>
      </el-table-column>
      <el-table-column
        prop="earthResistivity"
        :label="fieldLabel('tower.earthResistivity')"
        width="20ch"
        align="center"
        sortable
      >
        <template #default="{ row }">
          {{ unitString(asTower(row).earthResistivity, fieldUnit('tower.earthResistivity')) }}
        </template>
      </el-table-column>
    </template>

    <template #empty>
      <p class="text-gray-500 text-xl">Sie haben bisher keine Masten angelegt.</p>

      <p class="mt-8 mb-12 font-normal text-gray-400 text-lg leading-relaxed">
        Durch die Positionierung der Masten definieren Sie die zu untersuchende Freileitung.
      </p>

      <p-btn @click="$router.push({ name: 'project-tower-create' })">Neuen Mast anlegen</p-btn>
    </template>

    <template #confirm-delete="{ item }">
      <p>
        Wollen Sie den Mast
        <b>{{ formatTowerName(asTower(item).id) }}</b>
        wirklich löschen?
      </p>
      <p class="!mt-2 text-gray-400 text-base">
        Die Daten des abgehenden Spannfeldes werden ebenfalls gelöscht.
      </p>
    </template>
  </EntityTableView>
</template>
