import { defineStore } from 'pinia'
import { Project, ProjectId } from '@/model'
import { v4 as uuid } from 'uuid'
import { useAuthentication } from '@prionect/ui'
import { ProjectApi } from '@/api'
import { UnsubscribeFn } from '@/api/HSBApiBase'

export const useProjectStore = defineStore('project', {
  state: () => {
    return {
      projectsById: {} as Record<ProjectId, Project>,
      loaded: false,
      loading: false,
      unsubscribe: [] as UnsubscribeFn[]
    }
  },

  getters: {
    findById(state) {
      return (projectId: ProjectId): Project | undefined => state.projectsById[projectId]
    },
    projects(): Project[] {
      return Object.values(this.projectsById)
    }
  },

  actions: {
    reset() {
      this.unsubscribe.map((unsub) => unsub())
      this.$reset()
    },
    // Initialize event handlers
    init() {
      this.unsubscribe.push(
        ProjectApi.onProjectUpdated((project: Project) => {
          this.projectsById[project.id] = project
        }),
        ProjectApi.onProjectDeleted((data) => {
          if (this.projectsById[data.id]) {
            delete this.projectsById[data.id]
          }
        })
      )
    },

    async delete(id: ProjectId) {
      const response = await ProjectApi.delete(id)
      if (response.status !== 200) {
        throw new Error(
          `${response.status} - ${response.statusText} - Projekt wird noch bearbeitet`
        )
      }
      delete this.projectsById[id]
    },

    async ensureLoaded() {
      if (!this.loaded && !this.loading) {
        await this.load()
      }
    },

    async lock(id: ProjectId) {
      const { currentUser } = useAuthentication()

      await ProjectApi.lock(id)
      // state will be changed via the 'Project.updated' event
    },

    async load() {
      const projectsById: Record<ProjectId, Project> = {}
      this.loading = true

      try {
        const projects = await ProjectApi.getAll()
        projects.forEach((project) => {
          projectsById[project.id] = project
        })
        this.projectsById = { ...projectsById }
        this.loaded = true
      } finally {
        this.loading = false
      }
    },

    async save(project: Project) {
      if (!project.id) {
        project.id = uuid()
      }
      const updatedProject = await ProjectApi.save(project)
      this.projectsById = { ...this.projectsById, [project.id]: updatedProject }
      return updatedProject
    },

    async unlock(id: ProjectId) {
      await ProjectApi.unlock(id)
      this.projectsById[id].locked = false
    }
  }
})
