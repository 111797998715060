<script lang="ts">
import { defineComponent, PropType } from 'vue'
import {
  ConductorAllocation,
  ConductorAllocationMapping,
  ConductorTypeId,
  ProjectId,
  System,
  SystemId,
  Tower,
  TowerDirectionProperties,
  TowerType,
  WirePositionLabeled,
  WirePositionLabeledConductors,
  WirePositionLabeledEarthwire
} from '@/model'
import {
  ConductorTowerTypeMapper,
  EarthwireTowerTypeMapper
} from '@/views/project/towers/TowerDetail.vue'
import { useSystemStore } from '@/stores/system'
import { useTowerTypeStore } from '@/stores/tower-type'
import { useConductorTypeStore } from '@/stores/conductor-type'
import { useConductorAllocationStore } from '@/stores/conductor-allocation'
import { useSystemsColored } from '@/composables/useSystemsColored'
import { towerConductorsLabelFn, towerEarthwireLabelFn } from '@/util'
import TowerSVG from '@/components/tower/TowerSVG.vue'
import { conductorPositionsLabeled } from '@/composables/useTowerTypePositions'

type WireDataInOutType = (
  | WirePositionLabeled
  | WirePositionLabeledConductors
  | WirePositionLabeledEarthwire
)[]
export type TowerWithWireData = {
  wireDataIn?: WireDataInOutType
  wireDataOut?: WireDataInOutType
}

export default defineComponent({
  name: 'TowerSVGMapped',
  components: { TowerSVG },
  data: () => ({
    towerWithPositionData: null as null | TowerWithWireData,
    systemsUsed: new Set<SystemId>()
  }),
  props: {
    tower: {
      type: Object as PropType<Partial<Tower> | Tower>,
      required: true
    },
    displaySystemLabels: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {
      systemStore: useSystemStore(),
      towerTypeStore: useTowerTypeStore(),
      conductorTypeStore: useConductorTypeStore(),
      conductorAllocationStore: useConductorAllocationStore(),
      useSystemsColored: useSystemsColored()
    }
  },
  async created() {
    // Make sure store data is loaded
    await this.systemStore.ensureLoaded(this.projectId)
    await this.towerTypeStore.ensureLoaded()
  },
  computed: {
    projectId(): ProjectId {
      return this.$route.params['projectId'] as string
    },
    towerTypes() {
      return {
        in: this.towerTypeStore.findById(this.tower.in?.type),
        out: this.towerTypeStore.findById(this.tower.out?.type)
      }
    },
    towerWireData(): TowerWithWireData | null {
      // Clear previous data
      this.systemsUsed.clear()

      // On draft the tower type can be invalid
      if (!this.towerTypeStore.loaded || !this.systemStore.loaded) {
        return null
      }

      return {
        wireDataIn: this.computeWireData(this.tower.in),
        wireDataOut: this.computeWireData(this.tower.out)
      }
    }
  },
  methods: {
    computeWireData(
      directionData?: TowerDirectionProperties | Partial<TowerDirectionProperties> | null
    ): WireDataInOutType {
      const towerType = this.towerTypeStore.findById(directionData?.type)
      if (!directionData || !towerType) {
        return []
      }

      let conductors: WirePositionLabeledConductors[] = []
      let earthwires: WirePositionLabeledEarthwire[] = []

      // Create mapper based on tower type
      const conductorsMapper = this.createConductorMapperFn(towerType)
      const earthwiresMapper = this.createEarthwireMapperFn(towerType)

      const allocation: ConductorAllocation | undefined = this.conductorAllocationStore.findById(
        directionData.allocation
      )

      // Conductors
      if (allocation) {
        conductors = this.extractConductorsData(allocation, conductorsMapper)
      }

      // Earthwires
      if (directionData.earthwires?.length) {
        earthwires = this.extractEarthwireData(directionData.earthwires, earthwiresMapper)
      }

      const wireData: WireDataInOutType = [...conductors, ...earthwires]

      // Fallback if no tower.allocation* is selected and therefore wireData is empty
      if (!conductors.length) {
        wireData.push(...conductorPositionsLabeled(towerType))
      }

      // Same fallback for earthwires. Just comment out for now, maybe PO wants this feature later...
      // if (!earthwires.length) {
      //   wireDataIn.push(...earthwirePositionsLabeled(towerType))
      // }

      return wireData
    },
    /**
     * Function returned is used to map a "system" to tower conductor slots.
     * Slots are determined by given "towerType".
     * @param towerType
     */
    createConductorMapperFn(towerType: TowerType): ConductorTowerTypeMapper {
      return (
        conductorMapping: ConductorAllocationMapping,
        index: number
      ): WirePositionLabeledConductors => {
        const system: System | undefined = conductorMapping.system
          ? this.systemStore.findById(conductorMapping.system)
          : undefined
        return {
          label: towerConductorsLabelFn(index),
          type: 'conductor',
          x: towerType?.conductorPositions[index]?.x || 0,
          y: towerType?.conductorPositions[index]?.y || 0,
          conductorSystemData: {
            system: conductorMapping.system,
            index: conductorMapping.index,
            ...system
          }
        }
      }
    },
    extractConductorsData(
      allocation: ConductorAllocation,
      mapper: ConductorTowerTypeMapper
    ): WirePositionLabeledConductors[] {
      return allocation.mapping.map((allocationMapping, positionIndex) => {
        const result = mapper(allocationMapping, positionIndex)

        const system = result.conductorSystemData.system
        this.systemsUsed.add(system ?? 'unbesetzt')
        return result
      })
    },

    /**
     * Function returned is used to map a given "conductor type" to a tower earthwire  slots.
     * Slots are determined by given "towerType".
     * @param towerType
     */
    createEarthwireMapperFn(towerType: TowerType): EarthwireTowerTypeMapper {
      return (conductorId: ConductorTypeId, index: number): WirePositionLabeledEarthwire => {
        this.conductorTypeStore.findById(conductorId)
        return {
          label: towerEarthwireLabelFn(index),
          type: 'earthwire',
          x: towerType.earthwirePositions[index]?.x || 0, // Sometimes backend was buggy and created more earthwires than can be allocated on tower type
          y: towerType.earthwirePositions[index]?.y || 0,
          earthwireData: this.conductorTypeStore.findById(conductorId) || null
        }
      }
    },
    extractEarthwireData(earthwires: ConductorTypeId[], mapper: EarthwireTowerTypeMapper) {
      return earthwires.map(mapper)
    }
  }
})
</script>

<template>
  <div v-if="towerWireData" class="col-span-full grid grid-cols-2 gap-2">
    <!-- In -->
    <TowerSVG
      :wire-data="towerWireData.wireDataIn"
      :tower-offset="tower.in?.offset"
      :line-tower-type="towerTypes.in?.lineTowerType"
      :caption="towerWireData.wireDataOut ? null : 'eingehend = ausgehend'"
      :class="tower.out ? ['flex', 'items-end', 'justify-center'] : ['col-span-2']"
    />

    <!-- Out -->
    <TowerSVG
      v-if="towerWireData.wireDataOut && tower.out"
      :wire-data="towerWireData.wireDataOut"
      :line-tower-type="towerTypes.out?.lineTowerType"
      :tower-offset="tower.out.offset"
      :class="['flex', 'items-end', 'justify-center']"
    />

    <!-- System Labels -->
    <div v-if="displaySystemLabels" class="col-span-2 text-center">
      <el-tag
        v-for="systemId in systemsUsed"
        :color="useSystemsColored.getColor(systemId)"
        :key="systemId"
      >
        <span class="text-white">
          {{ systemStore.findById(systemId)?.name || systemId }}
        </span>
      </el-tag>
    </div>
  </div>
</template>

<style scoped lang="css"></style>
