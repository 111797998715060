import { useEventListener } from '@vueuse/core'
import { nextTick } from 'vue'
import { v4 } from 'uuid'
import { Collection, Feature } from 'ol'
import { LineString, Point } from 'ol/geom'
import { Draw, Snap } from 'ol/interaction'
import { DrawEvent } from 'ol/interaction/Draw'
import { Coordinate } from 'ol/coordinate'
import { mediumSelectStyle } from '@/components/map/style/media'
import { useMapSelection } from '@/components/map/composables/useMapSelection'
import { MediumId } from '@/model'
import { useMediumStore } from '@/stores/medium'
import { MediaLayerContext } from '../util'

type XYCoordinates = { x: number; y: number }

const { deselect, clear } = useMapSelection()
let snapInteraction: Snap

export function createMediumDraft(coordinates: XYCoordinates, ctx: MediaLayerContext) {
  deleteDraftFeatures(ctx)

  const mapCoordinates = ctx.projectToMap([coordinates.x, coordinates.y])
  const id = v4()
  const features = new Collection<Feature>([new Feature(new Point(mapCoordinates))])
  const draw = new Draw({ type: 'LineString', features, style: mediumSelectStyle as any })
  const map = ctx.map

  const mediumStore = useMediumStore()
  mediumStore.draft = null
  mediumStore.drawing = true

  clear()

  map.addInteraction(draw)
  draw.appendCoordinates([mapCoordinates])
  draw.setActive(true)
  ctx.drawInteraction = draw

  snapInteraction = new Snap({ source: ctx.layer.getSource() })
  map.addInteraction(snapInteraction)

  useEventListener(
    document,
    'keydown',
    (event) => {
      if (event.key === 'Escape') {
        draw.abortDrawing()
      }
    },
    { once: true }
  )

  return new Promise((resolve) => {
    draw.once('drawend', (e: DrawEvent) => {
      nextTick(() => {
        mediumStore.drawing = false
        ctx.drawInteraction = undefined
        draw.setActive(false)
        map.removeInteraction(draw)
        map.removeInteraction(snapInteraction)

        resolve(true)
      })

      const initialPath = (e.feature.getGeometry() as LineString)
        .getCoordinates()
        .map((coordinates) => ctx.mapToProject(coordinates))
        .map(([x, y]) => ({ x, y }))

      mediumStore.draft = { name: 'Neues Medium', id, path: initialPath }
      mediumStore.selection = [id]
    })
  })
}

export function deleteDraftFeatures(ctx: MediaLayerContext) {
  const source = ctx.layer.getSource()
  if (source) {
    source
      .getFeatures()
      .filter((feature: Feature) => feature.get('_draft'))
      .forEach((feature: Feature) => {
        deselect(feature)
        source.removeFeature(feature)
      })
  }

  const mediumStore = useMediumStore()
  mediumStore.draft = null

  //ctx.drawInteraction = undefined
  if (snapInteraction) {
    ctx.map.removeInteraction(snapInteraction)
  }
}

export async function deleteMedia(mediumIds: MediumId[]) {
  const mediumStore = useMediumStore()
  for (const id of mediumIds) {
    await mediumStore.delete(id)
  }
}

export function updateMediumPath(
  mediumId: MediumId,
  coordinates: Coordinate[],
  ctx: MediaLayerContext
) {
  const mediumStore = useMediumStore()
  const medium = mediumStore.findById(mediumId)
  const path = coordinates.map((c) => ctx.mapToProject(c)).map(([x, y]) => ({ x, y }))
  if (medium) {
    mediumStore.save({ ...medium, path })
  } else if (mediumStore.draft) {
    mediumStore.draft.path = path
  }
}
