<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SplitView',

  props: {
    maxWidth: {
      type: String,
      default: '32rem'
    },
    overlay: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    showDetail: true
  }),

  computed: {
    /**
     * True if the view is in a transition between edit and create
     */
    duringTransition() {
      return this.hasDetail === false && this.showDetail === false
    },
    hasDetail(): boolean {
      return (
        this.showDetail &&
        !!this.$route.matched
          .map((route) => Object.keys(route.components || {}))
          .find((componentNames) => componentNames.includes('detail'))
      )
    },
    hasOverlay(): boolean {
      return !!this.$route.matched
        .map((route) => Object.keys(route.components || {}))
        .find((componentNames) => componentNames.includes('overlay'))
    }
  },

  beforeRouteUpdate(to) {
    if (to.meta.create) {
      // Hide detail pane if changing to create route
      this.showDetail = false
      setTimeout(() => (this.showDetail = true), 200)
    }
  }
})
</script>

<template>
  <div class="relative flex w-full h-full overflow-x-hidden">
    <!--
      For proper stacking context, each router view has its own (increasing) z-index
      See: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_positioned_layout/Understanding_z-index/Stacking_context
    -->
    <div
      class="flex-1 overflow-auto"
      :style="{
        marginRight: (hasDetail || duringTransition) && !overlay ? maxWidth : '0',
        minWidth: '50rem',
        zIndex: 0
      }"
    >
      <router-view />
    </div>
    <div
      class="absolute inset-y-0 right-0 border-l bg-white w-1/2 min-w-[28rem] shadow-lg transition-transform"
      :class="hasDetail ? '' : 'translate-x-[102%]'"
      :style="{ maxWidth: maxWidth, zIndex: 1 }"
    >
      <router-view v-if="hasDetail" name="detail" />
    </div>
    <div class="fixed" :style="{ zIndex: 2 }">
      <router-view v-if="hasOverlay" name="overlay" />
    </div>
  </div>
</template>

<style scoped lang="css"></style>
