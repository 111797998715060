<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import { RouteLocationRaw } from 'vue-router'

export default defineComponent({
  name: 'MenuItem',

  props: {
    to: {
      type: [String, Object] as PropType<RouteLocationRaw | string>,
      required: true
    }
  }
})
</script>

<template>
  <router-link :to="to" class="menu-item block px-2 py-1 hover:bg-gray-200 transition-colors">
    <slot></slot>
  </router-link>
</template>

<style scoped lang="css">
.menu-item {
  transition-property: background-color;
}
.menu-item.router-link-active {
  @apply text-primary font-bold rounded;
}
</style>
