<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import MenuSection from '@/components/navigation/MenuSection.vue'
import MenuItem from '@/components/navigation/MenuItem.vue'
import { useProjectStore } from '@/stores/project'
import { Project, ProjectId } from '@/model'
import { useSystemStore } from '@/stores/system'
import { useConductorAllocationStore } from '@/stores/conductor-allocation'
import { useTowerStore } from '@/stores/tower'
import { useSpanStore } from '@/stores/span'
import ProjectImportSourcesDialog from '@/components/project/ProjectImportSourcesDialog.vue'
import { useOperationalModeStore } from '@/stores/operational-mode'

export default defineComponent({
  name: 'ProjectNavigation',
  components: { ProjectImportSourcesDialog, MenuItem, MenuSection },
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true
    }
  },
  setup(props) {
    const projectStore = useProjectStore()
    projectStore.ensureLoaded()

    const systemStore = useSystemStore()
    const conductorAllocationStore = useConductorAllocationStore()
    const towerStore = useTowerStore()
    const spanStore = useSpanStore()
    const operationalModeStore = useOperationalModeStore()

    systemStore.ensureLoaded(props.project.id)
    conductorAllocationStore.ensureLoaded(props.project.id)
    towerStore.ensureLoaded(props.project.id)
    spanStore.ensureLoaded(props.project.id)
    operationalModeStore.ensureLoaded(props.project.id)

    const importSourceDisabled = computed(() => {
      const projectSourceItemsCount =
        systemStore.items.length +
        conductorAllocationStore.items.length +
        towerStore.items.length +
        spanStore.items.length +
        operationalModeStore.items.length
      return projectSourceItemsCount !== 0
    })

    const unlocking = ref(false)
    return { projectStore, unlocking, importSourceDisabled }
  },
  data() {
    return {
      tooltip: false,
      importDialog: false
    }
  },

  computed: {
    projectId(): ProjectId | undefined {
      return this.$route.params.projectId?.toString() || undefined
    }
  },

  methods: {
    async closeProject() {
      if (!this.projectId) {
        return
      }

      this.unlocking = true
      try {
        await this.projectStore.unlock(this.projectId)
        await this.$router.push({ name: 'projects' })
      } finally {
        this.unlocking = false
      }
    }
  }
})
</script>

<template>
  <div class="flex flex-col h-full">
    <div class="px-8 text-lg pt-6 pb-4 border-b border-gray-300">
      <div class="overflow-hidden">
        <div class="font-semibold">{{ project.name }}</div>
        <div class="font-normal opacity-60 text-base">{{ project.customer }}</div>
      </div>
    </div>
    <div class="py-6 px-6 flex-1">
      <MenuSection>
        <MenuItem :to="{ name: 'project-edit' }">Projektdaten</MenuItem>
        <MenuItem :to="{ name: 'project-map' }">Kartenansicht</MenuItem>
      </MenuSection>

      <MenuSection title="Quelle">
        <MenuItem :to="{ name: 'project-systems' }">Systeme</MenuItem>
        <MenuItem :to="{ name: 'project-conductor-allocations' }">Leiterzuordnungen</MenuItem>
        <MenuItem :to="{ name: 'project-towers' }">Masten</MenuItem>
        <MenuItem :to="{ name: 'project-spans' }">Spannfelder</MenuItem>
        <MenuItem :to="{ name: 'project-operational-modes' }">Betriebsfälle</MenuItem>

        <template #actions>
          <p-btn
            :disabled="importSourceDisabled"
            plain
            size="small"
            :title="
              importSourceDisabled
                ? 'Import nicht möglich, da bereits Quellen angelegt sind.'
                : 'Import aus dem Overheadline-Format'
            "
            :type="importSourceDisabled ? 'default' : 'primary'"
            @mouseenter="tooltip = true"
            @mouseleave="tooltip = false"
            @click="importDialog = true"
          >
            Import
          </p-btn>
        </template>
      </MenuSection>

      <MenuSection title="Medien">
        <MenuItem :to="{ name: 'project-media' }">Leitungen</MenuItem>
      </MenuSection>

      <MenuSection title="Projektbibliothek">
        <MenuItem :to="{ name: 'project-towertypes' }">Masttypen</MenuItem>
        <MenuItem :to="{ name: 'project-conductortypes' }">Leitertypen</MenuItem>
        <MenuItem :to="{ name: 'project-pipetypes' }">Rohrleitungstypen</MenuItem>
      </MenuSection>
    </div>
    <div class="pb-6 px-6">
      <p-btn
        class="w-full"
        @click="closeProject"
        title="Hebt die Sperrung dieses Projektes auf, damit andere Nutzer es bearbeiten können."
        :loading="unlocking"
        data-test="close-project"
      >
        Projekt schließen
      </p-btn>
    </div>
    <ProjectImportSourcesDialog
      v-model="importDialog"
      :disabled="importSourceDisabled"
    ></ProjectImportSourcesDialog>
  </div>
</template>

<style scoped lang="css"></style>
