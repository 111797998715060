<script lang="ts" setup>
import { LayerId } from '@/components/map'
import { useCalculationStore } from '@/stores/calculation'
import { computed, inject } from 'vue'
import { Vector as VectorSource } from 'ol/source'
import { GeoJSON as GeoJSONFormat } from 'ol/format'
import { useRoute } from 'vue-router'
import { useProjectStore } from '@/stores/project'
import { Feature, Map } from 'ol'
import { relationsStyle } from '../../style/relations'
import { UseMapInjectKeys } from '@/components/map/composables/useMapInjectKeys'
import LayerVector from '@/components/map/layer/LayerVector.vue'

const calculationStore = useCalculationStore()
const geoJSON = computed(() => calculationStore.resultRelations)

const projectId = useRoute().params['projectId'] as string
calculationStore.ensureLoaded(projectId)

const source = computed(() => {
  if (!geoJSON.value) {
    return undefined
  }

  const projectStore = useProjectStore()
  const project = projectStore.findById(projectId)

  const map = inject(UseMapInjectKeys.map) as Map

  const geoJsonOptions = {
    dataProjection: project?.crs,
    featureProjection: map.getView().getProjection()
  }

  return new VectorSource({
    features: new GeoJSONFormat(geoJsonOptions).readFeatures(geoJSON.value) as Feature[]
  })
})
</script>

<template>
  <LayerVector
    v-if="source"
    v-bind="$attrs"
    :id="LayerId.RELATIONS"
    :source="source"
    :layer-style="relationsStyle"
  />
</template>
