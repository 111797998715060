import { inject } from 'vue'
import useMapInteraction from '@/components/map/composables/useMapInteraction'
import { mediumHoverStyle } from '@/components/map/style/media'
import { Map } from 'ol'
import { pointerMove } from 'ol/events/condition'
import { Select } from 'ol/interaction'
import { HitTolerance, MediaLayerContext } from '../util'
import { UseMapInjectKeys } from '@/components/map/composables/useMapInjectKeys'

export default function useMediaHover(ctx: MediaLayerContext) {
  /**
   * Add map interaction to highlight media features on mouse hover
   */
  function highlightFeature() {
    const hoverInteraction = new Select({
      condition: pointerMove,
      filter: (feature) => {
        // Do not highlight selected features
        return !ctx.selectedFeatures.value.includes(feature)
      },
      hitTolerance: HitTolerance,
      layers: [ctx.layer],
      style: mediumHoverStyle
    })

    // Change cursor to pointer on hover
    const map = inject(UseMapInjectKeys.map) as Map
    hoverInteraction.on('select', (event) => {
      map.getViewport().style.cursor = event.selected.length > 0 ? 'pointer' : ''
    })

    useMapInteraction(hoverInteraction)
  }

  highlightFeature()
}
