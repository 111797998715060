import { Project } from '@/model'

export const enum LayerId {
  SOURCE = 'source',
  MEDIA = 'media',
  RESULT = 'result',
  // The following layer ids are not used in the layer config, but inside
  // other layers (i.e. results):
  CORRIDOR = 'corridor',
  LOAD_FLOW = 'loadFlow',
  EMF = 'emf',
  RELATIONS = 'relations',
  MAX_VOLTAGE = 'maxVoltage'
}

export type LayerDefinition = {
  id: LayerId
  label: string
  options?: OptionDefinition[]
  hiddenByDefault?: boolean
}

export type OptionDefinition = {
  id: string
  label: string
  defaultValue?: any
}

export type LayerConfig = LayerDefinition[]

/**
 * This object describes the structure of the user-visible layers and its options
 *
 * It is used i.e. by the LayerControl component to display layers and options in a tree
 */
export const createLayerConfig: (project: Project) => LayerConfig = (project) => {
  const config: LayerConfig = [
    {
      id: LayerId.SOURCE,
      label: 'Quelle',
      options: [
        { id: 'showTowers', label: 'Masten', defaultValue: true },
        { id: 'showSpans', label: 'Spannfelder', defaultValue: true },
        { id: 'showCorridor', label: 'Beeinflussungskorridor', defaultValue: true }
      ]
    },
    {
      id: LayerId.MEDIA,
      label: 'Medien'
    },
    {
      id: LayerId.RESULT,
      label: 'Berechnung',
      options: [
        project.mediaType === 'telecom'
          ? { id: 'showEmfResult', label: 'Längspannungen', defaultValue: true }
          : { id: 'showLoadFlow', label: 'Lastfluss', defaultValue: true },
        { id: 'showRelations', label: 'Beeinflussungsabschnitte', defaultValue: false }
      ]
    }
  ]

  return config
}
