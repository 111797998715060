import { constrainedSize, lineWidth } from '@/components/map/style/util'
import { Feature } from 'ol'
import { MultiLineString, MultiPoint, Polygon } from 'ol/geom'
import { Circle, Fill, Stroke, Style, Text } from 'ol/style'
import constants from './constants'

export function relationsStyle(feature: Feature, resolution: number) {
  return [
    // Polygon areas
    new Style({
      fill: new Fill({ color: constants.relation.color(0.2) }),
      stroke: new Stroke({ width: 1, color: constants.relation.color(1) }),
      text: new Text({
        font: `${constrainedSize(11, 13, resolution)}px ${constants.fontFamily}`,
        fill: new Fill({ color: constants.relation.color(0.8) }),
        text: `${feature.get('id') ?? ''}`
      })
    }),
    // Vertices (points)
    new Style({
      image: new Circle({
        radius: constrainedSize(2, 4, resolution),
        fill: new Fill({ color: constants.relation.color(1) })
      }),
      geometry: function (feature) {
        // return the coordinates of the first ring of the polygon
        // see https://openlayers.org/en/latest/examples/polygon-styles.html
        const geometry = feature.getGeometry() as Polygon | MultiLineString
        const coordinates = geometry?.getCoordinates()[0] || []
        return new MultiPoint(coordinates)
      }
    })
  ]
}
