import { ConductorAllocation, ConductorAllocationId, ProjectId } from '@/model'
import { HttpClient, PaginatedResponse } from '@/api/useHttpClient'
import { WebSocket } from '@/api/useWebSocket'
import { HSBApi } from '@/api/HSBApiBase'

const path = (projectId: ProjectId, conductorId?: string) => {
  return `projects/${projectId}/conductorAllocations` + (conductorId ? `/${conductorId}` : '')
}

export class ConductorAllocationApiClass implements HSBApi {
  constructor(public httpClient: HttpClient, public webSocket: WebSocket) {}
  async delete(id: ConductorAllocationId, projectId: ProjectId): Promise<void> {
    await this.httpClient.delete(path(projectId, id))
  }

  async getAllByProject(projectId: ProjectId): Promise<ConductorAllocation[]> {
    const { data } = await this.httpClient.get<PaginatedResponse<ConductorAllocation>>(
      path(projectId)
    )
    return data.results || []
  }

  async save(conductorAllocation: ConductorAllocation): Promise<ConductorAllocation> {
    const { data } = await this.httpClient.put<ConductorAllocation>(
      path(conductorAllocation.project, conductorAllocation.id),
      { data: conductorAllocation }
    )
    return data
  }
}
