import z from 'zod'
import { TowerBase, TowerDirectionProperties } from '@/model'

const TowerDirectionPropertiesZod = z.object({
  allocation: z.string(),
  earthwires: z.array(z.string()),
  offset: z.number().nullable(),
  type: z.string()
})
export const TowerDirectionPropertiesSchemaIn: z.ZodType<TowerDirectionProperties> =
  TowerDirectionPropertiesZod

export const TowerDirectionPropertiesSchemaOut: z.ZodType<Partial<TowerDirectionProperties>> =
  TowerDirectionPropertiesZod.partial() // All values can are optional on Out

const TowerBase = z.object({
  in: TowerDirectionPropertiesSchemaIn,
  out: TowerDirectionPropertiesSchemaOut.nullish(),
  earthResistivity: z.number().optional(),
  name: z.string().nullish(),
  position: z.number().optional(),
  project: z.string(),
  x: z.number(),
  y: z.number()
})

export const TowerBaseSchema: z.ZodType<TowerBase> = TowerBase.extend({ id: z.string() })
