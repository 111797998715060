const withAlpha =
  (color: [r: number, g: number, b: number]) =>
  (alpha = 1) =>
    `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${alpha})`

export default {
  fontFamily: '"Inter var", sans-serif',
  medium: {
    color: withAlpha([18, 11, 218]),
    hoverColor: withAlpha([30, 100, 240]),
    mutedColor: withAlpha([17, 13, 134])
  },
  relation: {
    color: withAlpha([123, 158, 27])
  },
  source: {
    color: withAlpha([231, 36, 60]),
    hoverColor: withAlpha([255, 100, 100])
  },
  selected: {
    color: (alpha = 1) => `rgba(0, 153, 255, ${alpha}`
  }
}
