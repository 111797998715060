<script lang="ts">
import { defineComponent } from 'vue'
import { ElMessage } from 'element-plus'
import { useMediumStore } from '@/stores/medium'
import { ProjectId, Medium, MediumId } from '@/model'
import DetailPanel from '@/layouts/pages/DetailPanel.vue'
import MediumForm from './MediumForm.vue'
import { useProjectStore } from '@/stores/project'
import { RouteLocationRaw } from 'vue-router'

export default defineComponent({
  name: 'MediumDetail.vue',
  components: { MediumForm, DetailPanel },

  props: {
    editPath: { type: Boolean, default: true }
  },

  setup() {
    const store = useMediumStore()
    const projectStore = useProjectStore()
    return { store, projectStore }
  },

  data: () => ({
    saving: false
  }),

  computed: {
    create(): boolean {
      return !this.$route.params['id']
    },
    defaultItem(): Partial<Medium> {
      const projectType = this.projectStore.findById(this.projectId)?.mediaType
      return {
        ...this.store.draft,
        type: projectType === 'telecom' ? 'wire' : projectType
      }
    },
    projectId(): ProjectId {
      return this.$route.params['projectId'] as string
    },
    selectedItems(): Medium[] {
      if (this.store.draft) {
        return [this.defaultItem as Medium]
      } else {
        return this.store.selection
          .map((id: MediumId) => this.store.findById(id))
          .filter((item): item is Medium => item !== undefined)
      }
    },
    title(): string {
      if (this.create) {
        return 'Neues Medium'
      }

      const selectionCount = this.selectedItems.length
      if (selectionCount > 1) {
        return `${selectionCount} Medien bearbeiten`
      } else {
        return 'Medium bearbeiten'
      }
    }
  },

  methods: {
    onClose() {
      this.store.selection = []
      this.$router.push(
        (this.$route.meta.returnRoute as RouteLocationRaw) || { name: 'project-media' }
      )
    },
    async onSave(items: Medium[]) {
      this.saving = true
      try {
        const itemCount = items.length
        for (const item of items) {
          const dto: Partial<Medium> = { ...item, project: this.projectId }
          if (itemCount > 1) {
            // do not sync pathes
            delete dto['path']
          }
          await this.store.save(dto as Medium)
        }

        ElMessage.success(
          this.create
            ? 'Medium wurde erfolgreich angelegt.'
            : 'Daten wurden erfolgreich gespeichert.'
        )
      } finally {
        this.saving = false
      }
    }
  }
})
</script>

<template>
  <DetailPanel :title="title" @close="onClose">
    <MediumForm
      v-if="store.loaded"
      :editPath="editPath"
      :items="selectedItems"
      :saving="saving"
      @cancel="onClose"
      @submit="onSave"
      :defaultItem="defaultItem"
    />
  </DetailPanel>
</template>

<style scoped lang="css"></style>
