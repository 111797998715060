<script lang="ts">
import { useProject } from '@/composables/useProject'
import { defineComponent } from 'vue'
import DetailPanel from '@/layouts/pages/DetailPanel.vue'
import { useSpanStore } from '@/stores/span'
import { Span, SpanId } from '@/model'
import SpanForm from '@/views/project/spans/SpanForm.vue'
import { ElMessage } from 'element-plus'
import { RouteLocationRaw } from 'vue-router'

export default defineComponent({
  name: 'SpanDetail.vue',
  components: { SpanForm, DetailPanel },

  setup() {
    const store = useSpanStore()
    const { projectId } = useProject()
    return { projectId, store }
  },

  data: () => ({
    saving: false
  }),

  computed: {
    selectedItems(): Span[] {
      return this.store.selection
        .map((id: SpanId) => this.store.findById(id))
        .filter((item): item is Span => item !== undefined)
    },
    title(): string {
      const selectionCount = this.selectedItems.length
      if (selectionCount > 1) {
        return `${selectionCount} Spannfelder bearbeiten`
      } else {
        return 'Spannfeld bearbeiten'
      }
    }
  },

  methods: {
    onClose() {
      this.store.selection = []
      this.$router.push(
        (this.$route.meta.returnRoute as RouteLocationRaw) || { name: 'project-spans' }
      )
    },
    async onSave(items: Span[]) {
      this.saving = true
      try {
        for (const item of items) {
          await this.store.save({ ...item }, this.projectId)
        }
        ElMessage.success('Daten wurden erfolgreich gespeichert.')
      } finally {
        this.saving = false
      }
    }
  }
})
</script>

<template>
  <DetailPanel :title="title" @close="onClose">
    <SpanForm :items="selectedItems" :saving="saving" @cancel="onClose" @submit="onSave" />
  </DetailPanel>
</template>

<style scoped lang="css"></style>
