<script lang="ts">
import { useAppAbility } from '@/permissions/useAppAbility'
import { defineComponent, ref } from 'vue'
import DetailPanel from '@/layouts/pages/DetailPanel.vue'
import { useTowerTypeStore } from '@/stores/tower-type'
import {
  LineTowerType,
  LineTowerTypeTranslations,
  ProjectId,
  TowerType,
  TowerTypeId
} from '@/model'
import { ElMessage, FormInstance, FormRules } from 'element-plus'
import CoordinateList from '@/components/form/CoordinateList.vue'
import TowerSVG from '@/components/tower/TowerSVG.vue'
import { v4 } from 'uuid'
import { useTowerStore } from '@/stores/tower'
import { values } from '@/util/helpers'
import { towerConductorsLabelFn, towerEarthwireLabelFn } from '@/util'
import { useTowerTypePositions } from '@/composables/useTowerTypePositions'

export default defineComponent({
  name: 'TowerTypeForm',
  components: { TowerSVG, CoordinateList, DetailPanel },
  setup() {
    const fields = ref<Partial<TowerType>>({})
    const { allPositionsFiltered } = useTowerTypePositions(fields)
    const { can } = useAppAbility()
    return {
      can,
      towerTypeStore: useTowerTypeStore(),
      towerStore: useTowerStore(),
      fields,
      allPositionsFiltered
    }
  },

  data: () => ({
    activeTab: 'conductors',
    saving: false,
    towerConductorsLabelFn,
    towerEarthwireLabelFn,
    LineTowerType,
    LineTowerTypeTranslations
  }),

  computed: {
    create(): boolean {
      return !this.id
    },
    id(): TowerTypeId {
      return this.$route.params.id as string
    },
    projectId(): ProjectId | undefined {
      return this.$route.params.projectId as string
    },
    item(): TowerType | undefined {
      return (
        this.towerTypeStore.findById(this.id) || {
          project: this.projectId as string,
          id: v4(),
          name: '',
          conductorPositions: [],
          earthwirePositions: [],
          lineTowerType: LineTowerType.SUSPENSION_TOWER
        }
      )
    },

    readOnly(): boolean {
      if (this.$route.params.projectId && this.can('update', 'LibraryProject')) {
        return false
      }
      return !this.can('update', 'Library')
    },
    /**
     * This view should have a route-name glob of "*-edit" or "*-create".
     * This way we can redirect to parent by slicing off everything after "-"
     */
    routeNameBack() {
      const routeName = String(this.$route.name)
      return routeName.slice(0, routeName.lastIndexOf('-'))
    },
    title(): string {
      if (this.readOnly) {
        return 'Masttyp-Eigenschaften'
      } else if (this.create) {
        return 'Neuer Masttyp'
      } else {
        return 'Masttyp bearbeiten'
      }
    },
    validationRules(): FormRules {
      return {
        name: {
          required: true,
          type: 'string',
          trigger: 'blur',
          message: 'Bitte geben Sie einen Namen ein.'
        }
      }
    }
  },

  mounted() {
    this.fields = { ...this.item }
  },

  watch: {
    item() {
      this.fields = { ...this.item }
      ;(this.$refs.form as FormInstance).resetFields()
    }
  },

  methods: {
    async save() {
      const valid = await (this.$refs.form as FormInstance).validate(() => {})
      if (valid) {
        if (await this.conductorPositionsAmountLocked(this.fields, this.item, this.projectId)) {
          return
        }

        this.saving = true
        try {
          const savedItem = await this.towerTypeStore.save(this.fields as TowerType)
          if (this.create) {
            ElMessage.success('Masttyp wurde erfolgreich angelegt.')
            this.$router.push({ name: this.routeNameBack + '-edit', params: { id: savedItem.id } })
          } else {
            ElMessage.success('Daten wurden erfolgreich gespeichert.')
          }
        } finally {
          this.saving = false
        }
      }
    },
    /**
     * The user cannot change the **amount** of conductor positions of a tower type
     * if the tower type is assigned to a tower in the project.
     */
    async conductorPositionsAmountLocked(
      update: Partial<TowerType>,
      item: TowerType | undefined,
      projectId: ProjectId | undefined
    ) {
      if (projectId === undefined || item === undefined) {
        return false
      }
      // Value update has same value
      if (update.conductorPositions === item.conductorPositions) {
        return false
      }

      // Refresh tower Store
      await this.towerStore.load(projectId)
      const usedInTowers = values(this.towerStore.itemsById)
        .map((tower) => (tower.in.type === item.id || tower.out?.type === item.id ? tower : null))
        .filter((item) => item)
      if (!usedInTowers.length) {
        return false
      }

      // Send notification
      ElMessage.error(
        'Fehler: Anzahl der Leiter kann nicht geändert werden, da Masttyp bei folgenden Masten verwendet wird: ' +
          usedInTowers.map((tower) => `${tower?.name || tower?.id}`).join(', ')
      )
      return true
    }
  }
})
</script>

<template>
  <DetailPanel :title="title" @close="$router.push({ name: routeNameBack })">
    <el-form
      ref="form"
      :model="fields"
      :rules="validationRules"
      label-position="top"
      require-asterisk-position="right"
    >
      <el-form-item prop="name" label="Name">
        <el-input
          v-model="fields.name"
          class="font-semibold"
          autofocus
          data-field="name"
          :disabled="readOnly"
        />
      </el-form-item>

      <el-form-item prop="lineTowerType" label="Bauweise">
        <el-select
          v-model="fields.lineTowerType"
          class="font-semibold w-full"
          autofocus
          data-field="lineTowerType"
          :disabled="readOnly"
        >
          <el-option
            v-for="item in LineTowerType"
            :key="item"
            :label="LineTowerTypeTranslations[item]"
            :value="item"
          />
        </el-select>
      </el-form-item>
    </el-form>

    <TowerSVG :wireData="allPositionsFiltered" />

    <el-tabs v-model="activeTab">
      <el-tab-pane label="Leiterseile" name="conductors">
        <CoordinateList
          v-model="fields.conductorPositions"
          unit="m"
          :min-rows="1"
          :label-fn="towerConductorsLabelFn"
          :read-only="readOnly"
        />
      </el-tab-pane>
      <el-tab-pane label="Erdseile" name="earthwires">
        <CoordinateList
          :label-fn="towerEarthwireLabelFn"
          v-model="fields.earthwirePositions"
          unit="m"
          :min-rows="0"
          :read-only="readOnly"
        />
      </el-tab-pane>
    </el-tabs>

    <div v-if="!readOnly">
      <el-button type="primary" @click="save">Speichern</el-button>
      <el-button text @click="$router.push({ name: routeNameBack })">Abbrechen</el-button>
    </div>
  </DetailPanel>
</template>

<style scoped lang="css"></style>
