import { ProjectId } from '@/model/project'
import { SystemId, uuid } from '@/model/index'
import { HSBLibConductorType } from '@/config/schemas/hsb-lib/ConductorType'
import { v4 } from 'uuid'
import { Container } from '@/config/schemas/hsb-lib/util'

export type ConductorTypeId = uuid
export type ConductorType = {
  id: ConductorTypeId
  name: string
  project?: ProjectId | null
  innerRadius?: number // required for type: 'tubular'
  muR: number
  outerRadius: number
  rDC: number
  subconductorCount: number // == 1 => "Einzelleiter", > 1 => "Bündelleiter"
  subconductorAngle?: number // required if subconductorCount > 1
  subconductorSpacing?: number // required if subconductorCount > 1
  type: ConductorTypeEnum
}
export enum ConductorTypeEnum {
  SOLID = 'solid',
  TUBULAR = 'tubular'
}
export type ConductorTypeCreate = Omit<ConductorType, 'id'>

export type ConductorAllocationId = uuid
export type ConductorAllocation = {
  id: ConductorAllocationId
  name: string
  mapping: ConductorAllocationMapping[] // index maps TowerType->conductorPositions (A, B, C ...)
  project: ProjectId
}

export type ConductorAllocationMapping = {
  system: SystemId | null
  index: number | null // L1 L2 L3 ... (backend is 0-based, we only display in frontend index+1)
}

export function fromHSBConductorTypes(
  container: Container<HSBLibConductorType>
): Container<ConductorType> {
  const conductorTypes: Container<ConductorType> = {}
  for (const objectId in container) {
    const el = container[objectId]
    if (conductorTypes[objectId]) {
      throw Error(`ConductorType ${objectId} mehrmals definiert!`)
    }

    const name = `${el.subconductor_number > 1 ? el.subconductor_number + 'x ' : ''}${
      el.type === 0 ? 'Vollleiter' : 'Hohlleiter'
    } ${el.type !== 0 ? el.inner_radius + ' / ' : ''}${el.outer_radius} mm`

    conductorTypes[objectId] = {
      id: v4(),
      type: el.type === 0 ? ConductorTypeEnum.SOLID : ConductorTypeEnum.TUBULAR,
      muR: el.mu_r,
      rDC: el.R_dc20,
      name,
      innerRadius: el.inner_radius,
      outerRadius: el.outer_radius,
      subconductorAngle: el.subconductor_angle,
      subconductorSpacing: el.subconductor_spacing,
      subconductorCount: el.subconductor_number
    }
  }
  return conductorTypes
}
