import { LayerId } from '@/components/map'
import useContextMenu, { CollectorFunction } from '@/components/map/composables/useContextMenu'
import { UseMapItems } from '@/components/map/composables/useMap'
import { useMapSelection } from '@/components/map/composables/useMapSelection'
import { createMediumDraft, deleteMedia } from '@/components/map/layer/media/functions'
import { MediumId } from '@/model'
import { useMediumStore } from '@/stores/medium'
import { Feature } from 'ol'
import { LineString } from 'ol/geom'
import { inject, onMounted, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { MediaLayerContext } from '../util'
import { UseMapInjectKeys } from '@/components/map/composables/useMapInjectKeys'

export default function useMediaContextMenu(ctx: MediaLayerContext) {
  const { registerCollector, unregisterCollector } = useContextMenu()

  const { clear } = useMapSelection()
  const { showLayer } = inject(UseMapInjectKeys.useMap) as UseMapItems

  const router = useRouter()
  const mediumStore = useMediumStore()

  const collectorFunction: CollectorFunction = (coordinate, options) => {
    const items = [
      {
        id: 'mediumCreate',
        label: 'Neues Medium...',
        handler: async () => {
          showLayer(LayerId.MEDIA)
          await createMediumDraft({ x: coordinate[0], y: coordinate[1] }, ctx)
          router.push({ name: 'project-map-medium-create' })
        },
        priority: 12,
        icon: 'MediumAdd'
      }
    ]

    const mediaIds = ctx.selectedMedia.value
      .filter((feature) => !feature.get('_draft'))
      .map((feature) => feature.getId() as MediumId)
    const mediaCount = mediaIds.length

    if (mediaCount > 0) {
      items.push({
        id: 'mediumDelete',
        label: mediaCount > 1 ? `${mediaCount} Medien löschen` : 'Medium löschen',
        handler: async () => {
          await deleteMedia(ctx.selectedMedia.value.map((f) => f.getId() as MediumId))
          clear()
        },
        priority: 220,
        icon: 'MediumDelete'
      })
    }

    if (mediaCount === 1) {
      items.push({
        id: 'mediumSplit',
        label: 'Medium teilen',
        handler: async () => {
          const feature = ctx.selectedMedia.value[0] as Feature<LineString>
          const closestPoint = feature.getGeometry()?.getClosestPoint(options.mapCoordinate) || [
            0, 0
          ]
          await mediumStore.split(feature.getId() as MediumId, ctx.mapToProject(closestPoint))
        },
        priority: 215,
        icon: 'MediumSplit'
      })
    }

    if (mediaCount >= 2) {
      items.push({
        id: 'mediumJoin',
        label: `${mediaCount} Medien verbinden`,
        handler: async () => {
          await mediumStore.join(ctx.selectedMedia.value.map((m) => m.getId() as MediumId))
        },
        priority: 210,
        icon: 'MediumJoin'
      })
    }
    return items
  }

  onMounted(() => registerCollector(collectorFunction))

  onUnmounted(() => unregisterCollector(collectorFunction))
}
