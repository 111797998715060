import { Project, ProjectId, ProjectExport } from '@/model'
import { PaginatedResponse } from '@/api/useHttpClient'

import { HSBApiBase } from '@/api/HSBApiBase'

const path = (projectId?: ProjectId) => {
  return `projects` + (projectId ? `/${projectId}` : '')
}

export class ProjectApiClass extends HSBApiBase {
  async delete(projectId: ProjectId) {
    return await this.httpClient.delete(path(projectId))
  }

  async duplicate(id: ProjectId) {
    return await this.httpClient.post<Project>(`${path(id)}/duplicate`)
  }

  async export(id: ProjectId) {
    return (await this.httpClient.get<ProjectExport>(`${path(id)}/dump`)).data
  }

  async load(payload: ProjectExport) {
    return (
      await this.httpClient.post<ProjectExport, ProjectExport>('projects/load', {
        data: payload
      })
    ).data
  }

  async get(id: ProjectId) {
    return await this.httpClient.get<Project>(`${path(id)}`)
  }

  async getAll(): Promise<Project[]> {
    const res = await this.httpClient.get<PaginatedResponse<Project>>(path())
    return res.data.results || []
  }

  async getExtent(id: ProjectId): Promise<[number, number, number, number] | null> {
    const response = await this.httpClient.get(`${path(id)}/extent`)
    return response.data || null
  }


  async lock(id: ProjectId) {
    return await this.httpClient.post(`${path(id)}/lock`)
  }

  async unlock(id: ProjectId) {
    return await this.httpClient.post(`${path(id)}/unlock`)
  }

  onProjectDeleted(handler: (data: { id: ProjectId }) => void) {
    this.webSocket.subscribe('Project.deleted', handler)
    return this.makeUnsubscribeFn('Project.deleted', handler)
  }

  onProjectUpdated(handler: (project: Project) => void) {
    this.webSocket.subscribe('Project.updated', handler)
    return this.makeUnsubscribeFn('Project.updated', handler)
  }

  async save(project: Project): Promise<Project> {
    const res = await this.httpClient.put<Project, Project>(path(project.id), { data: project })
    return res.data
  }
}
