<script lang="ts">
import { useSpanStore } from '@/stores/span'
import { defineComponent } from 'vue'
import DetailPanel from '@/layouts/pages/DetailPanel.vue'
import { useTowerStore } from '@/stores/tower'
import {
  ConductorAllocationMapping,
  ConductorTypeId,
  ProjectId,
  Tower,
  TowerId,
  WirePositionLabeledConductors,
  WirePositionLabeledEarthwire
} from '@/model'
import TowerForm from '@/views/project/towers/TowerForm.vue'
import { ElMessage } from 'element-plus'
import { RouteLocationRaw } from 'vue-router'
import { useTowerTypeStore } from '@/stores/tower-type'

export type ConductorTowerTypeMapper = (
  conductorMapping: ConductorAllocationMapping,
  index: number
) => WirePositionLabeledConductors

export type EarthwireTowerTypeMapper = (
  conductorId: ConductorTypeId,
  index: number
) => WirePositionLabeledEarthwire

export default defineComponent({
  name: 'TowerDetail.vue',
  components: { TowerForm, DetailPanel },

  setup() {
    return {
      spanStore: useSpanStore(),
      towerStore: useTowerStore(),
      towerTypeStore: useTowerTypeStore()
    }
  },

  data: () => ({
    saving: false
  }),

  computed: {
    create(): boolean {
      return !this.$route.params['id']
    },
    projectId(): ProjectId {
      return this.$route.params['projectId'] as string
    },
    selectedItems(): Tower[] {
      if (this.create && this.towerStore.draft) {
        return [this.towerStore.draft as Tower]
      }
      return this.towerStore.selection
        .map((id: TowerId) => this.towerStore.findById(id))
        .filter((item): item is Tower => item !== undefined)
    },

    title(): string {
      if (this.create) {
        return 'Neuer Mast'
      }

      const selectionCount = this.selectedItems.length
      if (selectionCount > 1) {
        return `${selectionCount} Masten bearbeiten`
      } else {
        return 'Mast bearbeiten'
      }
    }
  },

  methods: {
    async onClose() {
      this.towerStore.draft = undefined
      this.towerStore.selection = []
      await this.$router.push(
        (this.$route.meta.returnRoute as RouteLocationRaw) || { name: 'project-towers' }
      )
    },
    async onSave(items: Tower[]) {
      this.saving = true
      try {
        for (const item of items) {
          // Make sure project id is correct
          const sanitizedItem = { ...item, project: this.projectId }
          await this.towerStore.save(sanitizedItem)
        }

        ElMessage.success(
          this.create ? 'Mast wurde erfolgreich angelegt.' : 'Daten wurden erfolgreich gespeichert.'
        )
        this.spanStore.load(this.projectId)
      } finally {
        this.saving = false
      }
    }
  }
})
</script>

<template>
  <DetailPanel :title="title" @close="onClose">
    <TowerForm :items="selectedItems" :saving="saving" @cancel="onClose" @submit="onSave" />
  </DetailPanel>
</template>

<style scoped lang="css"></style>
