<script setup lang="ts">
import { formatNumber } from '@/util/helpers'
import { Feature } from 'ol'

import { computed, PropType } from 'vue'

const props = defineProps({
  feature: {
    type: Object as PropType<Feature>,
    required: true
  }
})

const result = computed(() => {
  const requiredProps = {
    color: null as null | string,
    EMF_abs: null as null | number
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { _type, geometry, ...featurePropsFiltered } = props.feature.getProperties()

  return {
    ...requiredProps,
    ...featurePropsFiltered
  }
})
</script>

<template>
  <div>
    <h3>Induzierte Längsspannung</h3>
    <div class="font-bold text-xl flex items-center !text-base mb-2">
      <div class="w-4 h-4 rounded inline-block" :style="{ backgroundColor: result.color || '' }" />
      <span class="pl-2">{{ formatNumber(result.EMF_abs, 'V') }}</span>
    </div>
    <div class="grid" style="grid-template-columns: auto 1fr auto 1fr">
      <template v-for="(value, prop) in result" :key="prop">
        <div class="label capitalize">{{ prop }}</div>
        <div class="value">{{ formatNumber(value) }}</div>
      </template>
    </div>
  </div>
</template>

<style scoped lang="css"></style>
