import { Medium, ProjectId } from '@/model'
import { HttpClient, PaginatedResponse } from '@/api/useHttpClient'
import { WebSocket } from '@/api/useWebSocket'
import { HSBApi } from '@/api/HSBApiBase'

const path = (projectId: ProjectId, mediumId?: string) => {
  return `projects/${projectId}/media` + (mediumId ? `/${mediumId}` : '')
}

export class MediumApiClass implements HSBApi {
  constructor(public httpClient: HttpClient, public webSocket: WebSocket) {}
  async delete(medium: Medium) {
    return await this.httpClient.delete(path(medium.project, medium.id))
  }

  async deleteAllByProject(projectId: ProjectId) {
    const res = await this.httpClient.get<PaginatedResponse<Medium>>(path(projectId))
    return await Promise.all(
      res.data.results.map((item) => {
        return this.httpClient.delete<Medium>(path(projectId, item.id))
      })
    )
  }

  async getAllByProject(projectId: ProjectId): Promise<Medium[]> {
    const res = await this.httpClient.get<PaginatedResponse<Medium>>(path(projectId), {
      pagination: { pageSize: 1000, page: 1 }
    })
    return res.data.results || []
  }

  async save(medium: Medium): Promise<Medium> {
    const res = await this.httpClient.put<Medium>(path(medium.project, medium.id), { data: medium })
    return res.data
  }

  async saveList(project: ProjectId, list: Medium[]): Promise<Medium[]> {
    const res = await this.httpClient.put<Medium[]>(path(project), { data: list })
    return res.data
  }
}
