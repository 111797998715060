<script setup lang="ts">
import basemapImage from '@/assets/map/basemap-thumbnail.jpg'
import osmImage from '@/assets/map/osm-thumbnail.jpg'
import satelliteImage from '@/assets/map/satellite-thumbnail.jpg'
import { UseMapItems } from '@/components/map/composables/useMap'
import { inject } from 'vue'
import { UseMapInjectKeys } from '@/components/map/composables/useMapInjectKeys'

const mapItems = [
  { value: 'osm', label: 'OpenStreetMap (farbig)', image: osmImage },
  { value: 'grayscale', label: 'Basemap.de (Graustufen)', image: basemapImage },
  { value: 'satellite', label: 'MapTiler (Satellit)', image: satelliteImage }
]

const { mapState } = inject(UseMapInjectKeys.useMap) as UseMapItems
</script>

<template>
  <div>
    <div class="flex space-x-2">
      <img
        v-for="item in mapItems"
        :key="item.value"
        class="opacity-80 h-14 flex-1 cursor-pointer hover:opacity-100 transition-opacity object-cover border-2 border-white rounded-sm"
        :class="{ '!border-primary-500 opacity-100 ': item.value === mapState.basemap.type }"
        :src="item.image"
        :title="item.label"
        @click="mapState.basemap.type = item.value as any"
      />
    </div>
    <el-slider
      class="mt-3 -mb-2"
      size="small"
      label="Transparenz"
      title="Kartentransparenz"
      :min="0"
      :max="100"
      :step="10"
      :modelValue="mapState.basemap.opacity * 100"
      @update:modelValue="mapState.basemap.opacity = Number($event) / 100"
      :show-tooltip="false"
    />
  </div>
</template>
