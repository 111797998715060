import type { ContextMenuItem } from '@/components/map/ContextMenu.vue'
import { FeatureLike } from 'ol/Feature'

export type CollectorOptions = {
  features: FeatureLike[]
  selectedFeatures: FeatureLike[]
  mapCoordinate: number[]
  projectCoordinate: number[]
}

export type CollectorFunction = (
  coordinates: number[],
  options: CollectorOptions
) => ContextMenuItem[]

let registeredCollectors: CollectorFunction[] = []

/**
 * Collects ContextMenu items from the registered collector functions (usually defined at the layer level)
 *
 * @param coordinates
 * @param options
 */
const collectMenuItems: CollectorFunction = (coordinates, options) => {
  let items: ContextMenuItem[] = []
  registeredCollectors.forEach((collector) => {
    items = [...items, ...collector(coordinates, options)]
  })
  return items.sort((a, b) => (a.priority > b.priority ? 1 : -1))
}

function registerCollector(collector: CollectorFunction) {
  if (!registeredCollectors.includes(collector)) {
    registeredCollectors.push(collector)
  } else {
    console.info(
      '[ContextMenu.registerCollector()] Trying to register an already registered collector'
    )
  }
}

function unregisterCollector(collector: CollectorFunction) {
  if (registeredCollectors.includes(collector)) {
    registeredCollectors = registeredCollectors.filter((c) => c !== collector)
  } else {
    console.info('[ContextMenu.registerCollector()] Trying to unregister a non-existing collector')
  }
}

export default function useContextMenu() {
  return {
    collectMenuItems,
    registerCollector,
    unregisterCollector
  }
}
