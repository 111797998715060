<script lang="ts">
import { useTowerTypeStore } from '@/stores/tower-type'
import { defineComponent } from 'vue'
import SecondaryNavigation from '@/components/navigation/SecondaryNavigation.vue'
import ProjectNavigation from '@/components/navigation/ProjectNavigation.vue'
import { useProjectStore } from '@/stores/project'
import { Project, ProjectId } from '@/model'
import { useTowerStore } from '@/stores/tower'
import { useSpanStore } from '@/stores/span'
import ProjectLockedOverlay from '@/components/project/ProjectLockedOverlay.vue'
import { useAuthentication } from '@prionect/ui'
import { useCalculationStore } from '@/stores/calculation'

export default defineComponent({
  name: 'ProjectView',
  components: { ProjectLockedOverlay, ProjectNavigation, SecondaryNavigation },

  data: () => ({
    unlocking: false
  }),

  computed: {
    project(): Project | undefined {
      return this.projectId ? this.projectStore.findById(this.projectId) : undefined
    },

    projectId() {
      return this.$route.params.projectId as ProjectId
    },

    projectLockedByAnotherUser(): boolean {
      const { currentUser } = useAuthentication()
      const project = this.projectStore.findById(this.projectId)
      if (project) {
        return (project.locked && project.lockedBy !== currentUser.value.id) || false
      } else {
        // project is not loaded yet
        return false
      }
    }
  },

  setup() {
    const projectStore = useProjectStore()
    projectStore.ensureLoaded()

    return {
      projectStore,
      calculationStore: useCalculationStore(),
      spanStore: useSpanStore(),
      towerStore: useTowerStore(),
      towerTypeStore: useTowerTypeStore()
    }
  },

  mounted() {
    this.loadStores()
    this.lockProject()
  },

  watch: {
    projectId() {
      this.loadStores()
    },
    project(project: Project | undefined, prev: Project | undefined) {
      if (project?.id !== prev?.id) {
        this.lockProject()
        this.calculationStore.reset()
      } else {
        setTimeout(() => {
          const project = this.projectStore.findById(this.projectId)
          if (project?.locked === false && !this.unlocking) {
            // User closed the project, so it is no longer locked -> return to project list
            this.$router.push({ name: 'projects' })
          }
        }, 500)
      }
    }
  },

  methods: {
    loadStores() {
      // Project id might be empty while creating a new project
      if (this.projectId) {
        this.towerStore.ensureLoaded(this.projectId)
        this.spanStore.ensureLoaded(this.projectId)
        this.towerTypeStore.ensureLoaded()
        this.towerTypeStore.ensureLoadedByProject(this.projectId)
      }
    },

    /**
     * Lock this project, no matter whether it is locked by another user
     */
    async takeOver() {
      this.unlocking = true
      try {
        await this.projectStore.unlock(this.projectId)
        await this.projectStore.lock(this.projectId)
      } finally {
        this.unlocking = false
      }
    },

    async lockProject() {
      if (this.projectId && this.project && !this.project.locked) {
        await this.projectStore.lock(this.projectId)
      }
    }
  }
})
</script>

<template>
  <div class="flex w-full h-full overflow-hidden relative" data-component="ProjectView">
    <SecondaryNavigation class="project-navigation w-[15rem] overflow-auto">
      <ProjectNavigation v-if="project" :project="project" />
    </SecondaryNavigation>
    <div class="flex-1 overflow-auto bg-white">
      <router-view></router-view>
    </div>
    <ProjectLockedOverlay
      v-if="projectLockedByAnotherUser"
      :project-id="projectId"
      :unlocking="unlocking"
      @request-lock="takeOver"
    />
  </div>
</template>

<style lang="css">
.fullscreen .project-navigation {
  display: none;
}
</style>
