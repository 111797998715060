import { useCorridorStore } from '@/stores/corridor'
import { defineStore } from 'pinia'
import { v4 as uuid } from 'uuid'
import { ProjectId, Span, SpanId, TowerId } from '@/model'
import { SpanApi } from '@/api'
import { useTowerStore } from '@/stores/tower'
import { Feature, FeatureCollection } from 'geojson'

const towerStore = useTowerStore()

export const useSpanStore = defineStore('span', {
  state: () => ({
    itemsById: {} as Record<SpanId, Span>,
    loaded: false,
    loading: false,
    loadedProject: undefined as undefined | ProjectId,
    selection: [] as SpanId[]
  }),

  getters: {
    findById(state) {
      return (id: SpanId): Span | undefined => state.itemsById[id]
    },
    geoJSON(): FeatureCollection {
      const features: Feature[] = []

      this.items.forEach((span) => {
        const beginTower = towerStore.findById(span.beginTower)
        const endTower = towerStore.findById(span.endTower)

        if (beginTower && endTower) {
          features.push({
            id: span.id,
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: [
                [beginTower.x, beginTower.y],
                [endTower.x, endTower.y]
              ]
            },
            properties: {
              _type: 'span',
              beginTower: span.beginTower,
              endTower: span.endTower,
              corridor: span.corridor
            }
          })
        }
      })

      return {
        type: 'FeatureCollection',
        features
      }
    },
    items(): Span[] {
      const towerPosition = (towerId?: TowerId) =>
        (towerId && towerStore.findById(towerId)?.position) || 0
      return Object.values(this.itemsById).sort((a, b) => {
        return towerPosition(a.beginTower) - towerPosition(b.beginTower)
      })
    }
  },

  actions: {
    getPosition(spanId: SpanId) {
      return this.items.findIndex((item) => item.id === spanId)
    },
    async delete(id: SpanId, projectId: ProjectId) {
      const span = this.findById(id)
      if (span) {
        await SpanApi.delete(span, projectId)
        delete this.itemsById[id]
      }
    },

    async ensureLoaded(projectId: ProjectId) {
      const projectChanged = this.loadedProject !== projectId
      if (!(this.loaded || this.loading) || projectChanged) {
        await this.load(projectId)
      }
    },

    async load(projectId: ProjectId) {
      if (this.loadedProject !== projectId) {
        this.loaded = false
      }
      this.loading = true
      const itemsById: Record<SpanId, Span> = {}

      try {
        if (!projectId) {
          throw new Error('SpanStore.load: ProjectId must not be empty.')
        }

        const spans = await SpanApi.getAllByProject(projectId)
        spans.forEach((item) => {
          itemsById[item.id] = item
        })
        this.itemsById = { ...itemsById }
        this.loadedProject = projectId
        this.loaded = true
      } finally {
        this.loading = false
      }
    },

    async save(item: Span, projectId: ProjectId) {
      if (!item.id) {
        item.id = uuid()
      }
      const updatedItem = await SpanApi.save(item, projectId)
      this.itemsById = { ...this.itemsById, [item.id]: updatedItem }

      // Update corridor
      if (this.loadedProject) {
        const corridorStore = useCorridorStore()
        corridorStore.load(this.loadedProject)
      }

      return updatedItem
    }
  }
})
