<script lang="ts">
import type { ActionMenuItem } from '@/components/common/TableActionMenu.vue'

import TableActionMenu from '@/components/common/TableActionMenu.vue'
import TablePagination from '@/components/common/TablePagination.vue'
import { defineComponent, PropType } from 'vue'

export type EntityItem = {
  id: any
  name?: string | null // name property of TowerBase is nullish
  deletable?: boolean
  deletableHint?: string
} & Record<string, any>

export type ActionItem = {
  label: string
  action: (item: EntityItem) => void
  icon?: any
}

type SelectionMap = Record<any, boolean>

export default defineComponent({
  name: 'EntityTable',
  components: { TableActionMenu, TablePagination },

  data: () => ({
    page: 1,
    pageSize: 10
  }),

  props: {
    allowDelete: { type: Boolean, default: true },
    allowDuplicate: { type: Boolean, default: false },
    allowEdit: { type: Boolean, default: true },
    extraActions: {
      type: Array as PropType<ActionItem[]>,
      default: undefined
    },
    items: { type: Array as PropType<EntityItem[]>, default: () => [] },
    loading: Boolean,
    multiselect: { type: Boolean, default: false },
    selection: { type: Array, default: () => [] }
  },

  emits: ['create', 'edit', 'delete', 'duplicate', 'update:selection'],

  computed: {
    itemsOnPage(): EntityItem[] {
      const start = (this.page - 1) * this.pageSize
      const end = this.page * this.pageSize
      return this.items.slice(start, end)
    },

    table(): any {
      return this.$refs.table
    },
    selectionMap: {
      get(): SelectionMap {
        const map = {} as SelectionMap
        this.selection.forEach((id: any) => (map[id] = true))
        return map
      },
      set(map: SelectionMap) {
        // this method is usually not called, since we modify the selectionMap object directly
        this.$emit(
          'update:selection',
          Object.keys(map).filter((id) => map[id] === true)
        )
      }
    }
  },

  watch: {
    itemsOnPage() {
      this.updateTableSelection()
    },
    selection() {
      this.updateTableSelection()
    }
  },

  mounted() {
    this.updateTableSelection()
  },

  methods: {
    emitSelectionEvent() {
      this.$emit(
        'update:selection',
        Object.keys(this.selectionMap).filter((id: any) => this.selectionMap[id])
      )
    },

    getRowMenuItems(row: EntityItem) {
      const items: ActionMenuItem[] = []
      if (this.allowEdit) {
        items.push({
          label: 'bearbeiten',
          icon: 'EditIcon',
          action: () => this.$emit('edit', row)
        })
      }

      if (this.allowDuplicate) {
        items.push({
          label: 'duplizieren',
          icon: 'DuplicateIcon',
          action: () => this.$emit('duplicate', row)
        })
      }

      if (this.allowDelete) {
        items.push({
          label: 'löschen...',
          icon: 'DeleteIcon',
          class: 'text-danger-500',
          disabled: row.deletable === false,
          hint: row.deletableHint,
          action: () => this.$emit('delete', row)
        })
      }

      if (this.extraActions) {
        items.push(
          ...this.extraActions.map((item, index) => ({
            ...item,
            action: () => item.action && item.action(row),
            divided: index === 0
          }))
        )
      }
      return items
    },

    onRowClick(row: EntityItem, column: any, event: MouseEvent) {
      if (!this.multiselect) {
        this.$emit('edit', row)
        return
      }

      const shift = !!event?.shiftKey
      const ctrl = !!event?.ctrlKey

      if (ctrl) {
        this.selectionMap[row.id] = !this.selectionMap[row.id]
        this.emitSelectionEvent()
      } else if (shift) {
        // get the index of the selected row
        const currentRowIndex = this.items?.findIndex((item) => item.id === row.id)

        // find first selected item
        let firstSelectedRowIndex = 0
        for (const i in this.items) {
          if (this.selection?.includes(this.items[i].id)) {
            firstSelectedRowIndex = parseInt(i)
            break
          }
        }

        // select all items between them
        const ascending = currentRowIndex > firstSelectedRowIndex
        for (
          let s = firstSelectedRowIndex;
          ascending ? s <= currentRowIndex : s >= currentRowIndex;
          ascending ? s++ : s--
        ) {
          this.selectionMap[this.items[s].id] = true
        }
        this.emitSelectionEvent()
      } else {
        if (this.selectionMap[row.id] && this.selection.length === 1) {
          this.$emit('update:selection', [])
        } else {
          this.$emit('update:selection', [row.id])
        }
      }
    },

    onSelectAll(selection: EntityItem[]) {
      this.$emit(
        'update:selection',
        selection.map((item) => item.id)
      )
    },

    rowClassName({ row }: any) {
      let cssClass = 'cursor-pointer'

      if (this.multiselect) {
        const rowIsSelected = (this.$refs.table as any)
          ?.getSelectionRows()
          .find((item: EntityItem) => item.id === row.id)
        if (rowIsSelected) {
          cssClass += ' current-row'
        }
      }
      return cssClass
    },

    /**
     * Set ElTable selection according to selection prop
     */
    updateTableSelection() {
      this.$nextTick(() => {
        this.table.clearSelection()
        this.selection?.forEach((id) => {
          const record = this.items.find((item) => item.id === id)
          if (record) {
            this.table.toggleRowSelection(record, true)
          }
        })
      })
    }
  }
})
</script>

<template>
  <el-table
    ref="table"
    v-bind="$attrs"
    class="w-full mb-8 shadow rounded"
    :data="itemsOnPage"
    empty-text="keine Daten vorhanden"
    :highlight-current-row="!multiselect"
    :select-on-indeterminate="false"
    :row-class-name="rowClassName"
    row-key="id"
    table-layout="auto"
    @row-click="onRowClick"
    @select-all="onSelectAll"
    @mousedown.ctrl.prevent
    @mousedown.shift.prevent
  >
    <template #empty>
      <template v-if="loading">Daten werden geladen...</template>
    </template>

    <el-table-column v-if="multiselect" type="selection"></el-table-column>

    <slot></slot>

    <template #append>
      <TablePagination :count="items.length" v-model:page="page" v-model:page-size="pageSize" />
    </template>

    <el-table-column
      v-if="extraActions || allowEdit || allowDuplicate || allowDelete"
      width="100"
      align="center"
    >
      <template #default="{ row }">
        <TableActionMenu :items="getRowMenuItems(row)" @click.stop />
      </template>
    </el-table-column>
  </el-table>
</template>

<style scoped lang="css">
/** Need to block table checkbox click events since we need the mouse event from rowclick */
::v-deep(tbody .el-checkbox) {
  pointer-events: none !important;
}

::v-deep(.current-row:hover td) {
  background: var(--el-color-primary-light-8) !important;
}

::v-deep(.el-table__row:hover td) .actions {
  opacity: 1;
}
</style>
