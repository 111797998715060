import { ServerResponse } from '@/api'

export class WebsocketError extends Error {
  response: ServerResponse

  constructor(errorResponse: ServerResponse) {
    const message = errorResponse.error?.message || 'unbekannter Fehler'
    super(message)
    this.name = 'WebsocketError'
    //this.cause = errorResponse
    this.response = errorResponse

    // remove stack because it does not help on server-side problems
    this.stack = undefined
  }

  toString() {
    return `[${this.name}] ${this.response.error?.request?.messageId}: ${
      this.response.error?.message || this.response.data?.msg
    } (status ${this.response.status})`
  }
}
