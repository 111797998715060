import { z } from 'zod'
import { CorridorModeEnum } from '@/model'
import { unifyProjectionCode } from '@/util'

/**
 * https://gridside.gitlab.io/hsb-backend/hsblib_api.html#header-variables
 */
export const HSBLibGlobalsSchema = z.object({
  LIB_VERSION: z.string(),
  CRS_system: z.string().transform((arg) => unifyProjectionCode(arg) || arg),
  UTM_Reimport_Tolerance: z.number(),
  Trim_till_decimal_points: z.number(),
  PipelineSegmentation: z.number(),
  FLAG_Inner_Impedance_Bessel_Function: z.boolean(),
  UTM_trim_mode: z.boolean(),
  Rel_abstol: z.number(),
  Rel_min_segment_length: z.number(),
  Rel_max_angle: z.number(),
  Rel_simplifyMedium: z.boolean(),
  Rel_angletol: z.number(),
  corridor_mode: z.nativeEnum(CorridorModeEnum),
  corridor_flatstart: z.boolean(),
  corridor_flatend: z.boolean(),
  logging_traceback: z.boolean().optional()
})

export type HSBLibGlobals = z.infer<typeof HSBLibGlobalsSchema>
