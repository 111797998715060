<script lang="ts" setup>
import { Tile as TileLayer } from 'ol/layer'
import { OSM } from 'ol/source'
import LayerBase from '../LayerBase.vue'

defineProps({ id: { type: String, required: true } })

const layer = new TileLayer({
  source: new OSM()
})
</script>

<template>
  <LayerBase v-bind="$attrs" :id="id" :layer="layer" />
</template>
