import { ConductorState, ConductorTypeId, ProjectId, uuid } from '.'
import { HSBLibCurrent } from '@/config/schemas/hsb-lib/Current'

export type SystemId = uuid

export type SystemCreate = Omit<System, 'id' | 'name'>
export type System = {
  id: SystemId
  conductorTypes: ConductorTypeId[]
  name: string
  project: ProjectId
  wireCount: number
  isolatorLength: number | null
}

export type SystemWithConductorPositionInfo = System & {
  conductorStates: { [position: number]: ConductorState }
  conductorTypesPositions: { [position: number]: string }
}

/**
 * Divides name of Current to system name and position
 * @example "system_b_1" -> {name: "system_b" position: 1}
 */
export function getSystemNameAndPos(current: HSBLibCurrent, separator: string) {
  const lastIndex = current.name.lastIndexOf(separator)
  const firstPart = current.name.substring(0, lastIndex)
  const secondPart = current.name.substring(lastIndex + 1)
  const name = firstPart
  const position = Number(secondPart)
  if (isNaN(position)) {
    throw Error(
      `Konnte position von Current ${current.name} nicht ermitteln. Trennzeichen ${separator} -> ${name}, ${position}`
    )
  }
  return { name, position }
}
