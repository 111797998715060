import useMapInteraction from '@/components/map/composables/useMapInteraction'
import { HitTolerance, SourceLayerContext } from '@/components/map/layer/util'
import { Select } from 'ol/interaction'
import { pointerMove } from 'ol/events/condition'
import { sourceHoverStyle } from '@/components/map/style/source'

export default function useSourceHover(ctx: SourceLayerContext) {
  /**
   * Add map interaction to highlight source features on mouse hover
   */
  function highlightFeature() {
    const hoverInteraction = new Select({
      condition: pointerMove,
      filter: (feature) => {
        // Do not highlight selected features
        return !ctx.selectedFeatures.value.includes(feature)
      },
      hitTolerance: HitTolerance,
      layers: [ctx.layer],
      style: sourceHoverStyle
    })

    // Change cursor to pointer on hover
    hoverInteraction.on('select', (event) => {
      ctx.map.getViewport().style.cursor = event.selected.length > 0 ? 'pointer' : ''
    })

    useMapInteraction(hoverInteraction)
  }

  highlightFeature()
}
