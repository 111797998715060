import { createRouter, createWebHistory } from 'vue-router'
import { createAuthRoutes, useAuthentication } from '@prionect/ui'

import libraryRoutes from './library'
import projectRoutes from './project'
import userRoutes from './user'

export const routes = [
  ...createAuthRoutes(() => import('@/layouts/LoginLayout.vue')),
  {
    path: '/',
    name: 'home',
    redirect: { name: 'projects' }
  },
  ...libraryRoutes,
  ...projectRoutes,
  ...userRoutes
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from) => {
  const { isAuthenticated } = useAuthentication()

  // redirect login
  if (!isAuthenticated.value && to.name !== 'auth-login') {
    return { name: 'auth-login', query: { path: to.path } }
  }

  // redirect home
  if (isAuthenticated.value && to.name === 'auth-login') {
    return { name: 'home' }
  }
})

export default router
