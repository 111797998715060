<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SecondaryNavigation',

  setup() {
    return {}
  }
})
</script>

<template>
  <div class="bg-gray-100 text-gray-700 text-lg border-r border-r-gray-200">
    <slot></slot>
  </div>
</template>

<style scoped lang="css"></style>
