import { z } from 'zod'
import { Medium } from '@/model/medium'

const MediumPoint = z.object({
  x: z.number(),
  y: z.number()
})

const BaseMedium = z.object({
  height: z.number(),
  name: z.string(),
  path: z.array(MediumPoint),
  project: z.string()
})

const PipeMedium = BaseMedium.extend({
  type: z.literal('pipe'),
  pipeType: z.string(),
  rhoE: z.number()
})

const WireMedium = BaseMedium.extend({
  type: z.literal('wire')
})

export const MediumSchema: z.ZodType<Medium> = z.union([
  PipeMedium.extend({ id: z.string() }),
  WireMedium.extend({ id: z.string() })
])
