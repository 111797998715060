import { PipeTypeId } from '@/model'
import { Feature } from 'geojson'

export enum MappingMode {
  ONE_FOR_ALL = 'ONE_FOR_ALL'
}

export type PipeTypeMappingModeOptions = {
  label: string
  value: string | MappingMode.ONE_FOR_ALL
}[]
export type FeaturePropertyMapping = {
  featurePropertyValue: string
  pipeTypeId: PipeTypeId
  features: Feature[]
}
