import { useProjectStore } from '@/stores/project'
import { computed } from 'vue'
import { useRoute } from 'vue-router'

export function useProject() {
  const route = useRoute()
  const projectStore = useProjectStore()
  const projectId = computed(() => route.params.projectId as string)
  const project = computed(
    () => (projectStore.loaded && projectStore.findById(projectId.value)) || undefined
  )

  return { project, projectId }
}
