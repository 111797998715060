<script lang="ts">
import HsbMap from '@/components/map/HsbMap.vue'

import { defineComponent } from 'vue'
import { ProjectId } from '@/model'
import { useTowerStore } from '@/stores/tower'
import { useSpanStore } from '@/stores/span'

export default defineComponent({
  name: 'MapView',
  components: { HsbMap },

  computed: {
    projectId() {
      return this.$route.params.projectId as ProjectId
    }
  },

  setup() {
    return {
      spanStore: useSpanStore(),
      towerStore: useTowerStore()
    }
  },

  mounted() {
    this.towerStore.ensureLoaded(this.projectId)
    this.spanStore.ensureLoaded(this.projectId)
  }
})
</script>

<template>
  <div class="flex-1 relative h-full shadow-inner bg-white">
    <HsbMap />
  </div>
</template>

<style scoped lang="css"></style>
