import { FieldConfig } from '@prionect/ui/dist/types/components/form/types'
import { ConductorAllocation } from '@/model'

const fields: Record<
  | keyof Omit<ConductorAllocation, 'id' | 'project' | 'mapping'>
  | 'positionCount'
  | 'system'
  | 'conductor',
  FieldConfig
> = {
  name: {
    name: 'name',
    label: 'Name',
    required: 'Bitte geben Sie einen Namen ein.'
  },
  positionCount: {
    name: 'positionCount',
    type: 'select',
    items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
    label: 'Anzahl Leiterpositionen an Mast',
    placeholder: 'Wert auswählen',
    required: true
  },
  system: {
    name: '',
    type: 'select',
    placeholder: 'System',
    items: [] // will be added at runtime
  },
  conductor: {
    name: '',
    type: 'select',
    placeholder: 'Leiter',
    items: [] // will be added at runtime
  }
}

export default fields
