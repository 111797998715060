<script lang="ts">
import { useAppAbility } from '@/permissions/useAppAbility'
import { defineComponent, PropType } from 'vue'
import EntityTableView from '@/components/common/EntityTableView.vue'
import { useTowerTypeStore } from '@/stores/tower-type'
import {
  LineTowerType,
  LineTowerTypeTranslations,
  ProjectId,
  TowerId,
  TowerType,
  TowerTypeId
} from '@/model'
import { v4 } from 'uuid'
import { useTowerStore } from '@/stores/tower'
import { useCommonPermissions } from '@/permissions/useCommonPermissions'

export default defineComponent({
  name: 'TowerTypeListView',
  components: { EntityTableView },

  props: {
    projectId: {
      type: String as PropType<ProjectId>
    }
  },

  setup() {
    const { can } = useAppAbility()
    const { canImportLibrary } = useCommonPermissions()
    return {
      can,
      towerTypeStore: useTowerTypeStore(),
      towerStore: useTowerStore(),
      canImportLibrary
    }
  },

  data: () => ({
    search: ''
  }),

  computed: {
    readOnly(): boolean {
      return this.projectId ? false : !this.can('update', 'Library')
    },

    towerTypes(): TowerType[] {
      if (this.projectId) {
        // Add "deletable" flag to tower type items
        return this.towerTypeStore.projectItems.map((towerType) => {
          const deletable = !Object.keys(this.usedTowerTypes).includes(towerType.id)
          const deletableHint = deletable
            ? undefined
            : 'wird bei folgenden Masten genutzt: ' +
              this.usedTowerTypes[towerType.id]
                .map((towerId) => {
                  const tower = this.towerStore.findById(towerId)
                  return tower?.name || tower?.position
                })
                .join(', ')
          return { ...towerType, deletable, deletableHint }
        })
      } else {
        return this.towerTypeStore.globalItems
      }
    },

    /**
     * Record with all used tower types with the ids of all towers they are used in
     */
    usedTowerTypes(): Record<TowerTypeId, TowerId[]> {
      if (!this.projectId) {
        return {}
      }
      const data: Record<TowerTypeId, TowerId[]> = {}
      this.towerTypeStore.projectItems.forEach((towerType) => {
        const usedInTowers = this.towerStore.items
          .map((tower) => (tower.in.type === towerType.id ? tower.id : null))
          .filter((value) => value !== null) as TowerId[]
        if (usedInTowers.length > 0) {
          data[towerType.id] = usedInTowers
        }
      })
      return data
    }
  },

  mounted() {
    this.towerTypeStore.ensureLoaded()
    if (this.projectId) {
      this.towerTypeStore.ensureLoadedByProject(this.projectId)
      this.towerStore.ensureLoaded(this.projectId)
    }
  },

  methods: {
    formatLineTowerType(lineTowerType: LineTowerType): string {
      return LineTowerTypeTranslations[lineTowerType] || 'unbekannt'
    },

    async onDelete(id: TowerTypeId) {
      await this.towerTypeStore.delete(id)
      if (id === this.$route.params.id) {
        this.$router.push({ name: this.projectId ? 'project-towertypes' : 'towertypes' })
      }
    },

    onDuplicate(id: TowerTypeId) {
      const itemToDuplicate = this.towerTypeStore.findById(id)
      if (itemToDuplicate) {
        const newItem = {
          ...itemToDuplicate,
          id: v4(),
          name: itemToDuplicate.name + ' - Kopie'
        }
        this.towerTypeStore.save(newItem)
      }
    }
  }
})
</script>

<template>
  <EntityTableView
    :allow-edit="false"
    :allow-create="!readOnly"
    :allow-duplicate="!readOnly"
    :allow-delete="!readOnly"
    confirm-delete-label="Masttyp löschen"
    create-label="Neuer Masttyp"
    default-sort="name"
    :items="towerTypes"
    :loading="towerTypeStore.loading"
    :search-properties="['name']"
    :title="projectId ? 'Projekt-Masttypen' : 'Masttypen'"
    @create="$router.push({ name: projectId ? 'project-towertypes-create' : 'towertypes-create' })"
    @edit="
      $router.push({
        name: projectId ? 'project-towertypes-edit' : 'towertypes-edit',
        params: { id: $event }
      })
    "
    @delete="onDelete"
    @duplicate="onDuplicate"
  >
    <template #extra-tools v-if="!projectId">
      <p-btn :disabled="!canImportLibrary" @click="$router.push({ name: 'towertypes-import' })">
        Import
      </p-btn>
    </template>
    <template #columns>
      <el-table-column
        prop="name"
        label="Name"
        sortable
        class-name="font-semibold"
      ></el-table-column>
      <el-table-column prop="lineTowerType" label="Bauweise" sortable #default="{ row }">
        {{ formatLineTowerType(row.lineTowerType) }}
      </el-table-column>

      <el-table-column label="Leiterseile" width="150" align="center" sortable>
        <template #default="{ row }">
          {{ row.conductorPositions.length }}
        </template>
      </el-table-column>
      <el-table-column label="Erdseile" width="150" align="center" sortable>
        <template #default="{ row }">
          {{ row.earthwirePositions.length }}
        </template>
      </el-table-column>
    </template>

    <template #confirm-delete="{ item }">
      <p>
        Wollen Sie den Masttyp
        <b>{{ item.name }}</b>
        wirklich löschen?
      </p>
      <p class="!mt-2 text-gray-400 text-base">
        <template v-if="projectId">
          Das Löschen dieses Masttyps hat keinen Einfluss auf die globale Typbibliothek.
        </template>
        <template v-else>Die Kopien dieses Masttyps in Projekten bleiben erhalten.</template>
      </p>
    </template>
  </EntityTableView>
</template>

<style scoped lang="css"></style>
