<script lang="ts">
import ConductorTypeSelectDialog from '@/components/conductor-type/ConductorTypeSelectDialog.vue'
import TypeSelectField from '@/components/form/TypeSelectField.vue'
import { ConductorTypeId } from '@/model'
import { computed, defineComponent, PropType, watch } from 'vue'
import { useConductorTypeStore } from '@/stores/conductor-type'
import { useProject } from '@/composables/useProject'
import { FieldConfig, MIXED_VALUES } from '@prionect/ui'

export default defineComponent({
  name: 'ConductorTypeSelectField',
  components: { ConductorTypeSelectDialog, TypeSelectField },
  props: {
    fieldConfig: {
      type: Object as PropType<FieldConfig>,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    conductors: {
      type: Array as PropType<ConductorTypeId[]>
    }
  },
  emits: ['select'],
  setup(props, ctx) {
    const { projectId } = useProject()
    const conductorTypeStore = useConductorTypeStore()

    const conductorTypeId = computed(() => {
      if (Array.isArray(props.conductors)) {
        return props.conductors[props.index]
      }
      return undefined
    })
    watch(
      conductorTypeId,
      (value) => {
        ctx.emit('select', value)
      },
      {
        immediate: true
      }
    )

    return { conductorTypeStore, projectId, conductorTypeId }
  },
  async created() {
    await this.conductorTypeStore.ensureLoaded()
    await this.conductorTypeStore.ensureLoadedByProject(this.projectId)
  },
  computed: {
    fieldName() {
      return `${this.fieldConfig.name}.${this.index}`
    },
    fieldText() {
      if (this.conductorTypeId == undefined) {
        return ''
      }
      if (this.conductorTypeId === MIXED_VALUES) {
        return this.conductorTypeId
      }
      const item = this.conductorTypeStore.findById(this.conductorTypeId)
      return item?.name ?? 'unbekannt'
    }
  }
})
</script>

<template>
  <TypeSelectField
    v-bind="fieldConfig"
    :label="fieldConfig.label ? `${fieldConfig.label} E${index + 1}` : undefined"
    :name="fieldName"
    dense
    :text="fieldText"
  >
    <template #dialog="{ show, id, hide, handleChange }">
      <ConductorTypeSelectDialog
        v-if="show"
        :modelValue="id"
        :show="show"
        @close="hide"
        @update:modelValue="handleChange"
      />
    </template>
  </TypeSelectField>
</template>

<style scoped lang="css"></style>
