<script lang="ts">
import { defineComponent } from 'vue'
import { useCalculationStore } from '@/stores/calculation'
import { useOperationalModeStore } from '@/stores/operational-mode'
import { useProjectStore } from '@/stores/project'
import { HsbLibObjectEnum } from '@/model/calculation'
import { CalculationApi } from '@/api'
import { triggerDownload } from '@/util/helpers'
import { ElMessage } from 'element-plus'
import { useProject } from '@/composables/useProject'

export default defineComponent({
  name: 'CalculationResult',

  data() {
    return {
      HsbLibObjectEnum: HsbLibObjectEnum,
      pendingDownload: false
    }
  },
  computed: {
    limitVoltage() {
      const operationalModeId = this.store.operationalMode
      if (operationalModeId) {
        const opMode = this.operationalModeStore.findById(operationalModeId)
        return opMode?.limitVoltage
      }
      return undefined
    },
    isPipeProject() {
      const project = this.projectStore.findById(this.$route.params['projectId'] as string)
      return project?.mediaType === 'pipe'
    },
    result() {
      return this.store.result
    }
  },

  setup() {
    const { projectId, project } = useProject()
    return {
      store: useCalculationStore(),
      projectStore: useProjectStore(),
      operationalModeStore: useOperationalModeStore(),
      projectId,
      project
    }
  },

  methods: {
    async downloadResults(type: HsbLibObjectEnum) {
      try {
        this.pendingDownload = true
        const response = await CalculationApi.hsbLibObject(this.projectId, type)
        triggerDownload(
          response,
          `${this.projectId}_${type}_${Intl.DateTimeFormat('de', {
            dateStyle: 'short'
          })
            .format(new Date())
            .replaceAll('.', '_')}`,
          'application/json'
        )
      } catch (e) {
        ElMessage({
          message: 'Daten nicht verfügbar',
          duration: 5000
        })
      } finally {
        this.pendingDownload = false
      }
    },
    toggleLog() {
      this.store.showLog = !this.store.showLog
    },
    toggleResult() {
      this.store.showResult = !this.store.showResult
    }
  }
})
</script>

<template>
  <div>
    <div v-if="result" class="text-center my-3">
      <div class="text-md text-gray-600 mb-4">
        <template v-if="isPipeProject">maximales Rohrleitungspotenzial:</template>
        <template v-else>induzierte Längsspannung:</template>
      </div>
      <div
        v-if="store.resultVoltage && limitVoltage"
        :class="store.resultVoltage > limitVoltage ? 'text-error-500' : 'text-success-600'"
        class="mt-2 font-bold text-2xl"
      >
        {{ $n(store.resultVoltage, { maximumFractionDigits: 1 }) }} V
      </div>
      <div v-if="limitVoltage" class="font-semibold text-gray-400">
        Grenzwert: {{ $n(limitVoltage, { maximumFractionDigits: 1 }) }} V
      </div>
    </div>
    <div class="text-center">
      <p-btn v-if="result" class="mt-4" size="small" @click="toggleResult">Tabelle</p-btn>
      <div
        v-if="store.status === 'ERROR'"
        class="text-error-500 font-semibold my-2 bg-error-100 p-2 px-3"
      >
        Bei der Berechnung ist ein Fehler aufgetreten (siehe Log).
      </div>

      <p-btn class="mt-4" size="small" @click="toggleLog">Log-Ausgabe</p-btn>

      <el-dropdown class="mt-4" placement="bottom" trigger="click">
        <el-button :loading="pendingDownload" class="grow h-auto" icon="DownloadIcon" size="small">
          HSBLib - Berechnungsdaten
        </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="downloadResults(HsbLibObjectEnum.MESHED_MEDIUM_SIMULATION)">
              <span>Meshed Medium Simulation</span>
            </el-dropdown-item>
            <el-dropdown-item @click="downloadResults(HsbLibObjectEnum.OVERHEADLINE)">
              <span>Overheadline</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<style lang="css" scoped></style>
