<script lang="ts">
import { useAppAbility } from '@/permissions/useAppAbility'
import { defineComponent, PropType } from 'vue'
import EntityTableView from '@/components/common/EntityTableView.vue'
import { usePipeTypeStore } from '@/stores/pipe-type'
import { MediumId, PipeType, PipeTypeId, ProjectId } from '@/model'
import { v4 } from 'uuid'
import { useMediumStore } from '@/stores/medium'
import { useCommonPermissions } from '@/permissions/useCommonPermissions'

export default defineComponent({
  name: 'PipeTypeListView',
  components: { EntityTableView },
  props: {
    projectId: {
      type: String as PropType<ProjectId | undefined>
    }
  },
  setup() {
    const { can } = useAppAbility()
    const { canImportLibrary } = useCommonPermissions()
    return {
      can,
      canImportLibrary,
      pipeTypeStore: usePipeTypeStore(),
      mediumStore: useMediumStore()
    }
  },

  computed: {
    pipeTypes(): PipeType[] {
      if (this.projectId) {
        // Add "deletable" flag to pipe type items
        return this.pipeTypeStore.projectItems.map((pipeType) => {
          const deletable = !Object.keys(this.usedPipeTypes).includes(pipeType.id)
          const deletableHint = deletable
            ? undefined
            : 'wird bei folgenden Medien genutzt:\n' +
              this.usedPipeTypes[pipeType.id]
                .map((mediumId) => this.mediumStore.findById(mediumId)?.name)
                .join(', ')
          return { ...pipeType, deletable, deletableHint }
        })
      } else {
        return this.pipeTypeStore.globalItems
      }
    },

    readOnly(): boolean {
      return this.projectId ? false : !this.can('update', 'Library')
    },

    /**
     * Record with all used pipe types with the ids of all mediums they are used in
     */
    usedPipeTypes(): Record<PipeTypeId, MediumId[]> {
      if (!this.projectId) {
        return {}
      }
      const data: Record<PipeTypeId, MediumId[]> = {}
      this.pipeTypeStore.projectItems.forEach((pipeType) => {
        const usedInMediums = this.mediumStore.items
          .filter((medium) => ('pipeType' in medium ? medium.pipeType === pipeType.id : false))
          .map((medium) => medium.id)
        if (usedInMediums.length > 0) {
          data[pipeType.id] = usedInMediums
        }
      })
      return data
    }
  },

  mounted() {
    this.pipeTypeStore.ensureLoaded()
    if (this.projectId) {
      this.pipeTypeStore.ensureLoadedByProject(this.projectId)
      this.mediumStore.ensureLoaded(this.projectId)
    }
  },

  methods: {
    async onDelete(id: PipeTypeId) {
      await this.pipeTypeStore.delete(id)
      if (id === this.$route.params.id) {
        this.$router.push({ name: this.projectId ? 'project-pipetypes' : 'pipetypes' })
      }
    },

    onDuplicate(id: PipeTypeId) {
      const itemToDuplicate = this.pipeTypeStore.findById(id)
      if (itemToDuplicate) {
        const newItem = {
          ...itemToDuplicate,
          id: v4(),
          name: itemToDuplicate.name + ' - Kopie'
        }
        this.pipeTypeStore.save(newItem)
      }
    }
  }
})
</script>

<template>
  <EntityTableView
    :allow-create="!readOnly"
    :allow-duplicate="!readOnly"
    :allow-delete="!readOnly"
    :allow-edit="false"
    create-label="Neuer Rohrleitungstyp"
    confirm-delete-label="Rohrleitungstyp löschen"
    default-sort="name"
    :items="pipeTypes"
    :loading="pipeTypeStore.loading"
    :search-properties="['name', 'type']"
    :title="projectId ? 'Projekt-Rohrleitungstypen' : 'Rohrleitungstypen'"
    @create="$router.push({ name: projectId ? 'project-pipetypes-create' : 'pipetypes-create' })"
    @edit="
      $router.push({
        name: projectId ? 'project-pipetypes-edit' : 'pipetypes-edit',
        params: { id: $event }
      })
    "
    @delete="onDelete"
    @duplicate="onDuplicate"
  >
    <template #extra-tools v-if="!projectId">
      <p-btn :disabled="!canImportLibrary" @click="$router.push({ name: 'pipetypes-import' })">
        Import
      </p-btn>
    </template>

    <template #columns>
      <el-table-column
        prop="name"
        label="Name"
        sortable
        class-name="font-semibold"
      ></el-table-column>
      <!--      <el-table-column prop="type" label="Typ" sortable>-->
      <!--        <template #default="{ row }">-->
      <!--          <template v-if="row.type">{{ $t(`pipetype.type.${row.type}`) }}</template>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <!--      <el-table-column prop="outerRadius" label="Radius" sortable="">-->
      <!--        <template #default="{ row }">-->
      <!--          <template v-if="row.type === 'tubular'">-->
      <!--            {{ row.innerRadius === undefined ? '?' : $n(row.innerRadius) }} /-->
      <!--          </template>-->
      <!--          {{ $n(row.outerRadius) }} mm-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <!--      <el-table-column prop="subconductorCount" label="Bündelleiter" sortable="">-->
      <!--        <template #default="{ row }">-->
      <!--          <template v-if="row.subconductorCount">{{ row.subconductorCount }}</template>-->
      <!--          <template v-else>&ndash;</template>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
    </template>

    <template #confirm-delete="{ item }">
      <p>
        Wollen Sie den Rohrleitungstyp
        <b>{{ item.name }}</b>
        wirklich löschen?
      </p>
      <p class="!mt-2 text-gray-400 text-base">
        <template v-if="projectId">
          Das Löschen dieses Rohrleitungstyps hat keinen Einfluss auf die globale Typbibliothek.
        </template>
        <template v-else>
          Die Kopien dieses Rohrleitungstyps in Projekten bleiben erhalten.
        </template>
      </p>
    </template>
  </EntityTableView>
</template>

<style scoped lang="css"></style>
