import { MediumApi } from '@/api'
import { Medium, MediumId, MediumPoint, ProjectId } from '@/model'
import lineSplit from '@turf/line-split'
import { Feature, FeatureCollection } from 'geojson'
import { Coordinate } from 'ol/coordinate'
import { defineStore } from 'pinia'
import { v4 as uuid } from 'uuid'
import { toRaw } from 'vue'

export const useMediumStore = defineStore('medium', {
  state: () => ({
    draft: null as Partial<Medium> | null,
    drawing: false,
    itemsById: {} as Record<MediumId, Medium>,
    loaded: false,
    loading: false,
    loadedProject: undefined as undefined | ProjectId,
    selection: [] as MediumId[]
  }),

  getters: {
    findById(state) {
      return (id: MediumId): Medium | undefined => state.itemsById[id]
    },

    geoJSON(): FeatureCollection {
      const features: Feature[] = []
      const allItems = [...this.items]

      if (this.draft) {
        allItems.push(this.draft as Medium)
      }

      allItems.forEach((medium) => {
        features.push({
          id: medium.id,
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: medium.path?.map((item: any) => [item.x, item.y]) || []
          },
          properties: {
            _type: 'medium',
            _draft: medium === this.draft ? true : undefined,
            name: medium.name,
            type: medium.type
          }
        })
      })

      return {
        type: 'FeatureCollection',
        features
      }
    },

    items(): Medium[] {
      return Object.values(this.itemsById)
    }
  },

  actions: {
    async delete(id: MediumId) {
      const medium = this.findById(id)
      if (medium) {
        await MediumApi.delete(medium)
        delete this.itemsById[id]
      }
    },

    async ensureLoaded(projectId: ProjectId) {
      const projectChanged = this.loadedProject !== projectId
      if (!(this.loaded || this.loading) || projectChanged) {
        await this.load(projectId)
      }
    },

    /**
     * Join multiple media into one
     */
    async join(mediaIds: MediumId[]) {
      const media = mediaIds.map((id) => toRaw(this.itemsById[id]))

      // helper function to join a pair of media items
      const joinTwoMedia = async (m1: Medium, m2: Medium) => {
        const start1 = m1.path[0]
        const end1 = m1.path[m1.path.length - 1]
        let reverse1 = false

        const start2 = m2.path[0]
        const end2 = m2.path[m2.path.length - 1]
        let reverse2 = false

        // calculate distances
        const distances: Record<number, [MediumPoint, MediumPoint]> = {}
        const permutations = [
          [start1, start2],
          [start1, end2],
          [end1, start2],
          [end1, end2]
        ]
        permutations.forEach((pair) => {
          const c1 = [pair[0].x, pair[0].y]
          const c2 = [pair[1].x, pair[1].y]
          const d = Math.sqrt(Math.pow(c2[0] - c1[0], 2) + Math.pow(c2[1] - c1[1], 2))
          distances[d] = [pair[0], pair[1]]
        })
        const minimalDistance = Math.min(...Object.keys(distances).map((k) => parseFloat(k)))
        const connectPoints = distances[minimalDistance]

        // check whether medium directions have to be changed to connect the closest points
        if (connectPoints[0] === start1 && connectPoints[1] === end2) {
          reverse1 = true
          reverse2 = true
        } else if (connectPoints[0] === start1 && connectPoints[1] === start2) {
          reverse1 = true
          reverse2 = false
        } else if (connectPoints[0] === end1 && connectPoints[1] === start2) {
          reverse1 = false
          reverse2 = false
        } else if (connectPoints[0] === end1 && connectPoints[1] === end2) {
          reverse1 = false
          reverse2 = true
        }

        const joinedMedium: Medium = {
          ...m1,
          path: [
            ...(reverse1 ? m1.path.reverse() : m1.path),
            ...(reverse2 ? m2.path.reverse() : m2.path)
          ]
        }
        this.itemsById[joinedMedium.id] = joinedMedium

        await this.delete(m2.id)
        await this.save(joinedMedium)

        return joinedMedium
      }

      let joinedMedium: Medium | undefined = undefined
      for (let i = 0; i < media.length - 1; i++) {
        joinedMedium = await joinTwoMedia(joinedMedium || media[i], media[i + 1])
      }
    },

    async load(projectId: ProjectId) {
      if (this.loadedProject !== projectId) {
        this.loaded = false
      }
      this.loading = true
      const itemsById: Record<MediumId, Medium> = {}
      this.itemsById = {}

      try {
        if (!projectId) {
          throw new Error('MediumStore.load: ProjectId must not be empty.')
        }

        const media = await MediumApi.getAllByProject(projectId)
        media.forEach((item) => {
          itemsById[item.id] = item
        })
        this.itemsById = { ...itemsById }
        this.loadedProject = projectId
        this.loaded = true
      } finally {
        this.loading = false
      }
    },

    async save(item: Medium) {
      if (!item.id) {
        item.id = uuid()
      }
      const updatedItem = await MediumApi.save(item)
      this.itemsById = { ...this.itemsById, [item.id]: updatedItem }
      this.draft = null
      return updatedItem
    },

    /**
     * Split a medium into two at a given point
     */
    async split(mediumId: MediumId, splitAt: Coordinate) {
      const medium = this.itemsById[mediumId]
      if (!medium) {
        throw new Error('MediumStore.split: There is no medium with id ' + mediumId)
      }

      // Let TurfJS do the splitting math
      const splitFeatures = lineSplit(
        {
          type: 'Feature',
          geometry: { type: 'LineString', coordinates: medium.path.map((c) => [c.x, c.y]) },
          properties: {}
        },
        { type: 'Feature', geometry: { type: 'Point', coordinates: splitAt }, properties: {} }
      )

      const medium1: Medium = {
        ...medium,
        path: splitFeatures.features[0].geometry.coordinates.map((c) => ({
          x: c[0],
          y: c[1]
        }))
      }

      const medium2: Medium = {
        ...medium,
        id: uuid(),
        name: medium.name + ' (2)',
        path: splitFeatures.features[1].geometry.coordinates.map((c) => ({
          x: c[0],
          y: c[1]
        }))
      }

      // Update store to get immediate UI feedback
      this.itemsById[medium1.id] = medium1
      this.itemsById[medium2.id] = medium2

      // send to server
      await this.save(medium1)
      await this.save(medium2)
    }
  }
})
