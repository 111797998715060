<script lang="ts">
import { defineComponent, PropType } from 'vue'
import deepmerge from 'deepmerge'
import NumberField from './NumberField.vue'
import { WirePosition } from '@/model'

export default defineComponent({
  name: 'CoordinateList',
  components: {
    NumberField
  },

  props: {
    /**
     * Function to receive index of coordinate and render their label
     */
    labelFn: {
      type: Function as PropType<(i: number) => string>
    },
    modelValue: {
      type: Array as PropType<WirePosition[]>,
      default: () => []
    },
    minRows: {
      type: Number,
      default: 1
    },
    prefix: String,
    readOnly: {
      type: Boolean,
      default: false
    },
    unit: String
  },
  emits: ['update:modelValue'],

  data: () => ({
    rows: [] as WirePosition[],
    fieldRules: {
      required: true,
      message: 'Wert eingeben',
      trigger: 'blur'
    }
  }),

  mounted() {
    this.initializeRows()
  },

  watch: {
    modelValue() {
      this.initializeRows()
    }
  },

  methods: {
    deleteRow(index: number) {
      this.rows.splice(index, 1)
      this.emitUpdateEvent()
    },
    addRow(pos: WirePosition) {
      this.rows.push(pos)
      this.emitUpdateEvent()
    },

    async emitUpdateEvent() {
      this.$emit(
        'update:modelValue',
        deepmerge(
          [],
          this.rows.map((item) => ({ x: item.x || 0, y: item.y || 0 }))
        )
      )
    },

    initializeRows() {
      this.rows = deepmerge([], [...this.modelValue])
      const missingRows = this.minRows - this.rows.length
      if (missingRows > 0) {
        for (let i = 0; i < missingRows; i++) {
          this.addRow({ x: 0, y: 0 })
        }
      }
    }
  }
})
</script>

<template>
  <el-form
    class="mb-8"
    ref="form"
    :model="rows"
    label-position="top"
    data-component="coordinate-list"
  >
    <table class="w-full">
      <tr v-if="rows.length === 0">
        <td colspan="4" class="text-center text-gray-400">Keine Einträge</td>
      </tr>
      <tr v-else>
        <td></td>
        <td class="text-center text-sm font-semibold text-gray-500" style="width: 40%">X</td>
        <td class="text-center text-sm font-semibold text-gray-500" style="width: 40%">Y</td>
        <td v-if="!readOnly"></td>
      </tr>
      <tr v-for="(item, index) in rows" :key="index">
        <!-- Label -->
        <td class="text-sm font-semibold text-gray-500 pr-6">
          <template v-if="labelFn">{{ labelFn(index) }}</template>
          <template v-else>{{ prefix }}{{ index + 1 }}</template>
        </td>

        <!-- X -->
        <td>
          <div>
            <el-form-item :prop="index + '.x'" :rules="fieldRules">
              <number-field
                v-model="item.x"
                :unit="unit"
                @update:model-value="emitUpdateEvent"
                @change="emitUpdateEvent"
                :disabled="readOnly"
              />
            </el-form-item>
          </div>
        </td>

        <!-- Y -->
        <td>
          <el-form-item :prop="index + '.y'" :rules="fieldRules">
            <number-field
              v-model="item.y"
              :unit="unit"
              @update:model-value="emitUpdateEvent"
              @change="emitUpdateEvent"
              :disabled="readOnly"
            />
          </el-form-item>
        </td>

        <!-- delete button -->
        <td v-if="!readOnly">
          <el-button
            :class="{ 'opacity-0': rows.length <= minRows }"
            text
            @click="deleteRow(index)"
            title="Zeile löschen"
            class="!text-gray-500"
            icon="DeleteIcon"
            data-test="delete-row"
          ></el-button>
        </td>
      </tr>
      <tr v-if="!readOnly">
        <td></td>
        <td>
          <el-button
            icon="AddIcon"
            size="small"
            class="mt-2"
            @click="addRow({ x: 0, y: 0 })"
            data-test="add-row"
          >
            neue Zeile
          </el-button>
        </td>
        <td></td>
      </tr>
    </table>
  </el-form>
</template>

<style scoped lang="css">
td {
  @apply px-2 align-top;
}

tr:first-child td {
  @apply pb-3;
}
td:first-child {
  @apply pr-4 pt-3;
}

td:last-child {
  @apply pl-0;
}
</style>
