import { useSpanStore } from '@/stores/span'
import { useTowerStore } from '@/stores/tower'
import { computed } from 'vue'

/**
 * Generates a GeoJSON with both towers and spans
 */
export function useSourceGeoJSON() {
  return computed(() => {
    const towerGeoJSON = useTowerStore().geoJSON
    const spanGeoJSON = useSpanStore().geoJSON
    return {
      ...towerGeoJSON,
      features: [...towerGeoJSON.features, ...spanGeoJSON.features]
    }
  })
}
