<script lang="ts" setup>
import { PropType, toRefs, watch } from 'vue'
import { Vector as VectorLayer, VectorImage } from 'ol/layer'
import { Vector as VectorSource } from 'ol/source'
import LayerBase from './LayerBase.vue'
import { StyleLike } from 'ol/style/Style'
import { InformationOnHoverProp } from '@/components/map/layer/LayerBase.vue'

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  name: String,
  source: {
    type: Object as PropType<VectorSource>,
    required: true
  },
  layerStyle: {
    type: [Object, Function] as PropType<StyleLike>
  },
  // Render as image layer may improve performance with many styles / features
  renderAsImage: {
    type: Boolean,
    default: false
  },
  informationOnHover: {
    type: [Boolean, Function] as PropType<InformationOnHoverProp>,
    default: false
  }
})

const LayerClass = props.renderAsImage ? VectorImage : VectorLayer

const layer = new LayerClass({
  source: props.source ? props.source : undefined,
  style: props.layerStyle,
  properties: { id: props.id, name: props.name }
})

const { source } = toRefs(props)

watch(
  source,
  () => {
    layer.setSource(source.value || null)
  },
  { immediate: true }
)
</script>

<template>
  <LayerBase
    v-bind="$attrs"
    :layer="layer"
    :id="id"
    :name="name"
    :information-on-hover="informationOnHover"
  />
</template>
