import { FieldConfig } from '@prionect/ui/dist/types/components/form/types'
import { System } from '@/model'

const fields: Record<keyof Omit<System, 'id' | 'project'>, FieldConfig> = {
  name: {
    name: 'name',
    label: 'Name',
    required: 'Bitte geben Sie einen Namen ein.'
  },
  wireCount: {
    name: 'wireCount',
    type: 'select',
    items: [1, 2, 3],
    label: 'Anzahl Leiter',
    required: true
  },
  conductorTypes: {
    name: 'conductorTypes',
    type: 'select',
    placeholder: 'Leitertyp wählen',
    required: 'Bitte wählen Sie einen Typ aus.',
    items: [] // will be added at runtime
  },
  isolatorLength: {
    name: 'isolatorLength',
    type: 'number',
    label: 'Isolatorlänge',
    placeholder: 'Isolatorlänge',
    unit: 'm',
    min: 0,
    step: 0.01
  }
}

export default fields
