<script lang="ts">
import TypeSelectField from '@/components/form/TypeSelectField.vue'
import { defineComponent, PropType } from 'vue'
import { PipeTypeId, ProjectId } from '@/model'
import { usePipeTypeStore } from '@/stores/pipe-type'
import MediumPipeTypeSelectDialog from '@/components/medium/MediumPipeTypeSelectDialog.vue'

export default defineComponent({
  name: 'MediumPipeTypeSelectField',
  components: { MediumPipeTypeSelectDialog, TypeSelectField },

  props: {
    modelValue: {
      type: String as PropType<PipeTypeId>
    }
  },
  emits: ['update:modelValue'],

  setup() {
    const pipeTypeStore = usePipeTypeStore()

    return { pipeTypeStore }
  },
  created() {
    this.pipeTypeStore.ensureLoadedByProject(this.projectId)
  },
  computed: {
    projectId(): ProjectId {
      return this.$route.params['projectId'] as string
    },
    pipeTypeName() {
      if (!this.modelValue) {
        return ''
      }
      const item = this.pipeTypeStore.findById(this.modelValue)
      return item?.name ?? 'unbekannt'
    }
  }
})
</script>

<template>
  <TypeSelectField
    v-bind="$attrs"
    :modelValue="modelValue"
    :text="pipeTypeName"
    @update:modelValue="$emit('update:modelValue', $event)"
  >
    <template #dialog="{ show, id, hide, handleChange }">
      <MediumPipeTypeSelectDialog
        v-if="show"
        :modelValue="id"
        :show="show"
        @close="hide"
        @update:modelValue="handleChange"
      />
    </template>
  </TypeSelectField>
</template>

<style scoped lang="css"></style>
