import { useMapSelection } from '@/components/map/composables/useMapSelection'
import { TowerId } from '@/model'
import { useTowerStore } from '@/stores/tower'
import { Feature } from 'ol'
import { LineString, Point } from 'ol/geom'
import { v4 } from 'uuid'
import { SourceLayerContext } from '@/components/map/layer/util'

const { deselect } = useMapSelection()

export function createTowerDraft(coordinate: { x: number; y: number }, ctx: SourceLayerContext) {
  const { clear } = useMapSelection()
  const towerStore = useTowerStore()

  clear()
  deleteDraftFeatures(ctx)

  const transformedCoordinate = ctx.projectToMap([coordinate.x, coordinate.y])
  const id = v4()
  const draft = new Feature({
    geometry: new Point(transformedCoordinate),
    _type: 'tower',
    _draft: true,
    name: 'neuer Mast'
  })
  draft.setId(id)
  ctx.selectInteraction?.getFeatures().push(draft)

  const previousTower = towerStore.items.sort((a, b) => a.position - b.position).slice(-1)[0]
  if (previousTower) {
    const previousCoordinate = [previousTower.x, previousTower.y]
    const spanDraft = new Feature({
      geometry: new LineString([previousCoordinate, transformedCoordinate]),
      _type: 'span',
      _draft: true
    })
    spanDraft.setId(id + '-span')
    ctx.layer.getSource()?.addFeature(spanDraft)
  }

  ctx.layer.getSource()?.addFeature(draft)
  towerStore.createDraft({ id, x: coordinate.x, y: coordinate.y })
}

export function deleteDraftFeatures(ctx: SourceLayerContext) {
  const source = ctx.layer.getSource()
  source
    ?.getFeatures()
    .filter((feature: Feature) => feature.get('_draft'))
    .forEach((feature: Feature) => {
      deselect(feature)
      source.removeFeature(feature)
    })

  const towerStore = useTowerStore()
  towerStore.draft = undefined
}

export async function deleteTowers(towerIds: TowerId[]) {
  const towerStore = useTowerStore()
  for (const id of towerIds) {
    deselect(id)
    await towerStore.delete(id)
  }
}
