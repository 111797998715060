<script lang="ts">
import { defineComponent } from 'vue'
import CalculationResult from './CalculationResult.vue'
import CalculationLauncher from './CalculationLauncher.vue'
import { ProjectId } from '@/model'
import { useCalculationStore } from '@/stores/calculation'

export default defineComponent({
  components: { CalculationLauncher, CalculationResult },

  computed: {
    hasResult(): boolean {
      return !!this.calculationStore.result
    },
    projectId(): ProjectId {
      return this.$route.params['projectId'] as string
    }
  },

  setup() {
    const calculationStore = useCalculationStore()
    calculationStore.init()
    return { calculationStore }
  }
})
</script>

<template>
  <div class="flex flex-col h-full overflow-y-auto">
    <CalculationLauncher />
    <CalculationResult
      v-if="calculationStore.result || calculationStore.status === 'ERROR'"
      class="flex-1"
    />
  </div>
</template>

<style scoped lang="css"></style>
