<script lang="ts" setup>
import { computed, inject } from 'vue'
import { LayerId } from '@/components/map'
import { UseMapItems } from '@/components/map/composables/useMap'
import LayerGroup from '@/components/map/layer/LayerGroup.vue'
import LoadFlowLayer from '@/components/map/layer/result/LoadFlowLayer.vue'
import EmfLayer from './EmfLayer.vue'
import RelationLayer from './RelationLayer.vue'
import MaxVoltageLayer from './MaxVoltageLayer.vue'
import { UseMapInjectKeys } from '@/components/map/composables/useMapInjectKeys'

const { layers } = inject(UseMapInjectKeys.useMap) as UseMapItems

const showRelations = computed(() => layers.value[LayerId.RESULT].options.showRelations)
const showLoadFlow = computed(() => layers.value[LayerId.RESULT].options.showLoadFlow)
const showEmfResult = computed(() => layers.value[LayerId.RESULT].options.showEmfResult)
</script>

<template>
  <LayerGroup :id="LayerId.RESULT" :z-index="30">
    <RelationLayer :visible="showRelations" :z-index="30" />
    <LoadFlowLayer :visible="showLoadFlow" :z-index="31" />
    <MaxVoltageLayer :visible="showLoadFlow" :z-index="32" />
    <EmfLayer :visible="showEmfResult" :z-index="31" />
  </LayerGroup>
</template>
