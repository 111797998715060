import { defineStore } from 'pinia'
import { v4, v4 as uuid } from 'uuid'
import { ConductorType, ConductorTypeCreate, ConductorTypeId, ProjectId } from '@/model'
import { ConductorTypeApi } from '@/api'
import { findUniqueCopyName } from '@/util/helpers'

export const useConductorTypeStore = defineStore('conductorType', {
  state: () => ({
    globalItemsById: {} as Record<ConductorTypeId, ConductorType>,
    loaded: false,
    loadedProject: undefined as undefined | ProjectId,
    loading: false,
    projectItemsById: {} as Record<ConductorTypeId, ConductorType>
  }),

  getters: {
    findById(state) {
      return (id: ConductorTypeId): ConductorType | undefined =>
        state.globalItemsById[id] || state.projectItemsById[id]
    },
    /** @deprecated use globalItems or projectItems instead! */
    items(): ConductorType[] {
      return this.globalItems
    },
    globalItems(): ConductorType[] {
      return Object.values(this.globalItemsById)
    },
    projectItems(): ConductorType[] {
      return Object.values(this.projectItemsById)
    }
  },

  actions: {
    async copyIntoProject(id: ConductorTypeId, projectId: ProjectId) {
      const item = this.globalItemsById[id]
      if (!item) {
        throw Error(`Could not find global Item to duplicate (ID: ${id})`)
      }
      const newId = uuid()

      return await this.save({
        ...item,
        id: newId,
        name: findUniqueCopyName(
          item.name,
          this.projectItems.map((item) => item.name)
        ),
        project: projectId
      })
    },

    async delete(id: ConductorTypeId) {
      await ConductorTypeApi.delete(id)
      delete this.globalItemsById[id]
      delete this.projectItemsById[id]
    },

    async ensureLoaded() {
      if (!this.loaded && !this.loading) {
        await this.load()
      }
    },

    async ensureLoadedByProject(projectId: ProjectId) {
      if (projectId !== this.loadedProject) {
        await this.load(projectId)
      }
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async load(projectId?: ProjectId) {
      const itemsById: Record<ConductorTypeId, ConductorType> = {}
      this.loading = true
      try {
        const conductorTypes = await ConductorTypeApi.getAll(projectId)
        conductorTypes.forEach((item) => {
          itemsById[item.id] = item
        })
        if (projectId) {
          this.projectItemsById = { ...itemsById }
          this.loadedProject = projectId
        } else {
          this.globalItemsById = { ...itemsById }
          this.loaded = true
        }
      } finally {
        this.loading = false
      }
    },

    async save(item: ConductorType | ConductorTypeCreate) {
      const res = await ConductorTypeApi.save({
        ...item,
        id: 'id' in item ? item.id : v4()
      })

      if (res.project) {
        this.projectItemsById = { ...this.projectItemsById, [res.id]: res }
      } else {
        this.globalItemsById = { ...this.globalItemsById, [res.id]: res }
      }
      return res
    }
  }
})
