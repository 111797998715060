<script lang="ts">
import { defineComponent, inject } from 'vue'
import { Map } from 'ol'
import { Group } from 'ol/layer'
import { UseMapInjectKeys } from '@/components/map/composables/useMapInjectKeys'

export default defineComponent({
  name: 'LayerGroup',
  inheritAttrs: false,

  props: {
    id: String,
    name: String,
    zIndex: {
      type: Number,
      default: 0
    }
  },

  setup(props) {
    const map = inject(UseMapInjectKeys.map) as Map
    const layer = new Group({ layers: [], properties: { ...props } })
    map.addLayer(layer)
    return { layer, map }
  },

  beforeUnmount() {
    this.map.removeLayer(this.layer)
  }
})
</script>

<template>
  <div>
    <slot></slot>
  </div>
</template>
