import { getEnv, runningVitest } from '@/util'
import deepmerge from 'deepmerge'
import { ref } from 'vue'
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import axios, { Method } from 'axios'

export type PaginatedResponse<T = any> = {
  numPages: number
  page: number
  pageSize: number
  results: T[]
  total: number
}

/**
 * General settings
 */
const authToken = ref<string | null>(null)
const baseUrl = ref(getEnv('VITE_REST_BASE_URL', '/'))

/**
 * Sends an HTTP request to the specified path with the given options.
 */
async function request<Response = any, Request extends object = object>(
  path: string,
  options: AxiosRequestConfig<Request> = {}
): Promise<AxiosResponse<Response, Request>> {
  // make sure only one slash exists
  const sanitizedBaseUrl = baseUrl.value?.replace(/\/+$/, '')
  const sanitizedPath = path.replace(/^\/+/, '')
  const url = `${sanitizedBaseUrl}/${sanitizedPath}`

  // Merge defaults and override body
  const requestOptions = deepmerge(options, {
    url,
    headers: {
      Authorization: authToken.value ? `Bearer ${authToken.value}` : undefined,
      'content-type': 'application/json'
    }
  })

  try {
    return await axios.request<Response>(requestOptions)
  } catch (e) {
    // Keep trace for vitest debugging
    if (runningVitest) {
      const err = e as unknown as AxiosError
      console.trace(
        `Axios Request failed: Status ${err.response?.status}`,
        '\n\nREQUEST:\n',
        requestOptions,
        '\n\nRESPONSE:\n',
        err.response?.data
      )
    }

    throw e
  }
}

/**
 * CRUD convenience functions
 */
function createMethod<M extends Method, T>(
  method: M,
  optionsCreate?: M extends 'GET' | 'get' ? GetRequestOptions<T> : AxiosRequestConfig<T>
) {
  // return <Request extends object, Response = Request>(
  return <Response, Request extends object = object>(
    path: string,
    options?: M extends 'GET' | 'get' ? GetRequestOptions<Request> : AxiosRequestConfig<Request>
  ) => {
    const mergedOptions = { ...optionsCreate, method, ...(options ?? {}) }
    if (['get', 'GET'].includes(mergedOptions.method)) {
      return methods.get<Response>(path, mergedOptions)
    }
    return request<Response, Request>(path, mergedOptions)
  }
}

/**
 * Filters are defined as <field>:<operator>:<value>.
 * All the operators listed at https://github.com/juliotrigo/sqlalchemy-filters#filters-format are available
 */
type SQLAlchemyFilterOperator =
  | 'is_null'
  | 'is_not_null'
  | '=='
  | 'eq'
  | '!='
  | 'ne'
  | '>'
  | 'gt'
  | '<'
  | 'lt'
  | '>='
  | 'ge'
  | '<='
  | 'le'
  | 'like'
  | 'ilike'
  | 'not_ilike'
  | 'in'
  | 'not_in'
  | 'any'
  | 'not_any'
type GetRequestOptions<Entity> = Omit<AxiosRequestConfig, 'data'> & {
  query?: Record<string, string>
  filters?: {
    field: keyof Entity
    operator: SQLAlchemyFilterOperator
    value: Entity[keyof Entity]
  }[]
  sort?: keyof Entity
  pagination?: {
    page?: number // backend default 1
    pageSize?: number // backend default 100
  }
}
const methods = {
  get: <Response = any>(
    path: string,
    options: GetRequestOptions<
      Response extends PaginatedResponse<infer ResponsePagination> ? ResponsePagination : Response
    > = {}
  ) => {
    const { query = {}, filters, sort, pagination, ...reqOptions } = options
    reqOptions.method = 'GET'

    // apply custom filter
    if (filters) {
      query.filter = filters
        .map((filter) => `${String(filter.field)}:${filter.operator}:${filter.value}`)
        .join(',')
    }

    // apply sort
    if (sort) {
      query.sort = String(sort)
    }

    // apply pagination parameters
    if (pagination?.page !== undefined) {
      query.page = String(pagination.page)
    }
    if (pagination?.pageSize !== undefined) {
      query.pageSize = String(pagination.pageSize)
    }

    const params = new URLSearchParams(query)
    const withQuery = `${path}${params.toString() ? `?${params}` : ''}`
    return request<Response>(withQuery, reqOptions)
  },
  post: createMethod('POST'),
  put: createMethod('PUT'),
  patch: createMethod('PATCH'),
  delete: createMethod('DELETE')
}
/**
 * Used to make axios only throw errors error code above 500
 */
const softValidator = (status: number) => {
  return status < 500
}
/**
 * API
 */
export type HttpClient = ReturnType<typeof useHttpClient>

export function useHttpClient() {
  return {
    authToken,
    baseUrl,
    interceptors: axios.interceptors,
    request,
    ...methods,
    soft: {
      get: createMethod('GET', { validateStatus: softValidator }),
      post: createMethod('POST', { validateStatus: softValidator }),
      put: createMethod('PUT', { validateStatus: softValidator }),
      patch: createMethod('PATCH', { validateStatus: softValidator }),
      delete: createMethod('DELETE', { validateStatus: softValidator })
    }
  }
}
