import { ProjectId, Span, Tower } from '@/model'
import { HttpClient, PaginatedResponse } from '@/api/useHttpClient'
import { useWebSocket, WebSocket } from '@/api/useWebSocket'
import { HSBApi } from '@/api/HSBApiBase'

const request = useWebSocket().request

type SaveListPayload = {
  project: ProjectId
  list: Tower[]
  spanDefaults: Pick<Span, 'sag' | 'corridor' | 'rx' | 'rs' | 'rk'>
}

function path(projectId: string, towerId?: string) {
  return `projects/${projectId}/towers` + (towerId ? `/${towerId}` : '')
}

export class TowerApiClass implements HSBApi {
  constructor(public httpClient: HttpClient, public webSocket: WebSocket) {}
  async delete(tower: Tower) {
    return await this.httpClient.delete(path(tower.project, tower.id))
  }

  async get(projectId: string, towerId: string) {
    const res = await this.httpClient.get<Tower>(path(projectId, towerId))
    fixTowerPosition(res.data, 'getFromBackend')
    return res
  }

  async getAllByProject(projectId: ProjectId): Promise<Tower[]> {
    const { data } = await this.httpClient.get<PaginatedResponse<Tower>>(path(projectId), {
      query: { order: 'position' }
    })

    for (const tower of data.results) {
      fixTowerPosition(tower, 'getFromBackend')
    }

    return data.results || []
  }

  async save(tower: Tower): Promise<Tower> {
    const { data } = await this.httpClient.put<Tower>(path(tower.project, tower.id), {
      data: fixTowerPosition(tower, 'saveToBackend')
    })
    return fixTowerPosition(data, 'getFromBackend')
  }

  async saveList(payload: SaveListPayload): Promise<Tower[]> {
    for (const tower of payload.list) {
      fixTowerPosition(tower, 'saveToBackend')
    }
    const { data } = await this.httpClient.put<SaveListPayload>(path(payload.project), {
      data: payload
    })
    return data.list
  }
}

/**
 * Changes tower position in-place.
 * Because tower position is stored with 0-Index but its required to display it 1-Index based.
 */
export function fixTowerPosition(tower: Tower, mode: 'saveToBackend' | 'getFromBackend') {
  if (mode === 'saveToBackend') {
    tower.position = tower.position - 1
    if (tower.position < 0) {
      throw Error('Tower Position kann nicht kleiner als 0 sein!')
    }
  }
  if (mode === 'getFromBackend') {
    tower.position = tower.position + 1
  }
  return tower
}
