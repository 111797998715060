<script lang="ts">
import { defineComponent } from 'vue'
import { span } from '@/config/fields'

export default defineComponent({
  name: 'SpanForm',
  data: () => ({
    fieldConfig: span
  })
})
</script>

<template>
  <p-multi-form v-bind="$attrs">
    <p-field v-bind="fieldConfig.corridor" />
    <p-field v-bind="fieldConfig.sag" />

    <p-form-section title="Reduktionsfaktoren">
      <p-field v-bind="fieldConfig.rx" />
      <p-field v-bind="fieldConfig.rs" />
      <p-field v-bind="fieldConfig.rk" />
    </p-form-section>
  </p-multi-form>
</template>
