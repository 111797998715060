import z from 'zod'
import { PipeType, PipeTypeCreate } from '@/model'

const PipeTypeBase = z.object({
  name: z.string(),
  project: z.string().optional(),
  alpha: z.number(),
  coatingResistance: z.number(),
  coatingThickness: z.number(),
  diameter: z.number(),
  epsR: z.number(),
  materialResistivity: z.number(),
  muR: z.number()
})

export const PipeTypeSchema: z.ZodType<PipeType> = PipeTypeBase.extend({ id: z.string() })
export const PipeTypeCreateSchema: z.ZodType<PipeTypeCreate> = PipeTypeBase
