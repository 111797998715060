<template>
  <div class="section-heading">
    <slot></slot>
  </div>
</template>

<style scoped lang="css">
.section-heading {
  @apply mt-6 mb-2 text-xs font-semibold text-gray-500 uppercase;
}
</style>
