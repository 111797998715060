<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PageTitle'
})
</script>

<template>
  <h1 class="text-primary font-heading tracking-wider font-bold text-2xl mb-8 uppercase">
    <slot></slot>
  </h1>
</template>

<style scoped lang="css"></style>
