import { ProjectId, Span, SpanId } from '@/model'
import { HttpClient, PaginatedResponse } from '@/api/useHttpClient'
import { WebSocket } from '@/api/useWebSocket'
import { HSBApi } from '@/api/HSBApiBase'

const path = (projectId: ProjectId, spanId?: SpanId) => {
  return `projects/${projectId}/spans` + (spanId ? `/${spanId}` : '')
}

export class SpanApiClass implements HSBApi {
  constructor(public httpClient: HttpClient, public webSocket: WebSocket) {}
  async delete(span: Span, projectId: ProjectId) {
    return await this.httpClient.delete(path(projectId, span.id))
  }

  async getAllByProject(projectId: ProjectId): Promise<Span[]> {
    const res = await this.httpClient.get<PaginatedResponse<Span>>(path(projectId))
    return res.data.results || []
  }

  async save(span: Span, projectId: ProjectId): Promise<Span> {
    const res = await this.httpClient.put<Span>(path(projectId, span.id), { data: span })
    return res.data
  }
}
