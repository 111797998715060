import constants from '@/components/map/style/constants'
import { Fill, Stroke, Style } from 'ol/style'

export function corridorStyle() {
  return new Style({
    fill: new Fill({ color: [145, 46, 180, 0.1] }),
    stroke: new Stroke({
      color: constants.source.color(),
      width: 2,
      lineDash: [4, 8]
    })
  })
}
