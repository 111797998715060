<script lang="ts">
import { defineComponent } from 'vue'
import EntityTableView from '@/components/common/EntityTableView.vue'
import { useSystemStore } from '@/stores/system'
import { ProjectId, SystemId } from '@/model'
import { v4 } from 'uuid'

export default defineComponent({
  name: 'SystemListView',
  components: { EntityTableView },
  setup() {
    const store = useSystemStore()
    return { store }
  },

  computed: {
    projectId(): ProjectId {
      return this.$route.params['projectId'] as string
    }
  },

  mounted() {
    this.store.load(this.projectId)
  },

  methods: {
    async onDelete(id: SystemId) {
      await this.store.delete(id)
      if (id === this.$route.params.id) {
        this.$router.push({ name: 'project-systems' })
      }
    },

    onDuplicate(id: SystemId) {
      const itemToDuplicate = this.store.findById(id)
      if (itemToDuplicate) {
        const newItem = {
          ...itemToDuplicate,
          id: v4(),
          name: itemToDuplicate.name + ' - Kopie'
        }
        this.store.save(newItem)
      }
    }
  }
})
</script>

<template>
  <EntityTableView
    :allow-edit="false"
    allow-duplicate
    create-label="Neues System"
    confirm-delete-label="System löschen"
    default-sort="name"
    empty-text="Sie haben bisher keine Systeme angelegt."
    :items="store.items"
    :loading="store.loading"
    :search-properties="['name']"
    title="Systeme"
    @create="$router.push({ name: 'project-system-create' })"
    @edit="$router.push({ name: 'project-system-edit', params: { id: $event } })"
    @delete="onDelete"
    @duplicate="onDuplicate"
  >
    <template #columns>
      <el-table-column prop="name" label="Name" sortable class-name="font-semibold" />
      <el-table-column prop="wireCount" label="Leiteranzahl" sortable />
    </template>

    <template #empty>
      <p class="text-gray-500 text-xl">Sie haben bisher keine Systeme angelegt.</p>

      <p class="mt-8 mb-12 font-normal text-gray-400 text-lg leading-relaxed">
        Ein System ist eine Stromverbindung zwischen zwei Umspannwerken. Ein System kann aus
        mehreren Leitern bestehen (meist 3). Die Masten einer Freileitung können mehrere Systeme
        tragen.
      </p>

      <p-btn @click="$router.push({ name: 'project-system-create' })">Neues System anlegen</p-btn>
    </template>

    <template #confirm-delete="{ item }">
      Wollen Sie das System
      <b>{{ item.name }}</b>
      wirklich löschen?
    </template>
  </EntityTableView>
</template>

le>
