<script lang="ts">
import { defineComponent, ref, unref } from 'vue'
import ImportDialog from '@/components/common/ImportDialog.vue'
import FileInputResettable from '@/components/common/FileInputResettable.vue'
import useFileReader from '@/composables/useFileReader'
import { ProjectExportSchema } from '@/config/schemas/project-export-schema'
import { ProjectExport } from '@/model'
import { ProjectApi } from '@/api'
import { project } from '@/config/fields'
import { useUserStore } from '@/stores/user'
import { ZodError } from 'zod'
import { useBulkRequests } from '@/composables/useBulkRequests'
import RequestsProgress from '@/components/common/RequestsProgress.vue'
import ProjectImportErrorTabs from '@/components/project/ProjectImportErrorTabs.vue'

export default defineComponent({
  name: 'ProjectImportDialog',
  components: {
    ProjectImportErrorTabs,
    RequestsProgress,
    FileInputResettable,
    ImportDialog
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:model-value'],
  setup() {
    const parsedData = ref<null | ProjectExport>(null)
    const errors = ref<(string | Error | ZodError)[]>([])
    const bulkRequests = useBulkRequests((item: ProjectExport) => ProjectApi.load(item))
    /**
     * File reader bound function to read project import from file
     */
    const handleFileData = (data: string | ArrayBuffer | null) => {
      try {
        parsedData.value = null
        const fileJsonContent: object | any = JSON.parse(data as string)
        // Prevent iterating on null
        if (typeof fileJsonContent !== 'object') {
          errors.value.push('Could not parse JSON: Result is not an object')
          return
        }
        parsedData.value = ProjectExportSchema.parse(fileJsonContent)
      } catch (e) {
        errors.value.push(e as Error)
      }
    }

    /**
     * Reset errors and parsed data
     */
    function reset() {
      parsedData.value = null
      errors.value = []
      bulkRequests.progress.reset()
    }

    return {
      errors,
      reset,
      parsedData,
      userStore: useUserStore(),
      useBulkRequests: bulkRequests,
      useFileReader: useFileReader(handleFileData, errors)
    }
  },
  data() {
    return {
      ZodError: ZodError,
      fieldConfig: project
    }
  },
  computed: {
    /**
     * Grouped by ZodErrors and default errors
     */
    errorsGrouped() {
      const defaultErrors: (string | Error)[] = []
      const zodErrors: ZodError[] = []
      for (const err of this.errors) {
        if (err instanceof ZodError) {
          zodErrors.push(err)
        } else {
          defaultErrors.push(err)
        }
      }
      return { defaultErrors, zodErrors }
    }
  },
  methods: {
    doClose() {
      this.reset()
      this.$emit('update:model-value', false)
    },
    onFileChange(file: File | undefined) {
      this.reset()
      if (file) {
        this.useFileReader.onFileChange(file)
      }
    },
    unref,
    /**
     * Send parsed data to backend
     */
    async handleImport() {
      this.errors = []
      if (!this.parsedData) {
        return
      }
      await this.useBulkRequests.doRequests([this.parsedData])
      if (!this.useBulkRequests.progress.errors.length) {
        return this.doClose()
      }
    }
  }
})
</script>

<template>
  <ImportDialog :show="modelValue" panel-height="auto" title="Projekt importieren" @close="doClose">
    <template #panel>
      <div class="col-span-3">
        <file-input-resettable
          accept=".json"
          dropzone-style="padding: 0px"
          @change="onFileChange"
        ></file-input-resettable>
      </div>
    </template>

    <template #default>
      <!-- Errors -->
      <div v-if="errors.length" class="h-full">
        <ProjectImportErrorTabs :errors="errors"></ProjectImportErrorTabs>
      </div>

      <el-collapse v-else-if="parsedData" accordion>
        <!-- Project overview -->
        <el-collapse-item name="overview" title="Übersicht">
          <div class="grid grid-cols-2 gap-2">
            <p-form :item="parsedData" class="col-span-1 pointer-events-none">
              <p-field v-bind="fieldConfig.name" />
              <p-field v-bind="fieldConfig.customer" />
              <p-field :filterable="false" v-bind="fieldConfig.mediaType" />
              <p-field :filterable="false" v-bind="fieldConfig.crs" />
              <p-field v-bind="{ ...fieldConfig.users, items: userStore.userItems }" />
              <template #buttons><span></span></template>
            </p-form>

            <div class="table-simple table-simple--striped col-span-1">
              <table class="w-full">
                <tbody>
                  <tr v-for="(projectItems, dataKey) in parsedData.data" :key="`${dataKey}`">
                    <td>{{ dataKey }}</td>
                    <td>{{ unref(projectItems)?.length }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </el-collapse-item>

        <!-- project JSON -->
        <el-collapse-item title="Details">
          <pre>{{ parsedData }}</pre>
        </el-collapse-item>
      </el-collapse>
    </template>

    <template #actions>
      <RequestsProgress :progress="useBulkRequests.progress" class="flex-grow" />
      <p-btn
        :disabled="!parsedData"
        :loading="useBulkRequests.progress.pending"
        type="primary"
        @click="handleImport"
      >
        Projekt importieren
      </p-btn>
    </template>
  </ImportDialog>
</template>
<style lang="css" scoped></style>
