import { OperationalMode, OperationalModeId, ProjectId } from '@/model'
import { HttpClient, PaginatedResponse } from '@/api/useHttpClient'
import { WebSocket } from '@/api/useWebSocket'
import { HSBApi } from '@/api/HSBApiBase'

const path = (projectId: ProjectId, operationalModeId?: OperationalModeId) => {
  return (
    `projects/${projectId}/operationalModes` + (operationalModeId ? `/${operationalModeId}` : '')
  )
}

export class OperationalModeApiClass implements HSBApi {
  constructor(public httpClient: HttpClient, public webSocket: WebSocket) {}
  async delete(operationalMode: OperationalMode) {
    return await this.httpClient.delete(path(operationalMode.project, operationalMode.id))
  }

  async getAllByProject(projectId: ProjectId): Promise<OperationalMode[]> {
    const res = await this.httpClient.get<PaginatedResponse<OperationalMode>>(path(projectId))
    return res.data.results
  }

  async save(operationalMode: OperationalMode): Promise<OperationalMode> {
    // Add frequency to each conductorState
    const frequency = operationalMode.frequency
    const fixedConductorStates: any = {}
    Object.keys(operationalMode.conductorStates).forEach((systemId) => {
      fixedConductorStates[systemId] = operationalMode.conductorStates[systemId].map((item) => ({
        ...item,
        frequency
      }))
    })

    // do PUT-Request
    const res = await this.httpClient.put<OperationalMode>(
      path(operationalMode.project, operationalMode.id),
      {
        data: {
          ...operationalMode,
          conductorStates: fixedConductorStates
        }
      }
    )
    return res.data
  }
}
