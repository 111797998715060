<script lang="ts">
import { useAppAbility } from '@/permissions/useAppAbility'
import { defineComponent, PropType } from 'vue'
import EntityTableView from '@/components/common/EntityTableView.vue'
import { useConductorTypeStore } from '@/stores/conductor-type'
import { ConductorType, ConductorTypeEnum, ConductorTypeId, ProjectId } from '@/model'
import { v4 } from 'uuid'
import { useSystemStore } from '@/stores/system'
import { useCommonPermissions } from '@/permissions/useCommonPermissions'
import { useTowerStore } from '@/stores/tower'

export default defineComponent({
  name: 'ConductorTypeListView',

  components: { EntityTableView },
  props: {
    projectId: {
      type: String as PropType<ProjectId | undefined>
    }
  },
  data() {
    return {
      ConductorTypeEnum
    }
  },
  setup() {
    const { can } = useAppAbility()
    const { canImportLibrary } = useCommonPermissions()
    return {
      can,
      canImportLibrary,
      conductorTypeStore: useConductorTypeStore(),
      systemStore: useSystemStore(),
      towerStore: useTowerStore()
    }
  },

  computed: {
    conductorTypes(): ConductorType[] {
      if (this.projectId) {
        // Add "deletable" flag to conductor type items
        return this.conductorTypeStore.projectItems.map((conductorType) => {
          const deletable = !Object.keys(this.usedConductorTypes).includes(conductorType.id)
          let deletableHint = 'Löschen nicht möglich, da der Leitertyp verwendet wird.\n'
          if (!deletable) {
            // Systems
            if (this.usedConductorTypes[conductorType.id].systems.length) {
              deletableHint +=
                '\nWird bei folgenden Systemen verwendet:\n' +
                this.usedConductorTypes[conductorType.id].systems.join(', ')
            }

            // Towers
            if (this.usedConductorTypes[conductorType.id].towers.length) {
              deletableHint +=
                '\nWird bei folgenden Masten verwendet:\n' +
                this.usedConductorTypes[conductorType.id].towers.join(', ')
            }
          }
          return { ...conductorType, deletable, deletableHint }
        })
      } else {
        return this.conductorTypeStore.globalItems
      }
    },

    readOnly(): boolean {
      return this.projectId ? false : !this.can('update', 'Library')
    },

    /**
     * Record with all used conductor types with the ids of all systems and towers they are used in
     */
    usedConductorTypes(): Record<ConductorTypeId, { systems: string[]; towers: string[] }> {
      if (!this.projectId) {
        return {}
      }
      const data: Record<ConductorTypeId, { systems: string[]; towers: string[] }> = {}
      this.conductorTypeStore.projectItems.forEach((conductorType) => {
        // Systems
        const usedInSystems = this.systemStore.items
          .filter((system) => system.conductorTypes.includes(conductorType.id))
          .map((system) => system.name)

        // Towers
        const usedInTowers = this.towerStore.items
          .filter(
            (tower) =>
              tower.in.earthwires.includes(conductorType.id) ||
              tower.out?.earthwires?.includes(conductorType.id)
          )
          .map((tower) => tower.name || `Mast #${tower.position}`)

        if (usedInSystems.length || usedInTowers.length) {
          data[conductorType.id] = {
            systems: usedInSystems,
            towers: usedInTowers
          }
        }
      })
      return data
    }
  },

  mounted() {
    this.conductorTypeStore.ensureLoaded()
    if (this.projectId) {
      this.conductorTypeStore.ensureLoadedByProject(this.projectId)
      this.systemStore.ensureLoaded(this.projectId)
      this.towerStore.ensureLoaded(this.projectId)
    }
  },

  methods: {
    async onDelete(id: ConductorTypeId) {
      await this.conductorTypeStore.delete(id)
      if (id === this.$route.params.id) {
        this.$router.push({ name: this.projectId ? 'project-conductortypes' : 'conductortypes' })
      }
    },

    onDuplicate(id: ConductorTypeId) {
      const itemToDuplicate = this.conductorTypeStore.findById(id)
      if (itemToDuplicate) {
        const newItem = {
          ...itemToDuplicate,
          id: v4(),
          name: itemToDuplicate.name + ' - Kopie'
        }
        this.conductorTypeStore.save(newItem)
      }
    },
    radiusCountString(type: ConductorType) {
      return type.innerRadius === undefined ? '?' : this.$n(type.innerRadius)
    },
    /**
     * Type helper for conductor type
     */
    asConductorType(row: any): ConductorType {
      return row
    }
  }
})
</script>

<template>
  <EntityTableView
    :allow-create="!readOnly"
    :allow-duplicate="!readOnly"
    :allow-delete="!readOnly"
    :allow-edit="false"
    create-label="Neuer Leitertyp"
    confirm-delete-label="Leitertyp löschen"
    default-sort="name"
    :items="conductorTypes"
    :loading="conductorTypeStore.loading"
    :search-properties="['name', 'type']"
    :title="projectId ? 'Projekt-Leitertypen' : 'Leitertypen'"
    @create="
      $router.push({ name: projectId ? 'project-conductortypes-create' : 'conductortypes-create' })
    "
    @edit="
      $router.push({
        name: projectId ? 'project-conductortypes-edit' : 'conductortypes-edit',
        params: { id: $event }
      })
    "
    @delete="onDelete"
    @duplicate="onDuplicate"
  >
    <template #extra-tools v-if="!projectId">
      <p-btn :disabled="!canImportLibrary" @click="$router.push({ name: 'conductortypes-import' })">
        Import
      </p-btn>
    </template>

    <template #columns>
      <!-- name -->
      <el-table-column
        prop="name"
        label="Name"
        sortable
        class-name="font-semibold"
      ></el-table-column>

      <!-- type -->
      <el-table-column prop="type" label="Typ" sortable>
        <template #default="{ row }">
          <template v-if="asConductorType(row).type">
            {{ $t(`conductorType.type.${asConductorType(row).type}`) }}
          </template>
        </template>
      </el-table-column>

      <!-- outerRadius -->
      <el-table-column prop="outerRadius" label="Radius" sortable="">
        <template #default="{ row }">
          <template v-if="asConductorType(row).type === ConductorTypeEnum.TUBULAR">
            {{ radiusCountString(asConductorType(row)) }}
            /
          </template>
          {{ $n(asConductorType(row).outerRadius) }} mm
        </template>
      </el-table-column>

      <!-- subconductorCount -->
      <el-table-column prop="subconductorCount" label="Bündelleiter" sortable="">
        <template #default="{ row }">
          <template v-if="asConductorType(row).subconductorCount">
            {{ asConductorType(row).subconductorCount }}
          </template>
          <template v-else>&ndash;</template>
        </template>
      </el-table-column>
    </template>

    <template #confirm-delete="{ item }">
      <p>
        Wollen Sie den Leitertyp
        <b>{{ asConductorType(item).name }}</b>
        wirklich löschen?
      </p>
      <p class="!mt-2 text-gray-400 text-base">
        <template v-if="projectId">
          Das Löschen dieses Leitertyps hat keinen Einfluss auf die globale Typbibliothek.
        </template>
        <template v-else>Die Kopien dieses Leitertyps in Projekten bleiben erhalten.</template>
      </p>
    </template>
  </EntityTableView>
</template>

<style scoped lang="css"></style>
