<script lang="ts">
import { defineComponent } from 'vue'
import EntityTableView from '@/components/common/EntityTableView.vue'
import { useSpanStore } from '@/stores/span'
import { ProjectId, SpanId, formatTowerName, Tower, TowerId } from '@/model'
import { useTowerStore } from '@/stores/tower'

export default defineComponent({
  name: 'SpanListView',
  components: { EntityTableView },

  setup() {
    const store = useSpanStore()
    const towerStore = useTowerStore()
    return { store, towerStore }
  },

  computed: {
    projectId(): ProjectId {
      return this.$route.params['projectId'] as string
    },
    spanId(): ProjectId {
      return this.$route.params['id'] as string
    },
    searchStrings(): string[] {
      return this.store.items.map((item) => {
        return [
          this.formatTowerName(item.beginTower),
          this.formatTowerName(item.endTower),
          item.corridor && this.$n(item.corridor),
          item.sag && this.$n(item.sag),
          item.rk && this.$n(item.rk),
          item.rs && this.$n(item.rs),
          item.rx && this.$n(item.rx)
        ]
          .join(' ')
          .toLowerCase()
      })
    },
    towersById(): Record<TowerId, Tower> {
      return this.towerStore.itemsById || {}
    }
  },

  mounted() {
    this.store.load(this.projectId)
    this.towerStore.load(this.projectId)
    if (this.spanId) {
      this.store.selection = [this.spanId]
    }
  },

  methods: {
    formatTowerName(id: TowerId) {
      const tower = this.towersById[id]
      return tower ? formatTowerName(tower) : ''
    },

    onSelectionChange(selectedIds: SpanId[]) {
      if (selectedIds.length === 0) {
        this.$router.push({ name: 'project-spans' })
      } else {
        this.$router.push({ name: 'project-span-edit', params: { id: selectedIds[0] } })
      }
    }
  }
})
</script>

<template>
  <EntityTableView
    v-model:selection="store.selection"
    :allow-create="false"
    :allow-edit="false"
    :allow-delete="false"
    :allow-duplicate="false"
    :items="store.items"
    :loading="store.loading || towerStore.loading"
    multiselect
    :search-properties="['name']"
    :search-strings="searchStrings"
    title="Spannfelder"
    @edit="$router.push({ name: 'project-span-edit', params: { id: $event } })"
    @update:selection="onSelectionChange"
  >
    <template #columns>
      <el-table-column
        prop="position"
        label="Nr."
        sortable
        width="100px"
        class-name="font-semibold"
      >
        <template #default="{ row }">
          {{ towersById[row.beginTower] && towersById[row.beginTower].position }}
        </template>
      </el-table-column>
      <el-table-column prop="beginTower" label="Mast Beginn">
        <template #default="{ row }">
          <span
            :class="{
              'text-gray-400': !(towersById[row.beginTower] && towersById[row.beginTower].name)
            }"
          >
            {{ formatTowerName(row.beginTower) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="endTower" label="Mast Ende">
        <template #default="{ row }">
          <span
            :class="{
              'text-gray-400': !(towersById[row.endTower] && towersById[row.endTower].name)
            }"
          >
            {{ formatTowerName(row.endTower) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="corridor" label="Korridor">
        <template #default="{ row }">{{ row.corridor && $n(row.corridor) + ' m' }}</template>
      </el-table-column>
      <el-table-column prop="sag" label="Durchhang">
        <template #default="{ row }">{{ row.sag && $n(row.sag) + ' m' }}</template>
      </el-table-column>
      <el-table-column prop="rx">
        <template #header>
          <!-- prettier-ignore -->
          <span title="Umgebungsreduktionsfaktor">r<sub>x</sub></span>
        </template>
        <template #default="{ row }">{{ row.rx && $n(row.rx) }}</template>
      </el-table-column>
      <el-table-column prop="rs">
        <template #header>
          <!-- prettier-ignore -->
          <span title="Schienenreduktionsfaktor">r<sub>s</sub></span>
        </template>
        <template #default="{ row }">{{ row.rs && $n(row.rs) }}</template>
      </el-table-column>
      <el-table-column prop="rk" label="rk (Kabel)">
        <template #header>
          <!-- prettier-ignore -->
          <span title="Kabelreduktionsfaktor">r<sub>k</sub></span>
        </template>
        <template #default="{ row }">{{ row.rk && $n(row.rk) }}</template>
      </el-table-column>
    </template>

    <template #empty>
      <p class="text-gray-500 text-xl">Erstellen Sie Masten, um deren Spannfelder zu bearbeiten.</p>

      <!-- prettier-ignore -->
      <p class="mt-8 mb-12 font-normal text-gray-400 text-lg leading-relaxed">
        Ein Spannfeld ist der Bereich zwischen zwei Masten. Sie müssen zunächst
        <router-link :to="{ name: 'project-tower-create' }">Masten erstellen</router-link>,
        bevor Sie hier deren Spannfelder bearbeiten können.
      </p>
    </template>
  </EntityTableView>
</template>
