<script lang="ts" setup>
import { UseMapItems } from '@/components/map/composables/useMap'
import { UseMapInjectKeys } from '@/components/map/composables/useMapInjectKeys'
import { createLayerConfig, LayerId } from '@/components/map/LayerConfig'
import { useProject } from '@/composables/useProject'
import { useCalculationStore } from '@/stores/calculation'
import { Map } from 'ol'
import { computed, inject, onMounted, Ref, ref, watch } from 'vue'

type TreeNode = (LayerTreeNode | OptionTreeNode) & {
  id: string
  label: string
  children?: TreeNode[]
}

type LayerTreeNode = {
  type?: 'layer'
}

type OptionTreeNode = {
  type: 'option'
  layerId: string
  optionName: string
}

const map = inject(UseMapInjectKeys.map) as Map
const { layers, setLayerOption, showLayer } = inject(UseMapInjectKeys.useMap) as UseMapItems
const hasResult = computed(() => !!useCalculationStore().result)

const treeEl = ref()

const { project } = useProject()

/**
 * Build tree nodes from the LayerConfig object
 */
const treeData: Ref<TreeNode[]> = computed(() => {
  if (!project.value) {
    // we are not inside a project anymore (may occur while navigating away)
    return []
  }
  const layerConfig = createLayerConfig(project.value)

  return layerConfig
    .map((layerItem) => {
      const optionsChildren: TreeNode[] | undefined = layerItem.options?.map((optionConfig) => ({
        id: `${layerItem.id}-${optionConfig.id}`,
        label: optionConfig.label,
        layerId: layerItem.id,
        optionName: optionConfig.id,
        type: 'option'
      }))

      return {
        id: layerItem.id,
        label: layerItem.label,
        children: optionsChildren || []
      }
    })
    .map((treeNode) => {
      const isResultNode = treeNode.id === LayerId.RESULT
      return {
        ...treeNode,
        disabled: isResultNode && !hasResult.value
      }
    })
})

const allOptionIds = computed(() => {
  const options: string[] = []
  treeData.value.forEach((node) => {
    node.children?.forEach((option) => {
      options.push(option.id)
    })
  })
  return options
})

onMounted(() => {
  updateCheckedNodes()
})

watch(hasResult, () => {
  if (hasResult.value) {
    updateCheckedNodes()
  }
})

function updateCheckedNodes() {
  if (!project.value) {
    throw new Error('LayerControl.updateCheckedNodes: No project given')
  }
  const layerConfig = createLayerConfig(project.value)

  const layerState = layers.value

  // Find and check active layer nodes
  const checkedLayerNodes = Object.keys(layerState)
    .filter((layerId) => layerConfig.map((layer) => layer.id).includes(layerId as LayerId))
    .filter((layerId) => layerState[layerId].visible)

  treeEl.value?.setCheckedKeys(checkedLayerNodes)

  // For some reason we need to delay the (de)selection of subnodes to make sure
  // they do not infer with the selection of the root nodes before
  // (the nextTick() function did not work reliably either)
  setTimeout(() => {
    // Find and check active option nodes
    let checkedOptionNodes: string[] = []
    Object.values(layerState).forEach((layer) => {
      const options = Object.keys(layer.options)
        .filter((option) => !!layer.options[option])
        .map((option) => `${layer.id}-${option}`)
      checkedOptionNodes = [...checkedOptionNodes, ...options]
    })

    allOptionIds.value.forEach((optionNodeId) => {
      treeEl.value?.setChecked(optionNodeId, checkedOptionNodes.includes(optionNodeId), false)
    })
  }, 1000)
}

const onCheck = (node: TreeNode, treeState: any) => {
  const { checkedKeys, halfCheckedKeys } = treeState
  const checked = checkedKeys.includes(node.id)
  if (node.type === 'option') {
    const layerChecked =
      checkedKeys.includes(node.layerId) || halfCheckedKeys.includes(node.layerId)
    setLayerOption(node.layerId, node.optionName, checked)
    showLayer(node.layerId, layerChecked)
  } else {
    showLayer(node.id, checked)
    const layerOptions = node.children
    layerOptions?.forEach((option) => {
      if (option.type === 'option') {
        setLayerOption(option.layerId, option.optionName, checkedKeys.includes(option.id))
      }
    })
  }
}
</script>

<template>
  <el-tree
    ref="treeEl"
    :data="treeData"
    class="!bg-transparent !text-sm"
    check-on-click-node
    show-checkbox
    :expand-on-click-node="false"
    node-key="id"
    :render-after-expand="false"
    @check="onCheck"
  />
</template>

<style scoped lang="css"></style>
