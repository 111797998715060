<script lang="ts">
import { defineComponent } from 'vue'
import ImportDialog from '@/components/common/ImportDialog.vue'
import { triggerDownload } from '@/util/helpers'
import { PipeTypeCreate } from '@/model'
import { ParsedItem, useCSVParser } from '@/composables/useCSVParser'
import { useBulkRequests } from '@/composables/useBulkRequests'

import PipeTypeFields from '@/config/fields/pipe-type'
import { ElTable } from 'element-plus'
import { usePipeTypeStore } from '@/stores/pipe-type'
import { PipeTypeCreateSchema } from '@/config/schemas/pipe-type-schema'
import FileInputResettable from '@/components/common/FileInputResettable.vue'
import RequestsProgress from '@/components/common/RequestsProgress.vue'

export default defineComponent({
  name: 'PipeTypeImport',
  components: { RequestsProgress, FileInputResettable, ImportDialog },
  setup() {
    const pipeTypeStore = usePipeTypeStore()
    return {
      useBulkRequests: useBulkRequests(pipeTypeStore.save),
      useCSVParser: useCSVParser(PipeTypeCreateSchema)
    }
  },
  data() {
    return {
      showDialog: true,
      selectedItems: [] as Required<ParsedItem<PipeTypeCreate>>[],
      PipeTypeFields
    }
  },
  computed: {
    typeHelper() {
      return this.useCSVParser.typeHelper
    },
    getErrorMessage() {
      return this.useCSVParser.getErrorMessage
    }
  },
  methods: {
    elTable() {
      return this.$refs.table as InstanceType<typeof ElTable> | null
    },
    handleSelectionChange(val: any) {
      this.selectedItems = val
    },
    handleDownloadExampleCSV() {
      triggerDownload(
        Object.keys(this.PipeTypeFields).join(';'),
        'pipe_types_import_headers.csv',
        'text/plain'
      )
    },
    /**
     * Close dialog and navigate
     */
    closeDialog() {
      this.showDialog = false
      setTimeout(() => {
        this.$router.push({ name: 'pipetypes' })
      }, 200)
    },
    async handleImport() {
      await this.useBulkRequests.doRequests(this.selectedItems.map((item) => item.validated))
      this.elTable()?.clearSelection()
      if (!this.useBulkRequests.progress.errors.length) {
        this.closeDialog()
      }
    },

    rowIsSelectable(row: ParsedItem<PipeTypeCreate>) {
      return row.errors === null && row.validated
    },
    onFileChange(file: File) {
      this.selectedItems = []
      this.useCSVParser.parseCSV(file)
    }
  }
})
</script>

<template>
  <import-dialog v-model="showDialog" title="Rohrleitungstypen importieren" @close="closeDialog">
    <template #panel>
      <div class="col-span-full grid grid-cols-3 gap-4 h-full">
        <!-- File Input -->
        <file-input-resettable accept=".csv" @change="onFileChange">
          <small class="text-gray-500">Erste Reihe in CSV müssen headers sein.</small>
        </file-input-resettable>

        <!-- Controls -->
        <div class="flex flex-col gap-2">
          <p-select-input
            v-model="useCSVParser.parsingSettings.csvEncoding"
            :items="useCSVParser.parsingSettings.csvEncodingItems"
          />
          <p-select-input
            v-model="useCSVParser.parsingSettings.csvDelimiter"
            :items="useCSVParser.parsingSettings.csvDelimiterItems"
          />
          <p-btn icon="DownloadIcon" @click="handleDownloadExampleCSV">
            Beispiel CSV herunterladen
          </p-btn>
        </div>
      </div>
    </template>

    <template #default>
      <!-- Errors -->
      <div
        v-if="useCSVParser.parsingResult.parseErrors.length"
        class="bg-error-200 rounded-lg overflow-auto"
      >
        <el-scrollbar>
          <table class="w-full">
            <thead>
              <tr class="sticky top-0 bg-error-300">
                <th>Es sind Fehler aufgetreten</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(error, key) in useCSVParser.parsingResult.parseErrors" :key="key">
                <td>{{ String(error) }}</td>
              </tr>
            </tbody>
          </table>
        </el-scrollbar>
      </div>

      <!-- Data table -->
      <el-table
        v-else
        ref="table"
        :data="useCSVParser.parsingResult.parsedItems"
        border
        height="100%"
        size="small"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <!-- Index Column -->
        <el-table-column type="index" />

        <!-- All Columns -->
        <el-table-column
          v-for="field of PipeTypeFields"
          :key="field.name"
          :label="`${field.label} ${'unit' in field ? '(' + field.unit + ')' : ''}`"
          :prop="`raw.${field.name}`"
          width="auto"
        >
          <template #default="item">
            {{ typeHelper(item.row).raw[field.name] }}
            <template
              v-if="field.type === 'number' && typeHelper(item.row).raw[field.name]"
            ></template>
            <el-popover
              v-if="getErrorMessage(typeHelper(item.row), field.name)"
              :persistent="false"
              placement="left"
              trigger="hover"
              width="auto"
            >
              <template #default>
                <div class="max-h-60 overflow-auto">
                  {{ getErrorMessage(typeHelper(item.row), field.name) }}
                </div>
              </template>
              <template #reference>
                <el-button color="yellow">
                  <el-icon color="red">
                    <WarningIcon></WarningIcon>
                  </el-icon>
                </el-button>
              </template>
            </el-popover>
          </template>
        </el-table-column>

        <!-- Select -->
        <el-table-column :selectable="rowIsSelectable" fixed="right" type="selection" width="65" />
      </el-table>
    </template>

    <template #actions>
      <RequestsProgress :progress="useBulkRequests.progress" class="flex-grow" />
      <p-btn
        :disabled="!selectedItems.length"
        :loading="useBulkRequests.progress.pending || useCSVParser.parsingResult.pending"
        type="primary"
        @click="handleImport"
      >
        {{ selectedItems.length }} Rohrleitungstypen importieren
      </p-btn>
    </template>
  </import-dialog>
</template>

<style lang="css" scoped></style>
