<script lang="ts">
import ConductorTypeSelectField from '@/components/conductor-type/ConductorTypeSelectField.vue'
import TowerTypeSelectField from '@/components/tower-type/TowerTypeSelectField.vue'
import { defineComponent, PropType } from 'vue'
import { useTowerStore } from '@/stores/tower'
import { formatTowerName, ProjectId, Tower, TowerId } from '@/model'
import { useTowerTypeStore } from '@/stores/tower-type'
import { tower } from '@/config/fields'
import { MIXED_VALUES } from '@prionect/ui'
import TowerSVGMapped from '@/components/tower/TowerSVGMapped.vue'
import { ArrayType, Path, PathValue } from '@util/type-helpers'

import isEqual from 'lodash.isequal'
import { fieldsInOut } from '@/config/fields/tower'
import TowerAllocationSelectField from '@/components/tower/TowerAllocationSelectField.vue'

/**
 * If value of specified field of items is homogeneous returns the value
 * Otherwise return MIXED_VALUES
 * @param items
 * @param path
 */
export function getItemsValue<T extends object, P extends Path<T>>(
  items: T[],
  path: P
): PathValue<T, P> | typeof MIXED_VALUES {
  const firstItem = items[0]
  const firstItemValue = getByPath(firstItem, path)
  const allEqual = items.every((item) => {
    const value = getByPath(item, path)
    return isEqual(value, firstItemValue)
  })
  return allEqual ? firstItemValue : MIXED_VALUES
}

function getByPath<T extends object, P extends Path<T>>(obj: T, path: P): PathValue<T, P> {
  const keys = typeof path === 'string' ? path.split('.') : [path]
  let result: any = obj

  while (keys.length) {
    const key = keys.shift() as ArrayType<typeof keys>
    if (result === null || result === undefined) {
      return result
    }
    result = result[key]
  }
  return result
}

export default defineComponent({
  name: 'TowerForm',
  components: {
    TowerAllocationSelectField,
    ConductorTypeSelectField,
    TowerTypeSelectField,
    TowerSVGMapped
  },

  props: {
    items: {
      type: Array as PropType<Tower[]>,
      default: () => []
    }
  },

  data: () => ({
    fieldConfig: tower,
    fieldConfigInOut: fieldsInOut,
    changingAllocation: false,
    earthwiresInCount: 0,
    earthwiresOutCount: 0
  }),

  setup() {
    return {
      towerStore: useTowerStore(),
      towerTypeStore: useTowerTypeStore()
    }
  },

  async mounted() {
    await this.towerStore.ensureLoaded(this.projectId)
    await this.towerTypeStore.ensureLoadedByProject(this.projectId)

    if (this.create) {
      // Defer autofocus after animation hast finished
      setTimeout(() => {
        const nameInput: HTMLInputElement = this.$el.querySelector('[data-field="name"] input')
        nameInput?.focus()
      }, 150)
    }
  },

  computed: {
    create(): boolean {
      return !this.id
    },

    id(): TowerId {
      return this.$route.params.id as string
    },

    /**
     * Is this form shown inside the map view?
     */
    mapMode(): boolean {
      return (this.$route.name as string)?.includes('map') || false
    },

    projectId(): ProjectId {
      return this.$route.params.projectId as string
    },

    selectedItems(): Tower[] {
      return this.towerStore.selection
        .map((id) => this.towerStore.findById(id))
        .filter((item) => item != undefined) as Tower[]
    },

    title(): string {
      if (this.create) {
        return 'Neuer Mast'
      }

      const selectionCount = this.selectedItems.length
      if (selectionCount > 1) {
        return `${selectionCount} Masten bearbeiten`
      } else {
        return 'Mast bearbeiten'
      }
    }
  },

  watch: {
    items: {
      immediate: true,
      handler() {
        const out = getItemsValue(this.items, 'out')
        this.changingAllocation = !(out === undefined || out === null)
      }
    }
  },

  methods: {
    formatTowerName(id: TowerId | undefined): string {
      if (!id) {
        return ''
      }
      const tower = this.towerStore.findById(id)
      return tower ? formatTowerName(tower) : ''
    },
    asTower(value: any) {
      return value as Tower | Partial<Tower>
    },
    /**
     * Use "$nextTick" hack to update after dismount of vee-validate components
     */
    handleOutValue(value: boolean, setFieldValue: (key: string, value: any) => void) {
      if (value) {
        return
      }
      this.$nextTick(() => setFieldValue('out', null))
    }
  }
})
</script>

<template>
  <p-multi-form
    v-if="items"
    v-bind="$attrs"
    :items="items"
    v-slot="{ values, setFieldValue }"
    cancelable
    resettable
  >
    <div
      v-if="mapMode && values.x && values.y && items.length === 1"
      class="mb-4 text-gray-400 text-sm absolute -mt-8 z-20"
    >
      Koordinaten: X {{ $n(values.x) }} / Y {{ $n(values.y) }}
    </div>
    <div class="flex space-x-6">
      <p-field
        v-bind="fieldConfig.name"
        class="flex-1"
        dense
        :placeholder="
          (asTower(values).position &&
            selectedItems.length === 1 &&
            formatTowerName(asTower(values).id)) ||
          undefined
        "
      />
      <p-field
        v-if="!create"
        v-bind="fieldConfig.position"
        class="w-32"
        :disabled="selectedItems.length > 1"
        dense
      />
    </div>
    <div class="grid grid-cols-2 gap-x-6">
      <template v-if="!mapMode">
        <p-field v-bind="fieldConfig.x" />
        <p-field v-bind="fieldConfig.y" />
      </template>

      <p-field v-bind="fieldConfig.earthResistivity" dense />
    </div>

    <p-form-section title="Leiterzuordnung">
      <div class="mt-4 mb-2">
        <p-field
          type="switch"
          name="changingAllocation"
          standalone
          v-model="changingAllocation"
          @change="handleOutValue($event, setFieldValue)"
          label="Belegung wechselt (hybrider Mast)"
        />
      </div>

      <div class="grid gap-x-6 gap-y-3" :class="changingAllocation ? 'grid-cols-2' : 'grid-cols-1'">
        <template v-if="changingAllocation">
          <div class="font-semibold py-1 border-b mb-3 text-sm col-span-1">eingehend</div>
          <div class="font-semibold py-1 border-b mb-3 text-sm col-span-1">ausgehend</div>
        </template>
        <!-- Tower SVG (IN/OUT) -->
        <TowerSVGMapped :tower="asTower(values)" />

        <!-- IN-->
        <div>
          <!-- offset -->
          <p-field v-bind="fieldConfigInOut.in.offset" dense />

          <!-- type -->
          <TowerTypeSelectField
            v-bind="fieldConfigInOut.in.type"
            v-model:earthwire-count="earthwiresInCount"
          />

          <!-- conductor allocation -->
          <TowerAllocationSelectField v-bind="fieldConfigInOut.in.allocation" />

          <!-- earthwire allocation -->
          <ConductorTypeSelectField
            v-for="n in earthwiresInCount"
            :key="n"
            :field-config="fieldConfigInOut.in.earthwires"
            :index="n - 1"
            :conductors="asTower(values).in?.earthwires"
            dense
          />
        </div>

        <!-- OUT -->
        <div v-if="changingAllocation">
          <!-- offset  -->
          <p-field v-bind="fieldConfigInOut.out.offset" dense />

          <!-- type -->
          <TowerTypeSelectField
            v-bind="fieldConfigInOut.out.type"
            v-model:earthwire-count="earthwiresOutCount"
          />

          <!-- conductor allocation -->
          <TowerAllocationSelectField v-bind="fieldConfigInOut.out.allocation" />

          <!-- earthwire allocation -->
          <ConductorTypeSelectField
            v-for="n in earthwiresOutCount"
            :key="n"
            :field-config="fieldConfigInOut.out.earthwires"
            :index="n - 1"
            :conductors="asTower(values).out?.earthwires"
            dense
          />
        </div>
      </div>
    </p-form-section>
  </p-multi-form>
</template>
