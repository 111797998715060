import { useHttpClient } from '@/api/useHttpClient'
import { computed, nextTick, ref, watch } from 'vue'
import {
  AccountInfo,
  BrowserAuthError,
  Configuration,
  PublicClientApplication
} from '@azure/msal-browser'
import type { AuthProviderComposable } from '@prionect/ui'
import * as Sentry from '@sentry/vue'
import { getEnv } from '@/util'
import { AppAbility, defineRulesFor } from '@/permissions/ability'
import { User } from '@/model'
import { UserApi, useWebSocket } from '@/api'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { AxiosError } from 'axios'

/**
 * Configuration for Microsoft Authentication Library
 * see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md
 */
const msalConfig: Configuration = {
  auth: {
    clientId: getEnv('VITE_MSAL_CLIENT_ID'),
    redirectUri: '/',
    postLogoutRedirectUri: '/login'
  },
  cache: {
    cacheLocation: 'localStorage'
  }
}

const msal = new PublicClientApplication(msalConfig)
const currentUser = ref<Partial<User> | null>(null)
const { authToken } = useHttpClient()
export const pendingAuthentication = ref(false)

const doLogin = async (account: AccountInfo, accessToken: string) => {
  try {
    // Login WS
    await useWebSocket().connect({ token: accessToken })

    // Login HTTP
    authToken.value = accessToken
    const { data: user } = await UserApi.getMe()
    currentUser.value = user

    // Update user access
    AppAbility.update(defineRulesFor(currentUser.value as User))

    // Token refresh
    triggerTokenRefresh(account, accessToken)
  } catch (e) {
    throw 'Login fehlgeschlagen - ' + (e as AxiosError).message
  }
}

async function fetchToken(account: AccountInfo) {
  return await msal.acquireTokenSilent({
    account: account,
    scopes: []
  })
}

/**
 * Authentication for Gridside Websocket using Microsoft OAuth
 */
export const useGridsideAzureAuthentication: AuthProviderComposable = () => ({
  currentUser,
  isAuthenticated: computed(() => !!currentUser.value && !!authToken.value),

  async initialize() {
    pendingAuthentication.value = true

    // Get local storage data
    const accounts = msal.getAllAccounts()
    const account = accounts[0]
    if (!account) {
      pendingAuthentication.value = false
      return
    }

    try {
      // Fetch fresh token data
      const response = await fetchToken(account)
      if (!response.account) {
        pendingAuthentication.value = false
        return
      }

      // Perform login
      await doLogin(response.account, response.idToken)
    } catch (e) {
      console.warn('Could not fetch MSAL refresh token.')
      currentUser.value = null
    } finally {
      pendingAuthentication.value = false
    }
  },

  async login() {
    try {
      const result = await msal.acquireTokenPopup({ scopes: [] })
      const account = result.account

      if (account) {
        await doLogin(account, result.idToken)
      }
    } catch (e) {
      currentUser.value = null
      if (e instanceof BrowserAuthError) {
        if (e.errorCode !== 'user_cancelled') {
          console.warn('authentication error', e)
        }
        return false
      } else if ((e as { type: string }).type === 'TransportError') {
        console.warn('TransportError', e)
        return false
      } else {
        throw e
      }
    }

    return true
  },

  async logout() {
    const user = currentUser.value
    if (!user) {
      return
    }
    try {
      const logoutRequest = {
        account: user.providerId ? msal.getAccountByHomeId(user.providerId) : null
      }
      await msal.logoutPopup(logoutRequest)
      useWebSocket().disconnect()
    } catch (e) {
      console.warn(e)
    } finally {
      authToken.value = null
      currentUser.value = null
    }
  }
})

/**
 * Configure user for Sentry logging
 */
watch(currentUser, () => {
  if (currentUser.value) {
    Sentry.setUser({
      id: currentUser.value.email,
      role: currentUser.value.role
    })
  } else {
    Sentry.setUser(null)
  }
})

/**
 * Sets up the refresh mechanism for the authentication token.
 */
let refreshTimout: ReturnType<typeof setTimeout> | null = null
function triggerTokenRefresh(account: AccountInfo, token: string) {
  // clear previous refresh caused by new login
  if (refreshTimout) {
    clearTimeout(refreshTimout)
  }
  const jwt = jwtDecode<JwtPayload>(token)
  const now = Date.now()
  if (typeof jwt.exp !== 'number') {
    return
  }
  const fiveMinutes = 1000 * 60 * 5
  const fiveMinutesBeforeExpire = jwt.exp * 1000 - now - fiveMinutes
  refreshTimout = setTimeout(async () => {
    const { account: accountInfo, idToken } = await fetchToken(account)
    authToken.value = idToken
    if (accountInfo) {
      triggerTokenRefresh(accountInfo, idToken)
    }
  }, fiveMinutesBeforeExpire)
}
