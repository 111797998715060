import { AxiosError } from 'axios'
import { GeoJSON } from 'geojson'
import { defineStore } from 'pinia'
import { ProjectId } from '@/model'
import { CalculationApi } from '@/api'

export const useCorridorStore = defineStore('corridor', {
  state: () => ({
    corridorGeoJSON: undefined as undefined | GeoJSON,
    projectId: undefined as undefined | ProjectId,
    loaded: false,
    loading: false
  }),

  actions: {
    clear() {
      this.$reset()
    },

    async load(projectId: ProjectId) {
      if (projectId !== this.projectId) {
        // project changed
        this.loaded = false
      }

      this.loading = true
      try {
        this.corridorGeoJSON = await CalculationApi.getCorridor(projectId)
        this.loaded = true
      } catch (e) {
        if (e instanceof AxiosError && e.response?.status === 409) {
          // corridor can't be loaded because container is not ready yet
          // (retry is handled by the CalculationStore when an statusUpdated event is sent)
          console.log(
            `Corridor for project ${projectId} could not be loaded, because the worker is not ready yet.`
          )
        } else {
          throw e
        }
      } finally {
        this.loading = false
        this.projectId = projectId
      }
    }
  }
})
