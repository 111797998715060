<script setup lang="ts">
import { pendingAuthentication } from '@/composables/useGridsideAzureAuthentication'
import { hideLoadingScreen } from '@/util/loading-screen'
import { useAuthentication } from '@prionect/ui'
import { computed, watch } from 'vue'
import { useRouter } from 'vue-router'

import AppLayout from '@/layouts/AppLayout.vue'
import LoginLayout from '@/layouts/LoginLayout.vue'

import { useProjectStore } from '@/stores/project'
import { useUserStore } from '@/stores/user'
import { ElConfigProvider } from 'element-plus'
import deDe from 'element-plus/lib/locale/lang/de'
import { useEventListener } from '@vueuse/core'

const projectStore = useProjectStore()

const router = useRouter()
const route = router.currentRoute

const { isAuthenticated } = useAuthentication()
watch(isAuthenticated, async (authenticated) => {
  if (!authenticated) {
    projectStore.reset()
    router.push({ name: 'auth-login' })
    if (!pendingAuthentication.value) {
      hideLoadingScreen()
    }
  } else {
    if (route.value.name === 'auth-login') {
      const previousPath = route.value.query?.path as string
      await router.push(previousPath || { name: 'home' })
    }
    // Need to know all users names to display them properly
    await useUserStore().loadDisplayNames()
    hideLoadingScreen()
  }
})

watch(
  pendingAuthentication,
  () => {
    if (!pendingAuthentication.value && !isAuthenticated.value) {
      hideLoadingScreen()
    }
  },
  { immediate: true }
)

function setTitleByRoute() {
  const defaultTitle = 'GRIDSIDE HSB GUI'
  const route = router.currentRoute.value

  let routeTitle = route.matched
    .map((item: any) => item.meta?.title)
    .reverse()
    .find((title: string) => !!title)

  if (route.params.projectId) {
    const project = projectStore.findById(route.params.projectId as string)
    if (project?.name) {
      routeTitle = (routeTitle ? `${routeTitle} - ` : '') + project.name
    }
  }

  document.title = routeTitle ? `${routeTitle} | ${defaultTitle}` : defaultTitle
}

watch(router.currentRoute, setTitleByRoute)
// set to default title on init
setTitleByRoute()

const locale = deDe

// Set/remove helper class on fullscreen
useEventListener(document, 'fullscreenchange', () => {
  if (document.fullscreenElement) {
    document.documentElement.classList.add('fullscreen')
  } else {
    document.documentElement.classList.remove('fullscreen')
  }
})
const currentLayout = computed(() => {
  if (isAuthenticated && route.value.name !== 'auth-login') {
    return AppLayout
  }
  return LoginLayout
})

projectStore.init()
</script>

<template>
  <el-config-provider :locale="locale">
    <!-- Using <component> prevents re-rendering of children in <router-view> -->
    <component :is="currentLayout">
      <router-view />
    </component>
  </el-config-provider>
</template>
