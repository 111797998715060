<script lang="ts">
import { defineComponent, ref } from 'vue'
import { ZodError } from 'zod'
import FileInputResettable from '@/components/common/FileInputResettable.vue'
import useFileReader from '@/composables/useFileReader'
import RequestsProgress from '@/components/common/RequestsProgress.vue'
import {
  HSBLibModels,
  HSBLibModelsSchema,
  toHSBGuiModels
} from '@/config/schemas/hsb-lib/data-serialized'
import { HSBLibGlobals, HSBLibGlobalsSchema } from '@/config/schemas/hsb-lib/globals'
import { useProject } from '@/composables/useProject'
import ProjectImportErrorTabs from '@/components/project/ProjectImportErrorTabs.vue'
import { fromHSBLibGlobals, HSBLibParameter, Project } from '@/model'
import { project } from '@/config/fields'
import ProjectHSBLibParameterFieldRow from '@/components/project/ProjectHSBLibParameterFieldRow.vue'
import { useProgress } from '@/composables/useProgress'
import { useProjectStore } from '@/stores/project'
import { useConductorTypeStore } from '@/stores/conductor-type'
import { useSystemStore } from '@/stores/system'
import { useConductorAllocationStore } from '@/stores/conductor-allocation'
import { useTowerTypeStore } from '@/stores/tower-type'
import { useTowerStore } from '@/stores/tower'
import { HSBGuiItemsEnum } from '@/config/schemas/hsb-lib/util'
import { useSpanStore } from '@/stores/span'
import { useOperationalModeStore } from '@/stores/operational-mode'
import { TextFieldConfig } from '@prionect/ui'
import { ProjectionTypesEnum } from '@/config/projections'

type ValidatedHSBData = {
  crs: ProjectionTypesEnum | string
  hsbLibParameter: HSBLibParameter
  models: ReturnType<typeof toHSBGuiModels>
}

export default defineComponent({
  name: 'ProjectImportSourcesDialog',
  components: {
    ProjectHSBLibParameterFieldRow,
    ProjectImportErrorTabs,
    RequestsProgress,
    FileInputResettable
  },
  props: {
    /**
     * Dialog visibility
     */
    modelValue: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    const progress = useProgress()
    const project = useProject()
    const stores = {
      project: useProjectStore(),
      operationalMode: useOperationalModeStore(),
      [HSBGuiItemsEnum.conductorTypes]: useConductorTypeStore(),
      [HSBGuiItemsEnum.systems]: useSystemStore(),
      [HSBGuiItemsEnum.allocations]: useConductorAllocationStore(),
      [HSBGuiItemsEnum.towerTypes]: useTowerTypeStore(),
      [HSBGuiItemsEnum.towers]: useTowerStore(),
      [HSBGuiItemsEnum.spans]: useSpanStore()
    }
    const progresses = {
      [HSBGuiItemsEnum.systems]: useProgress(),
      [HSBGuiItemsEnum.allocations]: useProgress(),
      [HSBGuiItemsEnum.towers]: useProgress(),
      [HSBGuiItemsEnum.towerTypes]: useProgress(),
      [HSBGuiItemsEnum.spans]: useProgress(),
      [HSBGuiItemsEnum.conductorTypes]: useProgress(),
      hsbLibParameters: useProgress(),
      operationalMode: useProgress()
    }

    const dataParsed = ref<null | { globals: HSBLibGlobals; data: HSBLibModels }>(null)
    const dataValidated = ref<null | ValidatedHSBData>(null)
    const errors = ref<(string | Error | ZodError)[]>([])

    const systemNameSeparator = ref('_')
    /**
     * File reader bound function to read project import from file
     */
    const handleFileData = (data: string | ArrayBuffer | null) => {
      reset()
      try {
        const fileJsonContent: object | any = JSON.parse(data as string)

        // Prevent iterating on null
        if (typeof fileJsonContent !== 'object') {
          errors.value.push('Could not parse JSON: Result is not an object')
          return
        }
        const { globals, ...rest } = fileJsonContent
        if (!globals || !rest) {
          errors.value.push('Daten unvollständig! "globals" oder andere Daten fehlen!')
          return
        }

        // parse data
        const hsbGlobals = HSBLibGlobalsSchema.parse(globals)
        const hsbObjects = HSBLibModelsSchema.parse(rest)
        dataParsed.value = { globals: hsbGlobals, data: hsbObjects }

        const hsbDataValidated = toHSBGuiModels(
          hsbObjects,
          systemNameSeparator.value,
          project.projectId.value
        )
        dataValidated.value = {
          crs: hsbGlobals.CRS_system,
          hsbLibParameter: fromHSBLibGlobals(hsbGlobals),
          models: hsbDataValidated
        }
      } catch (e) {
        errors.value.push(e as Error)
      }
    }

    /**
     * Reset errors and parse data
     */
    function reset() {
      Object.values(progresses).forEach((item) => item.reset())
      dataParsed.value = null
      dataValidated.value = null
      errors.value = []
      progress.reset()
    }

    return {
      errors,
      reset,
      dataParsed,
      dataValidated,
      useFileReader: useFileReader(handleFileData, errors),
      progress,
      progresses,
      systemNameSeparator,
      stores,
      project
    }
  },
  data() {
    return {
      fieldConfig: project,
      separatorConfig: {
        label: '"Current"-Trennzeichen für die Erstellung der Systeme:',
        hint: 'Systeme werden aus den Namen der Current-Objekte in der Overheadline-Datei abgeleitet. So wird aus dem Current "1_0" der Strom auf dem ersten Leiter des Systems "1".',
        type: 'text',
        required: true,
        name: 'separator'
      } as TextFieldConfig
    }
  },
  emits: ['update:model-value'],
  computed: {
    hsbLibSettingFields() {
      return [
        {
          initialValue: this.dataValidated?.hsbLibParameter.corridor_flatend,
          config: this.fieldConfig.corridor_flatend
        },
        {
          initialValue: this.dataValidated?.hsbLibParameter.corridor_flatstart,
          config: this.fieldConfig.corridor_flatstart
        },
        {
          initialValue: this.dataValidated?.hsbLibParameter.corridor_mode,
          config: this.fieldConfig.corridor_mode
        },
        {
          initialValue: this.dataValidated?.hsbLibParameter.logging_traceback,
          config: this.fieldConfig.logging_traceback
        },
        {
          initialValue: this.dataValidated?.hsbLibParameter.log_level,
          config: this.fieldConfig.log_level
        },
        {
          initialValue: this.dataValidated?.hsbLibParameter.FLAG_Inner_Impedance_Bessel_Function,
          config: this.fieldConfig.FLAG_Inner_Impedance_Bessel_Function
        },
        {
          initialValue: this.dataValidated?.hsbLibParameter.Flag_Debugging,
          config: this.fieldConfig.Flag_Debugging
        },
        {
          initialValue: this.dataValidated?.hsbLibParameter.PipelineSegmentation,
          config: this.fieldConfig.PipelineSegmentation
        },
        {
          initialValue: this.dataValidated?.hsbLibParameter.Rel_abstol,
          config: this.fieldConfig.Rel_abstol
        },
        {
          initialValue: this.dataValidated?.hsbLibParameter.Rel_angletol,
          config: this.fieldConfig.Rel_angletol
        },
        {
          initialValue: this.dataValidated?.hsbLibParameter.Rel_max_angle,
          config: this.fieldConfig.Rel_max_angle
        },
        {
          initialValue: this.dataValidated?.hsbLibParameter.Rel_min_segment_length,
          config: this.fieldConfig.Rel_min_segment_length
        },
        {
          initialValue: this.dataValidated?.hsbLibParameter.Rel_simplifyMedium,
          config: this.fieldConfig.Rel_simplifyMedium
        },
        {
          initialValue: this.dataValidated?.hsbLibParameter.Trim_till_decimal_points,
          config: this.fieldConfig.Trim_till_decimal_points
        },
        {
          initialValue: this.dataValidated?.hsbLibParameter.UTM_Reimport_Tolerance,
          config: this.fieldConfig.UTM_Reimport_Tolerance
        },
        {
          initialValue: this.dataValidated?.hsbLibParameter.UTM_trim_mode,
          config: this.fieldConfig.UTM_trim_mode
        }
      ]
    }
  },
  methods: {
    doClose() {
      this.reset()
      this.$emit('update:model-value', false)
    },

    onFileChange(file: File) {
      this.reset()
      if (file) {
        this.useFileReader.onFileChange(file)
      }
    },

    /**
     * Send parsed data to backend
     */
    async handleImport() {
      this.errors = []
      if (!this.dataValidated || !this.project.project.value) {
        return
      }
      await this.doRequests(this.dataValidated, this.project.project.value)
      if (!this.progress.errors.length || !this.errors.length) {
        return
        // return this.doClose()
      }
      this.errors.push(...this.progress.errors)
    },

    async doRequests(data: ValidatedHSBData, project: Project) {
      this.progress.reset()
      this.progress.pending = true

      const jobs: Promise<any>[] = []
      const trackProgress = async (job: Promise<any>, scope: keyof typeof this.progresses) => {
        jobs.push(job)
        try {
          return await job
        } catch (e) {
          this.progress.errors.push(e as Error)
          this.progresses[scope].errors.push(e as Error)
        } finally {
          this.progress.done++
          this.progresses[scope].done++
        }
      }
      const conductorTypes = Object.values(data.models.containers[HSBGuiItemsEnum.conductorTypes])
      const systems = Object.values(data.models.containers[HSBGuiItemsEnum.systems])
      const allocations = Object.values(data.models.containers[HSBGuiItemsEnum.allocations])
      const towerTypes = Object.values(data.models.containers[HSBGuiItemsEnum.towerTypes])
      const towers = Object.values(data.models.containers[HSBGuiItemsEnum.towers])
      const spans = Object.values(data.models.containers[HSBGuiItemsEnum.spans])

      this.progresses.hsbLibParameters.of = 1
      this.progresses[HSBGuiItemsEnum.conductorTypes].of = conductorTypes.length
      this.progresses[HSBGuiItemsEnum.systems].of = systems.length
      this.progresses[HSBGuiItemsEnum.allocations].of = allocations.length
      this.progresses[HSBGuiItemsEnum.towerTypes].of = towerTypes.length
      this.progresses[HSBGuiItemsEnum.towers].of = 1 // towers done in one request
      this.progresses[HSBGuiItemsEnum.spans].of = spans.length
      this.progresses.operationalMode.of = 1

      this.progress.of = Object.values(this.progresses).reduce(
        (previousValue, currentValue) => previousValue + currentValue.of,
        0
      )

      try {
        // HSBLibParameters

        trackProgress(
          this.stores.project.save({
            ...project,
            crs: data.crs,
            hsblibParameter: data.hsbLibParameter
          }),
          'hsbLibParameters'
        )

        // Conductor Types
        await Promise.all(
          conductorTypes.map(async (item) => {
            item.project = project.id
            return trackProgress(
              this.stores[HSBGuiItemsEnum.conductorTypes].save(item),
              HSBGuiItemsEnum.conductorTypes
            )
          })
        )

        // Systems
        await Promise.all(
          systems.map(async (item) => {
            return trackProgress(
              this.stores[HSBGuiItemsEnum.systems].save(item),
              HSBGuiItemsEnum.systems
            )
          })
        )

        // ConductorAllocations
        await Promise.all(
          allocations.map(async (item) => {
            return trackProgress(
              this.stores[HSBGuiItemsEnum.allocations].save(item),
              HSBGuiItemsEnum.allocations
            )
          })
        )

        // wrap dependant requests into one async function
        const depending = async () => {
          // TowerTypes
          await Promise.all(
            towerTypes.map(async (item) => {
              return trackProgress(
                this.stores[HSBGuiItemsEnum.towerTypes].save(item),
                HSBGuiItemsEnum.towerTypes
              )
            })
          )

          // Towers
          await trackProgress(
            this.stores[HSBGuiItemsEnum.towers].replaceAll(towers, project),
            HSBGuiItemsEnum.towers
          )

          // Spans - update span store
          await this.stores[HSBGuiItemsEnum.spans].load(project.id)
          for (const span of this.stores[HSBGuiItemsEnum.spans].items) {
            const toUpdate = spans.find(
              (el) => el.beginTower === span.beginTower && el.endTower === span.endTower
            )
            if (!toUpdate) {
              this.progress.done++
              this.progress.errors.push(
                Error(`Konnte Spannfeld ${span.id} nicht mit Werten aus dem Import updated`)
              )
              continue
            }
            trackProgress(
              this.stores[HSBGuiItemsEnum.spans].save(
                { ...span, ...toUpdate, id: span.id },
                project.id
              ),
              HSBGuiItemsEnum.spans
            )
          }
        }
        depending()

        // Operational Mode
        trackProgress(
          this.stores.operationalMode.save(data.models.operationalMode),
          'operationalMode'
        )

        await Promise.all(jobs)
      } catch (e) {
        console.error(e)
        this.errors.push(e as Error)
      } finally {
        this.progress.pending = false
      }
    }
  }
})
</script>

<template>
  <p-dialog
    class="!max-w-none"
    :show="modelValue"
    title="Quelle importieren (Overheadline-Format)"
    @close="doClose"
  >
    <div class="flex space-x-12 w-[80vw] max-w-[160ch]" style="height: calc(100vh - 18rem)">
      <div class="w-96">
        <FileInputResettable
          class="mb-16"
          accept=".json"
          @change="onFileChange"
          label="Datei im Overhead-Line-Format auswählen"
        />
        <p-field v-model="systemNameSeparator" v-bind="separatorConfig"></p-field>
      </div>
      <div class="flex-1 overflow-hidden">
        <div
          v-if="!dataParsed && errors.length === 0"
          class="bg-gray-100 flex items-center justify-center h-full"
        >
          <div class="text-gray-400">
            Wählen Sie zunächst links eine Datei aus, um hier eine Vorschau der zu importierenden
            Daten zu sehen. {{ errors }}
          </div>
        </div>
        <el-scrollbar>
          <div v-if="errors.length" class="h-full">
            <ProjectImportErrorTabs :errors="errors"></ProjectImportErrorTabs>
          </div>
          <template v-else-if="dataParsed && dataValidated">
            <h2 class="text-lg font-bold text-primary-500 mb-4">Inhalte der Import-Datei:</h2>
            <el-collapse>
              <!-- models -->
              <el-collapse-item
                v-for="(data, containerName) in dataValidated.models.containers"
                :key="containerName"
                :name="containerName"
              >
                <template #title>
                  <div class="flex-1">{{ `${containerName} (${Object.values(data).length})` }}</div>
                  <RequestsProgress
                    v-show="progresses[containerName].percent > 0"
                    :progress="progresses[containerName]"
                    class="w-48 mr-4"
                  />
                </template>
                <pre class="source">{{ data }}</pre>
              </el-collapse-item>

              <!-- operationalMode -->
              <el-collapse-item name="operationalMode">
                <template #title>
                  <div class="flex-1">operationalMode</div>
                  <RequestsProgress
                    v-show="progresses.operationalMode.percent > 0"
                    :progress="progresses.operationalMode"
                    class="w-48 mr-4"
                  />
                </template>
                <!-- prettier-ignore -->
                <pre class="source">{{ dataValidated.models.operationalMode }}</pre>
              </el-collapse-item>

              <!-- hsbLibParameters -->
              <el-collapse-item name="parameters">
                <template #title>
                  <div class="flex-1">Projektdaten und HSBLib-Parameter</div>
                  <RequestsProgress
                    v-show="progresses.hsbLibParameters.percent > 0"
                    :progress="progresses.hsbLibParameters"
                    class="w-48 mr-4"
                  />
                </template>

                <div class="table-simple table-simple--striped overflow-auto mb-3">
                  <table style="width: 100%; pointer-events: none">
                    <caption>Projektdaten</caption>
                    <tbody>
                      <tr>
                        <td>CRS System</td>
                        <td>
                          <pre>{{ dataValidated.crs }}</pre>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="table-simple table-simple--striped overflow-auto">
                  <table style="width: 100%; pointer-events: none">
                    <caption>HSBLib-Parameter</caption>
                    <thead>
                      <tr>
                        <th>Parameter</th>
                        <th class="w-20">HSBLib-Standard benutzen</th>
                        <th style="max-width: 150px">Benutzerdefinierter Wert</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ProjectHSBLibParameterFieldRow
                        v-for="(field, index) in hsbLibSettingFields"
                        :key="index"
                        :field-config="field.config"
                        :initial-value="field.initialValue"
                      />
                    </tbody>
                  </table>
                </div>
              </el-collapse-item>
            </el-collapse>
          </template>
        </el-scrollbar>
      </div>
    </div>
    <template #footer>
      <RequestsProgress v-show="progress.percent > 0" :progress="progress" class="flex-1" />
      <p-btn
        :disabled="!dataParsed || disabled || errors.length > 0"
        :loading="progress.pending"
        type="primary"
        @click="handleImport"
      >
        Quellen importieren
      </p-btn>
      <p-btn
        v-if="!progress.pending && progress.percent === 100 && errors.length === 0"
        type="primary"
        @click="doClose"
      >
        Fertig
      </p-btn>
    </template>
  </p-dialog>
</template>

<style scoped lang="css">
.source {
  @apply w-full overflow-x-auto bg-gray-50 leading-tight text-gray-500 p-2 pl-16;
}
</style>
